import React, { useState, useEffect } from 'react';
import './MaterialList.css';

const MaterialList = ({ 
  displayMaterials = [], 
  allMaterials = [],
  isAdmin = false, 
  onEdit, 
  onDelete, 
  onClone,
  selectedMaterials = [],
  onToggleSelect,
  categories = [],
  onShowResults,
  onResetSelection
}) => {
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [localSelectedMaterials, setLocalSelectedMaterials] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [hasChangedSelection, setHasChangedSelection] = useState(false);

  const handleImageClick = (e, imageUrl) => {
    e.stopPropagation();
    setEnlargedImage(imageUrl);
  };

  // 선택된 자재 업데이트
  useEffect(() => {
    setLocalSelectedMaterials(selectedMaterials);
  }, [selectedMaterials]);

  // 초기 로드 시 한 번만 실행
  useEffect(() => {
    if (selectedMaterials.length > 0) {
      setIsInitialLoad(false);
    }
  }, []);

  // 선택 상태가 변경될 때마다 모든 카테고리 선택 여부 확인
  useEffect(() => {
    if (localSelectedMaterials.length === 0 || !categories || categories.length === 0 || isInitialLoad) {
      return;
    }

    const selectedCategories = new Set();
    const selectedMaterialsInfo = [];

    localSelectedMaterials.forEach(materialId => {
      if (!materialId) return;
      const material = allMaterials.find(m => m.id === materialId);
      if (material?.categoryId) {
        selectedCategories.add(material.categoryId);
        selectedMaterialsInfo.push({
          id: material.id,
          categoryId: material.categoryId,
          isNoSelection: Boolean(material.isNoSelection)
        });
      }
    });

    if (selectedCategories.size === categories.length && selectedCategories.size > 0) {
      const hasRealMaterial = selectedMaterialsInfo.some(material => !material.isNoSelection);
      if (hasRealMaterial && hasChangedSelection) {
        setShowCompletionModal(true);
      }
    }
  }, [localSelectedMaterials, categories, allMaterials, isInitialLoad, hasChangedSelection]);

  // 자재 선택 처리
  const handleMaterialSelect = (materialId) => {
    const material = allMaterials.find(m => m.id === materialId);
    if (!material || !material.categoryId) return;
    
    setIsInitialLoad(false);
    setHasChangedSelection(true);
    
    // 새로운 선택 목록 생성
    const otherCategoriesSelection = localSelectedMaterials.filter(id => {
      if (!id) return false;
      const mat = allMaterials.find(m => m.id === id);
      return mat && mat.categoryId !== material.categoryId;
    });

    const newSelection = [...otherCategoriesSelection, materialId];
    
    setLocalSelectedMaterials(newSelection);
    onToggleSelect && onToggleSelect(materialId);
  };

  // 다시 선택하기 버튼 클릭 시
  const handleReset = () => {
    setShowCompletionModal(false);
    setHasChangedSelection(false);
  };

  // "선택안함" 자재의 기본 이미지 URL
  const noSelectionImageUrl = encodeURI(`data:image/svg+xml,\
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>\
  `).replace(/#/g, '%23');

  // "선택안함" 옵션을 항상 가장 처음에 위치하도록 정렬
  const sortedMaterials = [...displayMaterials].sort((a, b) => {
    if (a.isNoSelection) return -1;
    if (b.isNoSelection) return 1;
    return 0;
  });

  return (
    <div className="bathroom-material-list">
      {sortedMaterials.map(material => {
        const isSelected = localSelectedMaterials.includes(material.id);
        const isNoSelection = material.isNoSelection;
        
        return (
          <div 
            key={material.id} 
            className={`bathroom-material-list__item 
              ${isSelected ? 'bathroom-material-list__item--selected' : ''} 
              ${isNoSelection ? 'bathroom-material-list__no-selection' : ''}`}
            onClick={() => !isAdmin && handleMaterialSelect(material.id)}
          >
            <div className="bathroom-material-list__image-container">
              {!isAdmin && isSelected && (
                <div className="bathroom-material-list__selected-mark">✓</div>
              )}
              <img 
                src={isNoSelection ? noSelectionImageUrl : material.image}
                alt={material.name}
                className="bathroom-material-list__image"
                onClick={(e) => !isNoSelection && handleImageClick(e, material.image)}
              />
            </div>
            <div className="bathroom-material-list__info">
              <h4 className="bathroom-material-list__name">{material.name}</h4>
              {!isNoSelection && (
                <>
                  {isAdmin && <p className="bathroom-material-list__model">모델명: {material.model}</p>}
                  <div className="bathroom-material-list__prices">
                    {isAdmin ? (
                      <>
                        <p className="bathroom-material-list__vendor-price">
                          업체가: {material.vendorPrice.toLocaleString()}원
                        </p>
                        <p className="bathroom-material-list__consumer-price">
                          소비자가: {material.consumerPrice.toLocaleString()}원
                        </p>
                      </>
                    ) : (
                      <p className="bathroom-material-list__consumer-price">
                        {material.consumerPrice.toLocaleString()}원
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
            {isAdmin && !isNoSelection && (
              <div className="bathroom-material-list__actions" onClick={e => e.stopPropagation()}>
                <button 
                  className="bathroom-material-list__clone-btn"
                  onClick={() => onClone(material)}
                >
                  복제
                </button>
                <button 
                  className="bathroom-material-list__edit-btn"
                  onClick={() => onEdit(material)}
                >
                  수정
                </button>
                <button 
                  className="bathroom-material-list__delete-btn"
                  onClick={() => onDelete(material.id)}
                >
                  삭제
                </button>
              </div>
            )}
          </div>
        );
      })}

      {enlargedImage && (
        <div 
          className="bathroom-material-list__image-modal"
          onClick={() => setEnlargedImage(null)}
        >
          <div className="bathroom-material-list__image-modal-content">
            <img 
              src={enlargedImage} 
              alt="확대된 이미지"
            />
          </div>
        </div>
      )}

      {showCompletionModal && (
        <div className="bathroom-material-list__completion-modal">
          <div className="bathroom-material-list__completion-content">
            <h3>모든 자재를<br />선택하셨습니다</h3>
            <div className="bathroom-material-list__completion-buttons">
              <button 
                className="bathroom-material-list__result-btn"
                onClick={() => {
                  setShowCompletionModal(false);
                  onShowResults && onShowResults();
                }}
              >
                결과보기
              </button>
              <button 
                className="bathroom-material-list__reset-btn"
                onClick={handleReset}
              >
                다시 선택하기
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MaterialList; 