import React, { useState, useEffect, useRef } from 'react';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import './SquarePipe.css';

// 공유 아이콘 컴포넌트
const ShareIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ marginRight: '8px' }}>
    <circle cx="18" cy="5" r="3"></circle>
    <circle cx="6" cy="12" r="3"></circle>
    <circle cx="18" cy="19" r="3"></circle>
    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
  </svg>
);

const SquarePipe = () => {
  // 상수 정의
  const margin = 50;

  const [dimensions, setDimensions] = useState({
    width: 3165,
    height: 450,
    thickness: 30,
    verticalSupports: 3,
    holeDiameter: 10
  });

  const [scale, setScale] = useState(0.2);
  const [isSharing, setIsSharing] = useState(false);
  const [shareSupported, setShareSupported] = useState(false);
  
  const shareContainerRef = useRef(null);
  const drawingContainerRef = useRef(null);

  // 타공 위치 관련 상수 정의
  const holePosY = margin; // 상단 선과 겹치도록 수정
  const baseHoleRadius = 4; // 기본 타공 표시 크기

  // 화면 크기에 따라 스케일 조정
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) { // 모바일
        setScale(0.1);
      } else if (width < 1024) { // 태블릿
        setScale(0.15);
      } else { // 데스크톱
        setScale(0.2);
      }
    };

    handleResize(); // 초기 실행
    window.addEventListener('resize', handleResize);
    
    // Web Share API 지원 여부 확인
    setShareSupported(!!navigator.share);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 도면 컨테이너 크기 설정
  useEffect(() => {
    if (drawingContainerRef.current) {
      const container = drawingContainerRef.current;
      // 최소 높이 설정
      const minHeight = Math.max(300, dimensions.height * scale + 100);
      container.style.minHeight = `${minHeight}px`;
    }
  }, [dimensions, scale]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // 세로 지지대 개수는 1~10 사이로 제한
    if (name === 'verticalSupports') {
      const numValue = value.replace(/[^0-9]/g, '');
      if (numValue === '' || (Number(numValue) >= 0 && Number(numValue) <= 10)) {
        setDimensions(prev => ({
          ...prev,
          [name]: numValue
        }));
      }
      return;
    }
    setDimensions(prev => ({
      ...prev,
      [name]: Number(value)
    }));
  };

  const svgWidth = dimensions.width * scale + margin * 2;
  const svgHeight = dimensions.height * scale + margin * 2;

  // 세로 지지대 위치 계산
  const supportPositions = [];
  const numSupports = Number(dimensions.verticalSupports) || 0;
  if (numSupports > 0) {
    const spacing = dimensions.width / (numSupports + 1);
    for (let i = 1; i <= numSupports; i++) {
      supportPositions.push(spacing * i);
    }
  }

  // 모든 세로 지지대 위치 (양 끝 포함)
  const allSupportPositions = [0, ...supportPositions, dimensions.width];

  // 이미지 생성 및 공유 함수
  const handleShare = async () => {
    try {
      setIsSharing(true);
      
      // 공유용 컨테이너를 화면에 임시로 표시
      if (shareContainerRef.current) {
        // 기존 위치에서 제거
        if (shareContainerRef.current.parentNode) {
          shareContainerRef.current.parentNode.removeChild(shareContainerRef.current);
        }
        
        // 임시 컨테이너 생성
        const tempContainer = document.createElement('div');
        tempContainer.style.position = 'absolute';
        tempContainer.style.left = '0';
        tempContainer.style.top = '0';
        tempContainer.style.width = '1200px';
        tempContainer.style.height = 'auto';
        tempContainer.style.zIndex = '-9999';
        tempContainer.style.visibility = 'hidden';
        tempContainer.style.background = 'white';
        document.body.appendChild(tempContainer);
        
        // 공유 컨테이너 스타일 설정
        shareContainerRef.current.style.position = 'static';
        shareContainerRef.current.style.transform = 'none';
        shareContainerRef.current.style.width = '1200px';
        shareContainerRef.current.style.height = 'auto';
        shareContainerRef.current.style.visibility = 'visible';
        shareContainerRef.current.style.background = 'white';
        shareContainerRef.current.style.zIndex = 'auto';
        
        // 임시 컨테이너에 추가
        tempContainer.appendChild(shareContainerRef.current);
      }

      // 잠시 대기하여 렌더링이 완료되도록 함
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // 공유용 컨테이너의 이미지 생성
      const canvas = await html2canvas(shareContainerRef.current, {
        backgroundColor: 'white',
        scale: 2,
        logging: true,
        useCORS: true,
        allowTaint: true,
        foreignObjectRendering: false,
        imageTimeout: 0,
        onclone: (clonedDoc) => {
          const clonedContainer = clonedDoc.querySelector('.share-container-wrapper');
          if (clonedContainer) {
            clonedContainer.style.visibility = 'visible';
            clonedContainer.style.position = 'static';
            clonedContainer.style.transform = 'none';
            clonedContainer.style.width = '1200px';
            clonedContainer.style.height = 'auto';
            clonedContainer.style.overflow = 'visible';
            clonedContainer.style.zIndex = 'auto';
          }
        }
      });
      
      // 공유용 컨테이너를 다시 원래 위치로
      if (shareContainerRef.current) {
        const tempContainer = shareContainerRef.current.parentNode;
        if (tempContainer && tempContainer !== document.body) {
          tempContainer.removeChild(shareContainerRef.current);
          document.body.removeChild(tempContainer);
        }
        
        // 공유 컨테이너를 원래 위치로 되돌리기
        const shareContainerWrapper = document.querySelector('.share-container-wrapper');
        if (shareContainerWrapper) {
          shareContainerWrapper.appendChild(shareContainerRef.current);
          
          // 원래 스타일로 되돌리기
          shareContainerRef.current.style.visibility = 'hidden';
          shareContainerRef.current.style.position = 'static';
          shareContainerWrapper.style.position = 'fixed';
          shareContainerWrapper.style.left = '50%';
          shareContainerWrapper.style.top = '50%';
          shareContainerWrapper.style.transform = 'translate(-50%, -50%)';
          shareContainerWrapper.style.visibility = 'hidden';
          shareContainerWrapper.style.zIndex = '-9999';
        }
      }

      // 캔버스를 Blob으로 변환
      canvas.toBlob(async (blob) => {
        if (blob === null) {
          console.error('이미지 생성 실패: 빈 Blob');
          setIsSharing(false);
          return;
        }
        
        if (shareSupported) {
          try {
            const file = new File([blob], '각파이프_도면.png', { type: 'image/png' });
            await navigator.share({
              title: '각파이프 도면',
              text: `가로: ${dimensions.width}mm, 세로: ${dimensions.height}mm, 두께: ${dimensions.thickness}mm, 지지대: ${dimensions.verticalSupports}개, 타공 직경: ⌀${dimensions.holeDiameter}mm`,
              files: [file]
            });
          } catch (error) {
            console.error('공유 실패:', error);
            saveAs(blob, '각파이프_도면.png');
          }
        } else {
          saveAs(blob, '각파이프_도면.png');
        }
        setIsSharing(false);
      }, 'image/png', 1.0);
    } catch (error) {
      console.error('이미지 생성 실패:', error);
      setIsSharing(false);
      
      // 에러가 발생해도 공유 컨테이너를 원래 위치로 되돌리기
      if (shareContainerRef.current) {
        const tempContainer = shareContainerRef.current.parentNode;
        if (tempContainer && tempContainer !== document.body && 
            tempContainer.tagName.toLowerCase() !== 'div' &&
            !tempContainer.classList.contains('share-container-wrapper')) {
          tempContainer.removeChild(shareContainerRef.current);
          document.body.removeChild(tempContainer);
        }
        
        const shareContainerWrapper = document.querySelector('.share-container-wrapper');
        if (shareContainerWrapper) {
          shareContainerWrapper.appendChild(shareContainerRef.current);
          
          shareContainerRef.current.style.visibility = 'hidden';
          shareContainerRef.current.style.position = 'static';
          shareContainerWrapper.style.position = 'fixed';
          shareContainerWrapper.style.left = '50%';
          shareContainerWrapper.style.top = '50%';
          shareContainerWrapper.style.transform = 'translate(-50%, -50%)';
          shareContainerWrapper.style.visibility = 'hidden';
          shareContainerWrapper.style.zIndex = '-9999';
        }
      }
    }
  };

  // SVG 도면 렌더링
  const renderDrawing = () => {
    // 치수 텍스트 크기와 타공 원 크기 계산
    const textSize = Math.max(10, Math.floor(dimensions.width * scale * 0.015));
    const holeRadius = Math.max(baseHoleRadius, Math.floor(dimensions.width * scale * 0.003));
    // 화살표 크기 계산
    const arrowWidth = Math.max(6, Math.floor(dimensions.width * scale * 0.002));
    const arrowHeight = Math.max(4, Math.floor(arrowWidth * 0.7));

    return (
      <svg 
        width="100%" 
        height="100%" 
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        className="main-drawing"
        preserveAspectRatio="xMidYMid meet"
      >
        {/* 배경 사각형 추가 */}
        <rect
          x="0"
          y="0"
          width={svgWidth}
          height={svgHeight}
          fill="white"
          stroke="none"
        />
        
        {/* 외곽 프레임 */}
        <rect
          x={margin}
          y={margin}
          width={dimensions.width * scale}
          height={dimensions.height * scale}
          fill="none"
          stroke="black"
          strokeWidth="1"
        />

        {/* 세로 지지대 */}
        {supportPositions.map((pos, index) => (
          <line
            key={index}
            x1={margin + pos * scale}
            y1={margin}
            x2={margin + pos * scale}
            y2={margin + dimensions.height * scale}
            stroke="black"
            strokeWidth="1"
          />
        ))}

        {/* 타공 위치 표시 */}
        {allSupportPositions.map((pos, index) => {
          if (index < allSupportPositions.length - 1) {
            const nextPos = allSupportPositions[index + 1];
            const centerX = margin + (pos + (nextPos - pos) / 2) * scale;
            
            return (
              <g key={`hole-${index}`}>
                {/* 타공 위치 원 */}
                <circle
                  cx={centerX}
                  cy={holePosY}
                  r={holeRadius}
                  fill="none"
                  stroke="black"
                  strokeWidth="1"
                  strokeDasharray="2,2"
                />
                {/* 타공 크기 표시 */}
                <text
                  x={centerX}
                  y={holePosY - 10}
                  textAnchor="middle"
                  className="dimension-text"
                  style={{ fontSize: `${textSize}px` }}
                >
                  ⌀{dimensions.holeDiameter}
                </text>
              </g>
            );
          }
          return null;
        })}

        {/* 치수선 - 가로 */}
        <line
          x1={margin}
          y1={margin + dimensions.height * scale + 20}
          x2={margin + dimensions.width * scale}
          y2={margin + dimensions.height * scale + 20}
          stroke="black"
          strokeWidth="0.5"
          markerEnd="url(#arrowhead)"
          markerStart="url(#arrowhead)"
        />
        <text
          x={margin + (dimensions.width * scale) / 2}
          y={margin + dimensions.height * scale + 35}
          textAnchor="middle"
          className="dimension-text"
          style={{ fontSize: `${textSize}px` }}
        >
          {dimensions.width}mm
        </text>

        {/* 치수선 - 세로 */}
        <line
          x1={margin - 20}
          y1={margin}
          x2={margin - 20}
          y2={margin + dimensions.height * scale}
          stroke="black"
          strokeWidth="0.5"
          markerEnd="url(#arrowhead)"
          markerStart="url(#arrowhead)"
        />
        <text
          x={margin - 35}
          y={margin + (dimensions.height * scale) / 2}
          textAnchor="middle"
          transform={`rotate(-90 ${margin - 35} ${margin + (dimensions.height * scale) / 2})`}
          className="dimension-text"
          style={{ fontSize: `${textSize}px` }}
        >
          {dimensions.height}mm
        </text>

        {/* 화살표 마커 정의 */}
        <defs>
          <marker
            id="arrowhead"
            markerWidth={arrowWidth}
            markerHeight={arrowHeight}
            refX={arrowWidth/2}
            refY={arrowHeight/2}
            orient="auto"
          >
            <polygon points={`0 0, ${arrowWidth} ${arrowHeight/2}, 0 ${arrowHeight}`} fill="black" />
          </marker>
        </defs>
      </svg>
    );
  };

  // 단면도 렌더링
  const renderSectionView = () => {
    return (
      <svg 
        width="100%" 
        height="auto" 
        viewBox="0 0 140 160"
        className="section-drawing"
        preserveAspectRatio="xMidYMid meet"
      >
        <rect
          x="30"
          y="30"
          width="80"
          height="80"
          fill="none"
          stroke="black"
          strokeWidth="1"
        />
        <rect
          x="40"
          y="40"
          width="60"
          height="60"
          fill="none"
          stroke="black"
          strokeWidth="1"
        />
        {/* 치수 표시 */}
        <text
          x="70"
          y="140"
          textAnchor="middle"
          className="dimension-text"
        >
          {dimensions.thickness}mm
        </text>
      </svg>
    );
  };

  return (
    <div className="square-pipe">
      <h2>각파이프 도면</h2>

      {/* 입력 폼 */}
      <div className="input-form">
        <div className="input-group">
          <label htmlFor="width">가로 길이 (mm)</label>
          <input
            type="number"
            id="width"
            name="width"
            value={dimensions.width}
            onChange={handleInputChange}
            min="1"
          />
        </div>
        <div className="input-group">
          <label htmlFor="height">세로 길이 (mm)</label>
          <input
            type="number"
            id="height"
            name="height"
            value={dimensions.height}
            onChange={handleInputChange}
            min="1"
          />
        </div>
        <div className="input-group">
          <label htmlFor="thickness">각파이프 두께 (mm)</label>
          <input
            type="number"
            id="thickness"
            name="thickness"
            value={dimensions.thickness}
            onChange={handleInputChange}
            min="1"
          />
        </div>
        <div className="input-group">
          <label htmlFor="verticalSupports">세로 지지대 개수 (0~10개)</label>
          <input
            type="text"
            id="verticalSupports"
            name="verticalSupports"
            value={dimensions.verticalSupports}
            onChange={handleInputChange}
            pattern="[0-9]*"
            inputMode="numeric"
            style={{ appearance: 'textfield' }}
          />
        </div>
        <div className="input-group">
          <label htmlFor="holeDiameter">타공 직경 (mm)</label>
          <input
            type="number"
            id="holeDiameter"
            name="holeDiameter"
            value={dimensions.holeDiameter}
            onChange={handleInputChange}
            min="1"
            max="30"
          />
        </div>
      </div>
      
      {/* 공유 버튼 */}
      <div className="share-button-container">
        <button 
          className="share-button" 
          onClick={handleShare}
          disabled={isSharing}
        >
          {isSharing ? '생성 중...' : (
            <>
              <ShareIcon />
              도면 공유하기
            </>
          )}
        </button>
      </div>
      
      {/* 메인 도면 */}
      <div className="drawing-container" ref={drawingContainerRef}>
        {renderDrawing()}
      </div>

      {/* 단면도 */}
      <div className="section-view">
        <h3>단면도</h3>
        <div className="section-drawing-container">
          {renderSectionView()}
        </div>
      </div>

      {/* 정보 표시 */}
      <div className="info-panel">
        <h3>제작 정보</h3>
        <p>가로 길이: {dimensions.width}mm</p>
        <p>세로 길이: {dimensions.height}mm</p>
        <p>각파이프 두께: {dimensions.thickness}mm</p>
        <p>세로 지지대 개수: {dimensions.verticalSupports}개</p>
        <p>타공 직경: ⌀{dimensions.holeDiameter}mm</p>
      </div>
      
      {/* 공유용 컨테이너 (화면에는 보이지 않음) */}
      <div className="share-container-wrapper">
        <div ref={shareContainerRef} className="share-container">
          <div className="share-header">
            <h2>각파이프 도면</h2>
          </div>
          
          <div className="share-drawing">
            <svg 
              width="100%" 
              height="100%" 
              viewBox={`0 0 ${svgWidth} ${svgHeight}`}
              className="main-drawing"
              preserveAspectRatio="xMidYMid meet"
            >
              {/* 치수 텍스트 크기 계산 */}
              {(() => {
                const textSize = Math.max(10, Math.floor(dimensions.width * scale * 0.015));
                const holeRadius = Math.max(baseHoleRadius, Math.floor(dimensions.width * scale * 0.003));
                // 화살표 크기 계산
                const arrowWidth = Math.max(6, Math.floor(dimensions.width * scale * 0.002));
                const arrowHeight = Math.max(4, Math.floor(arrowWidth * 0.7));

                return (
                  <>
                    {/* 배경 사각형 추가 */}
                    <rect
                      x="0"
                      y="0"
                      width={svgWidth}
                      height={svgHeight}
                      fill="white"
                      stroke="none"
                    />
                    
                    {/* 외곽 프레임 */}
                    <rect
                      x={margin}
                      y={margin}
                      width={dimensions.width * scale}
                      height={dimensions.height * scale}
                      fill="none"
                      stroke="black"
                      strokeWidth="1"
                    />

                    {/* 세로 지지대 */}
                    {supportPositions.map((pos, index) => (
                      <line
                        key={index}
                        x1={margin + pos * scale}
                        y1={margin}
                        x2={margin + pos * scale}
                        y2={margin + dimensions.height * scale}
                        stroke="black"
                        strokeWidth="1"
                      />
                    ))}

                    {/* 타공 위치 표시 */}
                    {allSupportPositions.map((pos, index) => {
                      if (index < allSupportPositions.length - 1) {
                        const nextPos = allSupportPositions[index + 1];
                        const centerX = margin + (pos + (nextPos - pos) / 2) * scale;
                        
                        return (
                          <g key={`hole-${index}`}>
                            {/* 타공 위치 원 */}
                            <circle
                              cx={centerX}
                              cy={holePosY}
                              r={holeRadius}
                              fill="none"
                              stroke="black"
                              strokeWidth="1"
                              strokeDasharray="2,2"
                            />
                            {/* 타공 크기 표시 */}
                            <text
                              x={centerX}
                              y={holePosY - 10}
                              textAnchor="middle"
                              className="dimension-text"
                              style={{ fontSize: `${textSize}px` }}
                            >
                              ⌀{dimensions.holeDiameter}
                            </text>
                          </g>
                        );
                      }
                      return null;
                    })}

                    {/* 치수선 - 가로 */}
                    <line
                      x1={margin}
                      y1={margin + dimensions.height * scale + 20}
                      x2={margin + dimensions.width * scale}
                      y2={margin + dimensions.height * scale + 20}
                      stroke="black"
                      strokeWidth="0.5"
                      markerEnd="url(#arrowhead)"
                      markerStart="url(#arrowhead)"
                    />
                    <text
                      x={margin + (dimensions.width * scale) / 2}
                      y={margin + dimensions.height * scale + 35}
                      textAnchor="middle"
                      className="dimension-text"
                      style={{ fontSize: `${textSize}px` }}
                    >
                      {dimensions.width}mm
                    </text>

                    {/* 치수선 - 세로 */}
                    <line
                      x1={margin - 20}
                      y1={margin}
                      x2={margin - 20}
                      y2={margin + dimensions.height * scale}
                      stroke="black"
                      strokeWidth="0.5"
                      markerEnd="url(#arrowhead)"
                      markerStart="url(#arrowhead)"
                    />
                    <text
                      x={margin - 35}
                      y={margin + (dimensions.height * scale) / 2}
                      textAnchor="middle"
                      transform={`rotate(-90 ${margin - 35} ${margin + (dimensions.height * scale) / 2})`}
                      className="dimension-text"
                      style={{ fontSize: `${textSize}px` }}
                    >
                      {dimensions.height}mm
                    </text>

                    {/* 화살표 마커 정의 */}
                    <defs>
                      <marker
                        id="arrowhead"
                        markerWidth={arrowWidth}
                        markerHeight={arrowHeight}
                        refX={arrowWidth/2}
                        refY={arrowHeight/2}
                        orient="auto"
                      >
                        <polygon points={`0 0, ${arrowWidth} ${arrowHeight/2}, 0 ${arrowHeight}`} fill="black" />
                      </marker>
                    </defs>
                  </>
                );
              })()}
            </svg>
          </div>
          
          <div className="share-section-view">
            <h3>단면도</h3>
            <svg 
              width="300" 
              height="300" 
              viewBox="0 0 140 160"
            >
              {/* 배경 사각형 추가 */}
              <rect
                x="0"
                y="0"
                width="140"
                height="160"
                fill="white"
                stroke="none"
              />
            
              <rect
                x="30"
                y="30"
                width="80"
                height="80"
                fill="none"
                stroke="black"
                strokeWidth="1"
              />
              <rect
                x="40"
                y="40"
                width="60"
                height="60"
                fill="none"
                stroke="black"
                strokeWidth="1"
              />
              <text
                x="70"
                y="140"
                textAnchor="middle"
                className="dimension-text"
              >
                {dimensions.thickness}mm
              </text>
            </svg>
          </div>
          
          <div className="share-info">
            <h3>제작 정보</h3>
            <p>가로 길이: {dimensions.width}mm</p>
            <p>세로 길이: {dimensions.height}mm</p>
            <p>각파이프 두께: {dimensions.thickness}mm</p>
            <p>세로 지지대 개수: {dimensions.verticalSupports}개</p>
            <p>타공 직경: ⌀{dimensions.holeDiameter}mm</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SquarePipe; 