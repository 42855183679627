import React, { useState, useEffect, useRef } from 'react';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import './BigSlab.css';

// 공유 아이콘 컴포넌트
const ShareIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ marginRight: '8px' }}>
    <circle cx="18" cy="5" r="3"></circle>
    <circle cx="6" cy="12" r="3"></circle>
    <circle cx="18" cy="19" r="3"></circle>
    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
  </svg>
);

const BigSlab = () => {
  // 상수 정의
  const margin = 80; // 여백 축소
  const dimensionOffset = 40;
  const textOffset = 50;
  const bottomTextOffset = 60;
  const rightTextOffset = 60;
  const shapeSpacing = 60;
  const pieceSpacing = 80; // 조각 사이 간격
  const pieceMargin = 20; // 조각 주변 여백

  // 선택된 모양 상태
  const [selectedShapes, setSelectedShapes] = useState([]);

  // 각 모양별 치수 상태
  const [shapeDimensions, setShapeDimensions] = useState({
    straight: {
      width: 3000,
      height: 500,
      jollyCutHeight: 50,
      jollycuts: {
        top: true,
        bottom: true,
        left: true,
        right: true
      }
    },
    lShape: {
      width1: 3000,
      height1: 500,
      width2: 1500,
      height2: 500,
      jollyCutHeight: 50,
      jollycuts: {
        top: true,
        bottom: true,
        left: true,
        right: true
      }
    },
    uShape: {
      width1: 3000,
      height1: 500,
      width2: 1500,
      height2: 500,
      width3: 1500,
      height3: 500,
      jollyCutHeight: 50,
      jollycuts: {
        top: true,
        bottom: true,
        left: true,
        right: true
      }
    }
  });

  const [scale, setScale] = useState(0.3);
  const [isSharing, setIsSharing] = useState(false);
  const [shareSupported, setShareSupported] = useState(false);
  
  const shareContainerRef = useRef(null);
  const drawingContainerRef = useRef(null);

  // 화면 크기에 따라 스케일 조정
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 480) { // 모바일 S
        setScale(0.15);
      } else if (width < 768) { // 모바일 M, L
        setScale(0.2);
      } else if (width < 1024) { // 태블릿
        setScale(0.25);
      } else if (width < 1440) { // 데스크탑
        setScale(0.3);
      } else { // 큰 화면
        setScale(0.35);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    setShareSupported(!!navigator.share);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 모양 선택 처리
  const handleShapeSelection = (shape) => {
    setSelectedShapes(prev => {
      if (prev.includes(shape)) {
        return prev.filter(s => s !== shape);
      }
      return [...prev, shape];
    });
  };

  // 모양별 치수 변경 처리
  const handleShapeDimensionChange = (shape, field, value) => {
    setShapeDimensions(prev => ({
      ...prev,
      [shape]: {
        ...prev[shape],
        [field]: field === 'jollycuts' ? value : Number(value)
      }
    }));
  };

  // 모양 선택 UI 렌더링
  const renderShapeSelector = () => (
    <div className="big-slab-shape-selector">
      <h3>상판 모양 선택</h3>
      <div className="shape-options">
        <label className={`shape-option ${selectedShapes.includes('straight') ? 'selected' : ''}`}>
          <input
            type="checkbox"
            checked={selectedShapes.includes('straight')}
            onChange={() => handleShapeSelection('straight')}
          />
          <span className="shape-icon">ㅡ</span>
          <span className="shape-label">일자형</span>
        </label>
        <label className={`shape-option ${selectedShapes.includes('lShape') ? 'selected' : ''}`}>
          <input
            type="checkbox"
            checked={selectedShapes.includes('lShape')}
            onChange={() => handleShapeSelection('lShape')}
          />
          <span className="shape-icon">ㄱ</span>
          <span className="shape-label">ㄱ자형</span>
        </label>
        <label className={`shape-option ${selectedShapes.includes('uShape') ? 'selected' : ''}`}>
          <input
            type="checkbox"
            checked={selectedShapes.includes('uShape')}
            onChange={() => handleShapeSelection('uShape')}
          />
          <span className="shape-icon">ㄷ</span>
          <span className="shape-label">ㄷ자형</span>
        </label>
      </div>
    </div>
  );

  // 선택된 모양별 치수 입력 폼 렌더링
  const renderShapeDimensionInputs = () => (
    <div className="shape-dimensions">
      {selectedShapes.map(shape => (
        <div key={shape} className="shape-dimension-group">
          <h4>
            {shape === 'straight' ? '일자형' : shape === 'lShape' ? 'ㄱ자형' : 'ㄷ자형'} 치수
          </h4>
          {shape === 'straight' && (
            <>
              <div className="input-group">
                <label>가로 길이 (mm)</label>
                <input
                  type="number"
                  value={shapeDimensions.straight.width}
                  onChange={(e) => handleShapeDimensionChange('straight', 'width', e.target.value)}
                  min="1"
                />
              </div>
              <div className="input-group">
                <label>세로 길이 (mm)</label>
                <input
                  type="number"
                  value={shapeDimensions.straight.height}
                  onChange={(e) => handleShapeDimensionChange('straight', 'height', e.target.value)}
                  min="1"
                />
              </div>
            </>
          )}
          {shape === 'lShape' && (
            <>
              <div className="input-group">
                <label>가로1 길이 (mm)</label>
                <input
                  type="number"
                  value={shapeDimensions.lShape.width1}
                  onChange={(e) => handleShapeDimensionChange('lShape', 'width1', e.target.value)}
                  min="1"
                />
              </div>
              <div className="input-group">
                <label>세로1 길이 (mm)</label>
                <input
                  type="number"
                  value={shapeDimensions.lShape.height1}
                  onChange={(e) => handleShapeDimensionChange('lShape', 'height1', e.target.value)}
                  min="1"
                />
              </div>
              <div className="input-group">
                <label>가로2 길이 (mm)</label>
                <input
                  type="number"
                  value={shapeDimensions.lShape.width2}
                  onChange={(e) => handleShapeDimensionChange('lShape', 'width2', e.target.value)}
                  min="1"
                />
              </div>
              <div className="input-group">
                <label>세로2 길이 (mm)</label>
                <input
                  type="number"
                  value={shapeDimensions.lShape.height2}
                  onChange={(e) => handleShapeDimensionChange('lShape', 'height2', e.target.value)}
                  min="1"
                />
              </div>
            </>
          )}
          {shape === 'uShape' && (
            <>
              <div className="input-group">
                <label>상단 가로 길이 (mm)</label>
                <input
                  type="number"
                  value={shapeDimensions.uShape.width1}
                  onChange={(e) => handleShapeDimensionChange('uShape', 'width1', e.target.value)}
                  min="1"
                />
              </div>
              <div className="input-group">
                <label>좌측 세로 길이 (mm)</label>
                <input
                  type="number"
                  value={shapeDimensions.uShape.height2}
                  onChange={(e) => handleShapeDimensionChange('uShape', 'height2', e.target.value)}
                  min="1"
                />
              </div>
              <div className="input-group">
                <label>하단 가로 길이 (mm)</label>
                <input
                  type="number"
                  value={shapeDimensions.uShape.width3}
                  onChange={(e) => handleShapeDimensionChange('uShape', 'width3', e.target.value)}
                  min="1"
                />
              </div>
              <div className="input-group">
                <label>우측 세로 길이 (mm)</label>
                <input
                  type="number"
                  value={shapeDimensions.uShape.height3}
                  onChange={(e) => handleShapeDimensionChange('uShape', 'height3', e.target.value)}
                  min="1"
                />
              </div>
            </>
          )}
          <div className="input-group">
            <label>졸리컷 높이 (mm)</label>
            <input
              type="number"
              value={shapeDimensions[shape].jollyCutHeight}
              onChange={(e) => handleShapeDimensionChange(shape, 'jollyCutHeight', e.target.value)}
              min="1"
            />
          </div>
          <div className="jollycut-options">
            <label>
              <input
                type="checkbox"
                checked={shapeDimensions[shape].jollycuts.top}
                onChange={(e) => handleShapeDimensionChange(shape, 'jollycuts', {
                  ...shapeDimensions[shape].jollycuts,
                  top: e.target.checked
                })}
              />
              상단 졸리컷
            </label>
            <label>
              <input
                type="checkbox"
                checked={shapeDimensions[shape].jollycuts.bottom}
                onChange={(e) => handleShapeDimensionChange(shape, 'jollycuts', {
                  ...shapeDimensions[shape].jollycuts,
                  bottom: e.target.checked
                })}
              />
              하단 졸리컷
            </label>
            <label>
              <input
                type="checkbox"
                checked={shapeDimensions[shape].jollycuts.left}
                onChange={(e) => handleShapeDimensionChange(shape, 'jollycuts', {
                  ...shapeDimensions[shape].jollycuts,
                  left: e.target.checked
                })}
              />
              좌측 졸리컷
            </label>
            <label>
              <input
                type="checkbox"
                checked={shapeDimensions[shape].jollycuts.right}
                onChange={(e) => handleShapeDimensionChange(shape, 'jollycuts', {
                  ...shapeDimensions[shape].jollycuts,
                  right: e.target.checked
                })}
              />
              우측 졸리컷
            </label>
          </div>
        </div>
      ))}
    </div>
  );

  // SVG 도면 렌더링
  const renderDrawing = () => {
    const textSize = Math.max(16, Math.floor(shapeDimensions.straight.width * scale * 0.025));
    const circleRadius = 4;

    // 좌우 여백 조정 (치수선과 텍스트를 고려하여 충분한 여백 확보)
    const extraMargin = 150; // 치수선과 텍스트를 고려한 추가 여백
    const horizontalCenter = svgWidth / 2; // 수평 중앙점

    // 각 모양별 도면의 높이 계산 (조각 영역 포함)
    const getShapeHeight = (shape) => {
      const baseHeight = (() => {
        switch(shape) {
          case 'straight':
            return shapeDimensions.straight.height * scale + margin * 2;
          case 'lShape':
            return (shapeDimensions.lShape.height1 + shapeDimensions.lShape.height2) * scale + margin * 2;
          case 'uShape':
            return (shapeDimensions.uShape.height1 + shapeDimensions.uShape.height2 + shapeDimensions.uShape.height3) * scale + margin * 2;
          default:
            return 0;
        }
      })();

      // 각 모양에 따른 조각 영역 높이 계산
      const pieceSectionHeight = (() => {
        switch(shape) {
          case 'straight':
            // 기존 계산 방식 유지
            const straightJollyCuts = shapeDimensions[shape].jollycuts;
            const straightActiveJollyCuts = Object.values(straightJollyCuts).filter(Boolean).length;
            return straightActiveJollyCuts > 0 ? 
              (shapeDimensions[shape].jollyCutHeight * scale + pieceSpacing) * straightActiveJollyCuts + pieceMargin * 2 : 0;
          
          case 'lShape':
            // ㄱ자형 조각 영역: 6개의 조각 (상단, 좌측, 하단 각각 메인 조각과 졸리컷 조각)
            return shapeDimensions.lShape.jollyCutHeight * scale * 9 + 300; // 조각 높이와 여백 포함
          
          case 'uShape':
            // ㄷ자형 조각 영역: 8개의 조각 (상단, 좌측, 하단, 우측 각각 메인 조각과 졸리컷 조각)
            return shapeDimensions.uShape.jollyCutHeight * scale * 12 + 400; // 조각 높이와 여백 포함
          
          default:
            return 0;
        }
      })();

      return baseHeight + pieceSectionHeight;
    };

    // 전체 SVG 높이 계산 (모양 사이 간격 포함)
    const totalHeight = selectedShapes.reduce((acc, shape, index) => {
      return acc + getShapeHeight(shape) + (index < selectedShapes.length - 1 ? shapeSpacing : 0);
    }, margin);

    // viewBox 계산 수정 - totalHeight 계산 이후로 이동
    const viewBoxWidth = svgWidth + margin * 2 + extraMargin * 2;
    const viewBoxHeight = totalHeight + extraMargin;
    const viewBoxX = -margin - extraMargin;
    const viewBoxY = -extraMargin / 2;

    // 각 모양별 시작 Y 위치 계산
    const getShapeStartY = (shapeIndex) => {
      return selectedShapes.slice(0, shapeIndex).reduce((acc, shape) => {
        return acc + getShapeHeight(shape) + shapeSpacing;
      }, margin);
    };

    // 졸리컷 조각 렌더링 함수
    const renderJollyCutPieces = (shape, startY, baseHeight) => {
      const pieces = [];
      const jollycuts = shapeDimensions[shape].jollycuts;
      const jollyCutHeight = shapeDimensions[shape].jollyCutHeight;
      let currentY = startY + baseHeight + pieceMargin;

      // 가로 방향 조각 (상단, 하단)
      Object.entries(jollycuts).forEach(([side, isEnabled]) => {
        if (!isEnabled || (side !== 'top' && side !== 'bottom')) return;

        const width = shape === 'straight' ? shapeDimensions[shape].width :
                     shape === 'lShape' ? (side === 'top' ? shapeDimensions[shape].width1 : shapeDimensions[shape].width2) :
                     shapeDimensions[shape][`width${side === 'top' ? '1' : '3'}`];

        pieces.push(
          <g key={`${shape}-${side}`}>
            <rect
              x={margin}
              y={currentY}
              width={width * scale}
              height={jollyCutHeight * scale}
              fill="#d9d9d9"
              stroke="black"
              strokeWidth="0.5"
            />
            {/* 체크 패턴 영역 추가 */}
            <rect
              x={margin}
              y={currentY}
              width={width * scale}
              height={jollyCutHeight * scale * 0.4}
              fill="url(#checkPattern)"
              stroke="black"
              strokeWidth="0.3"
              strokeDasharray="3,3"
            />
            <text
              x={margin + (width * scale) / 2}
              y={currentY - 5}
              textAnchor="middle"
              className="dimension-text"
              style={{ fontSize: `${textSize * 0.8}px` }}
            >
              {`${side === 'top' ? '상단' : '하단'} 조각: ${width} × ${jollyCutHeight}mm`}
            </text>
          </g>
        );

        currentY += jollyCutHeight * scale + pieceSpacing;
      });

      // 세로 방향 조각 (좌측, 우측)
      Object.entries(jollycuts).forEach(([side, isEnabled]) => {
        if (!isEnabled || (side !== 'left' && side !== 'right')) return;

        const height = shape === 'straight' ? shapeDimensions[shape].height :
                      shape === 'lShape' ? (side === 'left' ? shapeDimensions[shape].height1 : shapeDimensions[shape].height2) :
                      shapeDimensions[shape][`height${side === 'left' ? '2' : '3'}`];

        pieces.push(
          <g key={`${shape}-${side}`}>
            <rect
              x={margin}
              y={currentY}
              width={height * scale}
              height={jollyCutHeight * scale}
              fill="#d9d9d9"
              stroke="black"
              strokeWidth="0.5"
            />
            {/* 체크 패턴 영역 추가 */}
            <rect
              x={margin}
              y={currentY}
              width={height * scale}
              height={jollyCutHeight * scale * 0.5}
              fill="url(#checkPattern)"
              stroke="black"
              strokeWidth="0.3"
              strokeDasharray="3,3"
            />
            <text
              x={margin + (height * scale) / 2}
              y={currentY - 5}
              textAnchor="middle"
              className="dimension-text"
              style={{ fontSize: `${textSize * 0.8}px` }}
            >
              {`${side === 'left' ? '좌측' : '우측'} 조각: ${height} × ${jollyCutHeight}mm`}
            </text>
          </g>
        );

        currentY += jollyCutHeight * scale + pieceSpacing;
      });

      return pieces;
    };

    // 각 모양 렌더링 함수 수정 - 일자형 모양에 대한 중앙 정렬 개선
    const renderStraightShape = (startY) => {
      const baseHeight = shapeDimensions.straight.height * scale + margin * 2;
      // 도형의 중앙 위치를 계산
      const shapeWidth = shapeDimensions.straight.width * scale;
      // 중앙 배치를 위한 X 좌표 계산
      const centerX = horizontalCenter - shapeWidth / 2;
      
      return (
        <g>
          {/* 기존 도형 렌더링 */}
          <g transform={`translate(0, ${startY})`}>
            <rect
              x={centerX}
              y={0}
              width={shapeWidth}
              height={shapeDimensions.straight.height * scale}
              fill="#f0f0f0"
              stroke="black"
              strokeWidth="0.5"
            />
            {/* 졸리컷 영역 - 중앙 정렬 반영 */}
            {shapeDimensions.straight.jollycuts.top && (
              <rect
                x={centerX}
                y={0}
                width={shapeWidth}
                height={shapeDimensions.straight.jollyCutHeight * scale}
                fill="url(#checkPattern)"
                stroke="black"
                strokeWidth="0.3"
                strokeDasharray="3,3"
              />
            )}
            {shapeDimensions.straight.jollycuts.bottom && (
              <rect
                x={centerX}
                y={shapeDimensions.straight.height * scale - shapeDimensions.straight.jollyCutHeight * scale}
                width={shapeWidth}
                height={shapeDimensions.straight.jollyCutHeight * scale}
                fill="url(#checkPattern)"
                stroke="black"
                strokeWidth="0.3"
                strokeDasharray="3,3"
              />
            )}
            {shapeDimensions.straight.jollycuts.left && (
              <rect
                x={centerX}
                y={0}
                width={shapeDimensions.straight.jollyCutHeight * scale}
                height={shapeDimensions.straight.height * scale}
                fill="url(#checkPattern)"
                stroke="black"
                strokeWidth="0.3"
                strokeDasharray="3,3"
              />
            )}
            {shapeDimensions.straight.jollycuts.right && (
              <rect
                x={centerX + shapeWidth - shapeDimensions.straight.jollyCutHeight * scale}
                y={0}
                width={shapeDimensions.straight.jollyCutHeight * scale}
                height={shapeDimensions.straight.height * scale}
                fill="url(#checkPattern)"
                stroke="black"
                strokeWidth="0.3"
                strokeDasharray="3,3"
              />
            )}
            {/* 치수선 - 중앙 정렬 반영 */}
            <line
              x1={centerX}
              y1={shapeDimensions.straight.height * scale + dimensionOffset}
              x2={centerX + shapeWidth}
              y2={shapeDimensions.straight.height * scale + dimensionOffset}
              stroke="black"
              strokeWidth="1"
              markerEnd="url(#circle)"
              markerStart="url(#circle)"
            />
            <text
              x={centerX + (shapeWidth) / 2}
              y={shapeDimensions.straight.height * scale + bottomTextOffset}
              textAnchor="middle"
              className="dimension-text"
              style={{ fontSize: `${textSize}px`, fontWeight: '500' }}
            >
              {shapeDimensions.straight.width}
            </text>
            <line
              x1={centerX - dimensionOffset}
              y1={0}
              x2={centerX - dimensionOffset}
              y2={shapeDimensions.straight.height * scale}
              stroke="black"
              strokeWidth="1"
              markerEnd="url(#circle)"
              markerStart="url(#circle)"
            />
            <text
              x={centerX - rightTextOffset}
              y={shapeDimensions.straight.height * scale / 2}
              textAnchor="middle"
              transform={`rotate(-90 ${centerX - rightTextOffset} ${shapeDimensions.straight.height * scale / 2})`}
              className="dimension-text"
              style={{ fontSize: `${textSize}px`, fontWeight: '500' }}
            >
              {shapeDimensions.straight.height}
            </text>
          </g>
          {/* 졸리컷 조각들 - 중앙 정렬 */}
          <g className="big-slab-piece-group">
            {Object.entries(shapeDimensions.straight.jollycuts).filter(([side, isEnabled]) => 
              isEnabled && (side === 'top' || side === 'bottom')).map(([side], index) => {
              const width = shapeDimensions.straight.width;
              const currentY = startY + baseHeight + pieceMargin + 
                (shapeDimensions.straight.jollyCutHeight * scale + pieceSpacing) * index;
              
              return (
                <g key={`${side}`} className={`piece-item ${side}-piece`}>
                  <rect
                    x={centerX}
                    y={currentY}
                    width={width * scale}
                    height={shapeDimensions.straight.jollyCutHeight * scale}
                    fill="#d9d9d9"
                    stroke="black"
                    strokeWidth="0.5"
                  />
                  <rect
                    x={centerX}
                    y={currentY}
                    width={width * scale}
                    height={shapeDimensions.straight.jollyCutHeight * scale * 0.4}
                    fill="url(#checkPattern)"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeDasharray="3,3"
                  />
                  <text
                    x={centerX + (width * scale) / 2}
                    y={currentY - 15}
                    textAnchor="middle"
                    className="dimension-text piece-text"
                    style={{ fontSize: `${textSize * 0.8}px` }}
                  >
                    {side === 'top' ? '상단' : '하단'} 조각: <tspan className="dimension-label">{width}</tspan> × {shapeDimensions.straight.jollyCutHeight}mm
                  </text>
                </g>
              );
            })}
            
            {Object.entries(shapeDimensions.straight.jollycuts).filter(([side, isEnabled]) => 
              isEnabled && (side === 'left' || side === 'right')).map(([side], index) => {
              const height = shapeDimensions.straight.height;
              const topJollyCuts = Object.entries(shapeDimensions.straight.jollycuts)
                .filter(([s, enabled]) => enabled && (s === 'top' || s === 'bottom')).length;
              
              const currentY = startY + baseHeight + pieceMargin + 
                (shapeDimensions.straight.jollyCutHeight * scale + pieceSpacing) * (topJollyCuts + index);
              
              return (
                <g key={`${side}`} className={`piece-item ${side}-piece`}>
                  <rect
                    x={centerX}
                    y={currentY}
                    width={height * scale}
                    height={shapeDimensions.straight.jollyCutHeight * scale}
                    fill="#d9d9d9"
                    stroke="black"
                    strokeWidth="0.5"
                  />
                  <rect
                    x={centerX}
                    y={currentY}
                    width={height * scale}
                    height={shapeDimensions.straight.jollyCutHeight * scale * 0.5}
                    fill="url(#checkPattern)"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeDasharray="3,3"
                  />
                  <text
                    x={centerX + (height * scale) / 2}
                    y={currentY - 15}
                    textAnchor="middle"
                    className="dimension-text piece-text"
                    style={{ fontSize: `${textSize * 0.8}px` }}
                  >
                    {side === 'left' ? '좌측' : '우측'} 조각: <tspan className="dimension-label">{height}</tspan> × {shapeDimensions.straight.jollyCutHeight}mm
                  </text>
                </g>
              );
            })}
          </g>
        </g>
      );
    };

    const renderLShape = (startY) => {
      const baseHeight = (shapeDimensions.lShape.height1 + shapeDimensions.lShape.height2) * scale + margin * 2;
      
      // 도형의 중앙 위치를 계산
      const maxWidth = Math.max(shapeDimensions.lShape.width1, shapeDimensions.lShape.width2) * scale;
      const centerX = horizontalCenter - shapeDimensions.lShape.width1 * scale / 2;
      
      return (
        <g>
          {/* 기존 도형 렌더링 */}
          <g transform={`translate(0, ${startY})`}>
            <path
              d={`M ${centerX},0 
                  h ${shapeDimensions.lShape.width1 * scale} 
                  v ${shapeDimensions.lShape.height1 * scale} 
                  h ${-shapeDimensions.lShape.width1 * scale + shapeDimensions.lShape.width2 * scale}
                  v ${shapeDimensions.lShape.height2 * scale}
                  h ${-shapeDimensions.lShape.width2 * scale}
                  Z`}
              fill="#f0f0f0"
              stroke="black"
              strokeWidth="1"
            />
            {/* 전체 세로 치수선 */}
            <line
              x1={centerX - dimensionOffset * 2.5}
              y1={0}
              x2={centerX - dimensionOffset * 2.5}
              y2={shapeDimensions.lShape.height1 * scale + shapeDimensions.lShape.height2 * scale}
              stroke="black"
              strokeWidth="1"
              markerEnd="url(#circle)"
              markerStart="url(#circle)"
            />
            <text
              x={centerX - dimensionOffset * 2.5 - rightTextOffset}
              y={(shapeDimensions.lShape.height1 * scale + shapeDimensions.lShape.height2 * scale) / 2}
              textAnchor="middle"
              transform={`rotate(-90 ${centerX - dimensionOffset * 2.5 - rightTextOffset} ${(shapeDimensions.lShape.height1 * scale + shapeDimensions.lShape.height2 * scale) / 2})`}
              className="dimension-text"
              style={{ fontSize: `${textSize}px`, fontWeight: '500' }}
            >
              {shapeDimensions.lShape.height1 + shapeDimensions.lShape.height2}
            </text>
            {/* 기존 치수선들 */}
            <line
              x1={centerX}
              y1={-dimensionOffset}
              x2={centerX + shapeDimensions.lShape.width1 * scale}
              y2={-dimensionOffset}
              stroke="black"
              strokeWidth="1"
              markerEnd="url(#circle)"
              markerStart="url(#circle)"
            />
            <text
              x={centerX + (shapeDimensions.lShape.width1 * scale) / 2}
              y={-textOffset}
              textAnchor="middle"
              className="dimension-text"
              style={{ fontSize: `${textSize}px`, fontWeight: '500' }}
            >
              {shapeDimensions.lShape.width1}
            </text>
            <line
              x1={centerX - dimensionOffset}
              y1={0}
              x2={centerX - dimensionOffset}
              y2={shapeDimensions.lShape.height1 * scale}
              stroke="black"
              strokeWidth="1"
              markerEnd="url(#circle)"
              markerStart="url(#circle)"
            />
            <text
              x={centerX - textOffset}
              y={shapeDimensions.lShape.height1 * scale / 2}
              textAnchor="middle"
              transform={`rotate(-90 ${centerX - textOffset} ${shapeDimensions.lShape.height1 * scale / 2})`}
              className="dimension-text"
              style={{ fontSize: `${textSize}px`, fontWeight: '500' }}
            >
              {shapeDimensions.lShape.height1}
            </text>
            <line
              x1={centerX}
              y1={shapeDimensions.lShape.height1 * scale + shapeDimensions.lShape.height2 * scale + dimensionOffset}
              x2={centerX + shapeDimensions.lShape.width2 * scale}
              y2={shapeDimensions.lShape.height1 * scale + shapeDimensions.lShape.height2 * scale + dimensionOffset}
              stroke="black"
              strokeWidth="1"
              markerEnd="url(#circle)"
              markerStart="url(#circle)"
            />
            <text
              x={centerX + (shapeDimensions.lShape.width2 * scale) / 2}
              y={shapeDimensions.lShape.height1 * scale + shapeDimensions.lShape.height2 * scale + bottomTextOffset}
              textAnchor="middle"
              className="dimension-text"
              style={{ fontSize: `${textSize}px`, fontWeight: '500' }}
            >
              {shapeDimensions.lShape.width2}
            </text>
            <line
              x1={centerX + shapeDimensions.lShape.width2 * scale + dimensionOffset}
              y1={shapeDimensions.lShape.height1 * scale}
              x2={centerX + shapeDimensions.lShape.width2 * scale + dimensionOffset}
              y2={shapeDimensions.lShape.height1 * scale + shapeDimensions.lShape.height2 * scale}
              stroke="black"
              strokeWidth="1"
              markerEnd="url(#circle)"
              markerStart="url(#circle)"
            />
            <text
              x={centerX + shapeDimensions.lShape.width2 * scale + rightTextOffset}
              y={shapeDimensions.lShape.height1 * scale + shapeDimensions.lShape.height2 * scale / 2}
              textAnchor="middle"
              transform={`rotate(-90 ${centerX + shapeDimensions.lShape.width2 * scale + rightTextOffset} ${shapeDimensions.lShape.height1 * scale + shapeDimensions.lShape.height2 * scale / 2})`}
              className="dimension-text"
              style={{ fontSize: `${textSize}px`, fontWeight: '500' }}
            >
              {shapeDimensions.lShape.height2}
            </text>
          </g>
          {/* ㄱ자형(L자형) 조각들 - 중앙 정렬 */}
          <g className="big-slab-piece-group">
            {/* 상단 조각 (가로1) - 메인 조각과 졸리컷 조각으로 구분 */}
            <g className="piece-section top-section">
              <text
                x={centerX + (shapeDimensions.lShape.width1 * scale) / 2}
                y={startY + baseHeight + pieceMargin - 30}
                textAnchor="middle"
                className="dimension-text section-title"
                style={{ fontSize: `${textSize}px`, fontWeight: 'bold', fill: '#333' }}
              >
                상단 부분 (가로1) - 전체 <tspan className="dimension-label">{shapeDimensions.lShape.width1}mm</tspan>
              </text>
              
              {/* 메인 조각 */}
              <g className="piece-item main-piece top-piece">
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin}
                  width={shapeDimensions.lShape.width1 * scale}
                  height={shapeDimensions.lShape.jollyCutHeight * scale * 2}
                  fill="#e6f7ff"
                  stroke="black"
                  strokeWidth="0.5"
                />
                <text
                  x={centerX + (shapeDimensions.lShape.width1 * scale) / 2}
                  y={startY + baseHeight + pieceMargin - 15}
                  textAnchor="middle"
                  className="dimension-text piece-text"
                  style={{ fontSize: `${textSize * 0.8}px` }}
                >
                  상단 메인 조각: <tspan className="dimension-label">{shapeDimensions.lShape.width1}</tspan> × {shapeDimensions.lShape.jollyCutHeight * 2}mm
                </text>
              </g>
              
              {/* 졸리컷 조각 */}
              <g className="piece-item jollycut-piece top-piece">
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 2 + 20}
                  width={shapeDimensions.lShape.width1 * scale}
                  height={shapeDimensions.lShape.jollyCutHeight * scale}
                  fill="#d9d9d9"
                  stroke="black"
                  strokeWidth="0.5"
                />
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 2 + 20}
                  width={shapeDimensions.lShape.width1 * scale}
                  height={shapeDimensions.lShape.jollyCutHeight * scale * 0.4}
                  fill="url(#checkPattern)"
                  stroke="black"
                  strokeWidth="0.3"
                  strokeDasharray="3,3"
                />
                <text
                  x={centerX + (shapeDimensions.lShape.width1 * scale) / 2}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 2 + 5}
                  textAnchor="middle"
                  className="dimension-text piece-text"
                  style={{ fontSize: `${textSize * 0.8}px` }}
                >
                  상단 졸리컷 조각: <tspan className="dimension-label">{shapeDimensions.lShape.width1}</tspan> × {shapeDimensions.lShape.jollyCutHeight}mm
                </text>
              </g>
            </g>
            
            {/* 좌측 조각 (세로1) - 메인 조각과 졸리컷 조각으로 구분 */}
            <g className="piece-section left-section">
              <text
                x={centerX + (shapeDimensions.lShape.height1 * scale) / 2}
                y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 3 + 60}
                textAnchor="middle"
                className="dimension-text section-title"
                style={{ fontSize: `${textSize}px`, fontWeight: 'bold', fill: '#333' }}
              >
                좌측 부분 (세로1) - 전체 <tspan className="dimension-label">{shapeDimensions.lShape.height1}mm</tspan>
              </text>
              
              {/* 메인 조각 */}
              <g className="piece-item main-piece left-piece">
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 3 + 90}
                  width={shapeDimensions.lShape.height1 * scale}
                  height={shapeDimensions.lShape.jollyCutHeight * scale * 2}
                  fill="#f6ffed"
                  stroke="black"
                  strokeWidth="0.5"
                />
                <text
                  x={centerX + (shapeDimensions.lShape.height1 * scale) / 2}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 3 + 75}
                  textAnchor="middle"
                  className="dimension-text piece-text"
                  style={{ fontSize: `${textSize * 0.8}px` }}
                >
                  좌측 메인 조각: <tspan className="dimension-label">{shapeDimensions.lShape.height1}</tspan> × {shapeDimensions.lShape.jollyCutHeight * 2}mm
                </text>
              </g>
              
              {/* 졸리컷 조각 */}
              <g className="piece-item jollycut-piece left-piece">
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 5 + 110}
                  width={shapeDimensions.lShape.height1 * scale}
                  height={shapeDimensions.lShape.jollyCutHeight * scale}
                  fill="#d9d9d9"
                  stroke="black"
                  strokeWidth="0.5"
                />
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 5 + 110}
                  width={shapeDimensions.lShape.height1 * scale}
                  height={shapeDimensions.lShape.jollyCutHeight * scale * 0.5}
                  fill="url(#checkPattern)"
                  stroke="black"
                  strokeWidth="0.3"
                  strokeDasharray="3,3"
                />
                <text
                  x={centerX + (shapeDimensions.lShape.height1 * scale) / 2}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 5 + 95}
                  textAnchor="middle"
                  className="dimension-text piece-text"
                  style={{ fontSize: `${textSize * 0.8}px` }}
                >
                  좌측 졸리컷 조각: <tspan className="dimension-label">{shapeDimensions.lShape.height1}</tspan> × {shapeDimensions.lShape.jollyCutHeight}mm
                </text>
              </g>
            </g>
            
            {/* 하단 조각 (가로2) - 메인 조각과 졸리컷 조각으로 구분 */}
            <g className="piece-section bottom-section">
              <text
                x={centerX + (shapeDimensions.lShape.width2 * scale) / 2}
                y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 6 + 150}
                textAnchor="middle"
                className="dimension-text section-title"
                style={{ fontSize: `${textSize}px`, fontWeight: 'bold', fill: '#333' }}
              >
                하단 부분 (가로2) - 전체 <tspan className="dimension-label">{shapeDimensions.lShape.width2}mm</tspan>
              </text>
              
              {/* 메인 조각 */}
              <g className="piece-item main-piece bottom-piece">
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 6 + 180}
                  width={shapeDimensions.lShape.width2 * scale}
                  height={shapeDimensions.lShape.jollyCutHeight * scale * 2}
                  fill="#fffbe6"
                  stroke="black"
                  strokeWidth="0.5"
                />
                <text
                  x={centerX + (shapeDimensions.lShape.width2 * scale) / 2}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 6 + 165}
                  textAnchor="middle"
                  className="dimension-text piece-text"
                  style={{ fontSize: `${textSize * 0.8}px` }}
                >
                  하단 메인 조각: <tspan className="dimension-label">{shapeDimensions.lShape.width2}</tspan> × {shapeDimensions.lShape.jollyCutHeight * 2}mm
                </text>
              </g>
              
              {/* 졸리컷 조각 */}
              <g className="piece-item jollycut-piece bottom-piece">
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 8 + 200}
                  width={shapeDimensions.lShape.width2 * scale}
                  height={shapeDimensions.lShape.jollyCutHeight * scale}
                  fill="#d9d9d9"
                  stroke="black"
                  strokeWidth="0.5"
                />
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 8 + 200}
                  width={shapeDimensions.lShape.width2 * scale}
                  height={shapeDimensions.lShape.jollyCutHeight * scale * 0.4}
                  fill="url(#checkPattern)"
                  stroke="black"
                  strokeWidth="0.3"
                  strokeDasharray="3,3"
                />
                <text
                  x={centerX + (shapeDimensions.lShape.width2 * scale) / 2}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 8 + 185}
                  textAnchor="middle"
                  className="dimension-text piece-text"
                  style={{ fontSize: `${textSize * 0.8}px` }}
                >
                  하단 졸리컷 조각: <tspan className="dimension-label">{shapeDimensions.lShape.width2}</tspan> × {shapeDimensions.lShape.jollyCutHeight}mm
                </text>
              </g>
            </g>
            
            {/* 전체 치수 요약 */}
            <g className="total-dimensions-summary">
              <text
                x={centerX + (Math.max(shapeDimensions.lShape.width1, shapeDimensions.lShape.width2) * scale) / 2}
                y={startY + baseHeight + pieceMargin + shapeDimensions.lShape.jollyCutHeight * scale * 9 + 250}
                textAnchor="middle"
                className="dimension-text total-dimension"
                style={{ fontSize: `${textSize * 1.2}px`, fontWeight: 'bold', fill: '#333' }}
              >
                ㄱ자형 전체 치수: 가로 <tspan className="dimension-label">{shapeDimensions.lShape.width1}mm</tspan> × 세로 <tspan className="dimension-label">{shapeDimensions.lShape.height1 + shapeDimensions.lShape.height2}mm</tspan>
              </text>
            </g>
          </g>
        </g>
      );
    };

    const renderUShape = (startY) => {
      const baseHeight = (shapeDimensions.uShape.height1 + shapeDimensions.uShape.height2 + shapeDimensions.uShape.height3) * scale + margin * 2;
      
      // 도형의 중앙 위치를 계산
      const maxWidth = Math.max(shapeDimensions.uShape.width1, shapeDimensions.uShape.width3) * scale;
      const centerX = horizontalCenter - shapeDimensions.uShape.width1 * scale / 2;
      
      return (
        <g>
          {/* 기존 도형 렌더링 */}
          <g transform={`translate(0, ${startY})`}>
            <path
              d={`M ${centerX},0 
                  h ${shapeDimensions.uShape.width1 * scale} 
                  v ${shapeDimensions.uShape.height1 * scale} 
                  h ${-shapeDimensions.uShape.width1 * scale + shapeDimensions.uShape.width2 * scale}
                  v ${shapeDimensions.uShape.height2 * scale}
                  h ${shapeDimensions.uShape.width3 * scale - shapeDimensions.uShape.width2 * scale}
                  v ${shapeDimensions.uShape.height3 * scale}
                  h ${-shapeDimensions.uShape.width3 * scale}
                  Z`}
              fill="#f0f0f0"
              stroke="black"
              strokeWidth="1"
            />
            {/* 전체 세로 치수선 */}
            <line
              x1={centerX - dimensionOffset * 2.5}
              y1={0}
              x2={centerX - dimensionOffset * 2.5}
              y2={shapeDimensions.uShape.height1 * scale + shapeDimensions.uShape.height2 * scale + shapeDimensions.uShape.height3 * scale}
              stroke="black"
              strokeWidth="1"
              markerEnd="url(#circle)"
              markerStart="url(#circle)"
            />
            <text
              x={centerX - dimensionOffset * 2.5 - rightTextOffset}
              y={(shapeDimensions.uShape.height1 * scale + shapeDimensions.uShape.height2 * scale + shapeDimensions.uShape.height3 * scale) / 2}
              textAnchor="middle"
              transform={`rotate(-90 ${centerX - dimensionOffset * 2.5 - rightTextOffset} ${(shapeDimensions.uShape.height1 * scale + shapeDimensions.uShape.height2 * scale + shapeDimensions.uShape.height3 * scale) / 2})`}
              className="dimension-text"
              style={{ fontSize: `${textSize}px`, fontWeight: '500' }}
            >
              {shapeDimensions.uShape.height1 + shapeDimensions.uShape.height2 + shapeDimensions.uShape.height3}
            </text>
            {/* 기존 치수선들 */}
            <line
              x1={centerX}
              y1={-dimensionOffset}
              x2={centerX + shapeDimensions.uShape.width1 * scale}
              y2={-dimensionOffset}
              stroke="black"
              strokeWidth="1"
              markerEnd="url(#circle)"
              markerStart="url(#circle)"
            />
            <text
              x={centerX + (shapeDimensions.uShape.width1 * scale) / 2}
              y={-textOffset}
              textAnchor="middle"
              className="dimension-text"
              style={{ fontSize: `${textSize}px`, fontWeight: '500' }}
            >
              {shapeDimensions.uShape.width1}
            </text>
            <line
              x1={centerX - dimensionOffset}
              y1={0}
              x2={centerX - dimensionOffset}
              y2={shapeDimensions.uShape.height1 * scale}
              stroke="black"
              strokeWidth="1"
              markerEnd="url(#circle)"
              markerStart="url(#circle)"
            />
            <text
              x={centerX - textOffset}
              y={shapeDimensions.uShape.height1 * scale / 2}
              textAnchor="middle"
              transform={`rotate(-90 ${centerX - textOffset} ${shapeDimensions.uShape.height1 * scale / 2})`}
              className="dimension-text"
              style={{ fontSize: `${textSize}px`, fontWeight: '500' }}
            >
              {shapeDimensions.uShape.height1}
            </text>
            <line
              x1={centerX - dimensionOffset}
              y1={shapeDimensions.uShape.height1 * scale}
              x2={centerX - dimensionOffset}
              y2={shapeDimensions.uShape.height1 * scale + shapeDimensions.uShape.height2 * scale}
              stroke="black"
              strokeWidth="1"
              markerEnd="url(#circle)"
              markerStart="url(#circle)"
            />
            <text
              x={centerX - textOffset}
              y={shapeDimensions.uShape.height1 * scale + shapeDimensions.uShape.height2 * scale / 2}
              textAnchor="middle"
              transform={`rotate(-90 ${centerX - textOffset} ${shapeDimensions.uShape.height1 * scale + shapeDimensions.uShape.height2 * scale / 2})`}
              className="dimension-text"
              style={{ fontSize: `${textSize}px`, fontWeight: '500' }}
            >
              {shapeDimensions.uShape.height2}
            </text>
            <line
              x1={centerX}
              y1={shapeDimensions.uShape.height1 * scale + shapeDimensions.uShape.height2 * scale + shapeDimensions.uShape.height3 * scale + dimensionOffset}
              x2={centerX + shapeDimensions.uShape.width3 * scale}
              y2={shapeDimensions.uShape.height1 * scale + shapeDimensions.uShape.height2 * scale + shapeDimensions.uShape.height3 * scale + dimensionOffset}
              stroke="black"
              strokeWidth="1"
              markerEnd="url(#circle)"
              markerStart="url(#circle)"
            />
            <text
              x={centerX + (shapeDimensions.uShape.width3 * scale) / 2}
              y={shapeDimensions.uShape.height1 * scale + shapeDimensions.uShape.height2 * scale + shapeDimensions.uShape.height3 * scale + bottomTextOffset}
              textAnchor="middle"
              className="dimension-text"
              style={{ fontSize: `${textSize}px`, fontWeight: '500' }}
            >
              {shapeDimensions.uShape.width3}
            </text>
            <line
              x1={centerX + shapeDimensions.uShape.width3 * scale + dimensionOffset}
              y1={shapeDimensions.uShape.height1 * scale + shapeDimensions.uShape.height2 * scale}
              x2={centerX + shapeDimensions.uShape.width3 * scale + dimensionOffset}
              y2={shapeDimensions.uShape.height1 * scale + shapeDimensions.uShape.height2 * scale + shapeDimensions.uShape.height3 * scale}
              stroke="black"
              strokeWidth="1"
              markerEnd="url(#circle)"
              markerStart="url(#circle)"
            />
            <text
              x={centerX + shapeDimensions.uShape.width3 * scale + rightTextOffset}
              y={shapeDimensions.uShape.height1 * scale + shapeDimensions.uShape.height2 * scale + shapeDimensions.uShape.height3 * scale / 2}
              textAnchor="middle"
              transform={`rotate(-90 ${centerX + shapeDimensions.uShape.width3 * scale + rightTextOffset} ${shapeDimensions.uShape.height1 * scale + shapeDimensions.uShape.height2 * scale + shapeDimensions.uShape.height3 * scale / 2})`}
              className="dimension-text"
              style={{ fontSize: `${textSize}px`, fontWeight: '500' }}
            >
              {shapeDimensions.uShape.height3}
            </text>
            {/* 우측 전체 세로 치수선 */}
            <line
              x1={centerX + shapeDimensions.uShape.width3 * scale + dimensionOffset * 2.5}
              y1={shapeDimensions.uShape.height1 * scale}
              x2={centerX + shapeDimensions.uShape.width3 * scale + dimensionOffset * 2.5}
              y2={shapeDimensions.uShape.height1 * scale + shapeDimensions.uShape.height2 * scale + shapeDimensions.uShape.height3 * scale}
              stroke="black"
              strokeWidth="1"
              markerEnd="url(#circle)"
              markerStart="url(#circle)"
            />
            <text
              x={centerX + shapeDimensions.uShape.width3 * scale + dimensionOffset * 2.5 + rightTextOffset}
              y={shapeDimensions.uShape.height1 * scale + (shapeDimensions.uShape.height2 * scale + shapeDimensions.uShape.height3 * scale) / 2}
              textAnchor="middle"
              transform={`rotate(-90 ${centerX + shapeDimensions.uShape.width3 * scale + dimensionOffset * 2.5 + rightTextOffset} ${shapeDimensions.uShape.height1 * scale + (shapeDimensions.uShape.height2 * scale + shapeDimensions.uShape.height3 * scale) / 2})`}
              className="dimension-text"
              style={{ fontSize: `${textSize}px`, fontWeight: '500' }}
            >
              {shapeDimensions.uShape.height2 + shapeDimensions.uShape.height3}
            </text>
          </g>
          {/* ㄷ자형(U자형) 조각들 - 중앙 정렬 */}
          <g className="big-slab-piece-group">
            {/* 상단 조각 (가로1) - 메인 조각과 졸리컷 조각으로 구분 */}
            <g className="piece-section top-section">
              <text
                x={centerX + (shapeDimensions.uShape.width1 * scale) / 2}
                y={startY + baseHeight + pieceMargin - 30}
                textAnchor="middle"
                className="dimension-text section-title"
                style={{ fontSize: `${textSize}px`, fontWeight: 'bold', fill: '#333' }}
              >
                상단 부분 (가로1) - 전체 <tspan className="dimension-label">{shapeDimensions.uShape.width1}mm</tspan>
              </text>
              
              {/* 메인 조각 */}
              <g className="piece-item main-piece top-piece">
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin}
                  width={shapeDimensions.uShape.width1 * scale}
                  height={shapeDimensions.uShape.jollyCutHeight * scale * 2}
                  fill="#e6f7ff"
                  stroke="black"
                  strokeWidth="0.5"
                />
                <text
                  x={centerX + (shapeDimensions.uShape.width1 * scale) / 2}
                  y={startY + baseHeight + pieceMargin - 15}
                  textAnchor="middle"
                  className="dimension-text piece-text"
                  style={{ fontSize: `${textSize * 0.8}px` }}
                >
                  상단 메인 조각: <tspan className="dimension-label">{shapeDimensions.uShape.width1}</tspan> × {shapeDimensions.uShape.jollyCutHeight * 2}mm
                </text>
              </g>
              
              {/* 졸리컷 조각 */}
              <g className="piece-item jollycut-piece top-piece">
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 2 + 20}
                  width={shapeDimensions.uShape.width1 * scale}
                  height={shapeDimensions.uShape.jollyCutHeight * scale}
                  fill="#d9d9d9"
                  stroke="black"
                  strokeWidth="0.5"
                />
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 2 + 20}
                  width={shapeDimensions.uShape.width1 * scale}
                  height={shapeDimensions.uShape.jollyCutHeight * scale * 0.4}
                  fill="url(#checkPattern)"
                  stroke="black"
                  strokeWidth="0.3"
                  strokeDasharray="3,3"
                />
                <text
                  x={centerX + (shapeDimensions.uShape.width1 * scale) / 2}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 2 + 5}
                  textAnchor="middle"
                  className="dimension-text piece-text"
                  style={{ fontSize: `${textSize * 0.8}px` }}
                >
                  상단 졸리컷 조각: <tspan className="dimension-label">{shapeDimensions.uShape.width1}</tspan> × {shapeDimensions.uShape.jollyCutHeight}mm
                </text>
              </g>
            </g>
            
            {/* 좌측 조각 (세로2) - 메인 조각과 졸리컷 조각으로 구분 */}
            <g className="piece-section left-section">
              <text
                x={centerX + (shapeDimensions.uShape.height2 * scale) / 2}
                y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 3 + 60}
                textAnchor="middle"
                className="dimension-text section-title"
                style={{ fontSize: `${textSize}px`, fontWeight: 'bold', fill: '#333' }}
              >
                좌측 부분 (세로2) - 전체 <tspan className="dimension-label">{shapeDimensions.uShape.height2}mm</tspan>
              </text>
              
              {/* 메인 조각 */}
              <g className="piece-item main-piece left-piece">
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 3 + 90}
                  width={shapeDimensions.uShape.height2 * scale}
                  height={shapeDimensions.uShape.jollyCutHeight * scale * 2}
                  fill="#f6ffed"
                  stroke="black"
                  strokeWidth="0.5"
                />
                <text
                  x={centerX + (shapeDimensions.uShape.height2 * scale) / 2}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 3 + 75}
                  textAnchor="middle"
                  className="dimension-text piece-text"
                  style={{ fontSize: `${textSize * 0.8}px` }}
                >
                  좌측 메인 조각: <tspan className="dimension-label">{shapeDimensions.uShape.height2}</tspan> × {shapeDimensions.uShape.jollyCutHeight * 2}mm
                </text>
              </g>
              
              {/* 졸리컷 조각 */}
              <g className="piece-item jollycut-piece left-piece">
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 5 + 110}
                  width={shapeDimensions.uShape.height2 * scale}
                  height={shapeDimensions.uShape.jollyCutHeight * scale}
                  fill="#d9d9d9"
                  stroke="black"
                  strokeWidth="0.5"
                />
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 5 + 110}
                  width={shapeDimensions.uShape.height2 * scale}
                  height={shapeDimensions.uShape.jollyCutHeight * scale * 0.5}
                  fill="url(#checkPattern)"
                  stroke="black"
                  strokeWidth="0.3"
                  strokeDasharray="3,3"
                />
                <text
                  x={centerX + (shapeDimensions.uShape.height2 * scale) / 2}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 5 + 95}
                  textAnchor="middle"
                  className="dimension-text piece-text"
                  style={{ fontSize: `${textSize * 0.8}px` }}
                >
                  좌측 졸리컷 조각: <tspan className="dimension-label">{shapeDimensions.uShape.height2}</tspan> × {shapeDimensions.uShape.jollyCutHeight}mm
                </text>
              </g>
            </g>
            
            {/* 하단 조각 (가로3) - 메인 조각과 졸리컷 조각으로 구분 */}
            <g className="piece-section bottom-section">
              <text
                x={centerX + (shapeDimensions.uShape.width3 * scale) / 2}
                y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 6 + 150}
                textAnchor="middle"
                className="dimension-text section-title"
                style={{ fontSize: `${textSize}px`, fontWeight: 'bold', fill: '#333' }}
              >
                하단 부분 (가로3) - 전체 <tspan className="dimension-label">{shapeDimensions.uShape.width3}mm</tspan>
              </text>
              
              {/* 메인 조각 */}
              <g className="piece-item main-piece bottom-piece">
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 6 + 180}
                  width={shapeDimensions.uShape.width3 * scale}
                  height={shapeDimensions.uShape.jollyCutHeight * scale * 2}
                  fill="#fffbe6"
                  stroke="black"
                  strokeWidth="0.5"
                />
                <text
                  x={centerX + (shapeDimensions.uShape.width3 * scale) / 2}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 6 + 165}
                  textAnchor="middle"
                  className="dimension-text piece-text"
                  style={{ fontSize: `${textSize * 0.8}px` }}
                >
                  하단 메인 조각: <tspan className="dimension-label">{shapeDimensions.uShape.width3}</tspan> × {shapeDimensions.uShape.jollyCutHeight * 2}mm
                </text>
              </g>
              
              {/* 졸리컷 조각 */}
              <g className="piece-item jollycut-piece bottom-piece">
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 8 + 200}
                  width={shapeDimensions.uShape.width3 * scale}
                  height={shapeDimensions.uShape.jollyCutHeight * scale}
                  fill="#d9d9d9"
                  stroke="black"
                  strokeWidth="0.5"
                />
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 8 + 200}
                  width={shapeDimensions.uShape.width3 * scale}
                  height={shapeDimensions.uShape.jollyCutHeight * scale * 0.4}
                  fill="url(#checkPattern)"
                  stroke="black"
                  strokeWidth="0.3"
                  strokeDasharray="3,3"
                />
                <text
                  x={centerX + (shapeDimensions.uShape.width3 * scale) / 2}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 8 + 185}
                  textAnchor="middle"
                  className="dimension-text piece-text"
                  style={{ fontSize: `${textSize * 0.8}px` }}
                >
                  하단 졸리컷 조각: <tspan className="dimension-label">{shapeDimensions.uShape.width3}</tspan> × {shapeDimensions.uShape.jollyCutHeight}mm
                </text>
              </g>
            </g>
            
            {/* 우측 조각 (세로3) - 메인 조각과 졸리컷 조각으로 구분 */}
            <g className="piece-section right-section">
              <text
                x={centerX + (shapeDimensions.uShape.height3 * scale) / 2}
                y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 9 + 240}
                textAnchor="middle"
                className="dimension-text section-title"
                style={{ fontSize: `${textSize}px`, fontWeight: 'bold', fill: '#333' }}
              >
                우측 부분 (세로3) - 전체 <tspan className="dimension-label">{shapeDimensions.uShape.height3}mm</tspan>
              </text>
              
              {/* 메인 조각 */}
              <g className="piece-item main-piece right-piece">
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 9 + 270}
                  width={shapeDimensions.uShape.height3 * scale}
                  height={shapeDimensions.uShape.jollyCutHeight * scale * 2}
                  fill="#fff2e8"
                  stroke="black"
                  strokeWidth="0.5"
                />
                <text
                  x={centerX + (shapeDimensions.uShape.height3 * scale) / 2}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 9 + 255}
                  textAnchor="middle"
                  className="dimension-text piece-text"
                  style={{ fontSize: `${textSize * 0.8}px` }}
                >
                  우측 메인 조각: <tspan className="dimension-label">{shapeDimensions.uShape.height3}</tspan> × {shapeDimensions.uShape.jollyCutHeight * 2}mm
                </text>
              </g>
              
              {/* 졸리컷 조각 */}
              <g className="piece-item jollycut-piece right-piece">
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 11 + 290}
                  width={shapeDimensions.uShape.height3 * scale}
                  height={shapeDimensions.uShape.jollyCutHeight * scale}
                  fill="#d9d9d9"
                  stroke="black"
                  strokeWidth="0.5"
                />
                <rect
                  x={centerX}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 11 + 290}
                  width={shapeDimensions.uShape.height3 * scale}
                  height={shapeDimensions.uShape.jollyCutHeight * scale * 0.5}
                  fill="url(#checkPattern)"
                  stroke="black"
                  strokeWidth="0.3"
                  strokeDasharray="3,3"
                />
                <text
                  x={centerX + (shapeDimensions.uShape.height3 * scale) / 2}
                  y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 11 + 275}
                  textAnchor="middle"
                  className="dimension-text piece-text"
                  style={{ fontSize: `${textSize * 0.8}px` }}
                >
                  우측 졸리컷 조각: <tspan className="dimension-label">{shapeDimensions.uShape.height3}</tspan> × {shapeDimensions.uShape.jollyCutHeight}mm
                </text>
              </g>
            </g>
            
            {/* 전체 치수 요약 */}
            <g className="total-dimensions-summary">
              <text
                x={centerX + (Math.max(shapeDimensions.uShape.width1, shapeDimensions.uShape.width3) * scale) / 2}
                y={startY + baseHeight + pieceMargin + shapeDimensions.uShape.jollyCutHeight * scale * 12 + 340}
                textAnchor="middle"
                className="dimension-text total-dimension"
                style={{ fontSize: `${textSize * 1.2}px`, fontWeight: 'bold', fill: '#333' }}
              >
                ㄷ자형 전체 치수: 가로 <tspan className="dimension-label">{shapeDimensions.uShape.width1}mm</tspan> × 세로 <tspan className="dimension-label">{shapeDimensions.uShape.height1 + shapeDimensions.uShape.height2 + shapeDimensions.uShape.height3}mm</tspan>
              </text>
            </g>
          </g>
        </g>
      );
    };

    return (
      <svg 
        width="100%" 
        height="100%" 
        viewBox={`${viewBoxX} ${viewBoxY} ${viewBoxWidth} ${viewBoxHeight}`}
        className="big-slab-main-drawing"
        preserveAspectRatio="xMidYMid meet"
        style={{ 
          display: 'block',
          width: '100%',
          height: 'auto',
          margin: '0 auto',
          maxWidth: '100%',
          position: 'relative',
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      >
        {/* 체크 패턴 정의 */}
        <defs>
          <pattern id="checkPattern" x="0" y="0" width="4" height="4" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" fill="#f0f0f0"/>
            <path d="M0,0 L4,4 M4,0 L0,4" stroke="#ff0000" strokeWidth="0.3"/>
          </pattern>
          {/* 동그라미 마커 정의 */}
          <marker
            id="circle"
            markerWidth="8"
            markerHeight="8"
            refX="4"
            refY="4"
            orient="auto"
          >
            <circle cx="4" cy="4" r={circleRadius} fill="black" />
          </marker>
        </defs>

        {/* 배경 */}
        <rect
          x="0"
          y="0"
          width={svgWidth}
          height={totalHeight}
          fill="white"
        />

        {/* 선택된 모양들 렌더링 */}
        {selectedShapes.map((shape, index) => {
          const startY = getShapeStartY(index);
          switch(shape) {
            case 'straight':
              return renderStraightShape(startY);
            case 'lShape':
              return renderLShape(startY);
            case 'uShape':
              return renderUShape(startY);
            default:
              return null;
          }
        })}
      </svg>
    );
  };

  const svgWidth = Math.max(
    ...selectedShapes.map(shape => {
      switch(shape) {
        case 'straight':
          return shapeDimensions.straight.width * scale + margin * 3;
        case 'lShape':
          return Math.max(
            shapeDimensions.lShape.width1,
            shapeDimensions.lShape.width2
          ) * scale + margin * 3;
        case 'uShape':
          return Math.max(
            shapeDimensions.uShape.width1,
            shapeDimensions.uShape.width3
          ) * scale + margin * 3;
        default:
          return 0;
      }
    }),
    window.innerWidth < 768 ? window.innerWidth * 0.85 : window.innerWidth * 0.75  // 너비 더 줄임
  );

  // 이미지 생성 및 공유 함수
  const handleShare = async () => {
    let tempContainer = null;
    try {
      setIsSharing(true);
      
      if (shareContainerRef.current) {
        // 임시 컨테이너 생성
        tempContainer = document.createElement('div');
        tempContainer.style.position = 'absolute';
        tempContainer.style.left = '-9999px';
        tempContainer.style.top = '-9999px';
        tempContainer.style.width = '1200px';
        tempContainer.style.height = 'auto';
        tempContainer.style.visibility = 'visible';
        tempContainer.style.background = 'white';
        tempContainer.style.padding = '40px';
        tempContainer.style.overflow = 'visible';
        document.body.appendChild(tempContainer);
        
        // 컨텐츠 복제
        const clonedContent = shareContainerRef.current.cloneNode(true);
        clonedContent.style.position = 'static';
        clonedContent.style.transform = 'none';
        clonedContent.style.width = '100%';
        clonedContent.style.height = 'auto';
        clonedContent.style.visibility = 'visible';
        clonedContent.style.background = 'white';
        clonedContent.style.display = 'block';
        clonedContent.style.overflow = 'visible';
        
        tempContainer.appendChild(clonedContent);

        // 복제된 컨텐츠가 렌더링될 시간을 주기
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        const canvas = await html2canvas(tempContainer, {
          backgroundColor: '#ffffff',
          scale: 1,
          logging: false,
          useCORS: true,
          allowTaint: true,
          foreignObjectRendering: false,
          removeContainer: false,
          x: 0,
          y: 0,
          width: 1200,
          height: tempContainer.offsetHeight
        });

        canvas.toBlob(async (blob) => {
          if (blob === null) {
            setIsSharing(false);
            return;
          }
          
          if (shareSupported) {
            try {
              const file = new File([blob], '빅슬랩_도면.png', { type: 'image/png' });
              await navigator.share({
                title: '빅슬랩 도면',
                text: `가로: ${shapeDimensions.straight.width}mm, 세로: ${shapeDimensions.straight.height}mm`,
                files: [file]
              });
            } catch (error) {
              saveAs(blob, '빅슬랩_도면.png');
            }
          } else {
            saveAs(blob, '빅슬랩_도면.png');
          }
          
          // 임시 컨테이너 제거
          if (tempContainer && tempContainer.parentNode) {
            document.body.removeChild(tempContainer);
          }
          
          setIsSharing(false);
        }, 'image/png', 1.0);
      }
    } catch (error) {
      // 에러 발생 시에도 임시 컨테이너 제거
      if (tempContainer && tempContainer.parentNode) {
        document.body.removeChild(tempContainer);
      }
      setIsSharing(false);
    }
  };

  return (
    <div className="big-slab">
      <h2>빅슬랩 상판 도면</h2>

      {renderShapeSelector()}
      
      {selectedShapes.length > 0 && renderShapeDimensionInputs()}

      {/* 공유 버튼 */}
      <div className="share-button-container">
        <button 
          className="share-button" 
          onClick={handleShare}
          disabled={isSharing || selectedShapes.length === 0}
        >
          {isSharing ? '생성 중...' : (
            <>
              <ShareIcon />
              도면 공유하기
            </>
          )}
        </button>
      </div>

      {/* 메인 도면 */}
      <div className="drawing-container" ref={drawingContainerRef} style={{ 
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: '1200px',
        margin: '20px auto',
        position: 'relative'
      }}>
        {renderDrawing()}
      </div>

      {/* 정보 표시 */}
      <div className="info-panel">
        <h3>제작 정보</h3>
        {selectedShapes.map(shape => (
          <div key={shape} className="shape-info">
            <h4>{shape === 'straight' ? '일자형' : shape === 'lShape' ? 'ㄱ자형' : 'ㄷ자형'}</h4>
            {shape === 'straight' && (
              <>
                <p>가로 길이: {shapeDimensions.straight.width}mm</p>
                <p>세로 길이: {shapeDimensions.straight.height}mm</p>
              </>
            )}
            {shape === 'lShape' && (
              <>
                <p>가로1 길이: {shapeDimensions.lShape.width1}mm</p>
                <p>세로1 길이: {shapeDimensions.lShape.height1}mm</p>
                <p>가로2 길이: {shapeDimensions.lShape.width2}mm</p>
                <p>세로2 길이: {shapeDimensions.lShape.height2}mm</p>
              </>
            )}
            {shape === 'uShape' && (
              <>
                <p>상단 가로 길이: {shapeDimensions.uShape.width1}mm</p>
                <p>좌측 세로 길이: {shapeDimensions.uShape.height2}mm</p>
                <p>하단 가로 길이: {shapeDimensions.uShape.width3}mm</p>
                <p>우측 세로 길이: {shapeDimensions.uShape.height3}mm</p>
              </>
            )}
            {/* 졸리컷 정보 추가 */}
            {Object.entries(shapeDimensions[shape].jollycuts)
              .filter(([_, isEnabled]) => isEnabled)
              .map(([side]) => (
                <p key={side}>
                  {side === 'top' ? '상단' : side === 'bottom' ? '하단' : side === 'left' ? '좌측' : '우측'} 
                  졸리컷: {shapeDimensions[shape].jollyCutHeight}mm
                </p>
              ))
            }
          </div>
        ))}
      </div>

      {/* 공유용 컨테이너 */}
      <div className="share-container-wrapper">
        <div ref={shareContainerRef} className="share-container">
          <div className="share-header">
            <h2>빅슬랩 상판 도면</h2>
          </div>
          <div className="share-drawing" style={{ 
            textAlign: 'center',
            position: 'relative',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            {renderDrawing()}
          </div>
          <div className="share-info">
            <h3>제작 정보</h3>
            {selectedShapes.map(shape => (
              <div key={shape} className="shape-info">
                <h4>{shape === 'straight' ? '일자형' : shape === 'lShape' ? 'ㄱ자형' : 'ㄷ자형'}</h4>
                {shape === 'straight' && (
                  <>
                    <p>가로 길이: {shapeDimensions.straight.width}mm</p>
                    <p>세로 길이: {shapeDimensions.straight.height}mm</p>
                  </>
                )}
                {shape === 'lShape' && (
                  <>
                    <p>가로1 길이: {shapeDimensions.lShape.width1}mm</p>
                    <p>세로1 길이: {shapeDimensions.lShape.height1}mm</p>
                    <p>가로2 길이: {shapeDimensions.lShape.width2}mm</p>
                    <p>세로2 길이: {shapeDimensions.lShape.height2}mm</p>
                  </>
                )}
                {shape === 'uShape' && (
                  <>
                    <p>상단 가로 길이: {shapeDimensions.uShape.width1}mm</p>
                    <p>좌측 세로 길이: {shapeDimensions.uShape.height2}mm</p>
                    <p>하단 가로 길이: {shapeDimensions.uShape.width3}mm</p>
                    <p>우측 세로 길이: {shapeDimensions.uShape.height3}mm</p>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigSlab; 