import { db } from '../../firebase';
import { 
  collection, 
  addDoc, 
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
  serverTimestamp,
  onSnapshot
} from 'firebase/firestore';

// 위치 관련 함수
export const addLocation = async (name) => {
  try {
    const locationsRef = collection(db, 'workOrderMaterials/locations/items');
    await addDoc(locationsRef, {
      name,
      createdAt: serverTimestamp()
    });
  } catch (error) {
    throw error;
  }
};

export const deleteLocation = async (locationId) => {
  try {
    const locationRef = doc(db, 'workOrderMaterials/locations/items', locationId);
    await deleteDoc(locationRef);
  } catch (error) {
    throw error;
  }
};

export const subscribeToLocations = (callback) => {
  const locationsRef = collection(db, 'workOrderMaterials/locations/items');
  return onSnapshot(locationsRef, (snapshot) => {
    const locations = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    callback(locations);
  });
};

// 자재 종류 관련 함수
export const addMaterialType = async (name) => {
  try {
    const materialTypesRef = collection(db, 'workOrderMaterials/materialTypes/items');
    await addDoc(materialTypesRef, {
      name,
      createdAt: serverTimestamp()
    });
  } catch (error) {
    throw error;
  }
};

export const deleteMaterialType = async (typeId) => {
  try {
    const typeRef = doc(db, 'workOrderMaterials/materialTypes/items', typeId);
    await deleteDoc(typeRef);

    const materialNamesRef = collection(db, 'workOrderMaterials/materialNames/items');
    const q = query(materialNamesRef, where('materialTypeId', '==', typeId));
    const snapshot = await getDocs(q);
    
    const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));
    await Promise.all(deletePromises);
  } catch (error) {
    throw error;
  }
};

export const subscribeToMaterialTypes = (callback) => {
  const materialTypesRef = collection(db, 'workOrderMaterials/materialTypes/items');
  return onSnapshot(materialTypesRef, (snapshot) => {
    const types = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    callback(types);
  });
};

// 자재 이름 관련 함수
export const addMaterialName = async (name, materialTypeId) => {
  try {
    const materialNamesRef = collection(db, 'workOrderMaterials/materialNames/items');
    await addDoc(materialNamesRef, {
      name,
      materialTypeId,
      createdAt: serverTimestamp()
    });
  } catch (error) {
    throw error;
  }
};

export const deleteMaterialName = async (nameId) => {
  try {
    const nameRef = doc(db, 'workOrderMaterials/materialNames/items', nameId);
    await deleteDoc(nameRef);
  } catch (error) {
    throw error;
  }
};

export const subscribeToMaterialNames = (materialTypeId, callback) => {
  const materialNamesRef = collection(db, 'workOrderMaterials/materialNames/items');
  const q = query(materialNamesRef, where('materialTypeId', '==', materialTypeId));
  
  return onSnapshot(q, (snapshot) => {
    const names = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    callback(names);
  });
}; 