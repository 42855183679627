import React, { useState, useEffect, useRef } from 'react';
import './DragHandleItemList.css';

const DragHandleItemList = ({ 
  items, 
  onReorder, 
  processId, 
  isEditMode, 
  onEdit, 
  onDelete,
  formatNumber,
  getProcessName,
  calculateItemTotal,
  showActionButtons = true
}) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const menuRefs = useRef({});

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeMenu !== null) {
        const activeMenuRef = menuRefs.current[activeMenu];
        if (activeMenuRef && !activeMenuRef.contains(event.target)) {
          setActiveMenu(null);
        }
      }
    };

    if (activeMenu !== null) {
      document.addEventListener('mousedown', handleClickOutside, true);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [activeMenu]);

  const moveItem = (index, direction) => {
    setActiveMenu(null);
    
    const newItems = [...items];
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    
    if (newIndex < 0 || newIndex >= items.length) return;
    
    const movedItem = items[index];
    const targetItem = items[newIndex];
    
    [newItems[index], newItems[newIndex]] = [newItems[newIndex], newItems[index]];
    
    onReorder(processId, newItems, {
      type: 'move',
      description: `${getProcessName(processId)}: ${movedItem.name} 항목을 ${direction === 'up' ? '위로' : '아래로'} 이동 (${targetItem.name} ${direction === 'up' ? '위로' : '아래로'})`
    });
  };

  const handleDragHandleClick = (index, event) => {
    event.stopPropagation();
    setActiveMenu(activeMenu === index ? null : index);
  };

  const handleDeleteClick = (item, event) => {
    event.stopPropagation();
    onDelete(item);
    setActiveMenu(null);
  };

  const tableRowStyles = {
    position: 'relative',
    transition: 'background-color 0.2s'
  };

  const deleteButtonStyles = {
    position: 'absolute',
    right: '-30px',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '24px',
    height: '24px',
    backgroundColor: '#e74c3c',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: 'all 0.2s ease',
    fontSize: '16px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    zIndex: 2
  };

  const tableRowHoverStyles = {
    backgroundColor: '#f8f8f8'
  };

  const getItemDisplayName = (item) => {
    if (!item.name) return '';
    
    const cleanName = item.name.replace(/\((추가|별도)\)$/, '');
    
    return (
      <>
        <span>{cleanName}</span>
        {item.isAdditional && <span className="additional-tag">(추가)</span>}
        {(item.isSeparate || item.name?.includes('(별도)')) && <span className="separate-tag">(별도)</span>}
      </>
    );
  };

  return (
    <div className="drag-handle-list">
      {items.map((item, index) => (
        <div key={item.uniqueId} className="list-item">
          {isEditMode && (
            <div 
              className="drag-handle-container" 
              ref={el => menuRefs.current[index] = el}
            >
              <button
                className="drag-handle"
                onClick={(e) => handleDragHandleClick(index, e)}
                title="이동 메뉴"
              >
                ⋮⋮
              </button>
              <button
                className="delete-handle"
                onClick={(e) => handleDeleteClick(item, e)}
                title="항목 삭제"
              >
                ×
              </button>
              {activeMenu === index && (
                <div 
                  className="move-menu" 
                  onClick={e => e.stopPropagation()}
                >
                  <button
                    className="move-button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      moveItem(index, 'up');
                    }}
                    disabled={index === 0}
                  >
                    ↑ 위로 이동
                  </button>
                  <button
                    className="move-button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      moveItem(index, 'down');
                    }}
                    disabled={index === items.length - 1}
                  >
                    ↓ 아래로 이동
                  </button>
                </div>
              )}
            </div>
          )}
          <div 
            className="item-content"
            onClick={() => onEdit(item)}
          >
            <div className="col item">
              {getItemDisplayName(item)}
            </div>
            <div className="col quantity">{item.quantity}</div>
            <div className="col unit">{item.unit}</div>
            <div className="col unit-price">{formatNumber(item.unitPrice)}</div>
            <div className="col amount">
              {formatNumber(item.excludeFromTotal ? 0 : calculateItemTotal(item))}
            </div>
            <div className="col note">{item.note === '별도' ? '' : item.note}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DragHandleItemList; 