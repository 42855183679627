import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, query, where, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import './MessagePreview.css';

const MessagePreview = () => {
  const [messageData, setMessageData] = useState({
    processMessages: [],
    isDateChangeRequest: false
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddingSample, setIsAddingSample] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  const handleHeaderClick = () => {
    setClickCount(prev => prev + 1);
  };

  const getSampleData = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    
    const todayString = today.toISOString().split('T')[0];
    const tomorrowString = tomorrow.toISOString().split('T')[0];
    
    return {
      [todayString]: {
        process: "도배공사",
        dates: [
          {
            start: todayString,
            end: new Date(today.setDate(today.getDate() + 2)).toISOString().split('T')[0]
          }
        ],
        recipients: [
          {
            name: "김도배",
            phone: "010-1234-5678"
          },
          {
            name: "이도배",
            phone: "010-8765-4321"
          }
        ],
        messages: [
          "안녕하세요. 도배공사 일정이 변경되었습니다.",
          `새로운 일정: ${formatDate(todayString)} ~ ${formatDate(new Date(today.setDate(today.getDate() + 2)).toISOString().split('T')[0])}`,
          "현장 주소: 서울시 강남구 테스트로 123",
          "준비물: 도배지, 풀, 도배도구"
        ],
        date: todayString,
        status: "pending"
      },
      [tomorrowString]: {
        process: "타일공사",
        dates: [
          {
            start: tomorrowString,
            end: new Date(tomorrow.setDate(tomorrow.getDate() + 2)).toISOString().split('T')[0]
          }
        ],
        recipients: [
          {
            name: "박타일",
            phone: "010-9876-5432"
          }
        ],
        messages: [
          "안녕하세요. 타일공사 일정이 확정되었습니다.",
          `새로운 일정: ${formatDate(tomorrowString)} ~ ${formatDate(new Date(tomorrow.setDate(tomorrow.getDate() + 2)).toISOString().split('T')[0])}`,
          "현장 주소: 서울시 강남구 테스트로 123",
          "준비물: 타일, 시멘트, 타일공구"
        ],
        date: tomorrowString,
        status: "pending"
      }
    };
  };

  /**
   * 클로드가 확인해야 할 데이터 구조 설명
   * 
   * 1. Firestore 컬렉션 구조
   *    - scheduleMessages: 메시지 데이터 저장
   *    - contractors: 작업자 정보 (이름, 전화번호)
   *    - schedules: 공정 일정 정보
   * 
   * 2. scheduleMessages 컬렉션 문서 구조
   *    {
   *      process: string,        // 공정명 (예: "도배공사", "타일공사")
   *      dates: [{              // 공정 일정
   *        start: string,       // 시작일 (YYYY-MM-DD)
   *        end: string         // 종료일 (YYYY-MM-DD)
   *      }],
   *      recipients: [{         // 수신자 목록
   *        name: string,       // 작업자 이름
   *        phone: string      // 작업자 전화번호
   *      }],
   *      messages: string[],   // 메시지 내용 배열
   *      date: string,        // 메시지 날짜 (YYYY-MM-DD)
   *      status: string       // 메시지 상태 ("pending", "sent", "cancelled")
   *    }
   * 
   * 3. 데이터 연동 방법
   *    - contractors 컬렉션에서 작업자 정보 조회
   *    - schedules 컬렉션에서 공정 일정 조회
   *    - 두 컬렉션의 데이터를 조합하여 scheduleMessages 문서 생성
   * 
   * 4. 메시지 생성 규칙
   *    - 공정 시작일 3일 전에 메시지 생성
   *    - 메시지 상태는 초기에 "pending"으로 설정
   *    - 메시지 전송 후 상태를 "sent"로 업데이트
   *    - 일정 변경 시 "cancelled" 상태로 변경 후 새 메시지 생성
   */

  const fetchMessages = async () => {
    try {
      setLoading(true);
      const today = new Date();
      const todayString = today.toISOString().split('T')[0];
      
      const messagesRef = collection(db, 'scheduleMessages');
      const q = query(
        messagesRef,
        where('date', '>=', todayString)
      );
      
      const querySnapshot = await getDocs(q);
      const messages = [];
      
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        messages.push({
          process: data.process,
          dates: data.dates,
          recipients: data.recipients,
          messages: data.messages,
          date: data.date
        });
      });
      
      // 날짜별로 정렬
      messages.sort((a, b) => new Date(a.date) - new Date(b.date));

      setMessageData({
        processMessages: messages,
        isDateChangeRequest: false
      });
    } catch (err) {
      setError('메시지 데이터를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const addSampleData = async () => {
    try {
      setIsAddingSample(true);
      const messagesRef = collection(db, 'scheduleMessages');
      const sampleData = getSampleData();
      
      for (const [date, data] of Object.entries(sampleData)) {
        await addDoc(messagesRef, data);
      }

      await fetchMessages();
      alert('샘플 데이터가 추가되었습니다.');
    } catch (err) {
      setError('샘플 데이터 추가에 실패했습니다.');
    } finally {
      setIsAddingSample(false);
    }
  };

  const deleteSampleData = async () => {
    try {
      setIsAddingSample(true);
      const messagesRef = collection(db, 'scheduleMessages');
      const q = query(messagesRef);
      const querySnapshot = await getDocs(q);
      
      for (const doc of querySnapshot.docs) {
        await deleteDoc(doc.ref);
      }

      await fetchMessages();
      alert('샘플 데이터가 삭제되었습니다.');
    } catch (err) {
      setError('샘플 데이터 삭제에 실패했습니다.');
    } finally {
      setIsAddingSample(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}월 ${date.getDate()}일`;
  };

  if (loading) {
    return (
      <div className="message-preview">
        <div className="message-preview__header">
          <h2>메시지 미리보기</h2>
        </div>
        <div className="message-preview__loading">
          데이터를 불러오는 중...
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="message-preview">
        <div className="message-preview__header">
          <h2>메시지 미리보기</h2>
        </div>
        <div className="message-preview__error">
          {error}
        </div>
      </div>
    );
  }

  // 날짜별로 메시지 그룹화
  const groupedMessages = messageData.processMessages.reduce((acc, msg) => {
    const date = msg.date;
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(msg);
    return acc;
  }, {});
  
  return (
    <div className="message-preview">
      <div className="message-preview__header" onClick={handleHeaderClick}>
        <h2>메시지 미리보기</h2>
      </div>
      
      <div className="message-preview__content">
        {clickCount >= 4 && (
          <div className="message-preview__actions">
            <button 
              className="sample-button add"
              onClick={addSampleData}
              disabled={isAddingSample}
            >
              샘플 데이터 추가
            </button>
            <button 
              className="sample-button delete"
              onClick={deleteSampleData}
              disabled={isAddingSample}
            >
              샘플 데이터 삭제
            </button>
          </div>
        )}

        {/* 날짜별 메시지 목록 */}
        <div className="message-preview__messages">
          {Object.keys(groupedMessages).length === 0 ? (
            <div className="message-preview__empty">
              오늘 예정된 메시지가 없습니다.
            </div>
          ) : (
            Object.entries(groupedMessages).map(([date, messages]) => (
              <div key={date} className="date-group">
                <div className="date-header">
                  <h4>{formatDate(date)}</h4>
                </div>
                {messages.map((processMsg, index) => (
                  <div key={index} className="process-message-card">
                    <div className="process-header">
                      <h4>{processMsg.process}</h4>
                      <span className="date-range">
                        {processMsg.dates.map(date => 
                          `${date.start} ~ ${date.end}`
                        ).join(', ')}
                      </span>
                    </div>
                    
                    <div className="recipients-list">
                      <h5>수신자 목록</h5>
                      {processMsg.recipients.map((recipient, idx) => (
                        <div key={idx} className="recipient-item">
                          <span>{recipient.name}</span>
                          <span>{recipient.phone}</span>
                        </div>
                      ))}
                    </div>

                    <div className="message-preview-box">
                      <h5>메시지 내용</h5>
                      {processMsg.messages.map((msg, idx) => (
                        <pre key={idx} className="message-content">
                          {msg}
                        </pre>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))
          )}
        </div>

        {/* 전송 버튼 */}
        <div className="message-preview__actions">
          <button 
            className="send-button"
            onClick={() => {
              const deepLinkUrl = `amare://message?data=${encodeURIComponent(
                JSON.stringify({
                  ...messageData,
                  openMessageTab: true
                })
              )}`;
              window.location.href = deepLinkUrl;
            }}
          >
            안드로이드 앱으로 전송
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessagePreview; 