import React from 'react';
import './AISuggestButton.css';

const AISuggestButton = ({ onClick, processId, processName }) => {
  return (
    <button 
      className="ai-suggest-button"
      onClick={() => onClick(processId)}
      title="추천 템플릿 적용"
    >
      ⭐
    </button>
  );
};

export default AISuggestButton; 