import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, query, orderBy, doc, setDoc, deleteDoc, updateDoc, where, getDoc, writeBatch } from 'firebase/firestore';
import './InteriorMaterialSelection.css';

const InteriorMaterialManagementModal = ({ isOpen, onClose, address }) => {
  const [allMaterials, setAllMaterials] = useState([]);
  const [editedMaterials, setEditedMaterials] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchAllMaterials = async () => {
      try {
        const materialsRef = collection(db, 'materials');
        const q = address 
          ? query(materialsRef, where('address', '==', address))
          : query(materialsRef);
        const snapshot = await getDocs(q);
        const materialsList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        const sortedMaterials = materialsList.sort((a, b) => {
          if (a.process < b.process) return -1;
          if (a.process > b.process) return 1;
          if (a.materialType < b.materialType) return -1;
          if (a.materialType > b.materialType) return 1;
          return 0;
        });

        setAllMaterials(sortedMaterials);
        setEditedMaterials({});
        setIsEditing(false);
      } catch (error) {
        console.error('전체 자재 정보 불러오기 실패:', error);
      }
    };

    if (isOpen) {
      fetchAllMaterials();
    }
  }, [isOpen, address]);

  const handleEdit = (materialId, field, value) => {
    setEditedMaterials(prev => ({
      ...prev,
      [materialId]: {
        ...prev[materialId],
        [field]: value
      }
    }));
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const batch = writeBatch(db);
      
      for (const [materialId, changes] of Object.entries(editedMaterials)) {
        if (changes) {
          const materialRef = doc(db, 'materials', materialId);
          batch.update(materialRef, changes);
        }
      }

      await batch.commit();

      // 변경사항 적용 후 목록 새로고침
      const updatedMaterials = allMaterials.map(material => {
        if (editedMaterials[material.id]) {
          return {
            ...material,
            ...editedMaterials[material.id]
          };
        }
        return material;
      });

      setAllMaterials(updatedMaterials);
      setEditedMaterials({});
      setIsEditing(false);
      alert('저장이 완료되었습니다.');
    } catch (error) {
      console.error('자재 정보 저장 실패:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  const handleCancel = () => {
    setEditedMaterials({});
    setIsEditing(false);
  };

  const handleDeleteMaterial = async (id) => {
    try {
      await deleteDoc(doc(db, 'materials', id));
      setAllMaterials(allMaterials.filter(material => material.id !== id));
    } catch (error) {
      console.error('자재 삭제 실패:', error);
    }
  };

  return (
    <div className={`interior-material-modal ${isOpen ? 'open' : ''}`} onClick={onClose}>
      <div className="interior-material-modal-content" onClick={e => e.stopPropagation()}>
        <h2>{address ? `${address} 자재 목록` : '전체 자재 목록'}</h2>
        <table className="interior-material-table">
          <thead>
            <tr>
              <th>공정</th>
              <th>자재종류</th>
              <th>자재명</th>
              <th>작업된위치</th>
              <th>현장주소</th>
            </tr>
          </thead>
          <tbody>
            {allMaterials.map((material) => (
              <tr key={material.id}>
                <td>{material.process || ''}</td>
                <td>
                  <input
                    type="text"
                    value={(editedMaterials[material.id]?.materialType !== undefined 
                      ? editedMaterials[material.id].materialType 
                      : material.materialType) || ''}
                    onChange={(e) => handleEdit(material.id, 'materialType', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={(editedMaterials[material.id]?.materialName !== undefined 
                      ? editedMaterials[material.id].materialName 
                      : material.materialName) || ''}
                    onChange={(e) => handleEdit(material.id, 'materialName', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={(editedMaterials[material.id]?.location !== undefined 
                      ? editedMaterials[material.id].location 
                      : material.location) || ''}
                    onChange={(e) => handleEdit(material.id, 'location', e.target.value)}
                  />
                </td>
                <td>{material.address || ''}</td>
                <td className="action-buttons">
                  <button 
                    className="interior-material-delete-btn"
                    onClick={() => handleDeleteMaterial(material.id)}
                  >
                    삭제
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="interior-modal-buttons">
          {isEditing && (
            <button className="interior-material-save-btn" onClick={handleSave}>저장</button>
          )}
          <button className="interior-material-close-btn" onClick={onClose}>닫기</button>
        </div>
      </div>
    </div>
  );
};

const InteriorMaterialModal = ({ isOpen, onClose, process, address }) => {
  const [materials, setMaterials] = useState([]);
  const [newLocation, setNewLocation] = useState('');
  const [newMaterialType, setNewMaterialType] = useState('');
  const [newMaterialName, setNewMaterialName] = useState('');
  const [materialTypes, setMaterialTypes] = useState({});
  const [editedMaterials, setEditedMaterials] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [existingMaterials, setExistingMaterials] = useState([]);
  const [showExistingMaterials, setShowExistingMaterials] = useState(false);

  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        if (!address || !process) return;
        
        const materialsRef = collection(db, 'materials');
        const q = query(materialsRef, 
          where('address', '==', address), 
          where('process', '==', process)
        );
        const snapshot = await getDocs(q);
        const materialsList = snapshot.docs.map(doc => ({ 
          id: doc.id, 
          ...doc.data() 
        }));
        setMaterials(materialsList);
        setEditedMaterials({});
        setIsEditing(false);

        // 자재 종류별로 자재명을 그룹화
        const types = {};
        materialsList.forEach(material => {
          if (material.materialType) {
            if (!types[material.materialType]) {
              types[material.materialType] = new Set();
            }
            if (material.materialName) {
              material.materialName.split(',').forEach(name => {
                types[material.materialType].add(name.trim());
              });
            }
          }
        });
        setMaterialTypes(types);
      } catch (error) {
        console.error('자재 정보 불러오기 실패:', error);
      }
    };

    const fetchExistingMaterials = async () => {
      try {
        if (!process) return;
        
        const materialsRef = collection(db, 'materials');
        const q = query(materialsRef, where('process', '==', process));
        const snapshot = await getDocs(q);
        const existingList = snapshot.docs.map(doc => ({ 
          id: doc.id, 
          ...doc.data() 
        }));
        
        // 현재 주소의 자재는 제외
        const filteredList = existingList.filter(material => material.address !== address);
        
        // 자재 종류별로 그룹화
        const groupedMaterials = filteredList.reduce((acc, material) => {
          const key = material.materialType || '미분류';
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(material);
          return acc;
        }, {});
        
        setExistingMaterials(groupedMaterials);
      } catch (error) {
        console.error('기존 자재 정보 불러오기 실패:', error);
      }
    };

    if (isOpen) {
      fetchMaterials();
      fetchExistingMaterials();
    }
  }, [isOpen, address, process]);

  const handleAddMaterial = async () => {
    if (!newLocation.trim() || !newMaterialType.trim()) return;

    try {
      const newMaterial = {
        address,
        process,
        location: newLocation,
        materialType: newMaterialType,
        materialName: newMaterialName,
        createdAt: new Date()
      };

      const docRef = doc(collection(db, 'materials'));
      await setDoc(docRef, newMaterial);

      setMaterials([...materials, { id: docRef.id, ...newMaterial }]);
      setNewLocation('');
      setNewMaterialType('');
      setNewMaterialName('');
    } catch (error) {
      console.error('자재 추가 실패:', error);
    }
  };

  const handleExistingMaterialSelect = async (material) => {
    try {
      const { id, ...materialWithoutId } = material;  // id를 제외한 나머지 속성만 추출
      const newMaterial = {
        ...materialWithoutId,
        address,
        createdAt: new Date()
      };

      const docRef = doc(collection(db, 'materials'));
      await setDoc(docRef, newMaterial);

      setMaterials([...materials, { id: docRef.id, ...newMaterial }]);
      setShowExistingMaterials(false);
    } catch (error) {
      console.error('기존 자재 추가 실패:', error);
    }
  };

  const handleEdit = (materialId, field, value) => {
    setEditedMaterials(prev => ({
      ...prev,
      [materialId]: {
        ...prev[materialId],
        [field]: value
      }
    }));
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const batch = writeBatch(db);
      
      for (const [materialId, changes] of Object.entries(editedMaterials)) {
        if (changes) {
          const materialRef = doc(db, 'materials', materialId);
          batch.update(materialRef, changes);
        }
      }

      await batch.commit();

      // 변경사항 적용 후 목록 새로고침
      const updatedMaterials = materials.map(material => {
        if (editedMaterials[material.id]) {
          return {
            ...material,
            ...editedMaterials[material.id]
          };
        }
        return material;
      });

      setMaterials(updatedMaterials);
      setEditedMaterials({});
      setIsEditing(false);
      alert('저장이 완료되었습니다.');
    } catch (error) {
      console.error('자재 정보 저장 실패:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  const handleCancel = () => {
    setEditedMaterials({});
    setIsEditing(false);
  };

  const handleDeleteMaterial = async (id) => {
    try {
      await deleteDoc(doc(db, 'materials', id));
      setMaterials(materials.filter(material => material.id !== id));
    } catch (error) {
      console.error('자재 삭제 실패:', error);
    }
  };

  const handleClose = () => {
    setNewLocation('');
    setNewMaterialType('');
    setNewMaterialName('');
    setEditedMaterials({});
    setIsEditing(false);
    setShowExistingMaterials(false);
    onClose();
  };

  return (
    <div className={`interior-material-modal ${isOpen ? 'open' : ''}`} onClick={handleClose}>
      <div className="interior-material-modal-content" onClick={e => e.stopPropagation()}>
        <h2>{process} 자재 선택사항</h2>
        
        <div className="interior-material-buttons">
          <button 
            className="interior-material-toggle-btn"
            onClick={() => setShowExistingMaterials(!showExistingMaterials)}
          >
            {showExistingMaterials ? '새 자재 입력' : '기존 자재 선택'}
          </button>
        </div>

        {!showExistingMaterials ? (
          <div className="interior-material-input-group">
            <input
              type="text"
              value={newLocation}
              onChange={(e) => setNewLocation(e.target.value)}
              placeholder="위치 입력"
            />
            <input
              type="text"
              value={newMaterialType}
              onChange={(e) => setNewMaterialType(e.target.value)}
              placeholder="자재종류 입력"
            />
            <input
              type="text"
              value={newMaterialName}
              onChange={(e) => setNewMaterialName(e.target.value)}
              placeholder="자재명 입력"
            />
            <button onClick={handleAddMaterial}>추가</button>
          </div>
        ) : (
          <div className="existing-materials-container">
            {Object.entries(existingMaterials).map(([type, materials]) => (
              <div key={type} className="material-type-group">
                <h3>{type}</h3>
                {materials.map(material => (
                  <div key={material.id} className="existing-material-item">
                    <div className="material-info">
                      <span className="material-location">{material.location}</span>
                      <span className="material-name">{material.materialName}</span>
                      <span className="material-address">{material.address}</span>
                    </div>
                    <button 
                      onClick={() => handleExistingMaterialSelect(material)}
                      className="select-material-btn"
                    >
                      선택
                    </button>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}

        <table className="interior-material-table">
          <thead>
            <tr>
              <th>위치</th>
              <th>자재종류</th>
              <th>자재명</th>
              <th>작업</th>
            </tr>
          </thead>
          <tbody>
            {materials.map((material) => (
              <tr key={material.id}>
                <td>
                  <input
                    type="text"
                    value={(editedMaterials[material.id]?.location !== undefined 
                      ? editedMaterials[material.id].location 
                      : material.location) || ''}
                    onChange={(e) => handleEdit(material.id, 'location', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={(editedMaterials[material.id]?.materialType !== undefined 
                      ? editedMaterials[material.id].materialType 
                      : material.materialType) || ''}
                    onChange={(e) => handleEdit(material.id, 'materialType', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={(editedMaterials[material.id]?.materialName !== undefined 
                      ? editedMaterials[material.id].materialName 
                      : material.materialName) || ''}
                    onChange={(e) => handleEdit(material.id, 'materialName', e.target.value)}
                  />
                </td>
                <td className="action-buttons">
                  <button 
                    className="interior-material-delete-btn"
                    onClick={() => handleDeleteMaterial(material.id)}
                  >
                    삭제
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="interior-modal-buttons">
          {isEditing && (
            <button className="interior-material-save-btn" onClick={handleSave}>저장</button>
          )}
          <button className="interior-material-close-btn" onClick={handleClose}>닫기</button>
        </div>
      </div>
    </div>
  );
};

const InteriorMaterialSelection = () => {
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [processes, setProcesses] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isManagementModalOpen, setIsManagementModalOpen] = useState(false);
  const [disabledProcesses, setDisabledProcesses] = useState(new Set());
  const [showActiveOnly, setShowActiveOnly] = useState(false);
  const clickRef = useRef({
    count: 0,
    timer: null,
    lastProcess: null
  });

  useEffect(() => {
    fetchAddresses();
    fetchProcesses();
    fetchDisabledProcesses();
    fetchShowActiveOnlyState();
  }, []);

  // 비활성화된 공정 목록 불러오기
  const fetchDisabledProcesses = async () => {
    try {
      const disabledProcessesRef = doc(db, 'settings', 'disabledProcesses');
      const snapshot = await getDoc(disabledProcessesRef);
      if (snapshot.exists()) {
        const data = snapshot.data();
        setDisabledProcesses(new Set(data.processes || []));
      }
    } catch (error) {
      console.error('비활성화된 공정 목록 불러오기 실패:', error);
    }
  };

  // 비활성화된 공정 목록 저장
  const saveDisabledProcesses = async (processes) => {
    try {
      const disabledProcessesRef = doc(db, 'settings', 'disabledProcesses');
      await setDoc(disabledProcessesRef, {
        processes: Array.from(processes),
        updatedAt: new Date()
      });
    } catch (error) {
      console.error('비활성화된 공정 목록 저장 실패:', error);
    }
  };

  // 활성화 공정 보기 상태 불러오기
  const fetchShowActiveOnlyState = async () => {
    try {
      const settingsRef = doc(db, 'settings', 'viewSettings');
      const snapshot = await getDoc(settingsRef);
      if (snapshot.exists()) {
        const data = snapshot.data();
        setShowActiveOnly(data.showActiveOnly || false);
      }
    } catch (error) {
      console.error('활성화 공정 보기 상태 불러오기 실패:', error);
    }
  };

  // 활성화 공정 보기 상태 저장
  const saveShowActiveOnlyState = async (state) => {
    try {
      const settingsRef = doc(db, 'settings', 'viewSettings');
      await setDoc(settingsRef, {
        showActiveOnly: state,
        updatedAt: new Date()
      }, { merge: true });
    } catch (error) {
      console.error('활성화 공정 보기 상태 저장 실패:', error);
    }
  };

  const fetchAddresses = async () => {
    try {
      const addressesCollection = collection(db, 'addresses');
      const q = query(addressesCollection, orderBy('siteNumber', 'desc'));
      const snapshot = await getDocs(q);
      const addressList = snapshot.docs.map(doc => ({
        id: doc.id,
        description: doc.data().description,
        siteNumber: doc.data().siteNumber || 0
      }));
      console.log('주소 목록:', addressList);
      setAddresses(addressList);
    } catch (error) {
      console.error('주소 목록 불러오기 실패:', error);
    }
  };

  const fetchProcesses = async () => {
    try {
      const processOrderRef = doc(db, 'settings', 'processOrder');
      const snapshot = await getDoc(processOrderRef);
      if (snapshot.exists()) {
        const processData = snapshot.data();
        console.log('공정 목록:', processData.order);
        setProcesses(processData.order || []);
      }
    } catch (error) {
      console.error('공정 목록 불러오기 실패:', error);
    }
  };

  const handleProcessInteraction = (process) => {
    const { current: click } = clickRef;

    if (click.lastProcess !== process) {
      click.count = 0;
      if (click.timer) clearTimeout(click.timer);
    }
    
    click.lastProcess = process;
    click.count += 1;

    if (click.timer) {
      clearTimeout(click.timer);
    }

    click.timer = setTimeout(() => {
      if (click.count === 1) {
        // 단일 클릭
        if (!disabledProcesses.has(process)) {
          setSelectedProcess(process);
          setIsModalOpen(true);
        }
      } else if (click.count === 2) {
        // 더블 클릭
        setDisabledProcesses(prev => {
          const newDisabled = new Set(prev);
          if (newDisabled.has(process)) {
            newDisabled.delete(process);
          } else {
            newDisabled.add(process);
          }
          // Firebase에 저장
          saveDisabledProcesses(newDisabled);
          return newDisabled;
        });
      }
      click.count = 0;
      click.lastProcess = null;
    }, 200);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedProcess('');
  };

  const handleMaterialManagement = () => {
    setIsManagementModalOpen(true);
  };

  const filteredProcesses = showActiveOnly
    ? processes.filter(process => !disabledProcesses.has(process))
    : processes;

  return (
    <div className="interior-material-selection-container">
      <div className="header-section">
        <h1>자재 선택사항</h1>
        <div className="interior-material-management-container">
          <button 
            className="interior-material-management-button" 
            onClick={handleMaterialManagement}
          >
            자재 관리
          </button>
          <button 
            className={`interior-toggle-active-button ${showActiveOnly ? 'active' : ''}`}
            onClick={() => {
              const newState = !showActiveOnly;
              setShowActiveOnly(newState);
              saveShowActiveOnlyState(newState);
            }}
          >
            {showActiveOnly ? '전체 공정 보기' : '활성화 공정만 보기'}
          </button>
        </div>
      </div>
      
      <div className="interior-address-selection">
        <select
          value={selectedAddress}
          onChange={(e) => setSelectedAddress(e.target.value)}
        >
          <option value="">현장 주소를 선택하세요</option>
          {addresses.map((address) => (
            <option key={address.id} value={address.description}>
              {address.description}
            </option>
          ))}
        </select>
      </div>

      {selectedAddress && (
        <div className="interior-process-list">
          <h2>공정 목록</h2>
          <div className="interior-process-grid">
            {filteredProcesses.map((process, index) => (
              <div
                key={index}
                className={`interior-process-item ${disabledProcesses.has(process) ? 'disabled' : ''}`}
                onClick={() => handleProcessInteraction(process)}
              >
                {process}
              </div>
            ))}
          </div>
        </div>
      )}

      <InteriorMaterialModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        process={selectedProcess}
        address={selectedAddress}
      />

      <InteriorMaterialManagementModal
        isOpen={isManagementModalOpen}
        onClose={() => setIsManagementModalOpen(false)}
        address={selectedAddress}
      />
    </div>
  );
};

export default InteriorMaterialSelection; 