import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/ko';  // 한국어 로케일 추가
import './PaymentHistory.css';
import OngoingAddressesToggle from './OngoingAddressesToggle';
import { db } from '../firebase';
import { setDoc, doc, serverTimestamp, getDoc, updateDoc, deleteField } from 'firebase/firestore';

const formatNumber = (num) => {
  if (num === null || num === undefined) return '';
  const numStr = num.toString().replace(/[^\d]/g, '');
  return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// 확인 메시지 컴포넌트
const ConfirmMessage = ({ message }) => {
  return (
    <div className="PaymentHistory-confirm-message">
      <p>{message}</p>
    </div>
  );
};

// ConfirmModal 컴포넌트 수정
const ConfirmModal = ({ message, onConfirm, onCancel, showCancelButton }) => {
  useEffect(() => {
    // 모달이 열릴 때 스크롤 방지
    document.body.style.overflow = 'hidden';
    
    // ESC 키로 모달 닫기
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onCancel();
      } else if (e.key === 'Enter') {
        onConfirm();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    
    // 클린업 함수
    return () => {
      document.body.style.overflow = 'unset';
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onConfirm, onCancel]);

  // 모달 외부 클릭 시 닫기
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onCancel();
    }
  };

  return (
    <div className="PaymentHistory-modal-overlay" onClick={handleOverlayClick}>
      <div className="PaymentHistory-modal-content">
        <p>{message}</p>
        <div className="PaymentHistory-modal-buttons">
          <button onClick={onConfirm}>확인</button>
          {showCancelButton && <button onClick={onCancel}>취소</button>}
        </div>
      </div>
    </div>
  );
};

const PaymentHistoryItem = ({ onDelete, index, onSave, showMessage, initialData = {}, totalAmount, isAdmin, onUpdateTotalAmount }) => {
  const [dateInput, setDateInput] = useState(initialData.date ? 
    initialData.date.replace(/(\d{2})년\s(\d{2})월\s(\d{2})일/, '$1.$2.$3') : '');
  const [amount, setAmount] = useState(formatNumber(initialData.amount) || '');
  const [isPaymentComplete, setIsPaymentComplete] = useState(initialData.isPaymentComplete || false);
  const [isTaxBillIssued, setIsTaxBillIssued] = useState(initialData.isTaxBillIssued || false);
  const [isCashReceipt, setIsCashReceipt] = useState(initialData.isCashReceipt || false);
  const [selectedBank, setSelectedBank] = useState(initialData.selectedBank || '');

  const handleDateChange = (e) => {
    const value = e.target.value;
    setDateInput(value);
  };

  const handleAmountChange = (e) => {
    const formattedValue = formatNumber(e.target.value);
    setAmount(formattedValue);
  };

  const handleSave = () => {
    // 날짜 형식을 YY.MM.DD로 입력받아 YY년 MM월 DD일 형식으로 변환
    let formattedDate = '';
    if (dateInput) {
      const dateMatch = dateInput.match(/^(\d{2})\.(\d{1,2})\.(\d{1,2})$/);
      if (dateMatch) {
        const [_, year, month, day] = dateMatch;
        formattedDate = `${year}년 ${month.padStart(2, '0')}월 ${day.padStart(2, '0')}일`;
      } else {
        formattedDate = dateInput; // 형식이 맞지 않으면 그대로 저장
      }
    }

    const paymentData = {
      date: formattedDate,
      amount: amount.replace(/,/g, ''),
      isPaymentComplete,
      isTaxBillIssued,
      isCashReceipt,
      selectedBank
    };
    onSave(paymentData, index);
  };

  const handleCopy = () => {
    const formattedAmount = formatNumber(amount);
    const formattedTotalAmount = formatNumber(totalAmount.toString());
    
    // 날짜가 YY.MM.DD 형식이면 YY년 MM월 DD일 형식으로 변환
    let displayDate = dateInput;
    const dateMatch = dateInput.match(/^(\d{2})\.(\d{1,2})\.(\d{1,2})$/);
    if (dateMatch) {
      const [_, year, month, day] = dateMatch;
      displayDate = `${year}년 ${month.padStart(2, '0')}월 ${day.padStart(2, '0')}일`;
    }
    
    const copyText = `${displayDate} 아마레디자인에 ${formattedAmount}원 결제 해주셨습니다. 현재 ${formattedTotalAmount}원 결제 해주셨습니다. 감사합니다.`;
    
    navigator.clipboard.writeText(copyText).then(() => {
      showMessage('복사되었습니다.');
    }, function(err) {
      showMessage('복사 중 오류가 발생했습니다.');
    });
  };

  const handlePaymentCompleteChange = (e) => {
    const newIsPaymentComplete = e.target.checked;
    setIsPaymentComplete(newIsPaymentComplete);
  };

  const handleTaxBillChange = (e) => {
    const newIsTaxBillIssued = e.target.checked;
    setIsTaxBillIssued(newIsTaxBillIssued);
  };

  const handleCashReceiptChange = (e) => {
    const newIsCashReceipt = e.target.checked;
    setIsCashReceipt(newIsCashReceipt);
  };

  const handleBankChange = (bank) => {
    setSelectedBank(bank);
  };

  return (
    <div className="PaymentHistory-item-container">
      <div className="PaymentHistory-payment-item-row">
        <div className="PaymentHistory-flex-center PaymentHistory-w-full PaymentHistory-gap-2">
          <div className="PaymentHistory-input-group">
            <input
              type="text"
              value={dateInput}
              onChange={handleDateChange}
              className="PaymentHistory-date-input"
              placeholder="YY.MM.DD"
              readOnly={!isAdmin}
            />
            <input
              type="text"
              value={amount}
              onChange={handleAmountChange}
              className="PaymentHistory-amount-input"
              placeholder="0"
              readOnly={!isAdmin}
            />
          </div>
        </div>
      </div>
      
      <div className="PaymentHistory-payment-item-row">
        <div className="PaymentHistory-flex-center PaymentHistory-w-full">
          <div className="PaymentHistory-checkbox-group">
            <label className="PaymentHistory-checkbox-label">
              <input
                type="checkbox"
                checked={isPaymentComplete}
                onChange={handlePaymentCompleteChange}
                disabled={!isAdmin}
              />
              <span>결제 완료</span>
            </label>
            <label className="PaymentHistory-checkbox-label">
              <input
                type="checkbox"
                checked={isTaxBillIssued}
                onChange={handleTaxBillChange}
                disabled={!isAdmin}
              />
              <span>세금계산서</span>
            </label>
            <label className="PaymentHistory-checkbox-label">
              <input
                type="checkbox"
                checked={isCashReceipt}
                onChange={handleCashReceiptChange}
                disabled={!isAdmin}
              />
              <span>현금영수증</span>
            </label>
          </div>
        </div>
      </div>

      <div className="PaymentHistory-payment-item-row">
        <div className="PaymentHistory-flex-center PaymentHistory-w-full">
          <div className="PaymentHistory-bank-radio-container">
            <div className="PaymentHistory-bank-checkbox-group">
              <label className="PaymentHistory-bank-checkbox-label">
                <input
                  type="radio"
                  name={`bank-${index}`}
                  checked={selectedBank === '진동형'}
                  onChange={() => handleBankChange('진동형')}
                  disabled={!isAdmin}
                />
                <span>진동형 통장</span>
              </label>
              <label className="PaymentHistory-bank-checkbox-label">
                <input
                  type="radio"
                  name={`bank-${index}`}
                  checked={selectedBank === '권경대'}
                  onChange={() => handleBankChange('권경대')}
                  disabled={!isAdmin}
                />
                <span>권경대 통장</span>
              </label>
              <label className="PaymentHistory-bank-checkbox-label">
                <input
                  type="radio"
                  name={`bank-${index}`}
                  checked={selectedBank === '사업자'}
                  onChange={() => handleBankChange('사업자')}
                  disabled={!isAdmin}
                />
                <span>사업자 통장</span>
              </label>
            </div>
            <div className="PaymentHistory-bank-checkbox-group">
              <label className="PaymentHistory-bank-checkbox-label">
                <input
                  type="radio"
                  name={`bank-${index}`}
                  checked={selectedBank === '현금결제'}
                  onChange={() => handleBankChange('현금결제')}
                  disabled={!isAdmin}
                />
                <span>현금결제</span>
              </label>
              <label className="PaymentHistory-bank-checkbox-label">
                <input
                  type="radio"
                  name={`bank-${index}`}
                  checked={selectedBank === '업체바로결제'}
                  onChange={() => handleBankChange('업체바로결제')}
                  disabled={!isAdmin}
                />
                <span>업체 바로결제</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      {isAdmin && (
        <div className="PaymentHistory-payment-item-row">
          <div className="PaymentHistory-flex-center PaymentHistory-w-full PaymentHistory-gap-2">
            <div className="PaymentHistory-button-group">
              <button onClick={handleSave} className="PaymentHistory-action-button">저장</button>
              <button onClick={() => onDelete(index)} className="PaymentHistory-action-button">삭제</button>
              <button onClick={handleCopy} className="PaymentHistory-action-button">Copy</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const calculateAmounts = (items) => {
  return items.reduce((acc, item) => {
    if (item.isPaymentComplete) {
      const amount = parseInt(item.amount.replace(/,/g, '')) || 0;
      acc.total += amount;
      
      if (item.isTaxBillIssued) {
        acc.taxBill += amount;
      }
      
      if (item.isCashReceipt) {
        acc.cashReceipt += amount;
      }
    }
    return acc;
  }, { total: 0, taxBill: 0, cashReceipt: 0 });
};

const PaymentHistory = () => {
  const navigate = useNavigate();
  const [paymentItems, setPaymentItems] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  useEffect(() => {
    // 세션 스토리지에서 관리자 상태 확인
    const adminStatus = sessionStorage.getItem('isAdmin');
    setIsAdmin(adminStatus === 'true');

    // 컴포넌트가 언마운트될 때 관리자 모드 해제
    return () => {
      sessionStorage.removeItem('isAdmin');
    };
  }, []);

  const loadPaymentData = async (addressId) => {
    if (!addressId) {
      setPaymentItems([]);
      setTotalAmount(0);
      return;
    }

    try {
      const paymentDocRef = doc(db, 'payments', addressId);
      const paymentDocSnap = await getDoc(paymentDocRef);
      
      if (paymentDocSnap.exists()) {
        const paymentData = paymentDocSnap.data();
        setPaymentItems(paymentData.items || [{}]);
        
        // 총 결제 금액 계산 (결제 완료된 항목만)
        const amounts = calculateAmounts(paymentData.items || []);
        setTotalAmount(amounts.total);
      } else {
        setPaymentItems([{}]);
        setTotalAmount(0);
      }
    } catch (error) {
      setPaymentItems([]);
      setTotalAmount(0);
    }
  };

  const handleAddItem = async () => {
    if (!selectedAddressId) {
      showMessage('현장을 선택해세요.');
      return;
    }

    const newItem = {
      date: '',
      amount: '',
      isPaymentComplete: false,
      isTaxBillIssued: false,
      isCashReceipt: false
    };

    try {
      const paymentDocRef = doc(db, 'payments', selectedAddressId);
      const paymentDocSnap = await getDoc(paymentDocRef);

      let newPaymentItems;
      if (paymentDocSnap.exists()) {
        const existingData = paymentDocSnap.data();
        newPaymentItems = existingData.items ? [...existingData.items, newItem] : [newItem];
      } else {
        newPaymentItems = [newItem];
      }

      await setDoc(paymentDocRef, {
        items: newPaymentItems,
        addressId: selectedAddressId,
        lastUpdated: serverTimestamp()
      }, { merge: true });

      setPaymentItems(newPaymentItems);
      showMessage('새 항목 추가되었습니다.');
    } catch (error) {
      showMessage('항목 추가 중 오류가 발생했습니다.');
    }
  };

  const handleDeleteItem = async (index) => {
    if (!selectedAddressId) {
      showMessage('현장을 선택해주세요.');
      return;
    }

    try {
      const paymentDocRef = doc(db, 'payments', selectedAddressId);
      const paymentDocSnap = await getDoc(paymentDocRef);

      if (paymentDocSnap.exists()) {
        const existingData = paymentDocSnap.data();
        const newPaymentItems = existingData.items ? [...existingData.items] : [];
        
        // 해당 인덱스의 항목 삭제
        newPaymentItems.splice(index, 1);

        // 데이터베이스 업데이트
        await updateDoc(paymentDocRef, {
          items: newPaymentItems,
          lastUpdated: serverTimestamp()
        });

        setPaymentItems(newPaymentItems);
        showMessage('항목이 삭제되었습니다.');

        // 총 결제 금액 다시 계산
        const newTotal = newPaymentItems.reduce((sum, item) => {
          const amount = parseInt(item.amount.replace(/,/g, '')) || 0;
          return sum + amount;
        }, 0);
        setTotalAmount(newTotal);
      } else {
        showMessage('삭제할 데이터가 없습니다.');
      }
    } catch (error) {
      showMessage('항목 삭제 중 오류가 발생했습니다.');
    }
  };

  const showMessage = (message) => {
    setConfirmMessage(message);
    setShowConfirmMessage(true);
    setTimeout(() => setShowConfirmMessage(false), 3000);
  };

  const handleSaveItem = async (paymentData, index) => {
    if (!selectedAddressId) {
      showMessage('현장을 선택해주세요.');
      return;
    }

    try {
      const paymentDocRef = doc(db, 'payments', selectedAddressId);
      const paymentDocSnap = await getDoc(paymentDocRef);

      let newPaymentItems;
      if (paymentDocSnap.exists()) {
        const existingData = paymentDocSnap.data();
        newPaymentItems = existingData.items ? [...existingData.items] : [];
      } else {
        newPaymentItems = [];
      }

      if (index >= newPaymentItems.length) {
        newPaymentItems.push(paymentData);
      } else {
        newPaymentItems[index] = paymentData;
      }

      await setDoc(paymentDocRef, {
        items: newPaymentItems,
        addressId: selectedAddressId,
        lastUpdated: serverTimestamp()
      }, { merge: true });

      showMessage('저장되었습니다.');
      setPaymentItems(newPaymentItems);

      // 총 결제 금액 다시 계산
      const amounts = calculateAmounts(newPaymentItems);
      setTotalAmount(amounts.total);
    } catch (error) {
      showMessage('저장 중 오류가 발생했습니다.');
    }
  };

  const handleSelectAddress = (addressId, paymentData) => {
    setSelectedAddressId(addressId);
    if (addressId) {
      loadPaymentData(addressId);
    } else {
      setPaymentItems([]);
      setTotalAmount(0);
    }
  };

  const handleAdminLogin = (status) => {
    setIsAdmin(status);
    sessionStorage.setItem('isAdmin', status.toString());
  };

  const handleUpdateTotalAmount = () => {
    const amounts = calculateAmounts(paymentItems);
    setTotalAmount(amounts.total);
  };

  const handleDeleteRequest = (index) => {
    setDeleteIndex(index);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (deleteIndex !== null) {
      await handleDeleteItem(deleteIndex);
      setShowDeleteModal(false);
      setDeleteIndex(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setDeleteIndex(null);
  };

  return (
    <div className="PaymentHistory-container">
      <div className="PaymentHistory-wrapper">
        <div className="header-space"></div>
        <OngoingAddressesToggle 
          onSelectAddress={handleSelectAddress}
          onAdminLogin={handleAdminLogin}
        />
        {selectedAddressId ? (
          <div className="payment-list">
            {isAdmin && (
              <>
                <div className="PaymentHistory-total-amount-text">
                  <p>현재 {formatNumber(totalAmount)}원을 결제받았습니다.</p>
                  <p>세금계산서(부가세포함) {formatNumber(calculateAmounts(paymentItems).taxBill)}원을 발급해주었습니다.</p>
                  <p>현금영수증 {formatNumber(calculateAmounts(paymentItems).cashReceipt)}원을 발급해주었습니다.</p>
                </div>
                <button onClick={handleAddItem} className="PaymentHistory-add-payment-button">
                  <span>+</span> 결제 추가
                </button>
              </>
            )}
            {paymentItems.map((item, index) => (
              <PaymentHistoryItem 
                key={index} 
                index={index}
                onDelete={handleDeleteRequest}
                onSave={handleSaveItem}
                showMessage={showMessage}
                initialData={item}
                totalAmount={totalAmount}
                isAdmin={isAdmin}
                onUpdateTotalAmount={handleUpdateTotalAmount}
              />
            ))}
          </div>
        ) : (
          <div className="PaymentHistory-no-address-selected">
            <p>현장을 선택해주세요.</p>
          </div>
        )}
      </div>
      {showDeleteModal && (
        <ConfirmModal
          message="삭제하시겠습니까?"
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          showCancelButton={true}
        />
      )}
      {showConfirmMessage && <ConfirmMessage message={confirmMessage} />}
    </div>
  );
};

export default PaymentHistory;
