// PDF 문서 컴포넌트가 들어갈 파일 
import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { styles, detailStyles } from '../../styles/pdf/pdfStyles';
import { filterProcessesForPDF } from './utils';


  // MyDocument 컴포넌트 수정
  const MyDocument = ({ 
    selectedAddress,
    processData,
    notes,
    hiddenProcesses,
    calculateTotal,
    getProcessName,
    formatNumber
  }) => {
    // 필터링된 프로세스 데이터 생성
    const filteredProcessesForPDF = filterProcessesForPDF(processData, hiddenProcesses);

    // 첫 페이지 (공정별 합계)
    const firstPage = (
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerTop}>
            <View style={styles.headerTitle}>
              <Text style={styles.title}>견적서</Text>
              <Text style={styles.subtitle}>Estimate</Text>
            </View>
            <Image src="images/logo.png" style={styles.logo} />
          </View>
          <View style={styles.headerBottom}>
            <View style={styles.infoSection}>
              <Text style={styles.infoLabel}>업체정보</Text>
              <Text style={styles.infoText}>Amare design, 아마레디자인</Text>
              <Text style={styles.infoText}>712.17.01551</Text>
              <Text style={styles.infoText}>수성구 들안로 215</Text>
              <Text style={styles.infoText}>010. 8694.4078</Text>
              <Text style={styles.infoText}>대표 권경대</Text>
            </View>
            <View style={styles.infoSection}>
              <Text style={styles.infoLabel}>주소</Text>
              <Text style={styles.infoText}>{selectedAddress || '주소를 선택해주세요'}</Text>
            </View>
            <View style={styles.totalSection}>
              <Text style={styles.infoLabel}>TOTAL</Text>
              <Text style={styles.totalAmount}>
                {formatNumber(calculateTotal(filteredProcessesForPDF))}원
              </Text>
              <Text style={styles.vatNotice}>(VAT 별도)</Text>
            </View>
          </View>
        </View>

        {/* 공정별 합계 테이블 수정 */}
        <View style={styles.summaryTable}>
          <View style={styles.tableHeader}>
            <View style={styles.processCol}>
              <Text style={styles.tableHeaderCell}>공정</Text>
            </View>
            <View style={styles.priceCol}>
              <Text style={styles.tableHeaderCell}>가격</Text>
            </View>
            <View style={styles.noteCol}>
              <Text style={styles.tableHeaderCell}>비고</Text>
            </View>
          </View>

          {filteredProcessesForPDF.map((process, index) => (
            <View key={index} style={styles.tableRow}>
              <View style={styles.dataProcessCol}>
                <Text style={[styles.tableCell, styles.tableCellProcess]}>
                  {getProcessName(process)}
                </Text>
              </View>
              <View style={styles.dataPriceCol}>
                <Text style={[styles.tableCell, styles.tableCellPrice]}>
                  {formatNumber(process.total)}원
                </Text>
              </View>
              <View style={styles.dataNoteCol}>
                <Text style={[styles.tableCell, styles.tableCellNote]}>
                  {notes[process.id] || ''}
                </Text>
              </View>
            </View>
          ))}

          <View style={styles.summaryTotal}>
            <Text style={styles.summaryTotalLabel}>총 합계(VAT 미포함) :</Text>
            <Text style={styles.summaryTotalAmount}>
              {formatNumber(calculateTotal(filteredProcessesForPDF))}원
            </Text>
          </View>
          <View style={styles.summaryTotal}>
            <Text style={styles.summaryTotalLabel}>총 합계(VAT 포함) :</Text>
            <Text style={styles.summaryTotalAmount}>
              {formatNumber(Math.round(calculateTotal(filteredProcessesForPDF) * 1.1))}원
            </Text>
          </View>
        </View>
      </Page>
    );

    // 페이지 높이 계산을 위한 상수
    const PAGE_HEIGHT = 842; // A4 높이 (포인트)
    const PAGE_PADDING = 40;
    const USABLE_HEIGHT = PAGE_HEIGHT - (PAGE_PADDING * 2);
    const SECTION_TITLE_HEIGHT = 30;
    const PROCESS_HEADER_HEIGHT = 35;
    const TABLE_HEADER_HEIGHT = 30;
    const ROW_HEIGHT = 26;
    const PROCESS_TOTAL_HEIGHT = 30;
    const PROCESS_MARGIN = 20;

    // 상세 견적서 페이지 생성 함수 수정
    const generateDetailPages = () => {
      let pages = [];
      let currentPage = [];
      let currentHeight = 0;
      let isFirstDetailPage = true;
      let pageIndex = 0;

      // 모든 공정 데이터 준비 (일반 공정 + 추가 견적)
      const additionalProcesses = filteredProcessesForPDF.filter(process => 
        process.items.some(item => item.isAdditional)
      );

      // 모든 추가 항목 수집
      const allAdditionalItems = additionalProcesses.flatMap(process => 
        process.items.filter(item => item.isAdditional)
      );

      // 모든 공정 포함 (추가 항목이 있는 공정도 포함)
      const allProcesses = [
        ...filteredProcessesForPDF,
        // 추가 견적 요약을 마지막에 추가
        ...(allAdditionalItems.length > 0 ? [{
          id: 'additional_summary',
          name: '추가 견적 요약',
          items: allAdditionalItems,
          total: allAdditionalItems.reduce((sum, item) => sum + (item.totalPrice || 0), 0)
        }] : [])
      ];

      // 공정들 처리
      allProcesses.forEach((process, processIndex) => {
        // 빈 공정은 건너뛰기
        if (!process.items || process.items.length === 0) return;

        const itemsCount = process.items.length;
        const processHeight = PROCESS_HEADER_HEIGHT + TABLE_HEADER_HEIGHT + 
                             (itemsCount * ROW_HEIGHT) + PROCESS_TOTAL_HEIGHT;

        if (currentHeight + processHeight > USABLE_HEIGHT) {
          pages.push(
            <Page key={`page_${pageIndex}`} size="A4" style={detailStyles.page}>
              {currentPage}
            </Page>
          );
          pageIndex++;
          currentPage = [];
          currentHeight = 0;
        }

        if (isFirstDetailPage && currentHeight === 0) {
          currentPage.push(
            <View key="detail_title">
              <Text style={[detailStyles.sectionTitle, { marginBottom: 50 }]}>상세 견적서</Text>
            </View>
          );
          currentHeight += SECTION_TITLE_HEIGHT + 50;
          isFirstDetailPage = false;
        }

        currentPage.push(
          <View key={`process_${process.id}`} style={[
            detailStyles.processSection,
            { marginBottom: processIndex < allProcesses.length - 1 ? PROCESS_MARGIN : 0 }
          ]}>
            <Text style={detailStyles.processName}>
              {process.id === 'additional_summary' ? (
                '추가 견적 요약'
              ) : getProcessName(process)}
            </Text>
            
            <View style={detailStyles.table}>
              <View style={detailStyles.tableHeader}>
                <View style={detailStyles.colItem}>
                  <Text style={detailStyles.headerCell}>품목</Text>
                </View>
                <View style={detailStyles.colQuantity}>
                  <Text style={detailStyles.headerCell}>수량</Text>
                </View>
                <View style={detailStyles.colUnit}>
                  <Text style={detailStyles.headerCell}>단위</Text>
                </View>
                <View style={detailStyles.colUnitPrice}>
                  <Text style={detailStyles.headerCell}>단가</Text>
                </View>
                <View style={detailStyles.colAmount}>
                  <Text style={detailStyles.headerCell}>금액</Text>
                </View>
                <View style={detailStyles.colNote}>
                  <Text style={detailStyles.headerCell}>비고</Text>
                </View>
              </View>

              {process.items.map((item, itemIndex) => (
                <View key={`item_${item.uniqueId || itemIndex}`} style={detailStyles.tableRow}>
                  <View style={detailStyles.colItem}>
                    <Text style={detailStyles.cell}>
                      {item.name || ''}
                      {item.isAdditional && <Text style={detailStyles.additionalTag}> (추가)</Text>}
                      {item.isSeparate && item.note === '별도' && <Text style={detailStyles.separateTag}> (별도)</Text>}
                    </Text>
                  </View>
                  <View style={detailStyles.colQuantity}>
                    <Text style={detailStyles.cell}>{item.quantity || ''}</Text>
                  </View>
                  <View style={detailStyles.colUnit}>
                    <Text style={detailStyles.cell}>{item.unit || ''}</Text>
                  </View>
                  <View style={detailStyles.colUnitPrice}>
                    <Text style={detailStyles.cell}>
                      {item.unitPrice ? formatNumber(item.unitPrice) : ''}
                    </Text>
                  </View>
                  <View style={detailStyles.colAmount}>
                    <Text style={detailStyles.cell}>
                      {item.excludeFromTotal ? '0' : (item.totalPrice ? formatNumber(item.totalPrice) : '')}
                    </Text>
                  </View>
                  <View style={detailStyles.colNote}>
                    <Text style={detailStyles.cell}>{item.note || ''}</Text>
                  </View>
                </View>
              ))}
            </View>

            <View style={detailStyles.processTotal}>
              <Text style={detailStyles.processTotalLabel}>
                {process.id === 'additional_summary' ? '추가 견적' : getProcessName(process)} 소계 :
              </Text>
              <Text style={detailStyles.processTotalAmount}>
                {formatNumber(process.total)}원
              </Text>
            </View>
          </View>
        );

        currentHeight += processHeight + PROCESS_MARGIN;
      });

      // 마지막 페이지 추가
      pages.push(
        <Page key={`page_${pageIndex}`} size="A4" style={detailStyles.page}>
          {currentPage}
          <View style={detailStyles.totalSumContainer}>
            <View style={detailStyles.totalSum}>
              <Text style={[detailStyles.totalSumLabel, { fontWeight: 'normal' }]}>총 합계(VAT별도) :</Text>
              <Text style={[detailStyles.totalSumAmount, { fontWeight: 'normal', color: '#946A6A' }]}>
                {formatNumber(calculateTotal(filteredProcessesForPDF))}
                <Text style={[detailStyles.currency, { fontWeight: 'normal', color: '#946A6A' }]}>원</Text>
              </Text>
            </View>
            <View style={[detailStyles.totalSum, detailStyles.vatIncluded]}>
              <Text style={detailStyles.totalSumLabel}>총 합계(VAT포함) :</Text>
              <Text style={[detailStyles.totalSumAmount, { color: '#946A6A' }]}>
                {formatNumber(Math.round(calculateTotal(filteredProcessesForPDF) * 1.1))}
                <Text style={[detailStyles.currency, { color: '#946A6A' }]}>원</Text>
              </Text>
            </View>
          </View>
        </Page>
      );

      return pages;
    };

    // Document 반환부 수정
    return (
      <Document>
        {firstPage}
        {generateDetailPages()}
      </Document>
    );
  };


  export default MyDocument;