import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './CategoryList.css';

const CategoryList = ({ 
  categories = [], 
  onSelectCategory, 
  selectedCategory,
  isAdmin = false,
  onAdd,
  onEdit,
  onDelete,
  onReorder
}) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => {
      setEnabled(true);
    });

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination || !isAdmin) return;

    const items = Array.from(categories);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    if (onReorder) {
      onReorder(items);
    }
  };

  const handleAddClick = () => {
    const name = prompt('새 카테고리 이름을 입력하세요:');
    if (name) {
      onAdd({ name });
    }
  };

  const handleEditClick = (category, e) => {
    e.stopPropagation();
    const newName = prompt('카테고리 이름을 수정하세요:', category.name);
    if (newName && newName !== category.name) {
      onEdit(category.id, { name: newName });
    }
  };

  const handleDeleteClick = (category, e) => {
    e.stopPropagation();
    if (window.confirm(`'${category.name}' 카테고리를 삭제하시겠습니까?`)) {
      onDelete(category.id);
    }
  };

  if (!enabled) {
    return (
      <div className="bathroom-category-list-container">
        <div className="bathroom-category-list">
          {categories.map((category) => (
            <div
              key={category.id}
              className={`bathroom-category-list__item ${
                selectedCategory?.id === category.id ? 'bathroom-category-list__item--active' : ''
              }`}
              onClick={() => onSelectCategory(category)}
            >
              <span className="bathroom-category-list__item-name">
                {category.name}
              </span>
              {isAdmin && (
                <div className="bathroom-category-list__item-actions">
                  <button
                    className="bathroom-category-list__edit-btn"
                    onClick={(e) => handleEditClick(category, e)}
                  >
                    수정
                  </button>
                  <button
                    className="bathroom-category-list__delete-btn"
                    onClick={(e) => handleDeleteClick(category, e)}
                  >
                    삭제
                  </button>
                </div>
              )}
            </div>
          ))}
          {isAdmin && (
            <button
              className="bathroom-category-list__add-btn"
              onClick={handleAddClick}
            >
              + 새 카테고리
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="bathroom-category-list-container">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="bathroom-category-list"
            >
              {categories.map((category, index) => (
                <Draggable
                  key={category.id}
                  draggableId={String(category.id)}
                  index={index}
                  isDragDisabled={!isAdmin}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`bathroom-category-list__item ${
                        selectedCategory?.id === category.id ? 'bathroom-category-list__item--active' : ''
                      } ${snapshot.isDragging ? 'bathroom-category-list__item--dragging' : ''}`}
                      onClick={() => onSelectCategory(category)}
                      style={{
                        ...provided.draggableProps.style,
                        cursor: isAdmin ? 'grab' : 'pointer'
                      }}
                    >
                      <span className="bathroom-category-list__item-name">
                        {category.name}
                      </span>
                      {isAdmin && (
                        <div className="bathroom-category-list__item-actions">
                          <button
                            className="bathroom-category-list__edit-btn"
                            onClick={(e) => handleEditClick(category, e)}
                          >
                            수정
                          </button>
                          <button
                            className="bathroom-category-list__delete-btn"
                            onClick={(e) => handleDeleteClick(category, e)}
                          >
                            삭제
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {isAdmin && (
                <button
                  className="bathroom-category-list__add-btn"
                  onClick={handleAddClick}
                >
                  + 새 카테고리
                </button>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default CategoryList; 