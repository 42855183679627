import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, query, orderBy, doc, getDoc, limit, updateDoc } from 'firebase/firestore';
import { saveWorkOrderPage, updateWorkOrderPage, fetchWorkOrderPages, deleteWorkOrderPage } from './firebaseService';
import MaterialModal from './MaterialModal';
import LayoutModal from './LayoutModal';
import PageControls from './PageControls';
import PageMinimap from './PageMinimap';
import PdfControls from './PdfControls';
import BulkUploadModal from './BulkUploadModal';
import TemplateChangeModal from './TemplateChangeModal';
import MaterialManageModal from './MaterialManageModal';
import './WorkOrder.css';

const WorkOrder = () => {
  const [memo, setMemo] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState([{ imageFile: null, memo: '', workType: '' }]);
  const [showWorkTypeModal, setShowWorkTypeModal] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isAlternateLogo, setIsAlternateLogo] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState('');
  const [pageIds, setPageIds] = useState([]);  // Firestore 문서 ID 저장
  const [showMaterialModal, setShowMaterialModal] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [showLayoutModal, setShowLayoutModal] = useState(false);
  const [selectedLayout, setSelectedLayout] = useState('template1');
  const [selectedWorkType, setSelectedWorkType] = useState('');
  const [labels, setLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [isDraggingLabel, setIsDraggingLabel] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [selectedLabelId, setSelectedLabelId] = useState(null);
  const [isLabelDragEnded, setIsLabelDragEnded] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [labelToDelete, setLabelToDelete] = useState(null);
  const [editingPageIndex, setEditingPageIndex] = useState(null);
  const pageRefs = useRef({});
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [showTemplateChangeModal, setShowTemplateChangeModal] = useState(false);
  const [editingTemplateIndex, setEditingTemplateIndex] = useState(null);
  const observerRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeoutRef = useRef(null);
  const [showMaterialManageModal, setShowMaterialManageModal] = useState(false);
  const [arrowPosition, setArrowPosition] = useState(null);
  const [isArrowReversed, setIsArrowReversed] = useState(false);
  const [fontSize, setFontSize] = useState(24);

  const workTypes = [
    '도배',
    '바닥',
    '목공',
    '전기/조명',
    '필름',
    '제작가구',
    '에어컨',
    '이미지만',
    '텍스트만'
  ];

  useEffect(() => {
    // 주소 목록 가져오기
    const fetchAddresses = async () => {
      try {
        const addressesCollection = collection(db, 'addresses');
        const q = query(addressesCollection, orderBy('createdAt', 'desc'));
        const addressesSnapshot = await getDocs(q);
        const addressesList = addressesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setAddresses(addressesList);
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchAddresses();
  }, []);

  useEffect(() => {
    // Intersection Observer 설정
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.getAttribute('data-page-index'));
            if (!isNaN(index)) {
              setCurrentPageIndex(index);
            }
          }
        });
      },
      {
        root: null,
        rootMargin: '-50% 0px',
        threshold: 0
      }
    );

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    // 페이지 요소들에 대한 observer 설정
    Object.entries(pageRefs.current).forEach(([index, ref]) => {
      if (ref && observerRef.current) {
        ref.setAttribute('data-page-index', index);
        observerRef.current.observe(ref);
      }
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [pages, selectedAddress]);

  const handleImageUpload = async (file, pageIndex) => {
    if (!file || !selectedAddressId || !pageIds[pageIndex]) {
      return;
    }

    // 파일명에서 확장자를 제외한 위치 정보 추출
    const fileName = file.name;
    const fileNameWithoutExt = fileName.split('.').slice(0, -1).join('.');
    const location = fileNameWithoutExt || '위치 미지정';

    const reader = new FileReader();
    reader.onloadend = async () => {
      try {
        const newPages = [...pages];
        const oldImageUrl = newPages[pageIndex].imageFile;
        
        // 페이지 업데이트 전에 location과 originalFileName 정보를 포함
        const updatedPage = {
          ...newPages[pageIndex],
          imageFile: reader.result,
          location: location,
          oldImageUrl: oldImageUrl,
          originalFileName: fileName
        };
        
        newPages[pageIndex] = updatedPage;
        setPages(newPages);

        // updateWorkOrderPage 호출 시 originalFileName도 함께 전달
        const response = await updateWorkOrderPage(
          selectedAddressId, 
          pageIds[pageIndex], 
          {
            ...updatedPage,
            originalFileName: fileName
          }
        );

        if (response?.imageUrl) {
          const finalPage = {
            ...updatedPage,
            imageFile: response.imageUrl
          };
          newPages[pageIndex] = finalPage;
          setPages(newPages);
        }
      } catch (error) {
        console.error('Image upload error:', error);
        alert('이미지 업로드 중 오류가 발생했습니다.');
      }
    };
    reader.readAsDataURL(file);
  };

  const handleLocationEdit = async (newLocation, pageIndex) => {
    try {
      const newPages = [...pages];
      newPages[pageIndex] = {
        ...newPages[pageIndex],
        location: newLocation
      };
      setPages(newPages);

      await updateWorkOrderPage(
        selectedAddressId,
        pageIds[pageIndex],
        newPages[pageIndex]
      );
    } catch (error) {
      alert('위치 정보 수정 중 오류가 발생했습니다.');
    }
  };

  const handleMemoChange = async (value, pageIndex) => {
    if (!selectedAddressId || !pageIds[pageIndex]) return;

    const newPages = [...pages];
    newPages[pageIndex] = {
      ...newPages[pageIndex],
      memo: value
    };
    setPages(newPages);

    try {
      await updateWorkOrderPage(selectedAddressId, pageIds[pageIndex], newPages[pageIndex]);
    } catch (error) {
      alert('메모 업데이트 중 오류가 발생했습니다.');
    }
  };

  const handleAddressSelect = async (e) => {
    const selectedOption = addresses.find(addr => addr.description === e.target.value);
    setSelectedAddress(e.target.value);
    setSelectedAddressId(selectedOption?.id || '');

    if (selectedOption?.id) {
      try {
        const workOrderPages = await fetchWorkOrderPages(selectedOption.id);
        
        if (workOrderPages.length > 0) {
          const formattedPages = workOrderPages.map(page => ({
            imageFile: page.imageUrl || null,
            floorPlanFile: page.floorPlanUrl || null,  // 2D 평면도 URL 추가
            memo: page.memo || '',
            workType: page.workType,
            labels: page.labels || [],
            layoutType: page.layoutType,
            location: page.location || '위치 미지정',
            arrowPosition: page.arrowPosition || null,
            isArrowReversed: page.isArrowReversed || false
          }));
          
          setPages(formattedPages);
          setPageIds(workOrderPages.map(page => page.id));
          
          if (formattedPages[0]?.labels) {
            setLabels(formattedPages[0].labels);
          }

          // 현재 페이지의 화살표 정보 설정
          if (formattedPages[currentPageIndex]) {
            setArrowPosition(formattedPages[currentPageIndex].arrowPosition);
            setIsArrowReversed(formattedPages[currentPageIndex].isArrowReversed);
          }
        } else {
          setPages([{ 
            imageFile: null, 
            floorPlanFile: null,  // 초기 상태에도 floorPlanFile 추가
            memo: '', 
            workType: '', 
            labels: [], 
            location: '위치 미지정',
            arrowPosition: null,
            isArrowReversed: false
          }]);
          setPageIds([]);
          setLabels([]);
        }
      } catch (error) {
        console.error('작업지시서 로드 중 오류:', error);
        alert('작업지시서를 불러오는 중 오류가 발생했습니다.');
      }
    } else {
      setPages([{ 
        imageFile: null, 
        floorPlanFile: null,  // 초기 상태에도 floorPlanFile 추가
        memo: '', 
        workType: '', 
        labels: [], 
        location: '위치 미지정',
        arrowPosition: null,
        isArrowReversed: false
      }]);
      setPageIds([]);
      setLabels([]);
    }
  };

  const handleWorkTypeSelect = async (workType) => {
    if (editingPageIndex !== null) {
      try {
        const newPages = [...pages];
        newPages[editingPageIndex] = {
          ...newPages[editingPageIndex],
          workType,
          layoutType: workType === '이미지만' ? 'template3' : 
                     workType === '텍스트만' ? 'template4' : 
                     newPages[editingPageIndex].layoutType
        };
        setPages(newPages);

        await updateWorkOrderPage(
          selectedAddressId,
          pageIds[editingPageIndex],
          newPages[editingPageIndex]
        );

        setShowWorkTypeModal(false);
        setEditingPageIndex(null);
      } catch (error) {
        alert('작업 종류 수정 중 오류가 발생했습니다.');
      }
      return;
    }

    setSelectedWorkType(workType);
    if (workType === '이미지만') {
      handleLayoutSelect('template3');
    } else if (workType === '텍스트만') {
      handleLayoutSelect('template4');
    } else {
      setShowLayoutModal(true);
    }
  };

  const handleWorkTypeClick = (pageIndex) => {
    setEditingPageIndex(pageIndex);
    setShowWorkTypeModal(true);
  };

  const handleLayoutSelect = async (layoutType) => {
    setSelectedLayout(layoutType);
    setShowLayoutModal(false);
    
    if (!selectedAddressId) return;

    try {
      // 현재 존재하는 페이지들의 createdAt 시간을 가져옴
      const workOrderRef = collection(db, 'addresses', selectedAddressId, 'workOrders');
      const q = query(workOrderRef, orderBy('createdAt', 'desc'), limit(1));
      const snapshot = await getDocs(q);
      
      // 새 페이지의 createdAt 설정
      let newPageCreatedAt;
      if (!snapshot.empty) {
        // 가장 최근 페이지보다 1초 뒤로 설정
        const lastPage = snapshot.docs[0].data();
        newPageCreatedAt = new Date(lastPage.createdAt.toDate().getTime() + 1000);
      } else {
        // 첫 페이지인 경우 현재 시간으로 설정
        newPageCreatedAt = new Date();
      }

      if (pages.length === 1 && !pages[0].workType) {
        const newPage = { 
          imageFile: null, 
          memo: '', 
          workType: selectedWorkType,
          layoutType,
          createdAt: newPageCreatedAt
        };
        const docId = await saveWorkOrderPage(selectedAddressId, newPage);
        setPages([newPage]);
        setPageIds([docId]);
      } else {
        const newPage = { 
          imageFile: null, 
          memo: '', 
          workType: selectedWorkType,
          layoutType,
          createdAt: newPageCreatedAt
        };
        const docId = await saveWorkOrderPage(selectedAddressId, newPage);
        setPages([...pages, newPage]);
        setPageIds([...pageIds, docId]);
      }
      setShowWorkTypeModal(false);
    } catch (error) {
      console.error('페이지 저장 중 오류:', error);
      alert('페이지 저장 중 오류가 발생했습니다.');
    }
  };

  const addNewPage = () => {
    setPages([...pages, { imageFile: null, memo: '', workType: '' }]);
    setCurrentPage(pages.length + 1);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e, pageIndex) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      handleImageUpload(file, pageIndex);
    }
  };

  const handleLogoClick = () => {
    setIsAlternateLogo(!isAlternateLogo);
  };

  const handleDeletePage = async (pageIndex) => {
    if (!selectedAddressId || !pageIds[pageIndex]) return;

    // 실제 작업 페이지만 필터링
    const workPages = pages.filter(page => page.workType);
    const actualPageIndex = workPages.findIndex(page => page === pages[pageIndex]);

    console.log('삭제 시도하는 페이지 정보:', {
      pageIndex,
      totalPages: workPages.length,
      actualPageIndex,
      pageToDelete: pages[pageIndex],
      currentPageIndex
    });

    if (window.confirm('이 페이지를 삭제하시겠습니까?')) {
      try {
        const currentPage = pages[pageIndex];
        console.log('삭제 전 작업 페이지 목록:', workPages);
        
        // Firestore에서 페이지 삭제
        await deleteWorkOrderPage(
          selectedAddressId, 
          pageIds[pageIndex],
          currentPage.imageFile,
          currentPage.floorPlanFile
        );

        console.log('Firestore 삭제 완료');

        // 상태 업데이트
        const newPages = [...pages];
        const newPageIds = [...pageIds];
        
        console.log('삭제할 페이지 인덱스:', pageIndex);
        console.log('삭제 전 전체 페이지 수:', newPages.length);
        console.log('삭제 전 작업 페이지 수:', workPages.length);
        
        newPages.splice(pageIndex, 1);
        newPageIds.splice(pageIndex, 1);
        
        console.log('삭제 후 전체 페이지 수:', newPages.length);
        console.log('삭제 후 작업 페이지 수:', newPages.filter(page => page.workType).length);

        setPages(newPages);
        setPageIds(newPageIds);

        // 현재 페이지 인덱스 조정 (작업 페이지 기준)
        const newWorkPages = newPages.filter(page => page.workType);
        if (actualPageIndex === currentPageIndex) {
          if (newWorkPages.length > 0) {
            const newIndex = Math.min(actualPageIndex, newWorkPages.length - 1);
            console.log('현재 페이지 삭제됨, 새 작업 페이지 인덱스:', newIndex);
            setCurrentPageIndex(newIndex);
          } else {
            console.log('마지막 작업 페이지 삭제됨, 인덱스 0으로 설정');
            setCurrentPageIndex(0);
          }
        } else if (actualPageIndex < currentPageIndex) {
          const newIndex = currentPageIndex - 1;
          console.log('이전 작업 페이지 삭제됨, 인덱스 조정:', newIndex);
          setCurrentPageIndex(newIndex);
        }
      } catch (error) {
        console.error('페이지 삭제 중 오류:', error);
        alert('페이지 삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const handleImageDelete = async (pageIndex) => {
    if (!selectedAddressId || !pageIds[pageIndex]) return;

    if (window.confirm('이미지를 삭제하시겠습니까?')) {
      try {
        const newPages = [...pages];
        const currentPage = newPages[pageIndex];
        
        // 현재 이미지 URL 저장
        let imageUrl = null;
        if (typeof currentPage.imageFile === 'string' && !currentPage.imageFile.startsWith('data:')) {
          imageUrl = currentPage.imageFile;
        }
        
        // 먼저 상태 업데이트
        newPages[pageIndex] = {
          ...currentPage,
          imageFile: null
        };
        setPages(newPages);

        // Firebase 업데이트
        await updateWorkOrderPage(
          selectedAddressId,
          pageIds[pageIndex],
          {
            ...newPages[pageIndex],
            imageUrl: null,
            oldImageUrl: imageUrl
          }
        );
      } catch (error) {
        alert('이미지 삭제 중 오류가 발생했습니다.');
        // 에러 발생 시 이전 상태로 복구
        const newPages = [...pages];
        setPages(newPages);
      }
    }
  };

  const handleFloorPlanUpload = async (file, pageIndex) => {
    if (!file || !selectedAddressId || !pageIds[pageIndex]) return;

    // 파일 타입 검증
    if (!file.type.startsWith('image/')) {
      alert('이미지 파일만 업로드 가능합니다.');
      return;
    }

    // 파일 크기 검증 (5MB 제한)
    if (file.size > 5 * 1024 * 1024) {
      alert('파일 크기가 너무 큽니다. 5MB 이하의 파일만 업로드 가능합니다.');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = async () => {
      try {
        // 이미지 미리보기를 위한 임시 상태 업데이트
        const newPages = [...pages];
        const oldFloorPlanUrl = newPages[pageIndex].floorPlanFile;
        
        newPages[pageIndex] = {
          ...newPages[pageIndex],
          floorPlanFile: reader.result
        };
        setPages(newPages);

        // 이미지 품질 최적화
        const img = new Image();
        img.onload = async () => {
          try {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            
            // 최대 크기 설정 (1920px)
            const maxSize = 1920;
            let width = img.width;
            let height = img.height;
            
            if (width > maxSize || height > maxSize) {
              if (width > height) {
                height = (height / width) * maxSize;
                width = maxSize;
              } else {
                width = (width / height) * maxSize;
                height = maxSize;
              }
            }
            
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            
            // 최적화된 이미지 데이터
            const optimizedImageData = canvas.toDataURL('image/jpeg', 0.8);

            const response = await updateWorkOrderPage(
              selectedAddressId, 
              pageIds[pageIndex], 
              {
                ...newPages[pageIndex],
                floorPlanFile: optimizedImageData,
                oldFloorPlanUrl,
                isFloorPlan: true
              }
            );

            if (response?.floorPlanUrl) {
              newPages[pageIndex] = {
                ...newPages[pageIndex],
                floorPlanFile: response.floorPlanUrl
              };
              setPages(newPages);
            }
          } catch (error) {
            console.error('이미지 최적화 중 오류:', error);
            alert('2D 평면도 업로드 중 오류가 발생했습니다.');
            // 에러 발생 시 이전 상태로 복구
            newPages[pageIndex] = {
              ...newPages[pageIndex],
              floorPlanFile: oldFloorPlanUrl
            };
            setPages(newPages);
          }
        };
        
        img.onerror = () => {
          alert('이미지 처리 중 오류가 발생했습니다.');
          // 에러 발생 시 이전 상태로 복구
          newPages[pageIndex] = {
            ...newPages[pageIndex],
            floorPlanFile: oldFloorPlanUrl
          };
          setPages(newPages);
        };
        
        img.src = reader.result;
      } catch (error) {
        console.error('2D 평면도 업로드 중 오류:', error);
        alert('2D 평면도 업로드 중 오류가 발생했습니다.');
      }
    };

    reader.onerror = () => {
      alert('파일 읽기 중 오류가 발생했습니다.');
    };

    reader.readAsDataURL(file);
  };

  const handleFloorPlanDrop = (e, pageIndex) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      handleFloorPlanUpload(file, pageIndex);
    }
  };

  const handleFloorPlanDelete = async (e, pageIndex) => {
    e.preventDefault();
    e.stopPropagation();

    if (!selectedAddressId || !pageIds[pageIndex]) return;

    if (window.confirm('2D 평면도를 삭제하시겠습니까?')) {
      try {
        const newPages = [...pages];
        const currentPage = newPages[pageIndex];
        
        let floorPlanUrl = null;
        if (typeof currentPage.floorPlanFile === 'string') {
          if (!currentPage.floorPlanFile.startsWith('data:')) {
            floorPlanUrl = currentPage.floorPlanFile;
          }
        }
        
        // 화살표 정보도 함께 초기화
        setArrowPosition(null);
        setIsArrowReversed(false);
        
        newPages[pageIndex] = {
          ...currentPage,
          floorPlanFile: null,
          floorPlanUrl: null,
          arrowPosition: null,
          isArrowReversed: false
        };
        
        setPages(newPages);

        const updateData = {
          ...newPages[pageIndex],
          floorPlanUrl: null,
          oldFloorPlanUrl: floorPlanUrl,
          isFloorPlan: true,
          arrowPosition: null,
          isArrowReversed: false
        };

        await updateWorkOrderPage(
          selectedAddressId,
          pageIds[pageIndex],
          updateData
        );
      } catch (error) {
        alert('2D 평면도 삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const handleEditClick = (e, labelId) => {
    e.stopPropagation();
    setSelectedLabelId(labelId);
    setShowMaterialModal(true);
  };

  const handleMaterialSave = (materialData) => {
    if (selectedLabelId) {
      const pageIndex = currentPageIndex;
      const currentLabels = pages[pageIndex].labels || [];
      const newLabels = currentLabels.map(label => {
        if (label.id === selectedLabelId) {
          return { ...label, materialInfo: materialData };
        }
        return label;
      });

      const newPages = [...pages];
      newPages[pageIndex] = {
        ...newPages[pageIndex],
        labels: newLabels
      };
      setPages(newPages);
      setLabels(newLabels);
      updatePageLabels(pageIndex, newLabels);
    }
    setShowMaterialModal(false);
    setSelectedLabelId(null);
  };

  const handleLabelDragStart = (e, labelId) => {
    e.stopPropagation();
    setSelectedLabel(labelId);
    setIsDraggingLabel(true);
    setIsLabelDragEnded(false);
  };

  const handleLabelDragEnd = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isDraggingLabel) {
      setSelectedLabel(null);
      setIsDraggingLabel(false);
      setIsLabelDragEnded(true);
      setTimeout(() => {
        setIsLabelDragEnded(false);
      }, 500);
    }
  };

  const handleImageClick = (e, pageIndex) => {
    if (isLabelDragEnded) return;
    
    if (!pages[pageIndex]?.imageFile || isDraggingLabel || selectedLabel !== null) return;

    if (e.target.closest('.image-label')) return;

    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    const currentLabels = pages[pageIndex].labels || [];
    const newLabel = {
      id: Date.now(),
      position: { x, y },
      number: (currentLabels.length + 1),
      materialInfo: null
    };

    const newLabels = [...currentLabels, newLabel];
    
    const newPages = [...pages];
    newPages[pageIndex] = {
      ...newPages[pageIndex],
      labels: newLabels
    };
    setPages(newPages);
    setLabels(newLabels);
    updatePageLabels(pageIndex, newLabels);
  };

  const handleLabelDrag = (e, pageIndex) => {
    if (!isDraggingLabel || selectedLabel === null) return;
    e.stopPropagation();
    e.preventDefault();

    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    const currentLabels = pages[pageIndex].labels || [];
    const newLabels = currentLabels.map(label => {
      if (label.id === selectedLabel) {
        return { ...label, position: { x, y } };
      }
      return label;
    });

    const newPages = [...pages];
    newPages[pageIndex] = {
      ...newPages[pageIndex],
      labels: newLabels
    };
    setPages(newPages);
    setLabels(newLabels);
    updatePageLabels(pageIndex, newLabels);
  };

  const updatePageLabels = async (pageIndex, newLabels) => {
    if (!selectedAddressId || !pageIds[pageIndex]) return;

    try {
      const newPages = [...pages];
      newPages[pageIndex] = {
        ...newPages[pageIndex],
        labels: newLabels
      };
      setPages(newPages);

      await updateWorkOrderPage(
        selectedAddressId,
        pageIds[pageIndex],
        {
          ...newPages[pageIndex],
          labels: newLabels
        }
      );
    } catch (error) {
      alert('라벨 업데이트 중 오류가 발생했습니다.');
    }
  };

  const handleLabelDoubleClick = (e, labelId) => {
    e.stopPropagation();
    setLabelToDelete(labelId);
    setShowDeleteModal(true);
  };

  const handleDeleteLabel = async () => {
    if (!labelToDelete) return;

    const pageIndex = currentPageIndex;
    const currentLabels = pages[pageIndex].labels || [];
    const newLabels = currentLabels.filter(label => label.id !== labelToDelete);
    
    // 라벨 번호 재정렬
    const updatedLabels = newLabels.map((label, index) => ({
      ...label,
      number: index + 1
    }));

    const newPages = [...pages];
    newPages[pageIndex] = {
      ...newPages[pageIndex],
      labels: updatedLabels
    };
    
    setPages(newPages);
    setLabels(updatedLabels);
    updatePageLabels(pageIndex, updatedLabels);
    setShowDeleteModal(false);
    setLabelToDelete(null);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (showDeleteModal && e.code === 'Space') {
        e.preventDefault();
        handleDeleteLabel();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [showDeleteModal, labelToDelete]);

  const renderImageLabels = (pageIndex) => {
    const currentLabels = pages[pageIndex]?.labels || [];
    if (currentLabels.length === 0) return null;

    const materialsWithLabels = currentLabels
      .filter(label => label.materialInfo)
      .map(label => ({
        ...label.materialInfo,
        labelNumber: label.number
      }));

    return (
      <div className="label-container">
        {currentLabels.map((label) => (
          <div
            key={label.id}
            className={`image-label ${selectedLabel === label.id ? 'dragging' : ''} ${label.materialInfo ? 'has-material' : ''}`}
            style={{
              left: `${label.position.x}%`,
              top: `${label.position.y}%`,
              transform: 'translate(-50%, -50%)'
            }}
            onMouseDown={(e) => handleLabelDragStart(e, label.id)}
            onMouseUp={handleLabelDragEnd}
            onDoubleClick={(e) => handleLabelDoubleClick(e, label.id)}
          >
            {label.number}
            <div 
              className="edit-icon"
              onClick={(e) => handleEditClick(e, label.id)}
            >
              ✎
            </div>
            {label.materialInfo && (
              <div className="label-info">
                <div className="label-info-content">
                  <div className="label-info-item">
                    <span className="label-info-title">종류:</span>
                    <span className="label-info-value">{label.materialInfo.materialType}</span>
                  </div>
                  <div className="label-info-item">
                    <span className="label-info-title">이름:</span>
                    <span className="label-info-value">{label.materialInfo.materialName}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
        {showDeleteModal && (
          <div className="delete-modal-overlay" onClick={() => setShowDeleteModal(false)}>
            <div className="delete-modal-content" onClick={e => e.stopPropagation()}>
              <h3>라벨 삭제</h3>
              <p>이 라벨을 삭제하시겠습니까?</p>
              <p className="delete-modal-hint">(스페이스바를 누르면 삭제됩니다)</p>
              <div className="delete-modal-buttons">
                <button onClick={handleDeleteLabel} className="delete-confirm-button">삭제</button>
                <button onClick={() => setShowDeleteModal(false)} className="delete-cancel-button">취소</button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleBulkUpload = async (files, workType, templateType = 'template2') => {
    if (!selectedAddressId) return;

    try {
      for (const file of files) {
        const fileName = file.name;
        const fileNameWithoutExt = fileName.split('.').slice(0, -1).join('.');
        const location = fileNameWithoutExt || '위치 미지정';

        const newPage = {
          imageFile: null,
          memo: '',
          workType: workType,
          layoutType: templateType,
          location: location,
          originalFileName: fileName
        };

        const docId = await saveWorkOrderPage(selectedAddressId, newPage);
        const reader = new FileReader();

        reader.onloadend = async () => {
          try {
            const response = await updateWorkOrderPage(
              selectedAddressId,
              docId,
              {
                ...newPage,
                imageFile: reader.result,
                originalFileName: fileName,
                location: location
              }
            );

            if (response?.imageUrl) {
              newPage.imageFile = response.imageUrl;
            }

            setPages(prevPages => [...prevPages, newPage]);
            setPageIds(prevIds => [...prevIds, docId]);
          } catch (error) {
            console.error('Error updating page:', error);
          }
        };

        reader.readAsDataURL(file);
      }
    } catch (error) {
      alert('일괄 업로드 중 오류가 발생했습니다.');
    }
  };

  const renderCoverPage = () => (
    <div className="work-order-container">
      <div className="cover-page">
        <div className="logo-section">
          <div className="logo-text-container" onClick={handleLogoClick}>
            {isAlternateLogo ? (
              <>
                <div className="logo-text">BESPOKE</div>
                <div className="logo-text">SPACE</div>
              </>
            ) : (
              <>
                <div className="logo-text">AMARE</div>
                <div className="logo-text">DESIGN</div>
              </>
            )}
          </div>
          <div className="logo-subtext">작업지시서</div>
        </div>
        <div className="address-select-section">
          <select 
            className="address-select"
            value={selectedAddress}
            onChange={handleAddressSelect}
          >
            <option value="">현장 주소를 선택하세요</option>
            {addresses.map(address => (
              <option key={address.id} value={address.description}>
                {address.description}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );

  const WorkTypeModal = () => (
    <div className="worktype-modal-overlay" onClick={() => setShowWorkTypeModal(false)}>
      <div className="worktype-modal-content" onClick={e => e.stopPropagation()}>
        <h2 className="worktype-modal-title">작업 종류 선택</h2>
        <div className="worktype-modal-grid">
          {workTypes.map((type) => (
            <button
              key={type}
              className="worktype-modal-button"
              onClick={() => handleWorkTypeSelect(type)}
            >
              {type}
            </button>
          ))}
        </div>
      </div>
    </div>
  );

  const handleFontSizeChange = (delta) => {
    const newSize = Math.max(12, Math.min(72, fontSize + delta));
    setFontSize(newSize);
  };

  const renderWorkOrderPage = (pageIndex) => {
    const page = pages[pageIndex];
    const isTemplate2 = page.layoutType === 'template2';
    const isTemplate3 = page.layoutType === 'template3';
    const isTemplate4 = page.layoutType === 'template4';
    const pageNumber = pages.filter(p => p.workType).findIndex(p => p === page) + 1;
    const totalPages = pages.filter(p => p.workType).length;
    const currentLabels = page.labels || [];
    const materialsWithLabels = currentLabels
      .filter(label => label.materialInfo)
      .map(label => ({
        ...label.materialInfo,
        labelNumber: label.number
      }));

    return (
      <div className="work-order-container">
        <div className="work-order-header">
          <div className="work-order-title">
            작업지시서(
            <span
              className="location-text"
              onDoubleClick={(e) => {
                e.stopPropagation();
                const newLocation = prompt('위치를 입력하세요:', page.location || '위치 미지정');
                if (newLocation !== null) {
                  handleLocationEdit(newLocation, pageIndex);
                }
              }}
            >
              {page.location || '위치 미지정'}
            </span>
            ) {page?.workType && (
              <>
                : <span 
                    className="work-type-text" 
                    onClick={() => handleWorkTypeClick(pageIndex)}
                  >
                    {page.workType === '이미지만' ? '사이즈 확인 요망' : `${page.workType} 공정`}
                  </span>
                <span className="page-number-indicator">(page {pageNumber}/{totalPages})</span>
              </>
            )}
          </div>
          <div>
            <div className="site-address">{selectedAddress}</div>
            <div className="warning-text">*현장 상황에 따라 변동 될 수 있습니다.</div>
          </div>
        </div>

        <hr className="divider" />

        <div className={`work-order-content ${isTemplate2 ? 'template2' : ''} ${isTemplate3 ? 'template3' : ''} ${isTemplate4 ? 'template4' : ''}`}>
          {isTemplate4 ? (
            <div className="full-text-section">
              <div className="font-size-control">
                <button className="font-size-button" onClick={() => handleFontSizeChange(-2)}>-</button>
                <span className="font-size-display">{fontSize}px</span>
                <button className="font-size-button" onClick={() => handleFontSizeChange(2)}>+</button>
              </div>
              <textarea
                className="text-content"
                value={page?.memo || ''}
                onChange={(e) => handleMemoChange(e.target.value, pageIndex)}
                placeholder="텍스트를 입력하세요..."
                style={{ fontSize: `${fontSize}px` }}
                spellCheck="false"
              />
            </div>
          ) : isTemplate3 ? (
            <div className="full-image-section">
              {renderBlueprintSection(pageIndex)}
            </div>
          ) : isTemplate2 ? (
            <>
              <div className="left-section">
                <div className="blueprint-section">
                  {renderBlueprintSection(pageIndex)}
                </div>
                <div className="memo-section">
                  <textarea
                    className="memo-content"
                    value={page?.memo || ''}
                    onChange={(e) => handleMemoChange(e.target.value, pageIndex)}
                    placeholder="작업 지시 사항을 입력하세요..."
                    id={`memo-content-${pageIndex}`}
                    spellCheck="false"
                  />
                </div>
              </div>
              <div className="right-section">
                <div className="materials-container">
                  <div className="materials-title">자재 목록</div>
                  {materialsWithLabels.length > 0 ? (
                    <div className="materials-list">
                      {materialsWithLabels.map((material, idx) => (
                        <div key={idx} className="material-item">
                          <span className="material-label-number">[{material.labelNumber}]</span>
                          <span className="material-item-text">
                            {material.materialType} / {material.materialName}
                            {material.size && <br />}
                            {material.size && `사이즈: ${material.size}`}
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div style={{ color: '#666', fontSize: '13px', textAlign: 'center', marginTop: '10px' }}>
                      등록된 자재가 없습니다
                    </div>
                  )}
                </div>
                <div className="floor-plan-section">
                  <div className="floor-plan-title">2D 평면도</div>
                  {renderFloorPlanSection(pageIndex)}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="blueprint-section">
                {renderBlueprintSection(pageIndex)}
              </div>
              <div className="right-section">
                <div className="materials-container">
                  <div className="materials-title">자재 목록</div>
                  {materialsWithLabels.length > 0 ? (
                    <div className="materials-list">
                      {materialsWithLabels.map((material, idx) => (
                        <div key={idx} className="material-item">
                          <span className="material-label-number">[{material.labelNumber}]</span>
                          <span className="material-item-text">
                            {material.materialType} / {material.materialName}
                            {material.size && <br />}
                            {material.size && `사이즈: ${material.size}`}
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div style={{ color: '#666', fontSize: '13px', textAlign: 'center', marginTop: '10px' }}>
                      등록된 자재가 없습니다
                    </div>
                  )}
                </div>
                <div className="memo-section">
                  <textarea
                    className="memo-content"
                    value={page?.memo || ''}
                    onChange={(e) => handleMemoChange(e.target.value, pageIndex)}
                    placeholder="작업 지시 사항을 입력하세요..."
                    id={`memo-content-${pageIndex}`}
                    spellCheck="false"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderBlueprintSection = (pageIndex) => (
    <div 
      className={`blueprint-upload-area ${isDragging ? 'dragging' : ''}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={(e) => handleDrop(e, pageIndex)}
    >
      <input 
        type="file" 
        accept="image/*" 
        onChange={(e) => {
          if (e.target.files[0]) {
            handleImageUpload(e.target.files[0], pageIndex);
          }
        }}
        style={{ display: 'none' }}
        id={`blueprint-upload-${pageIndex}`}
      />
      {pages[pageIndex]?.imageFile ? (
        <div 
          className="blueprint-image-container"
          onClick={(e) => handleImageClick(e, pageIndex)}
          onMouseMove={(e) => handleLabelDrag(e, pageIndex)}
          onMouseUp={handleLabelDragEnd}
          onMouseLeave={handleLabelDragEnd}
        >
          {pages[pageIndex].imageFile && (
            <>
              <img 
                src={pages[pageIndex].imageFile} 
                alt="도면" 
                className="blueprint-image"
                draggable={false}
                onError={() => {
                  const newPages = [...pages];
                  newPages[pageIndex] = {
                    ...newPages[pageIndex],
                    imageFile: null
                  };
                  setPages(newPages);
                }}
              />
              {renderImageLabels(pageIndex)}
              <button 
                className="image-delete-button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleImageDelete(pageIndex);
                }}
              >
                ×
              </button>
            </>
          )}
          <div className={`image-overlay ${isDragging ? 'dragging' : ''}`}>
            <div className="overlay-text">
              {isDragging ? '새 이미지를 여기에 놓으세요' : ''}
            </div>
          </div>
        </div>
      ) : (
        <label 
          htmlFor={`blueprint-upload-${pageIndex}`}
          className="blueprint-upload-label"
        >
          <div className="upload-icon">
            <i className="fas fa-cloud-upload-alt"></i>
          </div>
          <div className="upload-text">
            {isDragging ? 
              '이미지를 여기에 놓으세요' : 
              '도면 이미지를 드래그하거나 클릭하여 업로드하세요'}
          </div>
        </label>
      )}
    </div>
  );

  const handleFloorPlanClick = async (e, pageIndex) => {
    e.preventDefault();
    e.stopPropagation();

    if (!selectedAddressId || !pageIds[pageIndex]) {
      console.log('저장 실패: 필수 ID 없음', { selectedAddressId, pageId: pageIds[pageIndex] });
      return;
    }

    // 더블 클릭 시 화살표 삭제
    if (e.detail === 2) {
      if (arrowPosition) {
        if (window.confirm('화살표를 삭제하시겠습니까?')) {
          // 화살표 정보 초기화
          setArrowPosition(null);
          setIsArrowReversed(false);

          // 페이지 상태 업데이트
          const newPages = [...pages];
          newPages[pageIndex] = {
            ...newPages[pageIndex],
            arrowPosition: null,
            isArrowReversed: false
          };
          setPages(newPages);

          // 파이어스토어 업데이트
          try {
            await updateWorkOrderPage(
              selectedAddressId,
              pageIds[pageIndex],
              newPages[pageIndex]
            );
            console.log('화살표 삭제 성공');
          } catch (error) {
            console.error('화살표 삭제 실패:', error);
            alert('화살표 삭제 중 오류가 발생했습니다.');
          }
        }
        return;
      }
    }

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const arrowLength = 60;
    const cursorOffset = 12;

    // 오른쪽 클릭인 경우 방향 전환
    if (e.button === 2) {
      const newIsReversed = !isArrowReversed;
      setIsArrowReversed(newIsReversed);
      
      const newAngle = newIsReversed ? -135 : -45; // 각도를 반대로 수정
      const newX = x - arrowLength;
      
      const newArrowPosition = {
        x: newX,
        y: y - cursorOffset,
        angle: newAngle
      };
      
      setArrowPosition(newArrowPosition);

      // 페이지 상태도 함께 업데이트
      const newPages = [...pages];
      newPages[pageIndex] = {
        ...newPages[pageIndex],
        arrowPosition: newArrowPosition,
        isArrowReversed: newIsReversed
      };
      setPages(newPages);

      // 파이어스토어에 화살표 정보 저장
      try {
        const updateData = {
          ...newPages[pageIndex],
          arrowPosition: newArrowPosition,
          isArrowReversed: newIsReversed
        };
        
        await updateWorkOrderPage(
          selectedAddressId,
          pageIds[pageIndex],
          updateData
        );
        
        console.log('화살표 정보 저장 성공');
      } catch (error) {
        console.error('화살표 정보 저장 실패:', error);
        alert('화살표 정보 저장 중 오류가 발생했습니다.');
      }
      return;
    }

    // 일반 클릭인 경우 화살표 생성
    const angle = isArrowReversed ? -135 : -45; // 각도를 반대로 수정
    const startX = x - arrowLength;
    
    const newArrowPosition = {
      x: startX,
      y: y - cursorOffset,
      angle: angle
    };
    
    setArrowPosition(newArrowPosition);

    // 페이지 상태도 함께 업데이트
    const newPages = [...pages];
    newPages[pageIndex] = {
      ...newPages[pageIndex],
      arrowPosition: newArrowPosition,
      isArrowReversed
    };
    setPages(newPages);

    console.log('새 화살표 생성 시도:', {
      addressId: selectedAddressId,
      pageId: pageIds[pageIndex],
      newArrowPosition,
      isArrowReversed
    });

    // 파이어스토어에 화살표 정보 저장
    try {
      const updateData = {
        ...newPages[pageIndex],
        arrowPosition: newArrowPosition,
        isArrowReversed
      };
      
      await updateWorkOrderPage(
        selectedAddressId,
        pageIds[pageIndex],
        updateData
      );
      
      console.log('화살표 정보 저장 성공');
    } catch (error) {
      console.error('화살표 정보 저장 실패:', error);
      alert('화살표 정보 저장 중 오류가 발생했습니다.');
    }
  };

  // 컨텍스트 메뉴 방지
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const renderFloorPlanSection = (pageIndex) => (
    <div 
      className="floor-plan-upload-area"
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={(e) => handleFloorPlanDrop(e, pageIndex)}
    >
      <input 
        type="file" 
        accept="image/*" 
        onChange={(e) => handleFloorPlanUpload(e.target.files[0], pageIndex)}
        style={{ display: 'none' }}
        id={`floor-plan-upload-${pageIndex}`}
      />
      {pages[pageIndex]?.floorPlanFile ? (
        <div 
          className="floor-plan-image-container"
          onClick={(e) => {
            if (!e.target.closest('.image-delete-button')) {
              handleFloorPlanClick(e, pageIndex);
            }
          }}
          onMouseDown={(e) => {
            if (!e.target.closest('.image-delete-button')) {
              handleFloorPlanClick(e, pageIndex);
            }
          }}
          onContextMenu={handleContextMenu}
        >
          <img 
            src={pages[pageIndex].floorPlanFile} 
            alt="2D 평면도" 
            className="floor-plan-image"
            onError={() => {
              const newPages = [...pages];
              newPages[pageIndex] = {
                ...newPages[pageIndex],
                floorPlanFile: null
              };
              setPages(newPages);
              console.error('2D 평면도 이미지 로드 실패');
            }}
          />
          {arrowPosition && (
            <div 
              className="floor-plan-arrow"
              style={{
                left: `${arrowPosition.x}px`,
                top: `${arrowPosition.y}px`,
                transform: `rotate(${arrowPosition.angle}deg)`,
                cursor: 'pointer'
              }}
            />
          )}
          <button 
            className="image-delete-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleFloorPlanDelete(e, pageIndex);
            }}
          >
            ×
          </button>
        </div>
      ) : (
        <label 
          htmlFor={`floor-plan-upload-${pageIndex}`}
          className="floor-plan-upload-label"
        >
          <div className="upload-icon">
            <i className="fas fa-cloud-upload-alt"></i>
          </div>
          <div className="upload-text">
            2D 평면도를 업로드하세요
          </div>
        </label>
      )}
    </div>
  );

  const handleDuplicatePage = async (pageIndex) => {
    if (!selectedAddressId || !pageIds[pageIndex]) return;

    try {
      const pageToDuplicate = pages[pageIndex];
      
      // 현재 페이지와 다음 페이지의 createdAt 시간을 가져옴
      const currentPageRef = doc(db, 'addresses', selectedAddressId, 'workOrders', pageIds[pageIndex]);
      const currentPageSnap = await getDoc(currentPageRef);
      const currentPageData = currentPageSnap.data();
      
      let nextPageCreatedAt;
      
      // 다음 페이지가 있는 경우
      if (pageIndex + 1 < pages.length) {
        const nextPageRef = doc(db, 'addresses', selectedAddressId, 'workOrders', pageIds[pageIndex + 1]);
        const nextPageSnap = await getDoc(nextPageRef);
        const nextPageData = nextPageSnap.data();
        
        // 현재 페이지와 다음 페이지의 createdAt 사이의 중간값 계산
        const currentTime = currentPageData.createdAt.toDate().getTime();
        const nextTime = nextPageData.createdAt.toDate().getTime();
        nextPageCreatedAt = new Date((currentTime + nextTime) / 2);
      } else {
        // 마지막 페이지인 경우 현재 시간 + 1초로 설정
        nextPageCreatedAt = new Date(currentPageData.createdAt.toDate().getTime() + 1000);
      }
      
      // 새 페이지 데이터 생성
      const newPage = {
        memo: pageToDuplicate.memo || '',
        workType: pageToDuplicate.workType,
        layoutType: pageToDuplicate.layoutType,
        location: pageToDuplicate.location,
        labels: JSON.parse(JSON.stringify(pageToDuplicate.labels || [])),
        createdAt: nextPageCreatedAt,
        imageFile: currentPageData.imageUrl,
        floorPlanFile: currentPageData.floorPlanUrl,
        imageUrl: currentPageData.imageUrl,
        floorPlanUrl: currentPageData.floorPlanUrl
      };

      // 새 페이지 저장
      const docId = await saveWorkOrderPage(selectedAddressId, newPage);

      // 새 페이지를 현재 페이지 다음에 삽입
      const insertIndex = pageIndex + 1;
      const newPages = [...pages];
      const newPageIds = [...pageIds];
      
      newPages.splice(insertIndex, 0, {
        ...newPage,
        imageFile: currentPageData.imageUrl,
        floorPlanFile: currentPageData.floorPlanUrl
      });
      newPageIds.splice(insertIndex, 0, docId);
      
      setPages(newPages);
      setPageIds(newPageIds);
      
      // 새로 추가된 페이지로 이동
      setCurrentPageIndex(insertIndex);

      // 새 페이지로 스크롤
      setTimeout(() => {
        const pageElement = document.querySelectorAll('.page-wrapper')[insertIndex];
        if (pageElement) {
          pageElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      }, 100);

    } catch (error) {
      console.error('페이지 복제 중 오류:', error);
      alert('페이지 복제 중 오류가 발생했습니다.');
    }
  };

  const handlePageSelect = (index) => {
    // 작업 페이지만 필터링하여 실제 인덱스 찾기
    const workPages = pages.filter(page => page.workType);
    const actualPageIndex = pages.findIndex((page, i) => {
      return page === workPages[index];
    });
    
    setCurrentPageIndex(actualPageIndex);
    setIsScrolling(true);
    
    // 해당 페이지로 스크롤
    const pageElement = document.querySelectorAll('.page-wrapper')[actualPageIndex];
    if (pageElement) {
      pageElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });

      // 스크롤 애니메이션이 끝난 후 isScrolling 상태 해제
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      scrollTimeoutRef.current = setTimeout(() => {
        setIsScrolling(false);
      }, 1000);
    } else {
      setIsScrolling(false);
    }
  };

  // 스크롤 감지 및 현재 페이지 업데이트
  useEffect(() => {
    const handleScroll = debounce(() => {
      if (isScrolling) return;

      const pageElements = document.querySelectorAll('.page-wrapper');
      const scrollPosition = window.scrollY + window.innerHeight / 2; // 화면 중앙을 기준점으로 변경
      const documentHeight = document.documentElement.scrollHeight;
      const threshold = window.innerHeight / 2; // threshold를 화면 높이의 절반으로 설정

      // 스크롤이 최하단에 도달했는지 확인
      const isAtBottom = window.scrollY + window.innerHeight >= documentHeight - 10;

      if (isAtBottom) {
        // 작업 페이지만 필터링하여 마지막 페이지 인덱스 찾기
        const lastWorkPageIndex = [...pageElements].reduce((lastIndex, element, index) => {
          return pages[index]?.workType ? index : lastIndex;
        }, -1);

        if (lastWorkPageIndex !== -1 && lastWorkPageIndex !== currentPageIndex) {
          setCurrentPageIndex(lastWorkPageIndex);
          return;
        }
      }

      // 각 페이지의 중앙점을 기준으로 거리 계산
      let closestPage = null;
      let closestDistance = Infinity;

      pageElements.forEach((element, index) => {
        if (!pages[index]?.workType) return;

        const rect = element.getBoundingClientRect();
        const elementCenter = rect.top + (rect.height / 2);
        const viewportCenter = window.innerHeight / 2;
        const distance = Math.abs(elementCenter - viewportCenter);

        if (distance < closestDistance) {
          closestDistance = distance;
          closestPage = index;
        }
      });

      if (closestPage !== null && closestPage !== currentPageIndex) {
        setCurrentPageIndex(closestPage);
      }
    }, 100);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [currentPageIndex, pages, isScrolling]);

  // debounce 유틸리티 함수
  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const handlePageReorder = async (sourceIndex, targetIndex) => {
    if (!selectedAddress) return;

    try {
      const newPages = [...pages];
      const newPageIds = [...pageIds];
      
      // 페이지 순서 변경
      const [movedPage] = newPages.splice(sourceIndex, 1);
      const [movedPageId] = newPageIds.splice(sourceIndex, 1);
      
      newPages.splice(targetIndex, 0, movedPage);
      newPageIds.splice(targetIndex, 0, movedPageId);

      // 영향을 받는 페이지들의 범위 결정
      const start = Math.min(sourceIndex, targetIndex);
      const end = Math.max(sourceIndex, targetIndex);

      // 영향을 받는 모든 페이지의 참조 가져오기
      const pageRefs = await Promise.all(
        newPageIds.slice(start, end + 1).map(async (pageId) => {
          const ref = doc(db, 'addresses', selectedAddressId, 'workOrders', pageId);
          const snapshot = await getDoc(ref);
          return { ref, data: snapshot.data() };
        })
      );

      // 시간 간격 계산 (1초 = 1000ms)
      const timeInterval = 1000;

      // 첫 번째 페이지의 기준 시간 가져오기
      let baseTime;
      if (start > 0) {
        // start 이전 페이지의 시간 가져오기
        const prevPageRef = doc(db, 'addresses', selectedAddressId, 'workOrders', newPageIds[start - 1]);
        const prevSnapshot = await getDoc(prevPageRef);
        baseTime = prevSnapshot.data().createdAt.toDate().getTime() + timeInterval;
      } else {
        // 첫 번째 페이지인 경우 현재 시간에서 충분히 이전 시간으로 설정
        baseTime = new Date().getTime() - (pageRefs.length + 1) * timeInterval;
      }

      // 각 페이지의 새로운 createdAt 설정 및 업데이트
      const updatePromises = pageRefs.map(({ ref }, index) => {
        const newCreatedAt = new Date(baseTime + (index + 1) * timeInterval);
        return updateDoc(ref, {
          createdAt: newCreatedAt
        });
      });

      // 모든 업데이트 실행
      await Promise.all(updatePromises);

      // 로컬 상태 업데이트
      setPages(newPages);
      setPageIds(newPageIds);
      
      // 현재 페이지 인덱스 업데이트
      if (currentPageIndex === sourceIndex) {
        setCurrentPageIndex(targetIndex);
      } else if (
        currentPageIndex > sourceIndex && 
        currentPageIndex <= targetIndex
      ) {
        setCurrentPageIndex(currentPageIndex - 1);
      } else if (
        currentPageIndex < sourceIndex && 
        currentPageIndex >= targetIndex
      ) {
        setCurrentPageIndex(currentPageIndex + 1);
      }

    } catch (error) {
      console.error('페이지 순서 변경 중 오류:', error);
      alert('페이지 순서 변경 중 오류가 발생했습니다.');
    }
  };

  const handleTemplateChange = async (newTemplate) => {
    if (!selectedAddressId || !pageIds[editingTemplateIndex]) return;

    try {
      const currentPage = pages[editingTemplateIndex];
      const oldImageUrl = currentPage.imageFile;
      const oldFloorPlanUrl = currentPage.floorPlanFile;

      const newPages = [...pages];
      newPages[editingTemplateIndex] = {
        ...newPages[editingTemplateIndex],
        layoutType: newTemplate,
        labels: [], // 라벨 초기화
        imageFile: null, // 이미지 초기화
        floorPlanFile: null, // 2D 평면도 초기화
        arrowPosition: null, // 화살표 위치 초기화
        isArrowReversed: false // 화살표 방향 초기화
      };
      setPages(newPages);

      // Firebase Storage에서 이미지 삭제 및 Firestore 업데이트
      await updateWorkOrderPage(
        selectedAddressId,
        pageIds[editingTemplateIndex],
        {
          ...newPages[editingTemplateIndex],
          oldImageUrl, // 이전 이미지 URL을 전달하여 Storage에서 삭제
          oldFloorPlanUrl, // 이전 2D 평면도 URL을 전달하여 Storage에서 삭제
          imageUrl: null,
          floorPlanUrl: null
        }
      );

      setShowTemplateChangeModal(false);
      setEditingTemplateIndex(null);
    } catch (error) {
      console.error('템플릿 변경 중 오류:', error);
      alert('템플릿 변경 중 오류가 발생했습니다.');
    }
  };

  // currentPageIndex가 변경될 때마다 화살표 정보 업데이트
  useEffect(() => {
    if (pages[currentPageIndex]) {
      console.log('현재 페이지 변경됨:', {
        pageIndex: currentPageIndex,
        pageData: pages[currentPageIndex]
      });
      
      // 현재 페이지의 화살표 정보로 상태 업데이트
      setArrowPosition(pages[currentPageIndex].arrowPosition);
      setIsArrowReversed(pages[currentPageIndex].isArrowReversed || false);
      
      console.log('화살표 정보 업데이트:', {
        arrowPosition: pages[currentPageIndex].arrowPosition,
        isArrowReversed: pages[currentPageIndex].isArrowReversed
      });
    }
  }, [currentPageIndex, pages]);

  return (
    <div className="pages-wrapper">
      <div className={`pages-container ${pages.length > 1 ? 'has-multiple-pages' : ''}`}>
        {renderCoverPage()}
        {selectedAddress && pages.map((page, index) => (
          page.workType && (
            <div 
              key={index}
              ref={el => pageRefs.current[index] = el}
              className={`page-wrapper ${currentPageIndex === index ? 'active' : ''}`}
            >
              {renderWorkOrderPage(index)}
            </div>
          )
        ))}
      </div>
      {selectedAddress && (
        <>
          <PageControls
            onAddWorkType={() => setShowWorkTypeModal(true)}
            onDelete={() => handleDeletePage(currentPageIndex)}
            onDuplicate={() => handleDuplicatePage(currentPageIndex)}
            onChangeTemplate={() => {
              setEditingTemplateIndex(currentPageIndex);
              setShowTemplateChangeModal(true);
            }}
            onManageMaterial={() => setShowMaterialManageModal(true)}
            onBulkUpload={() => setShowBulkUploadModal(true)}
            hasPages={pages.some(page => page.workType)}
          />
          {pages.some(page => page.workType) && (
            <>
              <PdfControls
                currentPage={currentPageIndex}
                pages={pages.filter(page => page.workType)}
                pageRefs={pageRefs}
              />
              <PageMinimap
                pages={pages.filter(page => page.workType)}
                currentPage={currentPageIndex}
                onPageSelect={handlePageSelect}
                onPageReorder={handlePageReorder}
              />
            </>
          )}
        </>
      )}
      {showWorkTypeModal && <WorkTypeModal />}
      {showLayoutModal && <LayoutModal onSelect={handleLayoutSelect} onClose={() => setShowLayoutModal(false)} />}
      {showMaterialModal && selectedLabelId && (
        <MaterialModal
          onClose={() => {
            setShowMaterialModal(false);
            setSelectedLabelId(null);
          }}
          onSave={handleMaterialSave}
          initialData={pages[currentPageIndex]?.labels?.find(label => label.id === selectedLabelId)?.materialInfo}
        />
      )}
      {showMaterialManageModal && (
        <MaterialManageModal
          onClose={() => setShowMaterialManageModal(false)}
        />
      )}
      {showBulkUploadModal && (
        <BulkUploadModal
          onClose={() => setShowBulkUploadModal(false)}
          onUpload={handleBulkUpload}
        />
      )}
      {showTemplateChangeModal && editingTemplateIndex !== null && (
        <TemplateChangeModal
          onClose={() => {
            setShowTemplateChangeModal(false);
            setEditingTemplateIndex(null);
          }}
          onSelect={handleTemplateChange}
          currentTemplate={pages[editingTemplateIndex].layoutType}
        />
      )}
    </div>
  );
};

export default WorkOrder; 