import React, { useState, useEffect } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import SavedItemsModal from './saved-items/SavedItemsModal';
import SaveItemMemoModal from './saved-items/SaveItemMemoModal';
import './EditItemModal.css';

const EditItemModal = ({ item, processId, onSave, onClose }) => {
  const [editedItem, setEditedItem] = useState({
    ...item,
    name: item.name || '',
    quantity: item.quantity || '',
    unitPrice: item.unitPrice || '',
    unit: item.unit || '',
    note: item.note || '',
    excludeFromTotal: item.excludeFromTotal || false,
    isAdditional: item.isAdditional || false,
    isSeparate: item.isSeparate || false,
    uniqueId: item.uniqueId,
    originalName: item.name?.replace(/\s*\((가격미정|서비스|별도)\)$/, '') || ''
  });

  const [isUndecided, setIsUndecided] = useState(item.name?.endsWith('(가격미정)') || false);
  const [isExcluded, setIsExcluded] = useState(item.note === '미포함');
  const [isSeparate, setIsSeparate] = useState(item.isSeparate || item.note === '별도' || false);
  const [isService, setIsService] = useState(item.name?.endsWith('(서비스)') || false);
  const [isAdditional, setIsAdditional] = useState(item.isAdditional || false);
  const [showSavedItems, setShowSavedItems] = useState(false);
  const [showMemoModal, setShowMemoModal] = useState(false);

  const handleCheckboxChange = (type) => {
    // 모든 상태를 초기화하는 함수
    const resetAllStates = () => {
      setIsUndecided(false);
      setIsExcluded(false);
      setIsSeparate(false);
      setIsService(false);
    };

    // 추가 체크박스는 다른 체크박스들과 독립적으로 동작
    if (type === 'additional') {
      const newIsAdditional = !isAdditional;
      setIsAdditional(newIsAdditional);
      setEditedItem(prev => ({
        ...prev,
        isAdditional: newIsAdditional
      }));
      return;
    }

    // 현재 선택된 체크박스의 상태 확인
    const currentState = {
      undecided: isUndecided,
      excluded: isExcluded,
      separate: isSeparate,
      service: isService
    };

    // 이미 선택된 상태를 다시 클릭하면 해제
    if (currentState[type]) {
      resetAllStates();
      setEditedItem(prev => ({
        ...prev,
        name: prev.originalName,
        note: '',
        unitPrice: prev._originalPrice || '',
        isSeparate: false
      }));
      return;
    }

    // 새로운 체크박스 선택 시 다른 모든 체크박스 해제
    resetAllStates();

    // 선택된 체크박스에 따른 처리
    switch (type) {
      case 'service':
        setIsService(true);
        setEditedItem(prev => {
          const originalPrice = prev.unitPrice;
          return {
            ...prev,
            name: `${prev.originalName}(서비스)`,
            note: `${formatNumber(originalPrice)}원 상당의 서비스`,
            unitPrice: '0',
            _originalPrice: originalPrice
          };
        });
        break;

      case 'undecided':
        setIsUndecided(true);
        setEditedItem(prev => ({
          ...prev,
          name: `${prev.originalName}(가격미정)`,
          note: '가격 확인 필요'
        }));
        break;

      case 'excluded':
        setIsExcluded(true);
        setEditedItem(prev => ({
          ...prev,
          note: '미포함',
          unitPrice: '0'
        }));
        break;

      case 'separate':
        setIsSeparate(true);
        setEditedItem(prev => ({
          ...prev,
          name: `${prev.originalName}(별도)`,
          note: '별도',
          isSeparate: true
        }));
        break;
    }
  };

  const handleNameChange = (value) => {
    setEditedItem(prev => ({
      ...prev,
      name: value,
      originalName: value.replace(/\s*\((가격미정|서비스|별도)\)$/, '')
    }));
  };

  const handleNoteChange = (value) => {
    setEditedItem(prev => ({
      ...prev,
      note: value,
      isSeparate: false
    }));
    setIsUndecided(false);
    setIsExcluded(false);
    setIsSeparate(false);
  };

  const handleSave = () => {
    if (!editedItem.name?.trim()) {
      alert('품목명을 입력해주세요.');
      return;
    }

    const savedItem = {
      ...editedItem,
      quantity: parseInt(editedItem.quantity) || 0,
      unitPrice: parseInt(editedItem.unitPrice) || 0,
      totalPrice: calculateTotal(),
      isAdditional: isAdditional,
      isSeparate: isSeparate,
      uniqueId: editedItem.uniqueId
    };

    console.log('저장되는 항목:', savedItem);
    onSave(savedItem);
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleUnitPriceChange = (value) => {
    setEditedItem(prev => ({
      ...prev,
      unitPrice: value
    }));

    if (value && isExcluded) {
      setIsExcluded(false);
      setEditedItem(prev => ({
        ...prev,
        unitPrice: value,
        note: ''
      }));
    }
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose]);

  const handleSaveItem = async () => {
    try {
      if (!editedItem.name.trim() || !editedItem.unitPrice) {
        alert('품목명과 단가를 입력해주세요.');
        return;
      }
      setShowMemoModal(true);
    } catch (error) {
      console.error('품목 저장 중 오류:', error);
      alert('품목 저장 중 오류가 발생했습니다.');
    }
  };

  const handleSaveWithMemo = async (itemWithMemo) => {
    try {
      console.group('💾 품목 저장');
      console.log('저장할 데이터:', {
        processId,
        name: itemWithMemo.name,
        unit: itemWithMemo.unit,
        unitPrice: parseInt(itemWithMemo.unitPrice) || 0,
        note: itemWithMemo.note,
        memo: itemWithMemo.memo,
        createdAt: new Date().toISOString()
      });

      const docRef = await addDoc(collection(db, 'savedItems'), {
        processId,
        name: itemWithMemo.name,
        unit: itemWithMemo.unit,
        unitPrice: parseInt(itemWithMemo.unitPrice) || 0,
        note: itemWithMemo.note,
        memo: itemWithMemo.memo,
        createdAt: new Date().toISOString()
      });

      console.log('✅ 저장 완료. Document ID:', docRef.id);
      console.groupEnd();

      setShowMemoModal(false);
      alert('품목이 저장되었습니다.');
    } catch (error) {
      console.error('❌ 품목 저장 중 오류:', error);
      console.groupEnd();
      alert('품목 저장 중 오류가 발생했습니다.');
    }
  };

  const handleSelectSavedItem = (item) => {
    setEditedItem({
      ...editedItem,
      name: item.name,
      unit: item.unit,
      unitPrice: item.unitPrice.toString(),
      note: item.note || '',
      quantity: editedItem.quantity // 기존 수량 유지
    });
    setShowSavedItems(false);
  };

  const calculateTotal = () => {
    if (editedItem.excludeFromTotal) {
      return 0;  // 금액 0 표시가 체크되어 있으면 0 반환
    }
    return (parseInt(editedItem.quantity) || 0) * (parseInt(editedItem.unitPrice) || 0);
  };

  const getDisplayName = () => {
    if (!editedItem.name) return '';
    
    return (
      <div className="item-name-display">
        <span>{editedItem.name}</span>
        {isAdditional && <span className="additional-tag">(추가)</span>}
      </div>
    );
  };

  return (
    <div className="edit-modal-overlay">
      <div className="edit-modal">
        <div className="edit-modal-content">
          <h3>항목 수정</h3>
          <div className="edit-field">
            <label>품목명</label>
            <input
              type="text"
              value={editedItem.name}
              onChange={(e) => handleNameChange(e.target.value)}
              placeholder="품목명 입력"
            />
          </div>
          <div className="edit-field">
            <label>수량</label>
            <input
              type="number"
              value={editedItem.quantity}
              onChange={(e) => setEditedItem(prev => ({
                ...prev,
                quantity: e.target.value
              }))}
              placeholder="수량 입력"
            />
          </div>
          <div className="edit-field">
            <label>단위</label>
            <input
              type="text"
              value={editedItem.unit}
              onChange={(e) => setEditedItem(prev => ({
                ...prev,
                unit: e.target.value
              }))}
              placeholder="단위 입력"
            />
          </div>
          <div className="edit-field">
            <label>단가</label>
            <input
              type="text"
              className="no-spinner"
              value={editedItem.unitPrice === '0' ? '' : editedItem.unitPrice}
              onChange={(e) => handleUnitPriceChange(e.target.value)}
              placeholder="단가 입력"
            />
          </div>
          <div className="checkbox-group">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isUndecided}
                onChange={() => handleCheckboxChange('undecided')}
              />
              가격미정
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isExcluded}
                onChange={() => handleCheckboxChange('excluded')}
              />
              미포함
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isSeparate}
                onChange={() => handleCheckboxChange('separate')}
              />
              별도
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isService}
                onChange={() => handleCheckboxChange('service')}
              />
              서비스
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isAdditional}
                onChange={() => handleCheckboxChange('additional')}
              />
              추가
            </label>
          </div>
          <div className="edit-field">
            <label>비고</label>
            <textarea
              value={editedItem.note}
              onChange={(e) => setEditedItem(prev => ({
                ...prev,
                note: e.target.value
              }))}
              placeholder="비고 입력"
              className="note-textarea"
              rows={3}
            />
          </div>
          <div className="checkbox-group" style={{ marginTop: '10px' }}>
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={editedItem.excludeFromTotal}
                onChange={(e) => setEditedItem(prev => ({
                  ...prev,
                  excludeFromTotal: e.target.checked
                }))}
              />
              금액 0 표시
            </label>
          </div>
          <div className="total-price">
            <label>합계:</label>
            <span>{formatNumber(calculateTotal())}원</span>
          </div>
          <div className="item-management-buttons">
            <button onClick={() => setShowSavedItems(true)}>
              저장된 품목 검색
            </button>
            <button onClick={handleSaveItem}>
              현재 품목 저장
            </button>
          </div>
          <div className="edit-buttons">
            <button onClick={handleSave}>저장</button>
            <button onClick={onClose}>취소</button>
          </div>
        </div>
      </div>

      {showMemoModal && (
        <SaveItemMemoModal
          item={editedItem}
          onSave={handleSaveWithMemo}
          onClose={() => setShowMemoModal(false)}
        />
      )}

      {showSavedItems && (
        <SavedItemsModal
          processId={processId}
          onSelect={handleSelectSavedItem}
          onClose={() => setShowSavedItems(false)}
        />
      )}
    </div>
  );
};

export default EditItemModal; 