import React, { useState, useEffect, useRef } from 'react';
import './ProcessFilter.css';

const ProcessFilter = ({ processes, selectedProcess, onProcessChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProcesses, setFilteredProcesses] = useState(processes);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const filtered = processes.filter(process =>
      process.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProcesses(filtered);
  }, [searchTerm, processes]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleProcessSelect = (process) => {
    onProcessChange(process);
    setIsOpen(false);
    setSearchTerm('');
  };

  return (
    <div className="processfilter-container" ref={dropdownRef}>
      <div 
        className="processfilter-header" 
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="processfilter-selected">
          {selectedProcess || '모든 공정'}
        </span>
      </div>

      {isOpen && (
        <div className="processfilter-dropdown">
          <div className="processfilter-search-container">
            <input
              type="text"
              className="processfilter-search-input"
              placeholder="공정 검색..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <div className="processfilter-list">
            <div
              className={`processfilter-item ${!selectedProcess ? 'processfilter-item-selected' : ''}`}
              onClick={() => handleProcessSelect('')}
            >
              모든 공정
            </div>
            {filteredProcesses.map((process) => (
              <div
                key={process}
                className={`processfilter-item ${selectedProcess === process ? 'processfilter-item-selected' : ''}`}
                onClick={() => handleProcessSelect(process)}
              >
                {process}
              </div>
            ))}
            {filteredProcesses.length === 0 && (
              <div className="processfilter-no-results">검색 결과가 없습니다</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProcessFilter; 