import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';

// 나눔고딕 폰트 등록
Font.register({
  family: 'NanumGothic',
  src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf'
});

// PDF 스타일 정의
const styles = StyleSheet.create({
  page: {
    padding: 35,
    fontFamily: 'NanumGothic',
    fontSize: 10,
    backgroundColor: '#ffffff',
  },
  header: {
    marginTop: 10,
    marginBottom: 15,
    textAlign: 'center',
    position: 'relative',
  },
  title: {
    fontSize: 18,
    letterSpacing: 2,
    color: '#2c3e50',
    marginBottom: 8,
  },
  titleLine: {
    width: '40%',
    height: 0.5,
    backgroundColor: '#95a5a6',
    margin: '0 auto',
  },
  section: {
    marginBottom: 10,
    backgroundColor: '#ffffff',
    borderRadius: 4,
  },
  sectionTitle: {
    fontSize: 12,
    color: '#34495e',
    marginBottom: 12,
    paddingBottom: 4,
    borderBottom: 0.5,
    borderColor: '#bdc3c7',
    fontWeight: 'bold',
  },
  sectionTitleNoLine: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 3,
    paddingBottom: 0,
    color: '#2c3e50',
  },
  row: {
    flexDirection: 'row',
    marginBottom: 6,
    paddingHorizontal: 8,
    flexWrap: 'wrap',
  },
  label: {
    minWidth: 80,
    fontSize: 10,
    color: '#7f8c8d',
    marginRight: 4,
  },
  value: {
    flex: 1,
    fontSize: 10,
    color: '#2c3e50',
    wordBreak: 'break-all',
  },
  partyInfo: {
    flexDirection: 'row',
    gap: 20,
    marginBottom: 15,
  },
  party: {
    flex: 1,
    padding: 12,
    backgroundColor: '#f8f9fa',
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: '#ecf0f1',
  },
  partyTitle: {
    fontSize: 11,
    color: '#34495e',
    marginBottom: 10,
    textAlign: 'center',
  },
  terms: {
    marginTop: 1,
    paddingHorizontal: 6,
  },
  termItem: {
    fontSize: 9,
    marginBottom: 1,
    color: '#34495e',
    lineHeight: 1.3,
    position: 'relative',
    paddingLeft: 8,
  },
  paymentInfo: {
    backgroundColor: '#f8f9fa',
    padding: 10,
    marginTop: 8,
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: '#ecf0f1',
  },
  paymentTitle: {
    fontSize: 11,
    color: '#34495e',
    marginBottom: 8,
    paddingBottom: 4,
    borderBottom: 0.5,
    borderColor: '#ecf0f1',
  },
  scheduleItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 9,
    marginBottom: 0,
    paddingHorizontal: 4,
    paddingVertical: 6,
    borderBottomWidth: 0.5,
    borderBottomColor: '#ecf0f1',
  },
  scheduleItemLast: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 9,
    paddingHorizontal: 4,
    paddingVertical: 6,
    borderBottomWidth: 0,
  },
  scheduleLabel: {
    color: '#34495e',
    flex: 1,
  },
  scheduleAmount: {
    color: '#2c3e50',
    fontWeight: 'bold',
    textAlign: 'right',
    minWidth: 100,
  },
  footer: {
    marginTop: 20,
    position: 'relative',
  },
  date: {
    fontSize: 10,
    textAlign: 'center',
    marginBottom: 15,
    marginTop: 10,
    color: '#34495e',
  },
  signatures: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 10,
    paddingHorizontal: 40,
  },
  signatureItem: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    position: 'relative',
  },
  signatureName: {
    fontSize: 10,
    color: '#34495e',
    width: 100,
  },
  signatureText: {
    fontSize: 10,
    color: '#34495e',
    position: 'relative',
    width: 45,
    textAlign: 'center',
  },
  signatureBox: {
    width: 150,
    height: 40,
    border: 1,
    marginTop: 5,
    position: 'relative',
  },
  sealImage: {
    width: 45,
    height: 45,
    position: 'absolute',
    right: 0,
    top: -16,
    zIndex: 1,
  },
});

// PDF 문서 컴포넌트
const ContractDocument = ({ contractData = {}, hasSashWork = false }) => {
  const formatCurrency = (amount) => {
    if (!amount && amount !== 0) return '0원';
    
    if (typeof amount === 'number') {
      return new Intl.NumberFormat('ko-KR').format(amount) + '원';
    }
    
    if (typeof amount === 'string') {
      const numericValue = parseInt(amount.replace(/[^0-9]/g, '') || '0');
      return new Intl.NumberFormat('ko-KR').format(numericValue) + '원';
    }
    
    return '0원';
  };

  const calculateThirdPayment = () => {
    const total = typeof contractData?.totalAmount === 'number' 
      ? contractData.totalAmount 
      : parseInt((contractData?.totalAmount || '0').replace(/[^0-9]/g, '') || '0');
    
    const firstPayment = 1000000;
    const sashPayment = hasSashWork ? 7000000 : 0;
    const secondPayment = 5000000;
    const fifthPayment = 3000000;

    const thirdPayment = total - firstPayment - sashPayment - secondPayment - fifthPayment;
    return thirdPayment > 0 ? thirdPayment : 0;
  };

  const today = new Date();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>공사 계약서</Text>
          <View style={styles.titleLine} />
        </View>

        <View style={[styles.section, { marginTop: 10, marginBottom: 15 }]}>
          <Text style={[styles.sectionTitle, { color: '#2c3e50' }]}>공사 정보</Text>
          <View style={styles.row}>
            <Text style={styles.label}>공사명:</Text>
            <Text style={styles.value}>
              {contractData?.description || ''} {contractData?.area ? `${contractData.area}평` : ''}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>공사기간:</Text>
            <Text style={styles.value}>
              착공일: {contractData?.startDate || ''} / 준공일: {contractData?.endDate || ''}
            </Text>
          </View>
        </View>

        <View style={[styles.section, { marginTop: 10, marginBottom: 15 }]}>
          <Text style={[styles.sectionTitle, { color: '#2c3e50' }]}>계약 당사자</Text>
          <View style={styles.partyInfo}>
            <View style={styles.party}>
              <Text style={styles.partyTitle}>발주자 (갑)</Text>
              <View style={styles.row}>
                <Text style={styles.label}>성명:</Text>
                <Text style={styles.value}>{contractData?.clientName || ''}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>주소:</Text>
                <Text style={styles.value}>{contractData?.clientAddress || ''}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>주민등록번호:</Text>
                <Text style={styles.value}>{contractData?.clientId || ''}</Text>
              </View>
            </View>
            <View style={styles.party}>
              <Text style={styles.partyTitle}>수급자 (을)</Text>
              <View style={styles.row}>
                <Text style={styles.label}>상호:</Text>
                <Text style={styles.value}>아마레디자인</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>주소:</Text>
                <Text style={styles.value}>수성구 들안로 215</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>대표자:</Text>
                <Text style={styles.value}>권 경 대</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.section, { marginTop: 10, marginBottom: 15 }]}>
          <Text style={[styles.sectionTitle, { color: '#2c3e50' }]}>계약 내용</Text>
          <View style={styles.terms}>
            {['• "갑"은 전항의 공사대금지급과 관련하여 정당한 사유 없이 지급을 지연하여 서는 안된다.',
              '• 미지급으로 생기는 문제의 책임은 "갑"에게 있으며, "을"은 합당한 권리를 요구 할 수 있다.',
              '• 공사완공 후 공사금액을 D/C할시 하자보수이행을 인정하지 않는다.',
              '• 모든 공정 완료 및 입주 후에는 2주의 테스트 기간을 둘 수 있으며 서로의 완전한 합의 하에 잔금을 지급한다.'].map((text, index) => (
              <Text key={index} style={styles.termItem}>{text}</Text>
            ))}
          </View>
        </View>

        <View style={[styles.section, { marginTop: 10, marginBottom: 15 }]}>
          <Text style={[styles.sectionTitle, { color: '#2c3e50' }]}>계약 금액</Text>
          <View style={styles.row}>
            <Text style={styles.label}>총 공사금액:</Text>
            <Text style={styles.value}>{formatCurrency(contractData?.totalAmount)}</Text>
          </View>
          <View style={styles.paymentInfo}>
            <Text style={styles.paymentTitle}>지불 방식</Text>
            <View style={styles.scheduleItem}>
              <Text style={styles.scheduleLabel}>1차 계약금</Text>
              <Text style={styles.scheduleAmount}>{formatCurrency(1000000)}</Text>
            </View>
            {hasSashWork && (
              <View style={styles.scheduleItem}>
                <Text style={styles.scheduleLabel}>1.5차 샷시 제작 선수금</Text>
                <Text style={styles.scheduleAmount}>{formatCurrency(7000000)}</Text>
              </View>
            )}
            <View style={styles.scheduleItem}>
              <Text style={styles.scheduleLabel}>2차 착수금(공사 시작)</Text>
              <Text style={styles.scheduleAmount}>{formatCurrency(5000000)}</Text>
            </View>
            <View style={styles.scheduleItem}>
              <Text style={styles.scheduleLabel}>3차 목공사부터</Text>
              <Text style={styles.scheduleAmount}>
                {contractData?.totalAmount ? formatCurrency(calculateThirdPayment()) : '여러번의 중도금(제작가구 선급금포함)'}
              </Text>
            </View>
            <View style={styles.scheduleItem}>
              <Text style={styles.scheduleLabel}>4차</Text>
              <Text style={styles.scheduleAmount}>약속한 중도급 완납</Text>
            </View>
            <View style={styles.scheduleItemLast}>
              <Text style={styles.scheduleLabel}>5차 완공 후</Text>
              <Text style={styles.scheduleAmount}>{formatCurrency(3000000)}</Text>
            </View>
          </View>
        </View>

        <View style={styles.footer}>
          <Text style={styles.date}>
            {today.getFullYear()}년 {today.getMonth() + 1}월 {today.getDate()}일
          </Text>
          <View style={styles.signatures}>
            <View style={styles.signatureItem}>
              <Text style={styles.signatureName}>발주자: {contractData?.clientName || ''}</Text>
              <Text style={styles.signatureText}>(인)</Text>
            </View>
            <View style={styles.signatureItem}>
              <Text style={styles.signatureName}>수급자: 권 경 대</Text>
              <Text style={styles.signatureText}>(인)</Text>
              <Image src="/images/도장.png" style={styles.sealImage} />
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={[styles.header, { marginBottom: 8, paddingBottom: 3 }]}>
          <Text style={styles.title}>계약 일반조건</Text>
        </View>

        <View style={[styles.section, { marginTop: 2 }]}>
          <Text style={styles.sectionTitleNoLine}>제1조(공사내역)</Text>
          <View style={styles.terms}>
            <Text style={styles.termItem}>① "을"은 공사착수 전 "갑"에게 설계도서(도면, 시방서, 공사내역서 등)을 제출하여 승인을 득하여야 한다.</Text>
            <Text style={styles.termItem}>② 공사에 사용할 재료의 품질 및 규격은 설계서와 일치되어야 한다. 다만, 설계도서에 품질•품명이 명확히 규정되지 아니하거나 해당 재료가 품절인 경우 상호 협의하여 달리 결정할 수 있다.</Text>
            <Text style={styles.termItem}>③ 전항의 합의가 없는 경우 공사에 사용할 재료는 표준 폼 또는 표준 폼에 상당하는 재료로서 계약의 목적을 달성하는데 가장 적합한 것으로 한다.</Text>
          </View>
        </View>

        <View style={[styles.section, { marginTop: 2 }]}>
          <Text style={styles.sectionTitleNoLine}>제2조(공사의 변경 및 조정)</Text>
          <View style={styles.terms}>
            <Text style={styles.termItem}>① 공사 진행 중 또는 공사완료 후 "갑"의 요구에 의하여 공사내용을 변경하거나 추가할 경우 "을"은 이에 응할 수 있으며, "갑"은 변경 및 조정에 따르는 추가경비를 "을"에게 지불하여야 한다. 공사완료 후 공사변경은 별도계약에 의하여 처리하여야 한다.</Text>
            <Text style={styles.termItem}>② "을"은 공사의 원활한 진행 및 계약목적의 효율적 달성을 위해 공사내용 및 공법의 변경을 "갑"에게 요청할 수 있다.</Text>
            <Text style={styles.termItem}>③ 예정 준공일은 공사내용의 변경에 따라 추가로 소요되는 기간을 고려하여 연장 할 수 있다.</Text>
            <Text style={styles.termItem}>④ 제1항 및 제2항에 의하여 공사금액이 변경되는 경우 잔금 지급 시 정산된다.</Text>
          </View>
        </View>

        <View style={[styles.section, { marginTop: 2 }]}>
          <Text style={styles.sectionTitleNoLine}>제3조(하자보수)</Text>
          <View style={styles.terms}>
            <Text style={styles.termItem}>① "을"은 공사 완료 후 12개월 이내에 발생한 일부 또는 전부하자에 대해 보수할 책임이 있다. 다만, "갑"과 "을"은 하자보수를 위한 기간의 연장 또는 단축을 별도로 합의할 수 있다.</Text>
            <Text style={styles.termItem}>② "갑"의 부주의 또는 천재지변으로 인한 경우 "을"은 면책된다.</Text>
          </View>
        </View>

        <View style={[styles.section, { marginTop: 2 }]}>
          <Text style={styles.sectionTitleNoLine}>제4조(이행지체)</Text>
          <View style={styles.terms}>
            <Text style={styles.termItem}>① "을"은 준공 기한 내에 공사를 완성하지 못하였을 때에는 매 지체일당 공사금액의 2/1,000에 해당하는 금액을 "갑"에게 지불하여야 한다.</Text>
            <Text style={styles.termItem}>② "을"은 노사분규, 천재지변 등 기타 특수한 사정으로 인하여 공사가 지연될 경우에는 즉시 "갑"에게 통보하고, 당해 사유의 종료 시까지 기한의 연장을 요청할 수 있다.</Text>
            <Text style={styles.termItem}>③ "갑"의 요구에 의한 설계변경 및 제2항의 사유에 의한 공사지연의 경우에는 지체보상금을 면제한다.</Text>
          </View>
        </View>

        <View style={[styles.section, { marginTop: 2 }]}>
          <Text style={styles.sectionTitleNoLine}>제5조(계약의 해제 또는 해지)</Text>
          <View style={styles.terms}>
            <Text style={styles.termItem}>① "갑"또는 "을"은 다음 각 호의 1에 해당하는 경우 서면으로 당해 계약의 일부 또는 전부를 해제•해지할 수 있다.</Text>
            <Text style={styles.termItem}>1. "을"이 정당한 사유 없이 착공을 지연시킬 경우</Text>
            <Text style={styles.termItem}>2. "을"의 명백한 귀책사유로 인해 공사가 지연되거나 공사를 완성할 가능성이 없다고 인정될 경우</Text>
            <Text style={styles.termItem}>3. "갑" 또는 "을"이 기타 계약내용의 위반으로 계약의 목적을 달성할 수 없다고 인정될 경우</Text>
            <Text style={styles.termItem}>② 전항의 규정에 의하여 계약이 해제 또는 해지될 경우 책임이 있는 일방은 상대방의 손해를 배상할 책임이 있다. 단, 천재지변 등 불가항력 사유로 인한 경우에는 배상책임을 면제한다.</Text>
          </View>
        </View>

        <View style={[styles.section, { marginTop: 2 }]}>
          <Text style={styles.sectionTitleNoLine}>제6조(권리 및 의무의 양도)</Text>
          <View style={styles.terms}>
            <Text style={styles.termItem}>"을"은 계약된 공사의 일부 또는 전부를 제3자에게 양도 또는 하도급 할 수 없다. 다만, 공사의 편의 및 공정의 특수성이 있는 때 또는 "갑"의 동의가 있는 경우에는 예외로 한다.</Text>
          </View>
        </View>

        <View style={[styles.section, { marginTop: 2 }]}>
          <Text style={styles.sectionTitleNoLine}>제7조(기타 사항)</Text>
          <View style={styles.terms}>
            <Text style={styles.termItem}>① "갑"은 "을"이 당해 공사를 원활하게 진행할 수 있도록 필요한 제반 조치(건축허가 등 주무관청의 인허가, 민원해결 등)에 협조하여야 하며, 이와 관련된 제반비용도 부담하여야 한다.</Text>
            <Text style={styles.termItem}>② "갑"과 "을"은 상호 협의 하여 본 계약서를 변경 또는 수정할 수 있다.</Text>
            <Text style={styles.termItem}>③ 본 계약서에 규정하지 않은 사항에 대해서는 "갑"과 "을"이 대등한 지위에서 합의하여 특약으로 정할 수 있다.</Text>
          </View>
        </View>

        <View style={[styles.section, { marginTop: 2 }]}>
          <Text style={styles.sectionTitleNoLine}>제8조(분쟁의 해결)</Text>
          <View style={styles.terms}>
            <Text style={styles.termItem}>본 계약에서 발생한 분쟁은 합의에 의하여 해결함을 원칙으로 하고, 당사자 사이에 해결되지 않는 분쟁은 사법부에 의하여 최종 해결한다.</Text>
          </View>
        </View>

        <View style={styles.footer}>
          <Text style={styles.date}>
            {today.getFullYear()}년 {today.getMonth() + 1}월 {today.getDate()}일
          </Text>
          <View style={styles.signatures}>
            <View style={styles.signatureItem}>
              <Text style={styles.signatureName}>발주자: {contractData?.clientName || ''}</Text>
              <Text style={styles.signatureText}>(인)</Text>
            </View>
            <View style={styles.signatureItem}>
              <Text style={styles.signatureName}>수급자: 권 경 대</Text>
              <Text style={styles.signatureText}>(인)</Text>
              <Image src="/images/도장.png" style={styles.sealImage} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

// PDF 다운로드 버튼 컴포넌트
const ContractPDF = ({ contractData = {}, hasSashWork = false }) => {
  return (
    <PDFDownloadLink
      document={<ContractDocument contractData={contractData} hasSashWork={hasSashWork} />}
      fileName="공사계약서.pdf"
      style={{
        textDecoration: 'none',
        width: '100%',
        display: 'block'
      }}
    >
      {({ blob, url, loading, error }) => (
        <button 
          className="save-button contract-action-button"
          disabled={loading || error || !contractData}
          style={{
            width: '100%',
            padding: '12px',
            fontSize: '16px',
            marginTop: '16px',
            marginBottom: '16px'
          }}
        >
          {loading ? '생성 중...' : 'PDF 다운로드'}
        </button>
      )}
    </PDFDownloadLink>
  );
};

export default ContractPDF; 