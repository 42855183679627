import React, { useState } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, pdf } from '@react-pdf/renderer';
import { sendConstructionGuideEmail } from '../services/emailService';

// 나눔고딕 폰트 등록
Font.register({
  family: 'NanumGothic',
  src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf'
});

// PDF 스타일 정의
const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: '100%',
    padding: '20 20',
    backgroundColor: '#ffffff',
    fontFamily: 'NanumGothic',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    width: '100%',
    maxWidth: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    width: '100%',
    marginBottom: 15,
    textAlign: 'center',
    borderBottom: 1,
    borderColor: '#946A6A',
    paddingBottom: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#333333',
    textAlign: 'center',
  },
  greeting: {
    width: '100%',
    marginBottom: 12,
    fontSize: 11,
    textAlign: 'center',
  },
  addressSelect: {
    width: '100%',
    fontSize: 11,
    marginBottom: 12,
    padding: '6 12',
    backgroundColor: '#f8f9fa',
    borderRadius: 4,
    color: '#333333',
    textAlign: 'center',
  },
  notice: {
    width: '100%',
    marginBottom: 15,
    fontSize: 10,
    lineHeight: 1.4,
    textAlign: 'center',
  },
  noticeText: {
    marginBottom: 4,
    textAlign: 'center',
  },
  infoSection: {
    width: '100%',
    marginBottom: 15,
    padding: '10 15',
    backgroundColor: '#f8f9fa',
    borderRadius: 4,
  },
  infoRow: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: 0,
    fontSize: 10,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 8,
    paddingBottom: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#e9ecef',
  },
  infoRow_last: {
    width: '100%',
    flexDirection: 'row',
    fontSize: 10,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 8,
    paddingBottom: 8,
    borderBottomWidth: 0,
  },
  label: {
    width: '30%',
    color: '#666666',
    textAlign: 'center',
  },
  value: {
    flex: 1,
    color: '#333333',
    textAlign: 'center',
  },
  contact: {
    width: '100%',
    marginBottom: 15,
    fontSize: 10,
    textAlign: 'center',
    backgroundColor: '#f8f9fa',
    padding: '10 15',
    borderRadius: 4,
  },
  contactText: {
    marginBottom: 8,
    color: '#333333',
    textAlign: 'center',
  },
  phone: {
    fontSize: 12,
    color: '#946A6A',
    marginTop: 6,
    marginBottom: 6,
    textDecoration: 'none',
    textAlign: 'center',
  },
  companyName: {
    fontSize: 10,
    color: '#666666',
    textAlign: 'center',
  },
  schedule: {
    width: '100%',
    marginTop: 15,
  },
  scheduleTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
    borderBottom: 1,
    borderColor: '#946A6A',
    paddingBottom: 4,
    textAlign: 'center',
  },
  table: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#dddddd',
    borderRadius: 4,
  },
  tableHeader: {
    width: '100%',
    flexDirection: 'row',
    backgroundColor: '#f8f9fa',
    padding: '8 6',
    borderBottomWidth: 1,
    borderBottomColor: '#dddddd',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableHeaderCell: {
    flex: 1,
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableRow: {
    width: '100%',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#dddddd',
    padding: '6 6',
    minHeight: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableRow_last: {
    width: '100%',
    flexDirection: 'row',
    padding: '6 6',
    borderBottomWidth: 0,
    minHeight: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableCell: {
    flex: 1,
    fontSize: 9,
    textAlign: 'center',
    color: '#333333',
  }
});

const formatDate = (date) => {
  if (!date) return '';
  const d = new Date(date);
  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
};

// PDF 문서 컴포넌트
const ConstructionGuideDocument = ({ selectedAddress, constructionPeriod, processDates, hideUndecided }) => {
  const processItems = [
    { work: '철거 공사', noise: '매우 심함' },
    { work: '샷시 공사', noise: '매우 심함' },
    { work: '설비 공사', noise: '매우 심함' },
    { work: '전기 공사', noise: '중간(간헐적)' },
    { work: '목 공사', noise: '중간' },
    { work: '타일 공사', noise: '중간' },
    { work: '필름 공사', noise: '약함' },
    { work: '마루 공사', noise: '중간' },
    { work: '제작가구 공사', noise: '중간' },
    { work: '도배 공사', noise: '약함' },
    { work: '입주 청소', noise: '약함' }
  ].filter(item => {
    if (hideUndecided) {
      return processDates?.get(item.work) !== undefined;
    }
    return true;
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.contentWrapper}>
          <View style={styles.header}>
            <Text style={styles.title}>공사 안내문</Text>
          </View>

          <View style={styles.greeting}>
            <Text>주민 여러분 안녕하십니까.</Text>
          </View>

          <View style={styles.addressSelect}>
            <Text>{selectedAddress || '주소를 선택해주세요'}</Text>
          </View>

          <View style={styles.notice}>
            <Text style={styles.noticeText}>내부인테리어 공사를 진행하게 되었습니다.</Text>
            <Text style={styles.noticeText}>공사기간 동안 소음과 분진이 발생할 수 있어 입주민 여러분들의 양해를 구합니다.</Text>
            <Text style={styles.noticeText}>입주민분들께서 불편하지 않도록 철저히 감독하여 신속히 공사를 마무리하도록 최선을 다하겠습니다.</Text>
          </View>

          <View style={styles.infoSection}>
            <View style={styles.infoRow}>
              <Text style={styles.label}>공사 내용</Text>
              <Text style={styles.value}>전체 리모델링</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.label}>작업 기간</Text>
              <Text style={styles.value}>{constructionPeriod || '~'}</Text>
            </View>
            <View style={styles.infoRow_last}>
              <Text style={styles.label}>작업 시간</Text>
              <Text style={styles.value}>오전 9시 ~ 오후 5시</Text>
            </View>
          </View>

          <View style={styles.contact}>
            <Text style={styles.contactText}>기타 불편사항이나 문의사항이 있으시면 연락 바랍니다.</Text>
            <Text style={styles.phone}>010-8694-4078</Text>
            <Text style={styles.companyName}>(아마레디자인)</Text>
          </View>

          <View style={styles.schedule}>
            <Text style={styles.scheduleTitle}>공사 일정표</Text>
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <Text style={styles.tableHeaderCell}>공정</Text>
                <Text style={styles.tableHeaderCell}>공사기간</Text>
                <Text style={styles.tableHeaderCell}>소음강도</Text>
              </View>
              {processItems.map((item, index) => {
                const dates = processDates?.get(item.work);
                let period = '미정';
                if (dates) {
                  const start = formatDate(dates.start);
                  const end = formatDate(dates.end);
                  period = start === end ? start : `${start} ~ ${end}`;
                }
                return (
                  <View key={index} style={index === processItems.length - 1 ? styles.tableRow_last : styles.tableRow}>
                    <Text style={styles.tableCell}>{item.work}</Text>
                    <Text style={styles.tableCell}>{period}</Text>
                    <Text style={styles.tableCell}>{item.noise}</Text>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

// PDF 다운로드 버튼 컴포넌트
const ConstructionGuidePDF = ({ selectedAddress, constructionPeriod, processDates, hideUndecided, setHideUndecided }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSendEmail = async () => {
    if (!email) {
      setError('이메일 주소를 입력해주세요.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('유효한 이메일 주소를 입력해주세요.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // PDF 생성
      const doc = (
        <ConstructionGuideDocument 
          selectedAddress={selectedAddress}
          constructionPeriod={constructionPeriod}
          processDates={processDates}
          hideUndecided={hideUndecided}
        />
      );
      const pdfBlob = await pdf(doc).toBlob();

      // 이메일 전송 서비스 호출
      await sendConstructionGuideEmail({
        email,
        subject: '공사 안내문',
        content: '공사 안내문을 첨부파일로 보내드립니다.',
        selectedAddress,
        pdfBlob
      });

      setSuccessMessage('공사 안내문이 이메일로 전송되었습니다.');
      setEmail('');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      setError('이메일 전송 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="cg-new-controls-container">
      {/* 첫 번째 행: 미정표시/숨기기 버튼과 PDF 다운로드 버튼 */}
      <div className="cg-new-controls-row">
        <button 
          className="cg-new-button cg-new-button-secondary"
          onClick={() => setHideUndecided(!hideUndecided)}
          style={{ width: '50%' }}
        >
          {hideUndecided ? '미정 표시' : '미정 숨기기'}
        </button>
        
        <PDFDownloadLink
          document={
            <ConstructionGuideDocument 
              selectedAddress={selectedAddress}
              constructionPeriod={constructionPeriod}
              processDates={processDates}
              hideUndecided={hideUndecided}
            />
          }
          fileName="공사안내문.pdf"
          className="cg-new-link"
          style={{ width: '50%' }}
        >
          {({ blob, url, loading, error }) => (
            <button 
              className="cg-new-button cg-new-button-primary"
              disabled={loading || error}
              style={{ width: '100%' }}
            >
              {loading ? '생성 중...' : 'PDF 다운로드'}
            </button>
          )}
        </PDFDownloadLink>
      </div>
      
      {/* 두 번째 행: 이메일 입력 필드와 메일로 보내기 버튼 */}
      <div className="cg-new-controls-row">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="이메일 주소를 입력하세요"
          className="cg-new-input"
          style={{ width: '50%' }}
        />
        <button 
          className="cg-new-button cg-new-button-primary"
          onClick={handleSendEmail}
          disabled={loading}
          style={{ width: '50%' }}
        >
          {loading ? '전송 중...' : '메일로 보내기'}
        </button>
      </div>
      
      {error && <p className="cg-new-error-message">{error}</p>}
      {successMessage && <p className="cg-new-success-message">{successMessage}</p>}
    </div>
  );
};

export default ConstructionGuidePDF; 