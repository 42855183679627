import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import MainScreen from './components/MainScreen';
import EstimateProcess from './components/1min-estimate/EstimateProcess';
import AdminPage from './components/1min-estimate/AdminPage';
import AddressManagement from './components/AddressManagement';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import InstagramAuto from './components/InstagramAuto';
import SNSImageEdit from './components/SNSImageEdit';
import PaymentHistory from './components/PaymentHistory';
import AreaCalculation from './components/AreaCalculation';
import ConstructionGuide from './components/ConstructionGuide';
import ContractSettings from './components/ContractSettings';
import ImageClassification from './components/ImageClassification';
import ApiSettings from './components/ApiSettings';
import DetailedEstimate from './components/DetailedEstimate';
import DesktopEstimateV2 from './components/estimate-v2/DesktopEstimateV2';
import EstimateInquiry from './components/1min-estimate/EstimateInquiry';
import EstimateInquiryList from './components/1min-estimate/EstimateInquiryList';
import VideoMaker from './components/VideoMaker';
import ThumbnailEditor from './components/thumbnail-editor/ThumbnailEditor';
import ReactPixel from 'react-facebook-pixel';
import PdfExtract from './components/PdfExtract';
import DatabaseManager from './components/database-management/DatabaseManager';
import WindowSize from './components/WindowSize';
import AutoSchedule from './components/AutoSchedule';
import ContractorManagement from './components/ContractorManagement';
import MobileEstimate from './components/mobile-estimate/MobileEstimate';
import AutoProposal from './components/AutoProposal/AutoProposal';
import SketchupLicense from './components/SketchupLicense/SketchupLicense';
import SketchupLicenseAdmin from './components/SketchupLicense/SketchupLicenseAdmin';
import WorkOrder from './components/WorkOrder/WorkOrder';
import CostCalculation from './components/cost-calculation/CostCalculation';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/Login';
import InteriorMaterialSelection from './components/interior-material-selection/InteriorMaterialSelection';
import SharedEstimatePage from './components/estimate-v2/share/SharedEstimatePage';
import BathroomMaterial from './components/bathroom-material/BathroomMaterial';
import SquarePipe from './components/square-pipe/SquarePipe';
import BigSlab from './components/big-slab/BigSlab';
import MessagePreview from './components/MessagePreview/MessagePreview';

const AppWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        const modalElements = document.querySelectorAll('.mobile-add-item-modal, .mobile-edit-item-modal, .mobile-saved-items-modal, .mobile-memo-modal');
        if (modalElements.length > 0) {
          return;
        }

        if (location.pathname === '/vendor-price' || 
            location.pathname === '/mobile-estimate') {
          return;
        }
        if (!location.pathname.includes('/estimate')) {
          navigate(-1);
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [navigate, location]);

  useEffect(() => {
    const excludePaths = ['/estimate'];
    if (!excludePaths.some(path => location.pathname.startsWith(path))) {
      ReactPixel.pageView();
    }
  }, [location]);

  return (
    <AuthProvider>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <MainScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mobile-estimate"
            element={
              <ProtectedRoute>
                <MobileEstimate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/estimate"
            element={
              <ProtectedRoute>
                <EstimateProcess />
              </ProtectedRoute>
            }
          />
          <Route
            path="/estimate/:id"
            element={
              <ProtectedRoute>
                <EstimateProcess />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/address-management"
            element={<AddressManagement />}
          />
          <Route
            path="/material-selection"
            element={
              <ProtectedRoute>
                <InteriorMaterialSelection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <ProtectedRoute>
                <PrivacyPolicy />
              </ProtectedRoute>
            }
          />
          <Route
            path="/terms-of-service"
            element={
              <ProtectedRoute>
                <TermsOfService />
              </ProtectedRoute>
            }
          />
          <Route
            path="/instagram-auto"
            element={
              <ProtectedRoute>
                <InstagramAuto />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sns-image-edit"
            element={
              <ProtectedRoute>
                <SNSImageEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment-history"
            element={
              <ProtectedRoute>
                <PaymentHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/area-calculation"
            element={
              <ProtectedRoute>
                <AreaCalculation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/construction-guide"
            element={
              <ProtectedRoute>
                <ConstructionGuide />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contract"
            element={
              <ProtectedRoute>
                <ContractSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/image-classification"
            element={
              <ProtectedRoute>
                <ImageClassification />
              </ProtectedRoute>
            }
          />
          <Route
            path="/api-settings"
            element={
              <ProtectedRoute>
                <ApiSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/detailed-estimate/*"
            element={
              <ProtectedRoute>
                <DetailedEstimate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/estimate-v2"
            element={
              <ProtectedRoute>
                <DesktopEstimateV2 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/estimate-inquiry"
            element={
              <ProtectedRoute>
                <EstimateInquiry />
              </ProtectedRoute>
            }
          />
          <Route
            path="/estimate-inquiry/:id"
            element={
              <ProtectedRoute>
                <EstimateInquiry />
              </ProtectedRoute>
            }
          />
          <Route
            path="/estimate-inquiry-list"
            element={
              <ProtectedRoute>
                <EstimateInquiryList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/video-maker"
            element={
              <ProtectedRoute>
                <VideoMaker />
              </ProtectedRoute>
            }
          />
          <Route
            path="/thumbnail-editor"
            element={
              <ProtectedRoute>
                <ThumbnailEditor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pdf-extract"
            element={
              <ProtectedRoute>
                <PdfExtract />
              </ProtectedRoute>
            }
          />
          <Route
            path="/database"
            element={
              <ProtectedRoute>
                <DatabaseManager />
              </ProtectedRoute>
            }
          />
          <Route
            path="/window-size"
            element={
              <ProtectedRoute>
                <WindowSize />
              </ProtectedRoute>
            }
          />
          <Route
            path="/auto-schedule"
            element={
              <ProtectedRoute>
                <AutoSchedule />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contractor-management"
            element={
              <ProtectedRoute>
                <ContractorManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/auto-proposal"
            element={
              <ProtectedRoute>
                <AutoProposal />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sketchup-license"
            element={
              <ProtectedRoute>
                <SketchupLicense />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sketchup-license-admin"
            element={
              <ProtectedRoute>
                <SketchupLicenseAdmin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/work-order"
            element={
              <ProtectedRoute>
                <WorkOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bathroom-material"
            element={<BathroomMaterial />}
          />
          <Route
            path="/square-pipe"
            element={
              <ProtectedRoute>
                <SquarePipe />
              </ProtectedRoute>
            }
          />
          <Route
            path="/big-slab"
            element={
              <ProtectedRoute>
                <BigSlab />
              </ProtectedRoute>
            }
          />
          <Route
            path="/message-preview"
            element={
              <ProtectedRoute>
                <MessagePreview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cost-calculation"
            element={
              <ProtectedRoute>
                <CostCalculation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/s"
            element={<SharedEstimatePage />}
          />
        </Routes>
      </div>
    </AuthProvider>
  );
};

function App() {
  return (
    <BrowserRouter future={{ 
      v7_startTransition: true,
      v7_relativeSplatPath: true 
    }}>
      <AppWrapper />
    </BrowserRouter>
  );
}

export default App;
