import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { doc, getDoc, collection, query, orderBy, getDocs } from 'firebase/firestore';
import './MessageModal.css';

const MessageModal = ({ isOpen, onClose, address, events, processes, materials }) => {
  const [contractorsByProcess, setContractorsByProcess] = useState({});
  const [selectedContractors, setSelectedContractors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scheduleData, setScheduleData] = useState(null);
  const [isDateChangeRequest, setIsDateChangeRequest] = useState(false);
  const [includeMaterials, setIncludeMaterials] = useState({});

  // 해당 주소의 이벤트만 필터링
  const addressEvents = events.filter(event => 
    event.title.split('\n')[0] === address
  );

  // 공정별 일정 데이터 생성
  const processSchedules = addressEvents.reduce((acc, event) => {
    const eventLines = event.title.split('\n');
    const eventProcesses = eventLines.slice(1).map(line => line.replace('> ', ''));
    
    eventProcesses.forEach(process => {
      if (!acc[process]) {
        acc[process] = [];
      }
      // end 날짜에서 하루를 빼서 저장
      const endDate = new Date(event.end);
      endDate.setDate(endDate.getDate() - 1);
      acc[process].push({
        start: new Date(event.start),
        end: endDate
      });
    });
    
    return acc;
  }, {});

  // 전체 선택 상태 확인
  const isAllSelected = () => {
    const allContractors = Object.values(contractorsByProcess).flat();
    return allContractors.length > 0 && 
           allContractors.every(contractor =>
             selectedContractors.some(c => 
               c.name === contractor.name && 
               c.phone === contractor.phone
             )
           );
  };

  // 전체 선택/해제 토글
  const toggleSelectAll = () => {
    if (isAllSelected()) {
      setSelectedContractors([]);
    } else {
      const allContractors = Object.values(contractorsByProcess).flat();
      const uniqueContractors = allContractors.filter((contractor, index, self) =>
        index === self.findIndex(c => c.name === contractor.name && c.phone === contractor.phone)
      );
      setSelectedContractors(uniqueContractors);
    }
  };

  // 자재 메시지 생성 함수 수정
  const generateMaterialMessage = (process) => {
    const processMaterials = materials[address]?.[process] || [];
    
    const materialsByLocation = processMaterials.reduce((acc, material) => {
      const location = material.location || '기타';
      if (!acc[location]) {
        acc[location] = [];
      }
      acc[location].push(material);
      return acc;
    }, {});

    return Object.entries(materialsByLocation)
      .map(([location, materials]) => {
        const materialsText = materials
          .map(m => `${m.materialType || ''} - ${m.materialName}`)
          .join('\n');
        return `[${location}]\n${materialsText}`;
      })
      .join('\n\n');
  };

  // 날짜 포맷 함수 수정
  const formatDate = (date) => {
    // 날짜를 한국 시간대로 변환
    const koreaDate = new Date(date.getTime() + (9 * 60 * 60 * 1000));
    const year = koreaDate.getUTCFullYear();
    const month = String(koreaDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(koreaDate.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // generateMessageData 함수 수정
  const generateMessageData = () => {
    const processMessages = Object.entries(processSchedules).map(([process, dates]) => {
      const recipients = selectedContractors.filter(contractor => 
        contractorsByProcess[process]?.some(c => 
          c.name === contractor.name && 
          c.phone === contractor.phone
        )
      );

      const dateRanges = dates.map(date => {
        // 시작일과 종료일을 한국 시간대로 변환
        const startDate = new Date(date.start);
        const endDate = new Date(date.end); // end는 이미 하루가 빠진 날짜임
        
        // 시작일과 종료일이 같은지 비교 (시간은 무시)
        const startStr = formatDate(startDate);
        const endStr = formatDate(endDate);
        
        // 날짜가 같으면 하나만 표시, 다르면 범위로 표시
        return startStr === endStr ? startStr : `${startStr}~${endStr}`;
      }).join(',');

      // 날짜 변경 요청 prefix 추가
      const prefix = isDateChangeRequest ? '<날짜변경> ' : '';
      
      // 일정 메시지 생성
      const scheduleMessage = `${prefix}[${process}]\n${dateRanges}\n${address}\n답장:카톡으로`;
      
      // 자재 메시지 생성 (분할 없이)
      let materialMessage = null;
      if (includeMaterials[process]) {
        materialMessage = `${prefix}${address}\n${process} 자재내용\n\n${generateMaterialMessage(process)}\n\n답장:카톡으로`;
      }

      return {
        process,
        dates: dates.map(date => ({
          start: formatDate(new Date(date.start)),
          end: formatDate(new Date(date.end))
        })),
        recipients: recipients.map(r => ({
          name: r.name,
          phone: r.phone
        })),
        messages: materialMessage ? [scheduleMessage, materialMessage] : [scheduleMessage],
        isDateChangeRequest: isDateChangeRequest
      };
    }).filter(pm => pm.recipients.length > 0);

    return {
      address,
      processMessages,
      isDateChangeRequest: isDateChangeRequest,
      openMessageTab: true
    };
  };

  // 메시지 전송 처리
  const handleSendMessage = () => {
    if (selectedContractors.length === 0) {
      alert('최소 한 명의 거래처를 선택해주세요.');
      return;
    }

    const messageData = generateMessageData();

    // 데스크톱 환경에서는 클립보드 복사만 실행
    if (window.innerWidth >= 768) {
      try {
        const allMessages = messageData.processMessages.reduce((acc, curr) => {
          return [...acc, ...curr.messages];
        }, []);
        const clipboardText = allMessages.join('\n\n');
        navigator.clipboard.writeText(clipboardText);
        alert('클립보드에 복사되었습니다!');
      } catch (err) {
        alert('클립보드 복사에 실패했습니다.');
      }
      return;
    }

    // 안드로이드 앱으로 전달할 최종 데이터 구조
    const deepLinkData = {
      ...messageData,
      openMessageTab: true
    };

    // 딥링크 실행
    const deepLinkUrl = `amare://message?data=${encodeURIComponent(JSON.stringify(deepLinkData))}`;
    window.location.href = deepLinkUrl;
  };

  // 데이터 로드
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!isOpen || !address) {
        return;
      }

      try {
        // schedules 컬렉션에서 일정 데이터 로드
        const scheduleRef = doc(db, 'schedules', address);
        const scheduleDoc = await getDoc(scheduleRef);
        
        if (scheduleDoc.exists() && isMounted) {
          setScheduleData(scheduleDoc.data());
        }

        // contractors 컬렉션에서 거래처 데이터 로드
        const contractorsData = {};
        const processNames = Object.keys(processSchedules);
        
        if (processNames.length === 0) {
          if (isMounted) {
            setContractorsByProcess({});
            setLoading(false);
          }
          return;
        }
        
        for (const processName of processNames) {
          if (!isMounted) break;
          
          const processRef = doc(db, 'contractors', processName);
          const processDoc = await getDoc(processRef);
          
          if (processDoc.exists()) {
            const data = processDoc.data();
            contractorsData[processName] = data.contractors || [];
          } else {
            contractorsData[processName] = [];
          }
        }
        
        if (isMounted) {
          setContractorsByProcess(contractorsData);
        }
      } catch (error) {
        // 에러 처리
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    setLoading(true);
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [isOpen, address]);

  const toggleContractor = (contractor) => {
    setSelectedContractors(prev => {
      const exists = prev.some(c => 
        c.name === contractor.name && 
        c.phone === contractor.phone
      );

      if (exists) {
        return prev.filter(c => 
          !(c.name === contractor.name && 
            c.phone === contractor.phone)
        );
      } else {
        return [...prev, contractor];
      }
    });
  };

  // 공정별 모든 거래처 선택/해제
  const toggleProcess = (processName) => {
    const contractors = contractorsByProcess[processName] || [];
    const isCurrentlySelected = isProcessSelected(processName);
    
    if (!isCurrentlySelected) {
      const newContractors = contractors.filter(contractor => 
        !selectedContractors.some(c => 
          c.name === contractor.name && c.phone === contractor.phone
        )
      );
      setSelectedContractors(prev => [...prev, ...newContractors]);
    } else {
      setSelectedContractors(prev => 
        prev.filter(c => 
          !contractors.some(pc => 
            pc.name === c.name && pc.phone === c.phone
          )
        )
      );
    }
  };

  // 공정의 모든 거래처가 선택되었는지 확인
  const isProcessSelected = (processName) => {
    const contractors = contractorsByProcess[processName] || [];
    return contractors.every(contractor =>
      selectedContractors.some(c => 
        c.name === contractor.name && 
        c.phone === contractor.phone
      )
    );
  };

  // 자재 포함 여부 토글
  const toggleIncludeMaterials = (process) => {
    setIncludeMaterials(prev => ({
      ...prev,
      [process]: !Boolean(prev[process])
    }));
  };

  if (!isOpen) return null;

  // 공정 순서대로 정렬된 공정 목록 생성
  const sortedProcesses = Object.keys(processSchedules).sort((a, b) => {
    const aIndex = processes.indexOf(a);
    const bIndex = processes.indexOf(b);
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  });

  return (
    <div className="msg-modal">
      <div className="msg-modal__content">
        <div className="msg-modal__header">
          <h2 className="msg-modal__title">문자 메시지 보내기</h2>
          <h3 className="msg-modal__subtitle">{address}</h3>
          <label className="msg-modal__date-change">
            <input
              type="checkbox"
              checked={Boolean(isDateChangeRequest)}
              onChange={(e) => setIsDateChangeRequest(e.target.checked)}
            />
            <span>날짜 변경 요청</span>
          </label>
        </div>
        
        <div className="msg-modal__events">
          {loading ? (
            <div className="msg-modal__loading">데이터를 불러오는 중...</div>
          ) : (
            <>
              <label className="msg-modal__select-all">
                <input
                  type="checkbox"
                  className="msg-modal__checkbox"
                  checked={Boolean(isAllSelected())}
                  onChange={toggleSelectAll}
                />
                <span className="msg-modal__select-all-text">
                  전체 선택
                </span>
              </label>
              
              {sortedProcesses.map((process, index) => (
                <div key={index} className="msg-modal__event-item">
                  <div className="msg-modal__process-header">
                    <label className="msg-modal__process-checkbox">
                      <input
                        type="checkbox"
                        className="msg-modal__checkbox"
                        checked={Boolean(isProcessSelected(process))}
                        onChange={() => toggleProcess(process)}
                      />
                      <span className="msg-modal__process-name">
                        {process}
                      </span>
                    </label>
                    <div className="msg-modal__process-options">
                      <div className="msg-modal__dates">
                        {processSchedules[process].map((date, idx) => (
                          <div key={idx} className="msg-modal__date">
                            {formatDate(date.start) === formatDate(date.end) 
                              ? formatDate(date.start)
                              : `${formatDate(date.start)} ~ ${formatDate(date.end)}`}
                          </div>
                        ))}
                      </div>
                      <label className="msg-modal__material-checkbox">
                        <input
                          type="checkbox"
                          checked={Boolean(includeMaterials[process])}
                          onChange={() => toggleIncludeMaterials(process)}
                        />
                        <span>자재도 보내기</span>
                      </label>
                    </div>
                  </div>
                  
                  <div className="msg-modal__contractors">
                    {contractorsByProcess[process]?.map((contractor, cIndex) => (
                      <label key={cIndex} className="msg-modal__contractor">
                        <input
                          type="checkbox"
                          className="msg-modal__checkbox"
                          checked={Boolean(selectedContractors.some(c => 
                            c.name === contractor.name && 
                            c.phone === contractor.phone
                          ))}
                          onChange={() => toggleContractor(contractor)}
                        />
                        <span className="msg-modal__contractor-name">
                          {contractor.name}
                        </span>
                        <span className="msg-modal__contractor-phone">
                          {contractor.phone}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        <div className="msg-modal__buttons">
          <button 
            className="msg-modal__send-button"
            onClick={handleSendMessage}
            disabled={selectedContractors.length === 0}
          >
            보내기 ({selectedContractors.length}명)
          </button>
          <button 
            className="msg-modal__close-button"
            onClick={onClose}
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageModal; 