import React, { useState, useEffect } from 'react';
import { FaShare } from 'react-icons/fa';
import { db } from '../../../../src/firebase';
import { collection, addDoc, serverTimestamp, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import './ShareButton.css';

const ShareButton = ({ address, additionalItems, onClose }) => {
  const [showModal, setShowModal] = useState(false);
  const [expiryDays, setExpiryDays] = useState(30);
  const [shareUrl, setShareUrl] = useState('');
  
  useEffect(() => {
    const generateShareUrl = async () => {
      if (showModal) {
        let querySnapshot;
        try {
          const shareRef = collection(db, 'sharedEstimates');
          const q = query(shareRef, where('address', '==', address));
          
          querySnapshot = await getDocs(q);
          
          let shareDocId;
          
          if (!querySnapshot.empty) {
            const existingDoc = querySnapshot.docs[0];
            shareDocId = existingDoc.id;
            
            try {
              const docRef = doc(db, 'sharedEstimates', shareDocId);
              await updateDoc(docRef, {
                expiryDate: new Date(Date.now() + (expiryDays * 24 * 60 * 60 * 1000)),
                lastUpdated: serverTimestamp()
              });
            } catch (updateError) {
              throw new Error('만료일 업데이트 실패');
            }
          } else {
            const newShareDoc = await addDoc(shareRef, {
              address: address,
              expiryDate: new Date(Date.now() + (expiryDays * 24 * 60 * 60 * 1000)),
              createdAt: serverTimestamp()
            });
            shareDocId = newShareDoc.id;
          }

          const baseUrl = window.location.origin;
          const newShareUrl = `${baseUrl}/s?id=${shareDocId}`;
          setShareUrl(newShareUrl);
        } catch (error) {
          if (querySnapshot && !querySnapshot.empty) {
            alert('기존 공유 링크를 불러오는데 실패했습니다. 잠시 후 다시 시도해주세요.');
          } else {
            alert('새로운 공유 링크 생성에 실패했습니다. 잠시 후 다시 시도해주세요.');
          }
        }
      }
    };

    generateShareUrl();
  }, [showModal, address, expiryDays]);

  const handleShare = async () => {
    setShowModal(true);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      alert('URL이 클립보드에 복사되었습니다.');
    } catch (error) {
      alert('클립보드 복사에 실패했습니다.');
    }
  };

  return (
    <>
      <button 
        className="share-button-icon"
        onClick={handleShare}
        title="견적서 공유"
      >
        <FaShare />
      </button>

      {showModal && (
        <div className="share-button-modal-overlay">
          <div className="share-button-modal">
            <div className="share-button-modal-header">
              <h3>견적서 공유</h3>
            </div>

            <div className="share-button-settings">
              <div className="share-button-setting-group">
                <label>유효기간</label>
                <select 
                  value={expiryDays}
                  onChange={(e) => setExpiryDays(e.target.value)}
                >
                  <option value="7">7일</option>
                  <option value="30">30일</option>
                  <option value="60">60일</option>
                  <option value="90">90일</option>
                </select>
              </div>
            </div>

            <div className="share-button-url-section">
              <p>아래 링크를 공유하여 견적서를 확인할 수 있습니다.</p>
              <div className="share-button-url-display">
                {shareUrl}
              </div>
              <button 
                className="share-button-copy"
                onClick={handleCopy}
              >
                URL 복사
              </button>
            </div>

            <div className="share-button-modal-buttons">
              <button 
                className="share-button-modal-close"
                onClick={() => setShowModal(false)}
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShareButton; 