import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, checkAuth } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      checkAuth();
    }
  }, [isAuthenticated, checkAuth]);

  // 1min-estimate와 address-management 관련 경로는 인증을 건너뜁니다
  const isPublicPath = location.pathname.includes('/estimate') || 
                      location.pathname === '/admin' ||
                      location.pathname.includes('/estimate-inquiry') ||
                      location.pathname === '/address-management';

  if (!isAuthenticated && !isPublicPath) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute; 