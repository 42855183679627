import React, { useState, useEffect, useRef } from 'react';
import './NotificationPanel.css';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { GoogleGenerativeAI } from '@google/generative-ai';
import VoiceService from './VoiceService';

const NotificationPanel = ({ schedules, onAISettingsClick }) => {
  const [voiceEnabled, setVoiceEnabled] = useState(true);
  const [voiceRate, setVoiceRate] = useState(1.0);
  const [voicePitch, setVoicePitch] = useState(1.0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [currentSpeechText, setCurrentSpeechText] = useState('');
  const [currentSpeechPosition, setCurrentSpeechPosition] = useState(0);
  const [speechQueue, setSpeechQueue] = useState([]);
  const [speechHistory, setSpeechHistory] = useState([]); // 음성 재생 이력 저장
  const [currentSpeechId, setCurrentSpeechId] = useState(null); // 현재 재생 중인 음성 ID
  const [browserSupport, setBrowserSupport] = useState({ supported: true, message: '' }); // 브라우저 지원 상태
  const [retryCount, setRetryCount] = useState(0); // 재시도 횟수
  const [isVisible, setIsVisible] = useState(true); // 페이지 가시성 상태
  const [batteryLevel, setBatteryLevel] = useState(null); // 배터리 상태
  const [errorMessage, setErrorMessage] = useState(''); // 오류 메시지
  const [isRefreshing, setIsRefreshing] = useState(false); // 새로고침 상태
  const [resourceAnalysis, setResourceAnalysis] = useState(null); // 자원 분석 결과
  
  const voiceServiceRef = useRef(null);
  const utteranceRef = useRef(null);
  const wakeLockRef = useRef(null);
  const speechStartTimeRef = useRef(null);
  const MAX_RETRY_COUNT = 3;
  
  // 컴포넌트 마운트 시 초기화
  useEffect(() => {
    // VoiceService 인스턴스 생성
    voiceServiceRef.current = new VoiceService();
    
    // 브라우저 지원 확인
    checkBrowserSupport();
    
    // 배터리 상태 확인
    checkBatteryStatus();
    
    // 음성 설정 로드
    loadVoiceSettings();
    
    // 저장된 상태 복원
    loadStateFromStorage();
    
    // 이벤트 리스너 등록
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    // 오디오 인터럽트 감지
    document.addEventListener('play', handleAudioInterrupt, true);
    
    // 컴포넌트 언마운트 시 정리
    return () => {
      if (voiceServiceRef.current) {
        voiceServiceRef.current.cleanup();
      }
      
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('play', handleAudioInterrupt, true);
      
      releaseWakeLock();
    };
  }, []);
  
  // 음성 설정 로드
  const loadVoiceSettings = async () => {
    try {
      if (voiceServiceRef.current) {
        const settings = await voiceServiceRef.current.loadVoiceSettings();
        setVoiceEnabled(settings.voiceEnabled);
        setVoiceRate(settings.voiceRate);
        setVoicePitch(settings.voicePitch);
        
        // API 키 로드
        const apiKeysRef = doc(db, 'config', 'apiKeys');
        const apiKeysDoc = await getDoc(apiKeysRef);
        
        if (apiKeysDoc.exists()) {
          const data = apiKeysDoc.data();
          if (data.googleTTS && data.googleTTS.trim() !== '') {
            console.log('NotificationPanel: API 키 로드 성공');
            voiceServiceRef.current.updateApiKey(data.googleTTS);
          } else {
            console.warn('NotificationPanel: API 키가 비어 있습니다');
          }
        } else {
          console.warn('NotificationPanel: API 키 문서가 존재하지 않습니다');
        }
      }
    } catch (error) {
      console.error('음성 설정 로드 실패:', error);
    }
  };
  
  // 음성 중지
  const stopSpeech = () => {
    if (voiceServiceRef.current) {
      const state = voiceServiceRef.current.stopSpeech();
      setCurrentSpeechText(state.text);
      setCurrentSpeechPosition(state.position);
      setIsSpeaking(false);
    }
  };

  // 음성 버튼 클릭 핸들러
  const handleVoiceButtonClick = () => {
    // 브라우저가 지원하지 않으면 알림
    if (!browserSupport.supported) {
      alert(browserSupport.message);
      return;
    }
    
    // API 키 확인
    if (!voiceServiceRef.current || !voiceServiceRef.current.apiKey) {
      alert('Google TTS API 키가 설정되지 않았습니다. AI 설정에서 API 키를 입력해주세요.');
      onAISettingsClick(); // AI 설정 모달 열기
      return;
    }
    
    if (isSpeaking) {
      // 사용자가 의도적으로 중지할 때 오류 메시지 초기화
      setErrorMessage('');
      stopSpeech();
    } else {
      const newText = generateNotificationText();
      speakFromPosition(newText, 0);
    }
  };
  
  // 특정 위치부터 음성 시작
  const speakFromPosition = async (text, position) => {
    if (!voiceServiceRef.current || !text) return;
    
    // API 키 확인
    if (!voiceServiceRef.current.apiKey) {
      setErrorMessage('Google TTS API 키가 설정되지 않았습니다. AI 설정에서 API 키를 입력해주세요.');
      return;
    }
    
    try {
      // 음성 재생 시작
      voiceServiceRef.current.speakFromPosition(
        text, 
        position,
        // 음성 상태 변경 콜백
        (speaking) => {
          setIsSpeaking(speaking);
          
          // 음성 시작 시 WakeLock 요청
          if (speaking) {
            requestWakeLock();
          } else {
            releaseWakeLock();
          }
        },
        // 위치 변경 콜백
        (newPosition) => {
          setCurrentSpeechPosition(newPosition);
        },
        // 대기열 변경 콜백
        (newQueue) => {
          setSpeechQueue(newQueue);
        }
      );
      
      // 현재 텍스트 설정
      setCurrentSpeechText(text);
      
      // 오류 메시지 초기화
      setErrorMessage('');
    } catch (error) {
      console.error('음성 재생 오류:', error);
      setErrorMessage('음성 재생 중 오류가 발생했습니다.');
      setIsSpeaking(false);
    }
  };

  // 음성 알림 실행
  const speakNotification = (text) => {
    if (!voiceEnabled || !text || !voiceServiceRef.current) return;
    
    voiceServiceRef.current.speakNotification(
      text,
      (speaking) => {
        setIsSpeaking(speaking);
        
        // 음성 시작 시 WakeLock 요청
        if (speaking) {
          requestWakeLock();
        } else {
          releaseWakeLock();
        }
      },
      (newPosition) => {
        setCurrentSpeechPosition(newPosition);
      },
      (newQueue) => {
        setSpeechQueue(newQueue);
      }
    );
    
    // 현재 텍스트 설정
    setCurrentSpeechText(text);
  };

  // 다른 내용 재생 시작
  const startNewSpeech = () => {
    if (!voiceServiceRef.current) return;
    
    const text = generateNotificationText();
    if (!text) return;
    
    voiceServiceRef.current.resetAndPlayFromBeginning(
      text,
      (speaking) => {
        setIsSpeaking(speaking);
        
        // 음성 시작 시 WakeLock 요청
        if (speaking) {
          requestWakeLock();
        } else {
          releaseWakeLock();
        }
      },
      (newPosition) => {
        setCurrentSpeechPosition(newPosition);
      },
      (newQueue) => {
        setSpeechQueue(newQueue);
      }
    );
    
    // 현재 텍스트 설정
    setCurrentSpeechText(text);
  };

  // 브라우저 호환성 체크
  const checkBrowserSupport = () => {
    if (!('speechSynthesis' in window)) {
      setBrowserSupport({
        supported: false,
        message: '이 브라우저는 음성 합성을 지원하지 않습니다.'
      });
      return;
    }
    
    // iOS Safari 감지
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    
    if (isIOS && isSafari) {
      setBrowserSupport({
        supported: true,
        message: 'iOS Safari에서는 음성 재생을 위해 화면을 터치해야 할 수 있습니다.'
      });
    }
  };
  
  // 배터리 상태 확인
  const checkBatteryStatus = async () => {
    if ('getBattery' in navigator) {
      try {
        const battery = await navigator.getBattery();
        setBatteryLevel(battery.level);
        
        battery.addEventListener('levelchange', () => {
          setBatteryLevel(battery.level);
        });
      } catch (error) {
        // 배터리 상태 확인 실패 처리
      }
    }
  };
  
  // 페이지 가시성 변경 처리
  const handleVisibilityChange = () => {
    const isDocumentVisible = document.visibilityState === 'visible';
    setIsVisible(isDocumentVisible);
    
    if (!isDocumentVisible && isSpeaking) {
      stopSpeech();
      saveStateToStorage();
      releaseWakeLock();
    } else if (isDocumentVisible && isSpeaking) {
      loadStateFromStorage();
      requestWakeLock();
    }
  };
  
  // WakeLock 요청 (화면 켜짐 유지)
  const requestWakeLock = async () => {
    if ('wakeLock' in navigator && document.visibilityState === 'visible') {
      try {
        wakeLockRef.current = await navigator.wakeLock.request('screen');
        
        wakeLockRef.current.addEventListener('release', () => {
          wakeLockRef.current = null;
        });
      } catch (error) {
        // WakeLock 요청 실패 처리
      }
    }
  };
  
  // WakeLock 해제
  const releaseWakeLock = () => {
    if (wakeLockRef.current) {
      wakeLockRef.current.release()
        .then(() => {
          wakeLockRef.current = null;
        })
        .catch(() => {
          // WakeLock 해제 실패 처리
        });
    }
  };
  
  // 상태를 IndexedDB에 저장
  const saveStateToStorage = () => {
    if (currentSpeechText) {
      try {
        const timestamp = Date.now();
        
        localStorage.setItem('speechState', JSON.stringify({
          text: currentSpeechText,
          position: currentSpeechPosition,
          timestamp
        }));
        
        if ('indexedDB' in window) {
          const request = indexedDB.open('speechDB', 1);
          
          request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('speechState')) {
              db.createObjectStore('speechState', { keyPath: 'id' });
            }
          };
          
          request.onsuccess = (event) => {
            const db = event.target.result;
            const transaction = db.transaction(['speechState'], 'readwrite');
            const store = transaction.objectStore('speechState');
            
            store.put({
              id: 'currentState',
              text: currentSpeechText,
              position: currentSpeechPosition,
              timestamp
            });
          };
        }
      } catch (error) {
        // 음성 상태 저장 실패 처리
      }
    }
  };
  
  // IndexedDB에서 상태 복원
  const loadStateFromStorage = () => {
    try {
      const savedSpeechState = localStorage.getItem('speechState');
      if (savedSpeechState) {
        const { text, position, timestamp } = JSON.parse(savedSpeechState);
        if (text && position !== undefined) {
          setCurrentSpeechText(text);
          setCurrentSpeechPosition(position);
          return;
        }
      }
      
      if ('indexedDB' in window) {
        const request = indexedDB.open('speechDB', 1);
        
        request.onsuccess = (event) => {
          const db = event.target.result;
          try {
            const transaction = db.transaction(['speechState'], 'readonly');
            const store = transaction.objectStore('speechState');
            const getRequest = store.get('currentState');
            
            getRequest.onsuccess = () => {
              const state = getRequest.result;
              if (state && state.text) {
                setCurrentSpeechText(state.text);
                setCurrentSpeechPosition(state.position);
              }
            };
          } catch (error) {
            // IndexedDB에서 상태 복원 실패 처리
          }
        };
      }
    } catch (error) {
      // 음성 상태 복원 실패 처리
    }
  };
  
  // 음성 상태 변경 시 로컬 스토리지에 저장
  useEffect(() => {
    if (currentSpeechText) {
      saveStateToStorage();
    }
  }, [currentSpeechText, currentSpeechPosition]);
  
  // 페이지 언로드 시 음성 중지
  const handleBeforeUnload = () => {
    if (isSpeaking) {
      stopSpeech();
    }
  };
  
  // 오디오 인터럽트 감지 (모바일에서 중요)
  const handleAudioInterrupt = () => {
    if (isSpeaking) {
      stopSpeech();
    }
  };

  // 알림 텍스트 생성 함수
  const generateNotificationText = () => {
    let text = "오늘의 일정 알림입니다. ";
    
    // 오늘 일정
    const todaySchedules = getSchedulesForDate(new Date());
    if (todaySchedules.length > 0) {
      text += "오늘 일정은 ";
      todaySchedules.forEach((schedule, index) => {
        text += `${schedule.address}의 ${schedule.process}`;
        if (index < todaySchedules.length - 1) {
          text += ", ";
        }
      });
      text += " 입니다. ";
    } else {
      text += "오늘 예정된 일정이 없습니다. ";
    }
    
    // 내일 일정
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowSchedules = getSchedulesForDate(tomorrow);
    
    if (tomorrowSchedules.length > 0) {
      text += "내일 일정은 ";
      tomorrowSchedules.forEach((schedule, index) => {
        text += `${schedule.address}의 ${schedule.process}`;
        if (index < tomorrowSchedules.length - 1) {
          text += ", ";
        }
      });
      text += " 입니다.";
    } else {
      text += "내일 예정된 일정이 없습니다.";
    }
    
    // 모레 일정
    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
    const dayAfterTomorrowSchedules = getSchedulesForDate(dayAfterTomorrow);
    
    if (dayAfterTomorrowSchedules.length > 0) {
      text += "모레 일정은 ";
      dayAfterTomorrowSchedules.forEach((schedule, index) => {
        text += `${schedule.address}의 ${schedule.process}`;
        if (index < dayAfterTomorrowSchedules.length - 1) {
          text += ", ";
        }
      });
      text += " 입니다.";
    } else {
      text += "모레 예정된 일정이 없습니다.";
    }
    
    // 자재 준비 알림
    const materialSchedules = getMaterialSchedules();
    if (materialSchedules.length > 0) {
      text += " 자재 준비가 필요한 일정은 ";
      materialSchedules.forEach((schedule, index) => {
        text += `${formatDate(schedule.date)} ${schedule.address}의 ${schedule.process}`;
        if (index < materialSchedules.length - 1) {
          text += ", ";
        }
      });
      text += " 입니다.";
    }
    
    return text;
  };

  // 날짜 포맷팅 함수
  const formatDate = (date) => {
    return `${date.getMonth() + 1}월 ${date.getDate()}일`;
  };

  // 날짜를 YYYY-MM-DD 형식으로 변환
  const formatDateToString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // 자재 준비가 필요한 공정 목록
  const MATERIAL_PROCESSES = [
    '타일 공사',
    '필름 공사',
    '도배 공사',
    '제작가구 공사',
    '욕실 시공',
    '중문 시공',
    '마루 공사'
  ];

  // 도면 준비가 필요한 공정 목록 추가
  const DRAWING_PROCESSES = [
    '제작가구 공사',
    '중문 시공',
    '주방가구 공사',
    '붙박이장 공사',
    '현관장 공사',
    '신발장 공사',
    '시스템장 공사'
  ];

  // 공정 간격 최대 허용일수
  const MAX_GAP = 4;

  // 간격 체크에서 제외할 예외 공정 목록
  const EXCLUDED_PROCESSES = [
    '사무실 상담',
    '현장 상담',
    '현장상담',
    '주문',
    '자재 주문',
    '실측',
    '현장 실측',
    '현장실측'
  ];

  // 하루 최대 권장 공정 수
  const MAX_PROCESSES_PER_DAY = 4;

  // 철거 공정 시작 일정 찾기
  const getDemolitionSchedules = () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const thirtyDaysLater = new Date(currentDate);
    thirtyDaysLater.setDate(thirtyDaysLater.getDate() + 30);

    // 이미 처리된 주소 추적
    const processedAddresses = new Set();

    const filteredSchedules = schedules
      .filter(schedule => {
        const process = schedule.title.split('\n')[1]?.replace('> ', '') || '';
        const startDate = new Date(schedule.start);
        const address = schedule.title.split('\n')[0];

        // 철거가 포함된 모든 공정을 찾되, 같은 주소는 한 번만 포함
        if (process.includes('철거') && 
            startDate >= currentDate && 
            startDate <= thirtyDaysLater && 
            !processedAddresses.has(address)) {
          processedAddresses.add(address);
          return true;
        }
        return false;
      })
      .map(schedule => {
        const address = schedule.title.split('\n')[0];
        const process = schedule.title.split('\n')[1]?.replace('> ', '') || '';
        const startDate = new Date(schedule.start);
        return {
          address,
          process,
          date: startDate,
          dateStr: formatDateToString(startDate)
        };
      });
      
    // 주소와 날짜별로 그룹화
    const groupedSchedules = filteredSchedules.reduce((acc, schedule) => {
      const key = `${schedule.address}_${schedule.dateStr}`;
      if (!acc[key]) {
        acc[key] = {
          address: schedule.address,
          processes: [],
          date: schedule.date,
          dateStr: schedule.dateStr
        };
      }
      acc[key].processes.push(schedule.process);
      return acc;
    }, {});
    
    // 그룹화된 데이터를 배열로 변환하고 날짜순으로 정렬
    return Object.values(groupedSchedules)
      .map(group => ({
        address: group.address,
        process: group.processes.join(', '),
        date: group.date
      }))
      .sort((a, b) => a.date - b.date);
  };

  // 일정 필터링 및 그룹화
  const getSchedulesForDate = (targetDate) => {
    const targetDateStr = formatDateToString(targetDate);
    
    const filteredSchedules = schedules
      .filter(schedule => {
        const startDate = new Date(schedule.start);
        const endDate = new Date(schedule.end);
        const targetDateObj = new Date(targetDateStr);
        
        // 시작일과 종료일 사이에 있는지 확인
        return targetDateObj >= startDate && targetDateObj < endDate;
      })
      .map(schedule => {
        const titleParts = schedule.title.split('\n');
        const address = titleParts[0];
        // '> ' 로 시작하는 모든 공정을 추출
        const processes = titleParts.slice(1)
          .filter(line => line.startsWith('> '))
          .map(line => {
            const process = line.replace('> ', '');
            // 메모가 있는 경우 ': 메모' 형식으로 추가
            return schedule.memo ? `${process}: ${schedule.memo}` : process;
          });
        
        return {
          address,
          process: processes.join(', ') // 모든 공정을 쉼표로 구분하여 결합
        };
      });
    
    // 주소별로 그룹화
    const groupedByAddress = filteredSchedules.reduce((acc, schedule) => {
      if (!acc[schedule.address]) {
        acc[schedule.address] = [];
      }
      if (!acc[schedule.address].includes(schedule.process)) {
        acc[schedule.address].push(schedule.process);
      }
      return acc;
    }, {});
    
    // 그룹화된 데이터를 배열로 변환
    return Object.entries(groupedByAddress).map(([address, processes]) => ({
      address,
      process: processes.join(', ')
    }));
  };

  // 자재 준비가 필요한 일정 찾기
  const getMaterialSchedules = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const tenDaysLater = new Date(today);
    tenDaysLater.setDate(tenDaysLater.getDate() + 10);

    const filteredSchedules = schedules
      .filter(schedule => {
        const process = schedule.title.split('\n')[1]?.replace('> ', '') || '';
        const scheduleDate = new Date(schedule.start);
        // 오늘부터 10일 이내의 일정만 포함
        return MATERIAL_PROCESSES.includes(process) && 
               scheduleDate >= today && 
               scheduleDate <= tenDaysLater;
      })
      .map(schedule => {
        const titleParts = schedule.title.split('\n');
        const startDate = new Date(schedule.start);
        return {
          address: titleParts[0],
          process: titleParts[1]?.replace('> ', '') || '',
          date: startDate,
          dateStr: formatDateToString(startDate)
        };
      });
      
    // 주소와 날짜별로 그룹화
    const groupedSchedules = filteredSchedules.reduce((acc, schedule) => {
      const key = `${schedule.address}_${schedule.dateStr}`;
      if (!acc[key]) {
        acc[key] = {
          address: schedule.address,
          processes: [],
          date: schedule.date,
          dateStr: schedule.dateStr
        };
      }
      acc[key].processes.push(schedule.process);
      return acc;
    }, {});
    
    // 그룹화된 데이터를 배열로 변환하고 날짜순으로 정렬
    return Object.values(groupedSchedules)
      .map(group => ({
        address: group.address,
        process: group.processes.join(', '),
        date: group.date
      }))
      .sort((a, b) => a.date - b.date);
  };

  // 도면 준비가 필요한 일정 찾기 함수 추가
  const getDrawingSchedules = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const twentyDaysLater = new Date(today);
    twentyDaysLater.setDate(twentyDaysLater.getDate() + 20);

    const filteredSchedules = schedules
      .filter(schedule => {
        const process = schedule.title.split('\n')[1]?.replace('> ', '') || '';
        const scheduleDate = new Date(schedule.start);
        return DRAWING_PROCESSES.includes(process) && 
               scheduleDate >= today && 
               scheduleDate <= twentyDaysLater;
      })
      .map(schedule => {
        const titleParts = schedule.title.split('\n');
        const startDate = new Date(schedule.start);
        return {
          address: titleParts[0],
          process: titleParts[1]?.replace('> ', '') || '',
          date: startDate,
          dateStr: formatDateToString(startDate)
        };
      });
      
    // 주소와 날짜별로 그룹화
    const groupedSchedules = filteredSchedules.reduce((acc, schedule) => {
      const key = `${schedule.address}_${schedule.dateStr}`;
      if (!acc[key]) {
        acc[key] = {
          address: schedule.address,
          processes: [],
          date: schedule.date,
          dateStr: schedule.dateStr
        };
      }
      acc[key].processes.push(schedule.process);
      return acc;
    }, {});
    
    // 그룹화된 데이터를 배열로 변환하고 날짜순으로 정렬
    return Object.values(groupedSchedules)
      .map(group => ({
        address: group.address,
        process: group.processes.join(', '),
        date: group.date
      }))
      .sort((a, b) => a.date - b.date);
  };

  // 공정 간격 과다 체크 함수 수정
  const getProcessGapWarnings = () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    // 주말을 제외한 실제 작업일수 계산 함수
    const getWorkingDays = (startDate, endDate) => {
      let days = 0;
      let currentDate = new Date(startDate);
      
      while (currentDate <= endDate) {
        // 주말(토:6, 일:0) 제외
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
          days++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return days - 1; // 시작일과 종료일 사이의 간격이므로 1을 뺌
    };

    // 주소별로 일정 그룹화 (예외 공정 제외)
    const schedulesByAddress = schedules.reduce((acc, schedule) => {
      const address = schedule.title.split('\n')[0];
      const process = schedule.title.split('\n')[1]?.replace('> ', '') || '';
      const startDate = new Date(schedule.start);

      // 예외 공정은 제외
      if (EXCLUDED_PROCESSES.some(excluded => process.includes(excluded))) {
        return acc;
      }

      if (!acc[address]) {
        acc[address] = [];
      }
      acc[address].push({ process, date: startDate });
      return acc;
    }, {});

    const warnings = [];

    // 각 주소별로 공정 간격 체크
    Object.entries(schedulesByAddress).forEach(([address, processes]) => {
      // 날짜순으로 정렬
      processes.sort((a, b) => a.date - b.date);
      
      // 연속된 공정들 사이의 간격 체크
      for (let i = 0; i < processes.length - 1; i++) {
        const currentProcess = processes[i];
        const nextProcess = processes[i + 1];
        
        // 주말을 제외한 실제 작업일수 계산
        const workingDays = getWorkingDays(currentProcess.date, nextProcess.date);
        
        if (workingDays > MAX_GAP) {
          warnings.push({
            address,
            message: `${formatDate(currentProcess.date)}과 ${formatDate(nextProcess.date)} 사이에 ${workingDays}일(주말 제외)의 공백이 있습니다`,
            date: currentProcess.date
          });
        }
      }
    });

    return warnings.sort((a, b) => a.date - b.date);
  };

  // 공정 집중도 체크
  const getProcessConcentrationWarnings = () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    
    const thirtyDaysLater = new Date(currentDate);
    thirtyDaysLater.setDate(thirtyDaysLater.getDate() + 30);

    // 날짜별 공정 수 집계
    const processesByDate = {};

    schedules.forEach(schedule => {
      const startDate = new Date(schedule.start);
      const dateStr = formatDateToString(startDate);
      const address = schedule.title.split('\n')[0];
      const process = schedule.title.split('\n')[1]?.replace('> ', '') || '';

      if (startDate >= currentDate && startDate <= thirtyDaysLater) {
        if (!processesByDate[dateStr]) {
          processesByDate[dateStr] = {
            total: 0,
            processes: [],
            addresses: new Set()
          };
        }
        processesByDate[dateStr].total++;
        processesByDate[dateStr].processes.push({ address, process });
        processesByDate[dateStr].addresses.add(address);
      }
    });

    const warnings = [];

    // 날짜별로 공정 집중도 체크
    Object.entries(processesByDate).forEach(([date, data]) => {
      if (data.total > MAX_PROCESSES_PER_DAY) {
        const addresses = Array.from(data.addresses);
        warnings.push({
          date: new Date(date),
          total: data.total,
          addresses,
          processes: data.processes,
          message: `${formatDate(new Date(date))}에 ${data.total}개 공정이 집중되어 있습니다.`
        });
      }
    });

    return warnings.sort((a, b) => a.date - b.date);
  };

  const todaySchedules = getSchedulesForDate(new Date());
  const tomorrowSchedules = getSchedulesForDate(new Date(new Date().setDate(new Date().getDate() + 1)));
  const dayAfterTomorrowSchedules = getSchedulesForDate(new Date(new Date().setDate(new Date().getDate() + 2)));
  const fiveDaySchedules = getSchedulesForDate(new Date(new Date().setDate(new Date().getDate() + 5)));
  const materialSchedules = getMaterialSchedules();
  const demolitionSchedules = getDemolitionSchedules();
  const drawingSchedules = getDrawingSchedules(); // 도면 준비 일정 가져오기
  const gapWarnings = getProcessGapWarnings();
  const concentrationWarnings = getProcessConcentrationWarnings();

  // 일정 표시 컴포넌트
  const ScheduleList = ({ schedules, date, showMaterialAlert, isFiveDays }) => {
    if (schedules.length === 0) return null;

    return (
      <>
        <h4 className="date-header">{formatDate(date)}</h4>
        {schedules.map((schedule, index) => (
          <div key={index}>
            <p className="schedule-item">
              {schedule.address}: {schedule.process}
              {showMaterialAlert && (
                <span className="material-alert">
                  {isFiveDays ? 
                    `⚠️ ${formatDate(date)} ${schedule.process}입니다. 자재를 준비하세요.` :
                    '⚠️ 자재 준비가 필요합니다'
                  }
                </span>
              )}
            </p>
          </div>
        ))}
      </>
    );
  };

  // 자재/도면 준비 상황 분석
  const getResourceAnalysis = async () => {
    try {
      const docRef = doc(db, 'config', 'apiKeys');
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        return null;
      }
      
      const { gemini: apiKey } = docSnap.data();
      
      if (!apiKey) {
        return null;
      }

      // 분석에 필요한 데이터 준비
      const analysisData = {
        schedules: schedules.filter(schedule => {
          const date = new Date(schedule.start);
          const fourteenDaysLater = new Date();
          fourteenDaysLater.setDate(fourteenDaysLater.getDate() + 14);
          return date >= new Date() && date <= fourteenDaysLater;
        }).map(schedule => ({
          address: schedule.title.split('\n')[0],
          process: schedule.title.split('\n')[1]?.replace('> ', '') || '',
          date: schedule.start
        }))
      };

      // Gemini AI 초기화 및 응답 생성
      const genAI = new GoogleGenerativeAI(apiKey);
      const model = genAI.getGenerativeModel({ model: "gemini-2.0-flash" });
      const endDate = new Date(new Date().setDate(new Date().getDate() + 14));

      const result = await model.generateContent({
        contents: [{
          parts: [{ text: `
## 향후 14일간 공정 준비 상태 분석 (${formatDate(new Date())} ~ ${formatDate(endDate)})

다음 공정들의 준비 상태를 중점적으로 체크해주세요:

도면 준비 필요 공정:
- 목공사
- 전기공사
- 제작가구
- 타일 공사

자재 준비 필요 공정:
- 타일공사
- 욕실시공
- 조명시공
- 문시공
- 필름
- 빅슬랩 상판

분석 요청사항:
1. 현장별 시급하게 준비해야 할 자재/도면
2. 현장별 특별히 주의가 필요한 공정  
3. 현장별 일정 조정이 필요한 부분

분석 데이터:
${JSON.stringify(analysisData, null, 2)}

답변 형식:
- 3-4줄로 핵심만 간단히 작성
- 가장 중요한 사항부터 순서대로
- 현장 주소는 반드시 '<' 와 '>' 기호로 감싸서 표시 (예: <수목원 삼성래미안 103동 702호>)
- 날짜는 반드시 새로운 줄에서 시작하고 '3월 15일 - ' 형식으로 표시
- '자재/도면 : ' 는 반드시 새로운 줄에서 시작
- 각 항목은 새로운 줄에 표시
- 새로운 주소가 나올 때마다 빈 줄을 추가하여 단락 구분
- 절대로 주소에 '**' 기호나 ':' 기호를 사용하지 않음` }]
        }]
      });

      const analysisText = result.response?.text() || null;

      // 분석 결과를 Firestore에 저장
      if (analysisText) {
        const analysisRef = doc(db, 'analysis', 'current');
        await setDoc(analysisRef, {
          text: analysisText,
          updatedAt: new Date()
        });
      }
      
      return analysisText;
    } catch (error) {
      return null;
    }
  };

  // 저장된 분석 결과 불러오기
  const loadSavedAnalysis = async () => {
    try {
      const analysisRef = doc(db, 'analysis', 'current');
      const analysisDoc = await getDoc(analysisRef);
      if (analysisDoc.exists()) {
        setResourceAnalysis(analysisDoc.data().text);
      }
    } catch (error) {
      // 저장된 분석 결과 불러오기 실패 처리
    }
  };

  // 컴포넌트 마운트 시 저장된 분석 결과 불러오기
  useEffect(() => {
    loadSavedAnalysis();
  }, []);

  // 새로고침 버튼 클릭 핸들러
  const handleRefresh = async () => {
    setIsRefreshing(true);
    const analysis = await getResourceAnalysis();
    if (analysis) {
      setResourceAnalysis(analysis);
    }
    setIsRefreshing(false);
  };

  return (
    <div className="notification-panel">
      <div className="ai-message">
        <div className="ai-message-header">
          <h3>
            <span className="analysis-header-text">
              AI 일정 분석
              <button
                className={`refresh-icon ${isRefreshing ? 'rotating' : ''}`}
                onClick={handleRefresh}
                disabled={isRefreshing}
              >
                ↻
              </button>
            </span>
          </h3>
          <button
            className={`voice-button ${isSpeaking ? 'speaking' : ''}`}
            onClick={handleVoiceButtonClick}
            title={isSpeaking ? '음성 중지' : '음성으로 듣기'}
          >
            {isSpeaking ? '🔊' : '🔈'}
          </button>
          <button
            className="ai-settings-icon"
            onClick={onAISettingsClick}
            title="AI 설정"
          >
            ⚙️
          </button>
          {batteryLevel !== null && batteryLevel < 0.2 && (
            <span className="notificationpanel-low-battery-indicator">
              {Math.round(batteryLevel * 100)}%
            </span>
          )}
        </div>
        <div className="ai-message-content">
          {/* 브라우저 지원 메시지 */}
          {!browserSupport.supported && (
            <div className="notificationpanel-browser-support-message">
              {browserSupport.message}
            </div>
          )}
          
          {/* iOS Safari 안내 메시지 */}
          {browserSupport.supported && browserSupport.message && (
            <div className="notificationpanel-browser-support-message">
              {browserSupport.message}
            </div>
          )}
          
          {/* 오류 메시지 */}
          {errorMessage && (
            <div className="notificationpanel-speech-error-message">
              {errorMessage}
            </div>
          )}
          
          {/* 음성 진행 상태 표시 */}
          {isSpeaking && currentSpeechText && (
            <div className="notificationpanel-speech-progress-container">
              <div 
                className="notificationpanel-speech-progress-bar" 
                style={{ 
                  width: `${Math.min(100, (currentSpeechPosition / currentSpeechText.length) * 100)}%` 
                }}
              />
            </div>
          )}
          
          {todaySchedules.length > 0 && (
            <div className="schedule-section material-schedules">
              <h4 className="date-header">오늘 일정</h4>
              {todaySchedules.map((schedule, index) => (
                <div key={index}>
                  <p className="schedule-item">
                    {schedule.address}: {schedule.process}
                  </p>
                </div>
              ))}
            </div>
          )}
          <div className="schedule-section material-schedules">
            <h4 className="date-header">내일 일정</h4>
            {tomorrowSchedules.length > 0 ? (
              tomorrowSchedules.map((schedule, index) => (
                <div key={index}>
                  <p className="schedule-item">
                    {schedule.address}: {schedule.process}
                  </p>
                </div>
              ))
            ) : (
              <p className="schedule-item">
                내일 예정된 일정이 없습니다.
              </p>
            )}
          </div>
          <div className="schedule-section material-schedules">
            <h4 className="date-header">모레 일정</h4>
            {dayAfterTomorrowSchedules.length > 0 ? (
              dayAfterTomorrowSchedules.map((schedule, index) => (
                <div key={index}>
                  <p className="schedule-item">
                    {schedule.address}: {schedule.process}
                  </p>
                </div>
              ))
            ) : (
              <p className="schedule-item">
                모레 예정된 일정이 없습니다.
              </p>
            )}
          </div>
          {materialSchedules.length > 0 && (
            <div className="schedule-section material-schedules">
              <h4 className="date-header">자재 준비 필요 공정</h4>
              {materialSchedules.map((schedule, index) => (
                <div key={index}>
                  <p className="schedule-item">
                    {schedule.address}: {schedule.process}
                    <span className="material-alert">
                      🏗️ {formatDate(schedule.date)} 예정, 자재를 준비하세요
                    </span>
                  </p>
                </div>
              ))}
            </div>
          )}
          {demolitionSchedules.length > 0 && (
            <div className="schedule-section demolition-schedules">
              <h4 className="date-header">철거 시작 알림</h4>
              {demolitionSchedules.map((schedule, index) => (
                <div key={index}>
                  <p className="schedule-item">
                    {schedule.address}
                    <span className="demolition-alert">
                      🏗️ {formatDate(schedule.date)} {schedule.process} 시작. 도면과 자재 준비
                    </span>
                  </p>
                </div>
              ))}
            </div>
          )}
          {drawingSchedules.length > 0 && (
            <div className="schedule-section material-schedules">
              <h4 className="date-header">도면 준비 필요 공정</h4>
              {drawingSchedules.map((schedule, index) => (
                <div key={index}>
                  <p className="schedule-item">
                    {schedule.address}: {schedule.process}
                    <span className="material-alert">
                      📋 {formatDate(schedule.date)} 예정, 도면을 준비하세요
                      <br />
                      <strong>도면 미준비 시 공사 지연 및 추가 비용 발생 위험</strong>
                    </span>
                  </p>
                </div>
              ))}
            </div>
          )}
          {gapWarnings.length > 0 && (
            <div className="schedule-section material-schedules">
              <h4 className="date-header">공정 간격 경고</h4>
              {gapWarnings.map((warning, index) => (
                <div key={index}>
                  <p className="schedule-item">
                    {warning.address}
                    <span className="material-alert">
                      ⚠️ {warning.message}
                      <br />
                      <strong>입주 기간 연장 및 추가 비용 발생 위험</strong>
                    </span>
                  </p>
                </div>
              ))}
            </div>
          )}
          {concentrationWarnings.length > 0 && (
            <div className="schedule-section material-schedules">
              <h4 className="date-header">공정 집중도 경고</h4>
              {concentrationWarnings.map((warning, index) => (
                <div key={index}>
                  <p className="schedule-item concentration-warning">
                    <span className="material-alert">
                      ⚡ {warning.message}
                      {warning.addresses.length > 1 ? 
                        `\n관련 현장: ${warning.addresses.join(', ')}` : 
                        ''}
                      <br />
                      <strong>공정 집중으로 인한 품질 저하 및 안전사고 위험</strong>
                    </span>
                  </p>
                </div>
              ))}
            </div>
          )}
          <div className="schedule-section material-schedules">
            <h4 className="date-header">
              <div className="analysis-header-text">
                🔍 현장 상황 분석
                <button 
                  className={`refresh-icon ${isRefreshing ? 'rotating' : ''}`}
                  onClick={handleRefresh}
                  title="AI 분석 새로고침"
                  disabled={isRefreshing}
                >
                  ↻
                </button>
              </div>
            </h4>
            <div className="schedule-item">
              <div className="material-alert" style={{whiteSpace: 'pre-line', width: '100%'}}>
                {resourceAnalysis || '새로고침 버튼을 클릭하여 현장 상황을 분석해보세요.'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationPanel; 