import { pdf } from '@react-pdf/renderer';
import MyDocument from '../components/pdf/MyDocument';
import { formatNumber, calculateTotal, getProcessName } from '../components/pdf/utils';

const API_URL = 'https://us-central1-interior-one-click.cloudfunctions.net/sendEstimatePdfHttp';

export const sendEstimateEmail = async ({
  email,
  subject,
  content,
  selectedAddress,
  processData,
  notes,
  hiddenProcesses
}) => {
  try {
    console.group('📧 이메일 전송 시도');
    console.log('수신자 이메일:', email);
    console.log('선택된 주소:', selectedAddress);
    
    // PDF 생성
    console.log('📄 PDF 생성 시작');
    const blob = await pdf(
      <MyDocument 
        selectedAddress={selectedAddress}
        processData={processData}
        notes={notes}
        hiddenProcesses={hiddenProcesses}
        calculateTotal={calculateTotal}
        getProcessName={getProcessName}
        formatNumber={formatNumber}
      />
    ).toBlob();
    console.log('✅ PDF 생성 완료');

    // PDF를 Base64로 변환
    const reader = new FileReader();
    const base64Promise = new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
    const base64PDF = await base64Promise;

    // 이메일 전송 요청
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: email,
        subject: subject,
        html: content,
        attachments: [{
          filename: `아마레디자인 (${selectedAddress}) - 견적서.pdf`,
          content: base64PDF,
          encoding: 'base64',
          contentType: 'application/pdf'
        }]
      })
    });

    if (!response.ok) {
      throw new Error('이메일 전송에 실패했습니다.');
    }

    return true;
  } catch (error) {
    console.error('이메일 전송 중 오류:', error);
    throw error;
  }
};

export const sendConstructionGuideEmail = async ({
  email,
  subject,
  content,
  selectedAddress,
  pdfBlob
}) => {
  try {
    console.group('📧 공사 안내문 이메일 전송 시도');
    console.log('수신자 이메일:', email);
    console.log('선택된 주소:', selectedAddress);

    // PDF를 Base64로 변환
    const reader = new FileReader();
    const base64Promise = new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(pdfBlob);
    });
    const base64PDF = await base64Promise;

    // 이메일 전송 요청
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: email,
        subject: subject,
        html: content,
        attachments: [{
          filename: `아마레디자인 (${selectedAddress}) - 공사안내문.pdf`,
          content: base64PDF,
          encoding: 'base64',
          contentType: 'application/pdf'
        }]
      })
    });

    if (!response.ok) {
      throw new Error('이메일 전송에 실패했습니다.');
    }

    return true;
  } catch (error) {
    console.error('이메일 전송 중 오류:', error);
    throw error;
  }
}; 