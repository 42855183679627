import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext({
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
  checkAuth: () => {}
});

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuth = () => {
    const autoLogin = localStorage.getItem('autoLogin');
    const savedUsername = localStorage.getItem('savedUsername');
    const savedPassword = localStorage.getItem('savedPassword');
    const authStatus = localStorage.getItem('isAuthenticated');

    if (autoLogin === 'true' && savedUsername && savedPassword) {
      login(savedUsername, savedPassword, true);
      return true;
    } else if (authStatus === 'true' && autoLogin === 'true') {
      setIsAuthenticated(true);
      return true;
    }
    
    // 자동 로그인이 해제되어 있으면 저장된 인증 정보 삭제
    if (autoLogin !== 'true') {
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('username');
      localStorage.removeItem('autoLogin');
      localStorage.removeItem('savedUsername');
      localStorage.removeItem('savedPassword');
      setIsAuthenticated(false);
    }
    
    return false;
  };

  useEffect(() => {
    checkAuth();
    setIsLoading(false);
  }, []);

  const login = (username, password, rememberMe = false) => {
    if ((username === process.env.REACT_APP_ADMIN_USERNAME || username === 'admin') &&
        (password === process.env.REACT_APP_ADMIN_PASSWORD || password === '1')) {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('username', username);

      if (rememberMe) {
        localStorage.setItem('autoLogin', 'true');
        localStorage.setItem('savedUsername', username);
        localStorage.setItem('savedPassword', password);
      }
      return true;
    }
    return false;
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('username');
    localStorage.removeItem('autoLogin');
    localStorage.removeItem('savedUsername');
    localStorage.removeItem('savedPassword');
  };

  if (isLoading) {
    return null; // 또는 로딩 인디케이터
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, checkAuth }}>
      {children}
    </AuthContext.Provider>
  );
}; 