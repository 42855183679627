import React, { useState } from 'react';
import { db } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './CustomerNumberInput.css';

const CustomerNumberInput = ({ onSubmit }) => {
  const [customerNumber, setCustomerNumber] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!customerNumber.trim()) {
      setError('고객번호를 입력해주세요.');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const addressesRef = collection(db, 'addresses');
      const q = query(addressesRef, where('phoneLastFourDigits', '==', customerNumber.trim()));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError('등록되지 않은 고객번호입니다.');
        return;
      }

      const addressData = querySnapshot.docs[0].data();
      onSubmit(customerNumber.trim(), addressData);
    } catch (error) {
      console.error('Error checking customer number:', error);
      setError('고객번호 확인 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="customer-number-input">
      <div className="customer-number-input__content">
        <div className="customer-number-input__header">
          <h1 className="customer-number-input__title">AMARE DESIGN</h1>
          <p className="customer-number-input__subtitle">욕실 자재 선택 도우미</p>
        </div>
        
        <form className="customer-number-input__form" onSubmit={handleSubmit}>
          <div className="customer-number-input__field">
            <label htmlFor="customerNumber" className="customer-number-input__label">
              고객번호를 입력해주세요
            </label>
            <input
              id="customerNumber"
              type="text"
              className="customer-number-input__input"
              value={customerNumber}
              onChange={(e) => setCustomerNumber(e.target.value)}
              placeholder="고객번호 입력"
              required
            />
            {error && <p className="customer-number-input__error">{error}</p>}
          </div>
          <button type="submit" className="customer-number-input__submit" disabled={isLoading}>
            {isLoading ? '확인 중...' : '시작하기'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CustomerNumberInput; 