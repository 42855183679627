import React, { useState, useEffect, useCallback } from 'react';
import OngoingAddressesToggle from '../OngoingAddressesToggle';
import './DesktopEstimateV2.css';
import { db } from '../../firebase';
import { collection, getDocs, query, orderBy, doc, getDoc, updateDoc, setDoc, addDoc } from 'firebase/firestore';
import EditItemModal from '../estimate-version/EditItemModal';
import VersionSaveModal from '../estimate-version/VersionSaveModal';
import EditStartModal from '../estimate-version/EditStartModal';
import SelectVersionModal from '../estimate-version/SelectVersionModal';
import { styles, detailStyles } from '../../styles/pdf/pdfStyles';
import EmailPdfModal from '../EmailPdfModal';
import AddItemModal from '../estimate-version/AddItemModal';
import DragHandleItemList from '../estimate-version/DragHandleItemList';
import AISuggestButton from '../estimate-ai/AISuggestButton';
import AITemplateModal from '../estimate-ai/components/AITemplateModal';
import HideProcessButton from '../process-hide/HideProcessButton';
import HiddenProcessList from '../process-hide/HiddenProcessList';
import { useNavigate } from 'react-router-dom';
import { filterProcessesForPDF, formatNumber, calculateTotal, getProcessName } from '../pdf/utils';
import InterimSave from '../estimate-version/InterimSave';
import VersionManager from '../estimate-version/VersionManager';
import useKeyboardShortcut from '../../hooks/useKeyboardShortcut';
import PdfButton from '../pdf/PdfButton';
import MyDocument from '../pdf/MyDocument';
import { pdf } from '@react-pdf/renderer';
import ProcessManagementModal from './process-management/ProcessManagementModal';
import ShareButton from './share/ShareButton';

const DesktopEstimateV2 = () => {
  const navigate = useNavigate();
  
  // 기본 상태
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [processData, setProcessData] = useState([]);
  const [notes, setNotes] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [editingNote, setEditingNote] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [newVersionName, setNewVersionName] = useState(null);
  const [pendingEditItem, setPendingEditItem] = useState(null);
  const [skipRestore, setSkipRestore] = useState(false);
  const [modifications, setModifications] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  
  // 모달 상태
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showAIPromptModal, setShowAIPromptModal] = useState(false);
  const [showAITemplateModal, setShowAITemplateModal] = useState(false);
  const [showSelectVersionModal, setShowSelectVersionModal] = useState(false);
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [showHiddenProcessModal, setShowHiddenProcessModal] = useState(false);
  const [showEditStartModal, setShowEditStartModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);  // 추가된 상태
  const [showProcessManagementModal, setShowProcessManagementModal] = useState(false);
  
  // 기타 상태
  const [selectedProcessForAI, setSelectedProcessForAI] = useState(null);
  const [hiddenProcesses, setHiddenProcesses] = useState({});
  const [selectedProcessId, setSelectedProcessId] = useState(null);
  const [pendingModifications, setPendingModifications] = useState({});
  const [modificationCount, setModificationCount] = useState(0);
  const [isAdditionalSubtotalHidden, setIsAdditionalSubtotalHidden] = useState(false);

  useEffect(() => {
    if (selectedAddress) {
      console.group('🔄 주소 변경에 따른 데이터 로드 시작');
      console.log('선택된 주소:', selectedAddress);
      console.log('현재 processData 상태:', processData);
      
      const loadVersionData = async () => {
        try {
          const versions = await VersionManager.getVersionList(selectedAddress);
          console.group('📦 버전 데이터 조회 결과');
          console.log('버전 개수:', versions?.length || 0);
          console.log('전체 버전 목록:', versions);
          console.groupEnd();
          
          if (versions && versions.length > 0) {
            const latestVersion = versions[0];
            console.group('🔄 최신 버전 데이터 처리');
            console.log('버전명:', latestVersion.versionName);
            console.log('생성일:', latestVersion.createdAt);
            console.log('버전 전체 데이터:', latestVersion);
            
            if (latestVersion.data?.processData) {
              console.group('📝 프로세스 데이터 설정');
              console.log('이전 processData:', processData);
              console.log('설정할 processData:', latestVersion.data.processData);
              setProcessData(latestVersion.data.processData);
              console.groupEnd();
            } else {
              console.log('⚠️ 프로세스 데이터 없음 - 기본 데이터 로드 시도');
              await fetchProcessData();
            }

            if (latestVersion.data?.notes) {
              console.log('📝 노트 데이터 설정:', latestVersion.data.notes);
              setNotes(latestVersion.data.notes);
            }
            
            if (latestVersion.hiddenProcesses) {
              console.group('🎯 숨긴 공정 상태 설정');
              console.log('이전 hiddenProcesses:', hiddenProcesses);
              console.log('설정할 hiddenProcesses:', latestVersion.hiddenProcesses);
              setHiddenProcesses(latestVersion.hiddenProcesses);
              console.groupEnd();
            }
            
            console.log('📌 현재 버전 설정:', latestVersion);
            setCurrentVersion(latestVersion);
            console.groupEnd();
          } else {
            console.log('⚠️ 버전 정보 없음 - 기본 데이터 로드 시도');
            await fetchProcessData();
            // 버전이 없는 경우 모든 공정 표시
            setHiddenProcesses({});
            setCurrentVersion(null);
          }
        } catch (error) {
          console.error('❌ 데이터 로드 중 오류:', error);
          await fetchProcessData();
          // 에러 발생 시에도 모든 공정 표시
          setHiddenProcesses({});
          setCurrentVersion(null);
        }
      };
      
      loadVersionData();
      console.groupEnd();
    }
  }, [selectedAddress]);

  const fetchProcessData = async () => {
    try {
      console.log('📝 기본 프로세스 데이터 로드');
      const processesRef = collection(db, 'estimateProcessesV2');
      const q = query(processesRef, orderBy('processOrder', 'asc'));
      const snapshot = await getDocs(q);
      
      const processData = snapshot.docs
        .filter(doc => doc.data().isActive)
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          items: [],
          total: 0  // 초기 total 값 추가
        }));
      
      setProcessData(processData);
      console.log('✅ 기본 프로세스 데이터 로드 완료:', processData);
    } catch (error) {
      console.error("❌ 프로세스 데이터 로드 중 오류:", error);
      // 에러 발생 시 빈 배열로 초기화
      setProcessData([]);
    }
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();

        // 모달 닫기 우선순위 설정
        if (showAITemplateModal) {
          setShowAITemplateModal(false);
          setSelectedProcessForAI(null);
        } else if (showSelectVersionModal) {
          setShowSelectVersionModal(false);
          setPendingEditItem(null);
          setPendingModifications({});
          setIsEditingMode(false);

        } else if (showUploadModal) {
          setShowUploadModal(false);
        } else if (showAIPromptModal) {
          setShowAIPromptModal(false);
        } else if (modalType) {
          setModalType(null);
          setEditingItem(null);
        } else if (showEditStartModal) {
          setShowEditStartModal(false);
          setPendingEditItem(null);
          setIsEditingMode(false);
        } else if (showEmailModal) {
          setShowEmailModal(false);
        } else if (showAddItemModal) {
          setShowAddItemModal(false);
          setSelectedProcessId(null);
        } else if (isEditingMode) {
          // 다른 모달이 모두 닫혀있을 때만 수정 모드 취소 확인
          if (window.confirm('진행 중인 수정사항이 모두 취소됩니다. 계속하시겠습니까?')) {
            setIsEditingMode(false);
            setPendingModifications({});
            setModificationCount(0);
            loadModifications();
          }
        }

        return;
      }
    };

    document.addEventListener('keydown', handleEscKey, true);
    return () => {
      document.removeEventListener('keydown', handleEscKey, true);
    };
  }, [
    showAITemplateModal,  // AI 템플릿 모달 상태 추가
    showSelectVersionModal, 
    showUploadModal, 
    showAIPromptModal, 
    modalType, 
    showEditStartModal, 
    isEditingMode, 
    showEmailModal,
    showAddItemModal
  ]);

  useEffect(() => {
    const loadDataWithModifications = async () => {
      if (!selectedAddress) {
        console.log('❌ 주소가 선택되지 않음 - 데이터 로드 취소');
        return;
      }
      
      console.group('🔄 수정사항 적용 시작');
      console.log('현재 주소:', selectedAddress);
      console.log('현재 processData:', processData);
      console.log('현재 modifications:', modifications);
      
      try {
        let currentOrder = null;
        
        if (Object.keys(modifications).length > 0) {
          console.log('🔨 수정사항 적용 시도:', modifications);
          await applyModifications(modifications);
        }

        if (currentOrder) {
          console.group('🔄 프로세스 순서 적용');
          console.log('적용할 순서:', currentOrder);
          setProcessData(prevData => {
            const orderedData = prevData.map(process => {
              const savedOrder = currentOrder[process.id];
              if (!savedOrder) return process;

              const orderedItems = [...process.items].sort((a, b) => {
                const indexA = savedOrder.indexOf(a.uniqueId);
                const indexB = savedOrder.indexOf(b.uniqueId);
                if (indexA === -1) return 1;
                if (indexB === -1) return -1;
                return indexA - indexB;
              });

              return {
                ...process,
                items: orderedItems
              };
            });
            console.log('순서 적용 결과:', orderedData);
            return orderedData;
          });
          console.groupEnd();
        }

      } catch (error) {
        console.error('❌ 데이터 로드 중 오류:', error);
      }
      console.groupEnd();
    };

    const timeoutId = setTimeout(() => {
      loadDataWithModifications();
    }, 100);
    
    return () => clearTimeout(timeoutId);
  }, [selectedAddress, modifications]);

  const loadModifications = async (address) => {
    try {
      const targetAddress = address || selectedAddress;
      
      if (!targetAddress) {
        return;
      }

      const versions = await VersionManager.getVersionList(targetAddress);
      
      if (versions && versions.length > 0) {
        const sortedVersions = [...versions].sort((a, b) => {
          const timeA = a.lastModified || a.createdAt;
          const timeB = b.lastModified || b.createdAt;
          return new Date(timeB) - new Date(timeA);
        });

        const latestVersion = sortedVersions[0];

        if (latestVersion.data.notes) {
          setNotes(latestVersion.data.notes);
        }
        
        if (latestVersion.data.processData) {
          setProcessData(latestVersion.data.processData);
        }

        if (latestVersion.data.processOrder) {
          setProcessData(prevData => applyOrder(prevData, latestVersion.data.processOrder));
        }

        setCurrentVersion(latestVersion);
      } else {
        setModifications({});
        setCurrentVersion(null);
      }
    } catch (error) {
      console.error('❌ 수정이력 로드 중 오류:', error);
    }
  };

  const applyModifications = async (modifications) => {
    try {
      await new Promise((resolve) => {
        setProcessData(prevData => {
          if (!prevData) return [];

          const updatedData = prevData.map(process => {
            if (!process || !process.items) return null;

            const processModifications = modifications ? Object.values(modifications)
              .filter(mod => mod && 
                (mod.processId === process.id || 
                 (mod.type === 'add' && mod.newData?.processId === process.id))
              ) : [];
            
            if (processModifications.length === 0) return process;

            let updatedItems = [...process.items];

            const templateMods = processModifications.filter(mod => mod.type === 'template_apply');
            if (templateMods.length > 0) {
              templateMods.forEach(mod => {
                updatedItems = mod.items.map(item => ({
                  ...item,
                  totalPrice: parseInt(item.quantity) * parseInt(item.unitPrice)
                }));
              });
            }

            const deletedItemIds = Object.values(modifications)
              .filter(mod => mod.type === 'delete')
              .map(mod => mod.itemId);

            updatedItems = updatedItems.filter(item => !deletedItemIds.includes(item.uniqueId));

            updatedItems = updatedItems.map(item => {
              const modification = processModifications.find(
                mod => mod && mod.type !== 'delete' && mod.itemId === item.uniqueId
              );
              
              if (!modification || !modification.modifiedData) return item;
              
              return {
                ...item,
                ...modification.modifiedData,
                uniqueId: item.uniqueId
              };
            });

            const addedItems = processModifications
              .filter(mod => mod.type === 'add' && !deletedItemIds.includes(mod.itemId))
              .map(mod => ({
                ...mod.newData,
                uniqueId: mod.itemId
              }));

            addedItems.forEach(newItem => {
              if (!updatedItems.some(item => item.uniqueId === newItem.uniqueId)) {
                updatedItems.push(newItem);
              }
            });

            return {
              ...process,
              items: updatedItems,
              total: updatedItems.reduce((sum, item) => {
                const itemTotal = parseInt(item.totalPrice) || 0;
                return sum + itemTotal;
              }, 0)
            };
          }).filter(Boolean);

          return updatedData;
        });
        
        setTimeout(resolve, 100);
      });

    } catch (error) {
      alert('수정사항 적용 중 오류가 발생했습니다.');
    }
  };

  // 1. 현재 순서 수집 함수 추가
  const collectCurrentOrder = () => {
    return processData.reduce((acc, process) => {
      acc[process.id] = process.items.map(item => item.uniqueId);
      return acc;
    }, {});
  };

  // 2. 순서 적용 함수 수정
  const applyOrder = (data, orderData) => {
    if (!orderData) return data;

    return data.map(process => {
      const savedOrder = orderData[process.id];
      if (!savedOrder) return process;

      // 저장된 순서에 따라 items 재정렬
      const orderedItems = [...process.items].sort((a, b) => {
        const indexA = savedOrder.indexOf(a.uniqueId);
        const indexB = savedOrder.indexOf(b.uniqueId);
        return indexA - indexB;
      });

      return {
        ...process,
        items: orderedItems
      };
    });
  };

  // 3. 주소 선택 시 데이터 로드 함수 수정
  const handleSelectAddress = async (addressId, paymentData) => {
    try {
      console.group('🏠 주소 선택 및 최신 버전 로드');
      console.log('선택된 주소:', addressId);

      if (selectedAddress === addressId) {
        console.log('✖️ 같은 주소 재선택 - 무시');
        console.groupEnd();
        return;
      }

      await startLoadingProcess(async () => {
        // 초기화
        setProcessData([]);
        setNotes({});
        setHiddenProcesses({});
        setCurrentVersion(null);
        
        // 주소 설정
        setSelectedAddress(addressId);
        setPaymentData(paymentData);
      });

    } catch (error) {
      console.error('❌ 데이터 로드 중 오류:', error);
      console.groupEnd();
      alert('데이터를 불러오는 중 오류가 발생했습니다.');
    }
  };

  const handleAdminLogin = (isAdmin) => {
    setIsEditMode(isAdmin);
  };

  const getDisplayName = (item) => {
    if (!item.required && item.status === '별도') {
      return `${item.name} (별도)`;
    }
    return item.name;
  };

  const handleItemEdit = (process, item) => {
    if (!isEditMode) {
      alert('관리자 모드가 아닙니다. 수정하시려면 관리자 로그인이 필요합니다.');
      return;
    }
    
    if (!selectedAddress) {
      if (window.confirm('주소를 먼저 선택해주세요. 주소 선택 화면으로 이동하시겠습니까?')) {
        const addressComponent = document.querySelector('.desktop-ongoing-container');
        if (addressComponent) {
          addressComponent.scrollIntoView({ 
            behavior: 'smooth',
            block: 'center'
          });
        }
      }
      return;
    }
    
    if (!isEditingMode) {
      setPendingEditItem({ process, item });
      setShowSelectVersionModal(true);
      return;
    }

    setModalType('edit');
    setEditingItem({ processId: process.id, item });
  };

  // 수정 시작 핸들러
  const handleStartVersionEdit = () => {
    console.group('🔄 수정 시작 시도');
    console.log('isEditMode:', isEditMode);
    console.log('selectedAddress:', selectedAddress);
    console.log('isEditingMode:', isEditingMode);
    console.log('showSelectVersionModal 현재값:', showSelectVersionModal);

    if (!isEditMode) {
      console.log('❌ 관리자 모드가 아님');
      console.groupEnd();
      alert('관리자 모드가 아닙니다. 관리자 로그인이 필요합니다.');
      return;
    }

    if (!selectedAddress) {
      console.log('❌ 주소가 선택되지 않음');
      console.groupEnd();
      alert('주소를 먼저 선택해주세요.');
      return;
    }
    
    // 수정 시작 시 SelectVersionModal 표시
    console.log('✅ SelectVersionModal 표시 시도');
    setShowSelectVersionModal(true);
    console.log('showSelectVersionModal 설정 후:', true);
    
    // 수정 모드 상태 초기화
    console.log('✅ 상태 초기화');
    setPendingModifications({});
    setModificationCount(0);
    setNewVersionName(null);
    
    console.groupEnd();
  };

  // saveModification 함수 수정
  const saveModification = async (processId, itemId, changes) => {
    try {
      if (!selectedAddress || !isEditMode) {
        return;
      }

      const process = processData.find(p => p.id === processId);
      const item = process?.items.find(i => i.uniqueId === changes.uniqueId);

      if (!item) {
        return;
      }

      // 합계 계산 로직 수정
      const newItemTotal = changes.excludeFromTotal 
        ? parseInt(changes.unitPrice) || 0
        : (parseInt(changes.quantity) || 0) * (parseInt(changes.unitPrice) || 0);

      const existingModification = Object.entries(pendingModifications)
        .find(([key, mod]) => mod.itemId === changes.uniqueId);

      if (existingModification) {
        if (changes.quantity === item.quantity && 
            changes.unitPrice === item.unitPrice && 
            changes.note === item.note &&
            changes.name === item.name &&
            changes.unit === item.unit &&
            changes.excludeFromTotal === item.excludeFromTotal &&
            changes.isAdditional === item.isAdditional) {  // isAdditional 비교 추가
          setPendingModifications(prev => {
            const newMods = { ...prev };
            delete newMods[existingModification[0]];
            return newMods;
          });
          setModificationCount(prev => prev - 1);
        }
      } else {
        if (changes.quantity !== item.quantity || 
            changes.unitPrice !== item.unitPrice || 
            changes.note !== item.note ||
            changes.name !== item.name ||
            changes.unit !== item.unit ||
            changes.excludeFromTotal !== item.excludeFromTotal ||
            changes.isAdditional !== item.isAdditional) {  // isAdditional 비교 추가
          setPendingModifications(prev => ({
            ...prev,
            [changes.uniqueId]: {
              processId,
              itemId: changes.uniqueId,
              originalData: {
                name: item.name,
                quantity: item.quantity,
                unitPrice: item.unitPrice,
                unit: item.unit,
                note: item.note || '',
                excludeFromTotal: item.excludeFromTotal || false,
                isAdditional: item.isAdditional || false  // 원본 데이터에 추가
              },
              modifiedData: {
                name: changes.name,
                quantity: parseInt(changes.quantity),
                unitPrice: parseInt(changes.unitPrice),
                unit: changes.unit,
                note: changes.note || '',
                excludeFromTotal: changes.excludeFromTotal,
                isAdditional: changes.isAdditional,  // 수정된 데이터에 추가
                totalPrice: newItemTotal
              },
              modifiedAt: new Date().toISOString()
            }
          }));
          setModificationCount(prev => prev + 1);
        }
      }

      // UI 업데이트
      setProcessData(prevData => {
        return prevData.map(p => {
          if (p.id !== processId) return p;

          const updatedItems = p.items.map(i => {
            if (i.uniqueId !== changes.uniqueId) return i;
            
            return {
              ...i,
              name: changes.name,
              quantity: parseInt(changes.quantity),
              unitPrice: parseInt(changes.unitPrice),
              unit: changes.unit,
              note: changes.note || '',
              excludeFromTotal: changes.excludeFromTotal,
              isAdditional: changes.isAdditional,  // isAdditional 추가
              totalPrice: newItemTotal
            };
          });

          return {
            ...p,
            items: updatedItems,
            total: updatedItems.reduce((sum, i) => sum + (i.totalPrice || 0), 0)
          };
        });
      });
    } catch (error) {
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  const EditModal = ({ item, onSave, onClose }) => {
    const [editedItem, setEditedItem] = useState({
      name: item.name || '',
      quantity: item.quantity || 0,
      unitPrice: item.unitPrice || 0,
      note: item.note || ''
    });

    const handleSave = () => {
      if (!editedItem.quantity || !editedItem.unitPrice) {
        alert('수과 단가를 입력해주세요.');
        return;
      }
      onSave(editedItem);
    };

    return (
      <div className="edit-modal">
        <div className="edit-modal-content">
          <h3>항목 수정</h3>
          <div className="edit-field">
            <label>품목</label>
            <input
              type="text"
              value={editedItem.name}
              onChange={e => setEditedItem(prev => ({
                ...prev,
                name: e.target.value
              }))}
              placeholder="품목명"
            />
          </div>
          <div className="edit-field">
            <label>수량</label>
            <input
              type="number"
              value={editedItem.quantity}
              onChange={e => setEditedItem(prev => ({
                ...prev,
                quantity: parseInt(e.target.value) || 0
              }))}
              placeholder="수량 입력"
            />
          </div>
          <div className="edit-field">
            <label>단가</label>
            <input
              type="number"
              value={editedItem.unitPrice}
              onChange={e => setEditedItem(prev => ({
                ...prev,
                unitPrice: parseInt(e.target.value) || 0
              }))}
              placeholder="단 입력"
            />
          </div>
          <div className="edit-field">
            <label>비고</label>
            <input
              type="text"
              value={editedItem.note}
              onChange={e => setEditedItem(prev => ({
                ...prev,
                note: e.target.value
              }))}
              placeholder="비고 력"
            />
          </div>
          <div className="total-price">
            <label>합계:</label>
            <span>{formatNumber(editedItem.quantity * editedItem.unitPrice)}</span>
          </div>
          <div className="edit-buttons">
            <button onClick={handleSave}>저장</button>
            <button onClick={onClose}>취소</button>
          </div>
        </div>
      </div>
    );
  };

  const loadModificationHistory = async () => {
    if (!selectedAddress) {
      return;
    }
    
    const docRef = doc(db, 'estimateModifications', selectedAddress);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const data = docSnap.data();
      // setVersionHistory 제거
    }
  };

  const restoreVersion = async (versionId) => {
    try {
      const versions = await VersionManager.getVersionList(selectedAddress);
      const version = versions.find(v => v.versionId === versionId);
      if (!version) return;

      // 수정사항 적용
      if (version.modifications) {
        await applyModifications(version.modifications);
      }

      // 순서 정복
      if (version.processOrder) {
        setProcessData(prevData => {
          return prevData.map(process => {
            const savedOrder = version.processOrder[process.id];
            if (!savedOrder) return process;

            // 저장된 순서에 따라 items 재정렬
            const orderedItems = [...process.items].sort((a, b) => {
              const indexA = savedOrder.indexOf(a.uniqueId);
              const indexB = savedOrder.indexOf(b.uniqueId);
              return indexA - indexB;
            });

            return {
              ...process,
              items: orderedItems
            };
          });
        });
      }

      // Firebase 업데이트
      const docRef = doc(db, 'estimateModifications', selectedAddress);
      await updateDoc(docRef, {
        currentVersion: versionId,
        lastModified: new Date().toISOString()
      });

      alert('선택한 버전으로 복원되었습니다.');
    } catch (error) {
      alert('버전 복원 중 오류가 발생했습니다.');
    }
  };

  const resetModifications = async () => {
    try {
      if (!selectedAddress) {
        alert('주소를 먼저 선택해주세요.');
        return;
      }

      const docRef = doc(db, 'estimateModifications', selectedAddress);
      await setDoc(docRef, {
        versions: [],
        currentVersion: null,
        lastModified: new Date().toISOString()
      });

      setModifications({});
      setPendingModifications({});
      setModificationCount(0);
      await fetchProcessData();
      
      alert('수정 이력이 초기화되었습니다.');
    } catch (error) {
      alert('초기화 중 오류가 발생했습니다.');
    }
  };

  const handleDeleteVersion = async (versionId) => {
    try {
      if (!selectedAddress) {
        alert('주소를 먼저 선택해주세요.');
        return;
      }

      const docRef = doc(db, 'estimateModifications', selectedAddress);
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        return;
      }

      const currentData = docSnap.data();
      const updatedVersions = currentData.versions.filter(
        version => version.versionId !== versionId
      );

      await updateDoc(docRef, {
        versions: updatedVersions,
        lastModified: new Date().toISOString()
      });

      alert('버전이 삭제되었습니다.');
      
    } catch (error) {
      alert('버전 삭제 중 오류가 발생했습니다.');
    }
  };

  const closeModal = () => {
    setModalType(null);
    setEditingItem(null);
  };

  //  합계 계산 함수 추가
  const calculateTotal = (processData) => {
    if (!Array.isArray(processData)) return 0;
    
    // 기본 공정별 합계 계산
    const processTotal = processData.reduce((sum, process) => {
      if (!process || !Array.isArray(process.items)) return sum;
      
      const processTotal = process.items.reduce((itemSum, item) => {
        if (!item || item.isAdditional) return itemSum;  // 추가 항목은 제외
        const itemTotal = (parseInt(item.totalPrice) || 0);
        return itemSum + itemTotal;
      }, 0);
      
      return sum + processTotal;
    }, 0);

    // 추가 견적 합계 계산
    const additionalTotal = calculateAdditionalTotal(getAdditionalItems());
    
    // 총 합계 반환
    return processTotal + additionalTotal;
  };

  // VAT 포함 금액 계 함수 추가
  const calculateTotalWithVAT = (total) => {
    return total * 1.1; // VAT 10% 추가
  };

  // 버전 선택 시 처리 함수
  const handleVersionSelect = async (versionData) => {
    try {
      console.group('📂 버전 선택');
      console.log('선택된 버전:', versionData);
      
      if (!versionData) {
        console.log('❌ 버전 데이터가 없음');
        console.groupEnd();
        return;
      }

      setCurrentVersion(versionData);
      
      // 노트 복원
      if (versionData.data?.notes) {
        setNotes(versionData.data.notes);
      }
      
      // 프로세스 데이터 복원
      if (versionData.data?.processData) {
        setProcessData(versionData.data.processData);
      }
      
      // 프로세스 순서 복원
      if (versionData.data?.processOrder) {
        setProcessData(prevData => {
          return prevData.map(process => {
            const savedOrder = versionData.data.processOrder[process.id];
            if (!savedOrder) return process;

            const orderedItems = [...process.items].sort((a, b) => {
              const indexA = savedOrder.indexOf(a.uniqueId);
              const indexB = savedOrder.indexOf(b.uniqueId);
              return indexA - indexB;
            });

            return {
              ...process,
              items: orderedItems
            };
          });
        });
      }

      // 숨긴 공정 상태 복원
      console.group('📋 숨긴 공정 상태 복원');
      if (versionData.hiddenProcesses) {
        console.log('복원할 숨긴 공정 데이터:', versionData.hiddenProcesses);
        setHiddenProcesses(versionData.hiddenProcesses);
        console.log('숨긴 공정 상태 복원 완료');
      } else {
        console.log('복원할 숨긴 공정 데이터 없음');
        setHiddenProcesses({});
      }
      console.groupEnd();

      setIsEditingMode(true);
      setShowSelectVersionModal(false);
      console.log('✅ 버전 데이터 복원 완료');
      console.groupEnd();
    } catch (error) {
      console.error('❌ 버전 선택 중 오류:', error);
      console.groupEnd();
      alert('버전을 불러오는 중 오류가 발생했습니다.');
    }
  };

  // 새 버전 생성 핸들러
  const handleNewVersion = async (versionName) => {
    try {
      console.group('🆕 새 버전 생성');
      console.log('버전 이름:', versionName);

      // 현재 프로세스 순서 수집
      const currentProcessOrder = processData.reduce((acc, process) => {
        acc[process.id] = process.items.map(item => item.uniqueId);
        return acc;
      }, {});

      // 새로운 버전 데이터 생성
      const newVersion = {
        versionName: versionName,
        createdAt: new Date().toISOString(),
        lastModified: new Date().toISOString(),
        address: selectedAddress,
        data: {
          notes: notes,
          processData: processData,
          processOrder: currentProcessOrder
        }
      };

      // 버전 저장
      await VersionManager.saveVersion(selectedAddress, newVersion);
      
      // 상태 업데이트
      setCurrentVersion(newVersion);
      setIsEditingMode(true);
      setShowSelectVersionModal(false);

      console.log('✅ 새 버전 생성 완료');
      console.groupEnd();
    } catch (error) {
      console.error('❌ 새 버전 생성 중 오류:', error);
      console.groupEnd();
      alert('새 버전을 생성하는 중 오류가 발생했습니다.');
    }
  };

  // 수정 완료 핸들러
  const handleSaveVersion = async (versionName) => {
    if (!selectedAddress) {
      alert('주소를 선택해주세요.');
      return;
    }

    try {
      console.group('💾 버전 저장');
      console.log('버전 이름:', versionName);

      // 현재 프로세스 순서 수집
      const currentProcessOrder = processData.reduce((acc, process) => {
        acc[process.id] = process.items.map(item => item.uniqueId);
        return acc;
      }, {});
      console.log('현재 프로세스 순서:', currentProcessOrder);

      // 새로운 버전 데이터 생성
      const newVersion = {
        versionName: versionName,
        createdAt: new Date().toISOString(),
        lastModified: new Date().toISOString(),
        address: selectedAddress,
        data: {
          notes: notes,
          processData: processData,
          processOrder: currentProcessOrder
        },
        hiddenProcesses: hiddenProcesses || {}
      };
      
      // 버전 저장
      await VersionManager.saveVersion(selectedAddress, newVersion);
      console.log('✅ 버전 저장 완료');
      
      // 버전 목록 업데이트
      const versions = await VersionManager.getVersionList(selectedAddress);
      setCurrentVersion(newVersion);
      
      // 수정 상태 초기화
      setPendingModifications({});
      setModificationCount(0);
      
      // 모달 닫기
      setShowSelectVersionModal(false);
      console.groupEnd();
      
      alert('버전이 저장되었습니다.');
    } catch (error) {
      console.error('❌ 버전 저장 중 오류:', error);
      console.groupEnd();
      alert('버전 저장 중 오류가 발생했습니다.');
    }
  };

  // handleGeneratePDF 함수 수정
  

  // 비고 입력 중간 상태를 위한 state 가
  const [tempNote, setTempNote] = useState('');
  const [originalNote, setOriginalNote] = useState('');

  // 비고 입력 시작 시 본 값 저
  const handleNoteStart = (processId) => {
    setEditingNote(processId);
    setTempNote(notes[processId] || '');
    setOriginalNote(notes[processId] || '');
  };

  // 비고 입력 중 임시 장
  const handleNoteInput = (processId, value) => {
    setTempNote(value);
    setNotes(prev => ({
      ...prev,
      [processId]: value
    }));
  };

  // 비고 입력 완료 시 수이력 추가
  const handleNoteComplete = (processId) => {
    if (isEditingMode) {
      console.group('📝 비고 수정 완료');
      console.log('Process ID:', processId);
      console.log('이전 값:', originalNote);
      console.log('새로운 값:', tempNote);
      
      // 빈 문자로 변경되는 경우도 처리
      if (originalNote !== tempNote) {
        // 이전에 수정이력이 있었는지 확인
        const existingModification = Object.entries(pendingModifications)
          .find(([key, mod]) => mod.type === 'note' && mod.processId === processId);

        if (existingModification) {
          // 원래 값으로 돌아간 경우 수정이력 제거
          if (tempNote === existingModification[1].originalValue) {
            setPendingModifications(prev => {
              const newMods = { ...prev };
              delete newMods[existingModification[0]];
              return newMods;
            });
            setModificationCount(prev => prev - 1);
            console.log('수정 카운터 감소됨');
          }
        } else {
          // 새로 수정이력 추가
          setPendingModifications(prev => ({
            ...prev,
            [`note_${processId}`]: {
              type: 'note',
              processId: processId,
              originalValue: originalNote,
              modifiedValue: tempNote,
              modifiedAt: new Date().toISOString()
            }
          }));
          setModificationCount(prev => prev + 1);
          console.log('수정 카운터 추가됨');
        }
      }
      
      console.groupEnd();
    }
    
    setEditingNote(null);
    setTempNote('');
    setOriginalNote('');
  };

  const startLoadingProcess = async (callback) => {
    setIsLoading(true);
    setLoadingProgress(0);

    // 프그레스 바 애니이션
    const startTime = Date.now();
    const duration = 2000; // 2초

    const updateProgress = () => {
      const elapsed = Date.now() - startTime;
      const progress = Math.min((elapsed / duration) * 100, 100);
      setLoadingProgress(progress);

      if (progress < 100) {
        requestAnimationFrame(updateProgress);
      }
    };

    requestAnimationFrame(updateProgress);

    // 실제 작업 수행
    try {
      await new Promise(resolve => setTimeout(resolve, 500)); // 초기 지연
      await callback();
    } catch (error) {
      console.error('로딩  오류:', error);
    }

    // 최소 2초 보장
    const elapsed = Date.now() - startTime;
    if (elapsed < duration) {
      await new Promise(resolve => setTimeout(resolve, duration - elapsed));
    }

    setIsLoading(false);
    setLoadingProgress(0);
  };

  const LoadingProgress = () => {
    if (!isLoading) return null;

    return (
      <div className="loading-overlay">
        <div className="loading-container">
          <div className="loading-bar">
            <div 
              className="loading-progress" 
              style={{ width: `${loadingProgress}%` }} 
            />
          </div>
          <div className="loading-text">데이터를 불러오는 중...</div>
        </div>
      </div>
    );
  };


  // 삭제된 항목을 관리하기 위한 state 추가
  const [deletedItems, setDeletedItems] = useState({});

  // 항목 삭제 처리 함수 추가
  const handleItemDelete = (process, item) => {
    if (!isEditMode) return;
    if (!selectedAddress) {
      if (window.confirm('주소를 먼저 선택해주세요. 주소 선택 화면으로 이동하시겠습니까?')) {
        const addressComponent = document.querySelector('.desktop-ongoing-container');
        if (addressComponent) {
          addressComponent.scrollIntoView({ 
            behavior: 'smooth',
            block: 'center'
          });
        }
      }
      return;
    }
    
    if (!isEditingMode) {
      setPendingEditItem({ process, item });
      setShowSelectVersionModal(true);  // EditStartModal 대신 SelectVersionModal 표시
      return;
    }

    if (window.confirm('이 항목을 삭제하시겠습니까?')) {
      console.group('🗑️ 항목 삭제');
      console.log('삭제할 항목:', {
        processId: process.id,
        uniqueId: item.uniqueId,
        name: item.name
      });

      // 삭제된 항목 상태 업데이트
      setDeletedItems(prev => ({
        ...prev,
        [item.uniqueId]: true
      }));

      // 수정 이력에 삭제 기록 추가
      setPendingModifications(prev => ({
        ...prev,
        [`delete_${item.uniqueId}`]: {
          type: 'delete',
          processId: process.id,
          itemId: item.uniqueId,
          originalData: {
            name: item.name,
            quantity: item.quantity,
            unitPrice: item.unitPrice,
            unit: item.unit,
            note: item.note || '',
            totalPrice: item.totalPrice
          },
          modifiedAt: new Date().toISOString()
        }
      }));
      setModificationCount(prev => prev + 1);

      // UI 업데이트
      setProcessData(prevData => {
        return prevData.map(p => {
          if (p.id !== process.id) return p;

          const updatedItems = p.items.filter(i => i.uniqueId !== item.uniqueId);
          return {
            ...p,
            items: updatedItems,
            total: updatedItems.reduce((sum, i) => sum + (i.totalPrice || 0), 0)
          };
        });
      });

      console.log('✅ 항목 삭제 완료');
      console.groupEnd();
    }
  };

  // 삭제된 항목 복원 함수 추가
  const restoreDeletedItem = (processId, itemId) => {
    if (!isEditingMode) return;

    const modification = pendingModifications[`delete_${itemId}`];
    if (!modification) return;

    setDeletedItems(prev => {
      const newDeletedItems = { ...prev };
      delete newDeletedItems[itemId];
      return newDeletedItems;
    });

    setPendingModifications(prev => {
      const newModifications = { ...prev };
      delete newModifications[`delete_${itemId}`];
      return newModifications;
    });
    setModificationCount(prev => prev - 1);

    // UI 업데이트
    setProcessData(prevData => {
      return prevData.map(p => {
        if (p.id !== processId) return p;

        const restoredItem = {
          ...modification.originalData,
          uniqueId: itemId
        };

        const updatedItems = [...p.items, restoredItem];
        return {
          ...p,
          items: updatedItems,
          total: updatedItems.reduce((sum, i) => sum + (i.totalPrice || 0), 0)
        };
      });
    });
  };

  // 필터 변경 핸들러 추가
  const handleDesignChange = () => {};

  const handleAddItem = (processId) => {
    if (!isEditMode || !isEditingMode) return;
    setSelectedProcessId(processId);
    setShowAddItemModal(true);
  };

  const saveNewItem = (processId, newItem) => {
    console.group('➕ 품목 추가');
    console.log('공정:', processId);
    console.log('새 품목:', newItem);

    const uniqueId = `new_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
    const itemWithId = {
      ...newItem,
      uniqueId,
      processId,
      isAdditional: newItem.isAdditional || false,  // isAdditional 상태 추가
      totalPrice: newItem.excludeFromTotal 
        ? parseInt(newItem.unitPrice) || 0
        : (parseInt(newItem.quantity) || 0) * (parseInt(newItem.unitPrice) || 0)
    };

    setPendingModifications(prev => ({
      ...prev,
      [`add_${uniqueId}`]: {
        type: 'add',
        processId,
        itemId: uniqueId,
        newData: itemWithId,
        modifiedAt: new Date().toISOString()
      }
    }));
    setModificationCount(prev => prev + 1);

    setProcessData(prevData => {
      return prevData.map(p => {
        if (p.id !== processId) return p;

        const updatedItems = [...p.items, itemWithId];
        return {
          ...p,
          items: updatedItems,
          total: updatedItems.reduce((sum, i) => {
            const itemTotal = parseInt(i.totalPrice) || 0;
            return sum + itemTotal;
          }, 0)
        };
      });
    });

    console.log('✅ 품목 추가 완료');
    console.groupEnd();
  };

  // handleAiSuggest 함수 수정
  const handleAiSuggest = async (processId) => {
    if (!isEditMode || !isEditingMode) return;
    
    try {
      const process = processData.find(p => p.id === processId);
      if (!process) return;

      setSelectedProcessForAI(process);
      setShowAITemplateModal(true);
      
    } catch (error) {
      alert('AI 추천 중 오류가 발생했습니다.');
    }
  };

  // handleSaveAITemplate 함수 수정
  const handleSaveAITemplate = async (templateData, action) => {
    try {
      if (action === 'replace') {
        const templateDescription = `${getProcessName(templateData.processId)} 공정에 AI 템플릿 적용`;
        const itemsDescription = templateData.items.map(item => 
          `${item.name} (${item.quantity}${item.unit}, ${item.unitPrice.toLocaleString()}원)`
        ).join(', ');

        const modificationKey = `template_${templateData.processId}_${Date.now()}`;
        const newModification = {
          type: 'template_apply',
          processId: templateData.processId,
          templateId: templateData.templateId,
          items: templateData.items,
          modifiedAt: new Date().toISOString(),
          description: templateDescription,
          itemsDescription: itemsDescription,
          changeDescription: `AI 템플릿 적용: ${templateData.items.length}개 품목 변경`
        };

        setPendingModifications(prev => ({
          ...prev,
          [modificationKey]: newModification
        }));

        setModificationCount(prev => prev + 1);

        setProcessData(prevData => {
          return prevData.map(process => {
            if (process.id === templateData.processId) {
              const updatedItems = templateData.items.map(item => ({
                ...item,
                totalPrice: parseInt(item.quantity) * parseInt(item.unitPrice)
              }));

              return {
                ...process,
                items: updatedItems,
                total: updatedItems.reduce((sum, item) => 
                  sum + (parseInt(item.totalPrice) || 0), 0)
              };
            }
            return process;
          });
        });
      }
    } catch (error) {
      alert('템플릿 처리 중 오류가 발생했습니다.');
    }
  };

  // processData 필터링 함수 수정
  const filteredProcessData = processData.filter(process => 
    !hiddenProcesses[process.id]?.hidden
  );

  // toggleProcessVisibility 함수 수정
  const toggleProcessVisibility = (processId) => {
    console.group('👁️ 공정 표시 상태 변경');
    console.log('Process ID:', processId);
    console.log('현재 상태:', hiddenProcesses[processId]?.hidden ? '숨김' : '표시');
    
    const process = processData.find(p => p.id === processId);
    console.log('공정명:', getProcessName(process));
    
    const newHiddenState = !hiddenProcesses[processId]?.hidden;
    console.log('변경될 상태:', newHiddenState ? '숨김' : '표시');
    console.log('변경 유형:', 'manual');

    const newHiddenProcesses = {
      ...hiddenProcesses,
      [processId]: {
        hidden: newHiddenState,
        type: 'manual'
      }
    };
    
    console.log('업데이트된 전체 숨김 상태:', newHiddenProcesses);
    console.log('✅ 상태 변경 완료');
    console.groupEnd();
    
    setHiddenProcesses(newHiddenProcesses);
  };

  // 버전 선택 시 상태 복원 함수
  const restoreVersionState = async (version) => {
    try {
      if (!version || !version.data) return;
      
      // 노트 복원
      if (version.data.notes) {
        setNotes(version.data.notes);
      }
      
      // 프로세스 데이터 복원
      if (version.data.processData) {
        setProcessData(version.data.processData);
      }
      
      // 프로세스 순서 복원
      if (version.data.processOrder) {
        setProcessData(prevData => applyOrder(prevData, version.data.processOrder));
      }
    } catch (error) {
      console.error('❌ 상태 복원 중 오류:', error);
    }
  };

  // useEffect 수정 - 버전 변경 시 상태 복원
  useEffect(() => {
    if (currentVersion && !isEditingMode && !skipRestore) {
      // skipRestore가 false일 때만 상태 복원
      restoreVersionState(currentVersion);
    }
  }, [currentVersion?.versionId, isEditingMode, skipRestore]);

  // handleEditItem 함수 추가 (saveModification 함수 근처에 위치시키면 좋습니다)
  const handleEditItem = (updatedItem) => {
    if (!isEditMode) return;
    
    const processId = editingItem.processId;
    const itemId = editingItem.item.uniqueId;
    
    saveModification(processId, itemId, {
      ...updatedItem,
      uniqueId: itemId
    });
    
    setModalType(null);
    setEditingItem(null);
  };

  // showSelectVersionModal 상태 변경 추적
  useEffect(() => {
    console.log('SelectVersionModal 상태 변경:', showSelectVersionModal);
  }, [showSelectVersionModal]);

  const handleStartEdit = async () => {
    if (!selectedAddress) {
      alert('주소를 선택해주세요.');
      return;
    }

    // 버전 목록 가져오기
    const versions = await VersionManager.getVersionList(selectedAddress);
    
    // 현재 프로세스 순서 수집
    const currentProcessOrder = processData.reduce((acc, process) => {
      acc[process.id] = process.items.map(item => item.uniqueId);
      return acc;
    }, {});

    // 현재 상태를 새로운 버전으로 저장
    const newVersion = {
      createdAt: new Date().toISOString(),
      data: {
        notes: notes,
        processData: processData,
        processOrder: currentProcessOrder
      }
    };

    // 버전 목록 업데이트
    const updatedVersions = versions ? [newVersion, ...versions] : [newVersion];
    
    // 버전 저장
    await VersionManager.saveVersion(selectedAddress, newVersion);
    
    // 상태 업데이트
    setCurrentVersion(newVersion);
    setPendingModifications({});
    setModificationCount(0);
    setIsEditingMode(true);
  };

  // 중간저장 함수
  const handleInterimSave = async () => {
    try {
      console.group('🔄 중간 저장 시도');
      console.log('선택된 주소:', selectedAddress);
      console.log('현재 버전:', currentVersion);
      
      // 숨긴 공정 상태 로깅
      console.group('📋 숨긴 공정 상태 상세 로깅');
      if (hiddenProcesses) {
        console.log('숨긴 공정 상태 존재:', true);
        console.log('숨긴 공정 데이터:', JSON.stringify(hiddenProcesses, null, 2));
        console.log('숨긴 공정 ID 목록:', Object.keys(hiddenProcesses));
        console.log('숨긴 공정 개수:', Object.keys(hiddenProcesses).length);
        Object.entries(hiddenProcesses).forEach(([processId, status]) => {
          console.log(`공정 ID ${processId}:`, {
            hidden: status.hidden,
            type: status.type
          });
        });
      } else {
        console.log('숨긴 공정 상태 없음');
      }
      console.groupEnd();

      if (!selectedAddress || !currentVersion) {
        console.log('❌ 주소 또는 현재 버전 정보 없음');
        console.groupEnd();
        alert('주소와 현재 버전 정보가 필요합니다.');
        return;
      }

      // 현재 프로세스 순서 수집
      const currentProcessOrder = processData.reduce((acc, process) => {
        acc[process.id] = process.items.map(item => item.uniqueId);
        return acc;
      }, {});

      // 업데이트할 버전 데이터 생성
      const versionData = {
        ...currentVersion,
        data: {
          notes: notes,
          processData: processData,
          processOrder: currentProcessOrder
        },
        hiddenProcesses: hiddenProcesses || {}
      };
      
      // 저장할 데이터 로깅
      console.group('💾 저장할 버전 데이터 상세');
      console.log('전체 데이터 구조:', {
        ...versionData,
        hiddenProcesses: JSON.stringify(versionData.hiddenProcesses, null, 2)
      });
      console.log('숨긴 공정 데이터 (저장 전):', versionData.hiddenProcesses);
      console.groupEnd();

      // 중간 저장 실행
      const savedVersion = await VersionManager.saveInterimVersion(selectedAddress, versionData);
      
      console.group('🎉 중간 저장 완료');
      console.log('저장된 버전:', savedVersion);
      console.log('저장된 숨긴 공정 데이터:', savedVersion.hiddenProcesses);
      console.groupEnd();
      
      alert('중간 저장이 완료되었습니다.');
    } catch (error) {
      console.error('❌ 중간 저장 중 오류:', error);
      console.error('오류 상세:', {
        name: error.name,
        message: error.message,
        stack: error.stack
      });
      console.groupEnd();
      alert(`중간 저장 중 오류가 발생했습니다: ${error.message}`);
    }
  };

  // 키보드 단축키 설정
  useKeyboardShortcut({
    isEditingMode,
    currentVersion,
    onSave: handleInterimSave
  });

  // 추가 견적 항목만 필터링하는 함수 추가
  const getAdditionalItems = () => {
    return processData.reduce((items, process) => {
      const additionalItems = process.items.filter(item => item.isAdditional);
      return [...items, ...additionalItems.map(item => ({
        ...item,
        processName: getProcessName(process)
      }))];
    }, []);
  };

  // 추가 견적 총액 계산 함수
  const calculateAdditionalTotal = (items) => {
    return items.reduce((sum, item) => {
      return sum + (parseInt(item.totalPrice) || 0);
    }, 0);
  };

  // 추가 견적 항목 수정 핸들러
  const handleAdditionalEdit = (item) => {
    setEditingItem({
      processId: item.processId,  // 원래 공정 ID 유지
      item: item
    });
  };

  // 추가 견적 항목 삭제 핸들러
  const handleAdditionalDelete = (item) => {
    if (window.confirm('이 항목을 삭제하시겠습니까?')) {
      handleItemDelete(item.process, item);
    }
  };

  // 추가 견적 항목 합계 계산
  const calculateAdditionalItemTotal = (item) => {
    if (item.excludeFromTotal) {
      return parseInt(item.unitPrice) || 0;
    }
    return (parseInt(item.quantity) || 0) * (parseInt(item.unitPrice) || 0);
  };

  return (
    <div className="desktop-estimate">
      <div className="desktop-ongoing-container">
        {isEditMode && (
          <div className="admin-buttons">
            <div className="edit-buttons-group">
              {!isEditingMode ? (
                <>
                  <button 
                    className="edit-complete-button"
                    onClick={() => navigate('/address-management')}
                  >
                    현장주소입력
                  </button>
                  <button 
                    className="edit-complete-button"
                    onClick={handleStartVersionEdit}
                  >
                    수정 시작
                  </button>
                  <button 
                    className="edit-complete-button"
                    onClick={() => setShowProcessManagementModal(true)}
                  >
                    공정 관리
                  </button>
                </>
              ) : (
                <>
                  <button 
                    className="edit-complete-button"
                    onClick={() => navigate('/address-management')}
                  >
                    현장주소입력
                  </button>
                  <button 
                    className="edit-complete-button"
                    onClick={() => {
                      if (newVersionName) {
                        handleSaveVersion(newVersionName);
                      } else {
                        setModalType('saveVersion');
                      }
                    }}
                    disabled={Object.keys(pendingModifications).length === 0}
                  >
                    수정 완료
                  </button>
                  <button 
                    className="edit-cancel-button"
                    onClick={() => {
                      if (window.confirm('진행 중인 수정사항이 모두 취소됩니다. 계속하시겠습니까?')) {
                        setIsEditingMode(false);
                        setPendingModifications({});
                        setNewVersionName(null);
                        loadModifications();
                      }
                    }}
                  >
                    수정 취소
                  </button>
                  <HiddenProcessList 
                    hiddenProcesses={hiddenProcesses}
                    onToggle={toggleProcessVisibility}
                    getProcessName={getProcessName}
                    showModal={showHiddenProcessModal}
                    onShowModal={() => setShowHiddenProcessModal(true)}
                    onCloseModal={() => setShowHiddenProcessModal(false)}
                    onHiddenProcessesChange={(newHiddenProcesses) => setHiddenProcesses(newHiddenProcesses)}
                    processData={processData}
                  />
                </>
              )}
            </div>
            <div className="management-buttons-group">
              {isEditingMode && currentVersion && (
                <InterimSave
                  processData={processData}
                  selectedAddress={selectedAddress}
                  notes={notes}
                  currentVersion={currentVersion}
                />
              )}

              <button 
                className="database-button"
                onClick={() => navigate('/database')}
              >
                데이터베이스 관리
              </button>

              <PdfButton
                selectedAddress={selectedAddress}
                processData={processData}
                notes={notes}
                hiddenProcesses={hiddenProcesses}
                calculateTotal={calculateTotal}
                getProcessName={getProcessName}
                formatNumber={formatNumber}
                filterProcessesForPDF={filterProcessesForPDF}
              />

              {isEditingMode && (  // 수정 모드일 때만 이메일 전송 버튼 표시
                <button 
                  className="database-button"
                  onClick={() => setShowEmailModal(true)}
                >
                  이메일 전송
                </button>
              )}
            </div>
          </div>
        )}
        <OngoingAddressesToggle 
          onSelectAddress={handleSelectAddress}
          onAdminLogin={handleAdminLogin}
        />
      </div>
      
      <div className="estimate-header">
        <div className="header-content">
          <div className="header-left">
            <h2>견적서</h2>
            <h3>Estimate</h3>
          </div>
          <div className="info-item logo">
            <div className="logo-container">
              <img 
                src="/images/logo.png" 
                alt="로고"
                style={{
                  width: '90px',
                  height: 'auto'
                }}
              />
            </div>
          </div>
          <div className="info-item">
            <p className="label">업체정보</p>
            <p>Amare design, 아마레디자인</p>
            <p>712.17.01551</p>
            <p>수성구 들안로 215</p>
            <p>010. 8694.4078</p>
            <p>대표 권경대</p>
          </div>
          <div className="info-item address">
            <p className="label">주소</p>
            <p>{selectedAddress || '주소를 선택해주세요'}</p>
          </div>
          <div className="info-item total">
            <p className="label">TOTAL</p>
            <p className="amount">
              {formatNumber(calculateTotal(filteredProcessData))}원  {/* processData를 filteredProcessData로 변경 */}
              <span></span>
            </p>
            <p className="vat-notice">(VAT 별도)</p>
          </div>
        </div>
      </div>

      <div className="estimate-content">
        <div className="estimate-table-title">
          <h3>공정별 합계</h3>
        </div>
        <div className="process-summary-table">
          <div className="table-header">
            <div className="col process">공정</div>
            <div className="col price">가격</div>
            <div className="col note">비고</div>
          </div>
          
          {/* 0원인 공정 필터링 */}
          {filteredProcessData.map((process) => (
            <div key={process.id} className="table-row">
              <div className="col process">{getProcessName(process)}</div>
              <div className="col price">
                {formatNumber(process.total)}
              </div>
              <div 
                className="col note"
                onClick={() => isEditingMode && handleNoteStart(process.id)}
              >
                {editingNote === process.id ? (
                  <input
                    type="text"
                    value={notes[process.id] || ''}
                    onChange={(e) => handleNoteInput(process.id, e.target.value)}
                    onBlur={() => handleNoteComplete(process.id)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleNoteComplete(process.id);
                      }
                    }}
                    autoFocus
                  />
                ) : (
                  <span className={notes[process.id] ? '' : 'empty-note'}>
                    {notes[process.id] || (isEditingMode ? '클릭하여 입력' : '-')}
                  </span>
                )}
              </div>
            </div>
          ))}

          <div className="process-total">
            <span className="total-label">총 합계(VAT 미포함) :</span>
            <span className="total-amount">
              {formatNumber(calculateTotal(filteredProcessData))}원
            </span>
          </div>
          <div className="process-total">
            <span className="total-label">총 합계(VAT 포함) :</span>
            <span className="total-amount">
              {formatNumber(Math.round(calculateTotal(filteredProcessData) * 1.1))}원
            </span>
          </div>
        </div>

        <div className="estimate-table">
          <div className="estimate-table-title">
            <h3>상세 견적서</h3>
          </div>
          
          <div className="table-header">
            <div className="col item">품목</div>
            <div className="col quantity">수량</div>
            <div className="col unit">단위</div>
            <div className="col unit-price">단가</div>
            <div className="col amount">금액</div>
            <div className="col note">비고</div>
          </div>

          {/* 상세 견적서에서도 0원인 공정 필터링 */}
          {filteredProcessData.map((process, processIndex) => (
            <div key={processIndex} className="process-section">
              <div className="process-name">
                <HideProcessButton 
                  processId={process.id}
                  isHidden={hiddenProcesses[process.id]?.hidden}
                  onToggle={toggleProcessVisibility}
                />
                <span>{getProcessName(process)}</span>
                {isEditMode && isEditingMode && (
                  <>
                    <AISuggestButton 
                      onClick={handleAiSuggest}
                      processId={process.id}
                      processName={getProcessName(process)}
                    />
                    <button 
                      className="add-item-button"
                      onClick={() => handleAddItem(process.id)}
                      title="품목 추가"
                    >
                      +
                    </button>
                  </>
                )}
              </div>
              
              <DragHandleItemList
                items={process.items}
                onReorder={(processId, newItems, moveInfo) => {
                  setProcessData(prevData => 
                    prevData.map(p => 
                      p.id === processId 
                        ? { ...p, items: newItems }
                        : p
                    )
                  );
                  
                  if (isEditingMode) {
                    setPendingModifications(prev => ({
                      ...prev,
                      [`move_${process.id}_${Date.now()}`]: {
                        type: 'move',
                        processId: process.id,
                        originalOrder: process.items.map(item => item.uniqueId),
                        newOrder: newItems.map(item => item.uniqueId),
                        description: moveInfo.description,  // 이동 설명 추가
                        modifiedAt: new Date().toISOString()
                      }
                    }));
                    setModificationCount(prev => prev + 1);
                  }
                }}
                processId={process.id}
                isEditMode={isEditMode && isEditingMode}
                onEdit={(item) => handleItemEdit(process, item)}
                onDelete={(item) => handleItemDelete(process, item)}
                formatNumber={formatNumber}
                getProcessName={getProcessName}  // 추가
                calculateItemTotal={(item) => {  // 새로운 prop 추가
                  return item.excludeFromTotal 
                    ? parseInt(item.unitPrice) || 0
                    : (parseInt(item.quantity) || 0) * (parseInt(item.unitPrice) || 0);
                }}
              />
              
              <div className="process-subtotal">
                <span className="subtotal-label">
                  {getProcessName(process)} 소계 :
                </span>
                <span className="subtotal-amount">
                  {formatNumber(process.total)}원
                </span>
              </div>
            </div>
          ))}
        </div>
        
        {/* 추가 견적서 섹션 */}
        {(() => {
          const additionalItems = getAdditionalItems();
          if (additionalItems.length > 0) {
            const isHidden = hiddenProcesses['additional']?.hidden;
            return (
              <div className="estimate-table">
                <div className="process-section">
                  <div className="process-name">
                    <HideProcessButton 
                      processId="additional"
                      isHidden={isHidden}
                      onToggle={() => toggleProcessVisibility('additional')}
                    />
                    <span>추가 견적 요약</span>
                    {isEditMode && isEditingMode && (
                      <ShareButton 
                        address={selectedAddress}
                        additionalItems={additionalItems}
                        onClose={() => {}}
                      />
                    )}
                  </div>
                  
                  {!isHidden && (
                    <>
                      <DragHandleItemList
                        items={additionalItems}
                        onReorder={() => {}}
                        processId=""
                        isEditMode={isEditMode && isEditingMode}
                        onEdit={handleAdditionalEdit}
                        onDelete={handleAdditionalDelete}
                        formatNumber={formatNumber}
                        getProcessName={getProcessName}
                        calculateItemTotal={calculateAdditionalItemTotal}
                        showActionButtons={false}
                      />
                      
                      <div 
                        className={`process-subtotal ${isAdditionalSubtotalHidden ? 'hidden' : ''}`}
                        onClick={() => setIsAdditionalSubtotalHidden(!isAdditionalSubtotalHidden)}
                      >
                        <span className="subtotal-label">
                          추가 견적 소계 :
                        </span>
                        <span className="subtotal-amount">
                          {formatNumber(calculateAdditionalTotal(additionalItems))}원
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          }
          return null;
        })()}

        <div className="estimate-footer">
          <div className="total-sum-container">
            <div className="total-sum">
              <span className="label">총 합계(VAT 미포함) :</span>
              <span className="amount">
                {formatNumber(calculateTotal(filteredProcessData))}원
              </span>
            </div>
            <div className="total-sum vat-included">
              <span className="label">총 합계(VAT 포함) :</span>
              <span className="amount">
                {formatNumber(Math.round(calculateTotal(filteredProcessData) * 1.1))}원
              </span>
            </div>
          </div>
        </div>
      </div>

      {editingItem && (
        <EditItemModal
          item={editingItem.item}
          processId={editingItem.processId} // processId 추가
          onSave={(updatedItem) => {
            handleEditItem(updatedItem);
            setEditingItem(null);
          }}
          onClose={() => setEditingItem(null)}
        />
      )}

      {modalType === 'saveVersion' && (
        <VersionSaveModal
          onSave={handleSaveVersion}
          onClose={() => setModalType(null)}
          currentVersion={currentVersion}  // currentVersion 전달
        />
      )}



      {/* SelectVersionModal */}
      {showSelectVersionModal && (
        console.log('SelectVersionModal 렌더링 시도', {
          selectedAddress,
          showSelectVersionModal
        }),
        <SelectVersionModal
          selectedAddress={selectedAddress}
          onSelectVersion={handleVersionSelect}
          onNewVersion={handleNewVersion}
          onClose={() => {
            console.log('SelectVersionModal 닫기');
            setShowSelectVersionModal(false);
            setIsEditingMode(false);
          }}
        />
      )}



      {showAddItemModal && (
        <AddItemModal
          processId={selectedProcessId}
          onSave={(newItem) => {
            saveNewItem(selectedProcessId, newItem);
            setShowAddItemModal(false);
            setSelectedProcessId(null);
          }}
          onClose={() => {
            setShowAddItemModal(false);
            setSelectedProcessId(null);
          }}
        />
      )}

      {showAITemplateModal && selectedProcessForAI && (
        <AITemplateModal
          processId={selectedProcessForAI.id}
          processName={getProcessName(selectedProcessForAI.id)}
          currentItems={selectedProcessForAI.items}
          onSave={handleSaveAITemplate}
          onClose={() => {
            setShowAITemplateModal(false);
            setSelectedProcessForAI(null);
          }}
        />
      )}

      <LoadingProgress />

      {showEmailModal && (
        <EmailPdfModal
          selectedAddress={selectedAddress}
          processData={processData}
          notes={notes}
          hiddenProcesses={hiddenProcesses}
          onClose={() => setShowEmailModal(false)}
        />
      )}

      {showProcessManagementModal && (
        <ProcessManagementModal
          onClose={() => setShowProcessManagementModal(false)}
        />
      )}
    </div>
  );
};

export default DesktopEstimateV2; 