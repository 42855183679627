// PDF 스타일 관련 코드가 들어갈 파일 
import { StyleSheet } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';

// 한글 폰트 등록 (Pretendard 폰트 사용)
Font.register({
  family: 'Pretendard',
  src: 'https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff',
  fontStyle: 'normal',
  fontWeight: 'normal'
});

Font.register({
  family: 'Pretendard',
  src: 'https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff',
  fontStyle: 'normal',
  fontWeight: 'bold'
});

// PDF 기본 스타일
export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 25,
    fontFamily: 'Pretendard'
  },
  header: {
    backgroundColor: 'white',
    borderRadius: 6,
    padding: 15,
    marginBottom: 15,
    borderWidth: 0.5,
    borderColor: '#E1D8D8',
    height: 180
  },
  headerTop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
    paddingBottom: 15,
    borderBottomWidth: 0.5,
    borderColor: '#E1D8D8',
    height: 60
  },
  headerTitle: {
    flex: 1
  },
  title: {
    fontSize: 20,
    marginBottom: 4,
    fontWeight: 'bold'
  },
  subtitle: {
    fontSize: 12
  },
  logo: {
    width: 45,
    height: 45,
    borderRadius: 22.5
  },
  headerBottom: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 10,
    height: 85,
    alignItems: 'flex-start'
  },
  infoSection: {
    flex: 1,
    height: '100%',
    justifyContent: 'flex-start'
  },
  infoLabel: {
    fontSize: 10,
    marginBottom: 6,
    color: '#946A6A',
    fontWeight: 'medium'
  },
  infoText: {
    fontSize: 8,
    marginBottom: 3,
    lineHeight: 1.4
  },
  totalSection: {
    width: '25%',
    alignItems: 'flex-end',
    height: '100%',
    justifyContent: 'flex-start'
  },
  totalAmount: {
    fontSize: 16,
    marginBottom: 2,
    fontWeight: 'bold'
  },
  vatNotice: {
    fontSize: 8
  },
  
  // 공정별 합계 테이블 스타일
  summaryTable: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderTop: 0.5,
    borderBottom: 0.5,
    borderColor: '#E1D8D8'
  },
  tableSectionTitle: {
    fontSize: 12,
    marginBottom: 10,
    marginTop: 0
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#946A6A',
    borderBottomWidth: 0.5,
    borderBottomColor: '#E1D8D8'
  },
  processCol: { 
    width: '33.33%',  // 변경
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRightWidth: 0.5,
    borderRightColor: '#E1D8D8'
  },
  priceCol: { 
    width: '33.33%',  // 변경
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRightWidth: 0.5,
    borderRightColor: '#E1D8D8'
  },
  noteCol: { 
    width: '33.34%',  // 변경
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableHeaderCell: {
    color: 'white',
    fontSize: 8.5,
    padding: 7,
    textAlign: 'center'
  },
  tableCell: {
    fontSize: 8,
    padding: 5.5,
    textAlign: 'center',
    width: '100%'
  },
  tableCellProcess: {
    color: '#333333',
    fontWeight: 'medium',
    textAlign: 'center'
  },
  tableCellPrice: {
    color: '#946A6A',
    fontWeight: 'medium',
    textAlign: 'center'
  },
  tableCellNote: {
    color: '#666666',
    textAlign: 'center'
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderBottomColor: '#E1D8D8',
    minHeight: 26,
    alignItems: 'center'
  },
  summaryTotal: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '5px 12px',
    backgroundColor: '#F7F7F6',
    borderBottomWidth: 0.5,
    borderBottomColor: '#E1D8D8',
    marginTop: -0.5,
    width: '100%'
  },
  summaryTotalLabel: {
    fontSize: 8,
    marginRight: 7,
    width: 110,  // 레이블 너비 고정
    textAlign: 'right'  // 텍스트 우측 정렬
  },
  summaryTotalAmount: {
    fontSize: 9,
    fontWeight: 'bold',
    width: 100,  // 금액 너비 고정
    textAlign: 'right'  // 금액 우측 정렬
  },
  // 데이 행의 컬럼 스
  dataProcessCol: {
    width: '33.33%',  // 변경
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRightWidth: 0.5,
    borderRightColor: '#E1D8D8'
  },
  dataPriceCol: {
    width: '33.33%',  // 변경
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRightWidth: 0.5,
    borderRightColor: '#E1D8D8'
  },
  dataNoteCol: {
    width: '33.34%',  // 변경
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  // 상세 견적서 스타일
  detailSection: {
    marginTop: 20
  },
  sectionTitle: {
    fontSize: 12,
    color: '#333333',
    fontWeight: 'bold',
    marginBottom: 15,
    fontFamily: 'Pretendard'
  },
  processSection: {
    marginBottom: 20,  // 공정 섹션 아래 여백 추가
  },
  processName: {
    backgroundColor: '#F7F7F6',
    padding: 8,
    fontSize: 9,
    color: '#946A6A',
    fontWeight: 'bold',
    textAlign: 'center',
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
    borderColor: '#E1D8D8'
  },
  detailTable: {
    borderTop: 0.5,
    borderBottom: 0.5,
    borderColor: '#E1D8D8'
  },
  detailTableHeader: {
    flexDirection: 'row',
    backgroundColor: '#946A6A'
  },
  detailHeaderCell: {
    color: 'white',
    fontSize: 8,
    padding: 6,
    textAlign: 'center',
    fontFamily: 'Pretendard'
  },
  detailRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderBottomColor: '#E1D8D8',
    minHeight: 24
  },
  detailCell: {
    fontSize: 7,
    padding: 4,
    textAlign: 'center'
  },
  // 상세 견적서 컬럼 스타일
  detailItemCol: {
    width: '20%',
    borderRightWidth: 0.5,
    borderRightColor: '#E1D8D8'
  },
  detailQuantityCol: {
    width: '8%',
    borderRightWidth: 0.5,
    borderRightColor: '#E1D8D8'
  },
  detailUnitCol: {
    width: '7%',
    borderRightWidth: 0.5,
    borderRightColor: '#E1D8D8'
  },
  detailUnitPriceCol: {
    width: '15%',
    borderRightWidth: 0.5,
    borderRightColor: '#E1D8D8'
  },
  detailAmountCol: {
    width: '15%',
    borderRightWidth: 0.5,
    borderRightColor: '#E1D8D8'
  },
  detailNoteCol: {
    width: '35%'
  },
  // 소계 스타일
  processTotal: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '6px 12px',
    backgroundColor: '#F7F7F6',
    borderBottomWidth: 0.5,
    borderColor: '#E1D8D8',
    marginBottom: 15  // 소계 아래 여백 추가
  },
  processTotalLabel: {
    fontSize: 8,
    marginRight: 8,
    color: '#333333'
  },
  processTotalAmount: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  // 최종 합계 스타일 추가
  finalTotal: {
    marginTop: 20,
    paddingTop: 15,
    borderTop: 0.5,
    borderColor: '#E1D8D8',
  },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '6px 12px',
    backgroundColor: '#F7F7F6',
    marginBottom: 5,
    width: '100%'
  },
  totalLabel: {
    fontSize: 9,
    marginRight: 8,
    color: '#333333',
    fontWeight: 'medium',
    width: 110,  // 레이블 너비 고정
    textAlign: 'right'  // 텍스트 우측 정렬
  },
  totalAmount: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#946A6A',
    width: 100,  // 금액 너비 고정
    textAlign: 'right'  // 금액 우측 정렬
  },
});

// 상세 견적서 스타일
export const detailStyles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: 'Pretendard'
  },
  sectionTitle: {
    fontSize: 12,
    color: '#333333',
    fontWeight: 'bold',
    marginBottom: 15
  },
  processSection: {
    marginBottom: 20
  },
  processName: {
    backgroundColor: '#F7F7F6',
    padding: 8,
    fontSize: 9,
    color: '#946A6A',
    fontWeight: 'bold',
    textAlign: 'center',
    borderTop: 0.5,
    borderBottom: 0.5,
    borderColor: '#E1D8D8'
  },
  table: {
    width: '100%',
    borderColor: '#E1D8D8'
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#946A6A',
    borderBottom: 0.5,
    borderColor: '#E1D8D8',
    minHeight: 28,
    alignItems: 'center'
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: 0.5,
    borderColor: '#E1D8D8',
    minHeight: 26,
    alignItems: 'center'
  },
  headerCell: {
    color: 'white',
    fontSize: 8,
    padding: 6,
    textAlign: 'center'
  },
  cell: {
    fontSize: 7,
    padding: 4,
    textAlign: 'center'
  },
  // 컬럼 스타일
  colItem: { 
    width: '20%', 
    borderRight: 0.5, 
    borderColor: '#E1D8D8',
    height: '100%',
    justifyContent: 'center'
  },
  colQuantity: { 
    width: '8%', 
    borderRight: 0.5, 
    borderColor: '#E1D8D8',
    height: '100%',
    justifyContent: 'center'
  },
  colUnit: { 
    width: '7%', 
    borderRight: 0.5, 
    borderColor: '#E1D8D8',
    height: '100%',
    justifyContent: 'center'
  },
  colUnitPrice: { 
    width: '15%', 
    borderRight: 0.5, 
    borderColor: '#E1D8D8',
    height: '100%',
    justifyContent: 'center'
  },
  colAmount: { 
    width: '15%', 
    borderRight: 0.5, 
    borderColor: '#E1D8D8',
    height: '100%',
    justifyContent: 'center'
  },
  colNote: { 
    width: '35%',
    height: '100%',
    justifyContent: 'center'
  },
  processTotal: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '6px 12px',
    backgroundColor: '#F7F7F6',
    borderBottom: 0.5,
    borderColor: '#E1D8D8'
  },
  processTotalLabel: {
    fontSize: 8,
    marginRight: 8,
    color: '#333333'
  },
  processTotalAmount: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  // 추가 견적 요약 섹션 스타일
  additionalTag: {
    color: '#e74c3c',
    fontSize: 7,
    marginLeft: 4
  },
  separateTag: {
    color: '#3498db',
    fontSize: 7,
    marginLeft: 4
  },
  
  // 총합계 섹션 스타일
  totalSumContainer: {
    position: 'absolute',
    bottom: 40,
    right: 40,
    width: '100%',
    maxWidth: 400,
    alignSelf: 'flex-end',
  },
  totalSum: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
    paddingVertical: 4,
  },
  vatIncluded: {
    borderTopWidth: 1,
    borderTopColor: '#000',
    borderTopStyle: 'solid',
    paddingTop: 8,
  },
  totalSumLabel: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  totalSumAmount: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  currency: {
    marginLeft: 2,
  },
});