import React, { useState, useEffect } from 'react';
import {
  subscribeToMaterialTypes,
  subscribeToMaterialNames
} from './workOrderMaterialsService';
import './MaterialModal.css';

const MaterialModal = ({ onClose, onSave, initialData }) => {
  const [materialTypes, setMaterialTypes] = useState([]);
  const [materialNames, setMaterialNames] = useState([]);
  const [selectedMaterialType, setSelectedMaterialType] = useState('');
  const [selectedMaterialName, setSelectedMaterialName] = useState('');
  const [size, setSize] = useState(initialData?.size || '');

  useEffect(() => {
    // 자재 종류 목록 구독
    const unsubscribeMaterialTypes = subscribeToMaterialTypes((data) => {
      const sortedTypes = data.sort((a, b) => a.name.localeCompare(b.name));
      setMaterialTypes(sortedTypes);
      
      // 초기 데이터가 있는 경우 해당하는 type 찾기
      if (initialData?.materialType) {
        const matchingType = sortedTypes.find(type => type.name === initialData.materialType);
        if (matchingType) {
          setSelectedMaterialType(matchingType.id);
        }
      }
    });

    return () => {
      unsubscribeMaterialTypes();
    };
  }, [initialData]);

  // 선택된 자재 종류에 따른 자재 이름 목록 구독
  useEffect(() => {
    if (!selectedMaterialType) {
      setMaterialNames([]);
      return;
    }

    const unsubscribe = subscribeToMaterialNames(selectedMaterialType, (data) => {
      const sortedNames = data.sort((a, b) => a.name.localeCompare(b.name));
      setMaterialNames(sortedNames);
      
      // 초기 데이터가 있는 경우 해당하는 name 찾기
      if (initialData?.materialName) {
        const matchingName = sortedNames.find(name => name.name === initialData.materialName);
        if (matchingName) {
          setSelectedMaterialName(matchingName.id);
        }
      }
    });

    return () => unsubscribe();
  }, [selectedMaterialType, initialData]);

  const handleSave = () => {
    const materialType = materialTypes.find(type => type.id === selectedMaterialType)?.name;
    const materialName = materialNames.find(name => name.id === selectedMaterialName)?.name;

    if (!materialType || !materialName) return;

    const materialData = {
      materialType,
      materialName,
      ...(size.trim() && { size: size.trim() }), // size가 있을 때만 포함
      createdAt: new Date()
    };

    onSave(materialData);
    onClose();
  };

  return (
    <div className="material-modal-overlay" onClick={onClose}>
      <div className="material-modal-content" onClick={e => e.stopPropagation()}>
        <h2>자재 표시</h2>

        <div className="material-input-group">
          <label>마감재 종류</label>
          <select
            value={selectedMaterialType}
            onChange={(e) => {
              setSelectedMaterialType(e.target.value);
              setSelectedMaterialName('');
            }}
          >
            <option value="">선택하세요</option>
            {materialTypes.map(type => (
              <option key={type.id} value={type.id}>{type.name}</option>
            ))}
          </select>
        </div>

        <div className="material-input-group">
          <label>자재 이름</label>
          <select
            value={selectedMaterialName}
            onChange={(e) => setSelectedMaterialName(e.target.value)}
            disabled={!selectedMaterialType}
          >
            <option value="">선택하세요</option>
            {materialNames.map(name => (
              <option key={name.id} value={name.id}>{name.name}</option>
            ))}
          </select>
        </div>

        <div className="material-input-group">
          <label>사이즈</label>
          <input
            type="text"
            value={size}
            onChange={(e) => setSize(e.target.value)}
            placeholder="사이즈를 입력하세요"
          />
        </div>

        <div className="material-modal-buttons">
          <button 
            onClick={handleSave} 
            disabled={!selectedMaterialType || !selectedMaterialName}
          >
            저장
          </button>
          <button onClick={onClose}>취소</button>
        </div>
      </div>
    </div>
  );
};

export default MaterialModal; 