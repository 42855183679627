import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import './MaterialManagement.css';

const MaterialManagement = ({ onClose, selectedAddress }) => {
  const [materials, setMaterials] = useState([]);
  const [groupedMaterials, setGroupedMaterials] = useState({});

  useEffect(() => {
    if (selectedAddress) {
      fetchMaterials();
    }
  }, [selectedAddress]);

  const fetchMaterials = async () => {
    try {
      const materialsRef = collection(db, 'materials');
      const q = query(
        materialsRef,
        where('address', '==', selectedAddress)
      );

      const snapshot = await getDocs(q);
      const materialsList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setMaterials(materialsList);

      // 공정별로 자재 그룹화 및 정렬
      const grouped = materialsList.reduce((acc, material) => {
        const process = material.process || '미지정';
        if (!acc[process]) {
          acc[process] = [];
        }
        acc[process].push(material);
        return acc;
      }, {});

      // 공정별로 정렬된 객체 생성
      const sortedGrouped = Object.keys(grouped)
        .sort()
        .reduce((acc, key) => {
          acc[key] = grouped[key].sort((a, b) => 
            (a.location || '').localeCompare(b.location || '')
          );
          return acc;
        }, {});

      setGroupedMaterials(sortedGrouped);
    } catch (error) {
      // 에러 처리
    }
  };

  return (
    <div className="material-modal-overlay" onClick={onClose}>
      <div className="material-modal" onClick={e => e.stopPropagation()}>
        <div className="material-modal-header">
          <h3>{selectedAddress} 자재 목록</h3>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="material-modal-content">
          {Object.entries(groupedMaterials).map(([process, materials]) => (
            <div key={process} className="address-group">
              <h4 className="address-title">{process}</h4>
              <table className="material-table">
                <thead>
                  <tr>
                    <th>위치</th>
                    <th>자재종류</th>
                    <th>자재명</th>
                  </tr>
                </thead>
                <tbody>
                  {materials.map((material) => (
                    <tr key={material.id}>
                      <td>{material.location || '-'}</td>
                      <td>{material.materialType || '-'}</td>
                      <td>{material.materialName || '-'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
          {Object.keys(groupedMaterials).length === 0 && (
            <div className="no-materials">
              등록된 자재가 없습니다.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaterialManagement; 