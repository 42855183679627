import React, { useState, useEffect } from 'react';
import MobileSavedItemsModal from './MobileSavedItemsModal';
import MobileSaveItemMemoModal from './MobileSaveItemMemoModal';
import './MobileAddItemModal.css';

const MobileAddItemModal = ({ processId, onSave, onClose }) => {
  const [newItem, setNewItem] = useState({
    name: '',
    quantity: '',
    unit: '식',
    unitPrice: '',
    note: '',
    isAdditional: false
  });

  const [showSavedItems, setShowSavedItems] = useState(false);
  const [showMemoModal, setShowMemoModal] = useState(false);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        if (showSavedItems) {
          setShowSavedItems(false);
          return;
        }
        if (showMemoModal) {
          setShowMemoModal(false);
          return;
        }
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [showSavedItems, showMemoModal]);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!newItem.name || !newItem.quantity || !newItem.unitPrice) {
      alert('품목명, 수량, 단가를 모두 입력해주세요.');
      return;
    }

    onSave({
      ...newItem,
      uniqueId: `new_${Date.now()}`,
      quantity: parseInt(newItem.quantity),
      unitPrice: parseInt(newItem.unitPrice),
      totalPrice: parseInt(newItem.quantity) * parseInt(newItem.unitPrice),
      isAdditional: newItem.isAdditional
    });
  };

  const handleSaveItem = () => {
    if (!newItem.name.trim() || !newItem.unitPrice) {
      alert('품목명과 단가를 입력해주세요.');
      return;
    }
    setShowMemoModal(true);
  };

  const handleSaveWithMemo = (itemWithMemo) => {
    // 여기서 나중에 파이어베이스 저장 기능 추가
    alert('품목이 저장되었습니다.');
    setShowMemoModal(false);
  };

  const handleSelectSavedItem = (item) => {
    setNewItem({
      ...newItem,
      name: item.name,
      unit: item.unit,
      unitPrice: item.unitPrice.toString(),
      note: item.note || '',
      quantity: '1',  // 기본값 1로 설정
      isAdditional: item.isAdditional
    });
    setShowSavedItems(false);
  };

  return (
    <div className="mobile-add-item-modal">
      <div className="mobile-add-item-content">
        <h3>항목 추가</h3>
        <form onSubmit={handleSubmit}>
          <div className="mobile-add-item-field">
            <label>품목명</label>
            <input
              type="text"
              value={newItem.name}
              onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
              placeholder="품목명 입력"
            />
          </div>
          <div className="mobile-add-item-field">
            <label>수량</label>
            <input
              type="number"
              value={newItem.quantity}
              onChange={(e) => setNewItem({ ...newItem, quantity: e.target.value })}
              placeholder="수량 입력"
            />
          </div>
          <div className="mobile-add-item-field">
            <label>단위</label>
            <input
              type="text"
              value={newItem.unit}
              onChange={(e) => setNewItem({ ...newItem, unit: e.target.value })}
              placeholder="단위 입력"
            />
          </div>
          <div className="mobile-add-item-field">
            <label>단가</label>
            <input
              type="number"
              value={newItem.unitPrice}
              onChange={(e) => setNewItem({ ...newItem, unitPrice: e.target.value })}
              placeholder="단가 입력"
            />
          </div>
          <div className="mobile-add-item-field">
            <label>비고</label>
            <input
              type="text"
              value={newItem.note}
              onChange={(e) => setNewItem({ ...newItem, note: e.target.value })}
              placeholder="비고 입력"
            />
          </div>

          <div className="checkbox-group">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={newItem.isAdditional}
                onChange={(e) => setNewItem({ ...newItem, isAdditional: e.target.checked })}
              />
              추가 견적 항목
            </label>
          </div>

          <div className="mobile-total-price">
            <label>합계:</label>
            <span>
              {(parseInt(newItem.quantity || 0) * parseInt(newItem.unitPrice || 0)).toLocaleString()}원
            </span>
          </div>

          <div className="mobile-item-management-buttons">
            <button type="button" onClick={() => setShowSavedItems(true)}>
              저장된 품목 검색
            </button>
            <button type="button" onClick={handleSaveItem}>
              현재 품목 저장
            </button>
          </div>

          <div className="mobile-add-item-buttons">
            <button type="submit">저장</button>
            <button type="button" onClick={onClose}>취소</button>
          </div>
        </form>
      </div>

      {showSavedItems && (
        <MobileSavedItemsModal
          processId={processId}
          onSelect={handleSelectSavedItem}
          onClose={() => setShowSavedItems(false)}
        />
      )}

      {showMemoModal && (
        <MobileSaveItemMemoModal
          item={newItem}
          onSave={handleSaveWithMemo}
          onClose={() => setShowMemoModal(false)}
        />
      )}
    </div>
  );
};

export default MobileAddItemModal; 