import React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './PdfControls.css';

const PdfControls = ({ currentPage, pages, pageRefs }) => {
  const waitForImageLoad = (element) => {
    const images = element.getElementsByTagName('img');
    
    const promises = Array.from(images).map((img) => {
      if (img.complete || !img.src) {
        return Promise.resolve();
      }
      
      return new Promise((resolve) => {
        const timeout = setTimeout(() => {
          resolve();
        }, 5000);
        
        img.onload = () => {
          clearTimeout(timeout);
          resolve();
        };
        
        img.onerror = () => {
          clearTimeout(timeout);
          resolve();
        };
        
        const currentSrc = img.src;
        img.src = '';
        img.src = currentSrc;
      });
    });
    
    return Promise.all(promises);
  };

  const capturePageToPdf = async (pageElement, pdf, isFirstPage = true) => {
    try {
      // 이미지 로딩 대기
      await waitForImageLoad(pageElement);

      // PDF 생성을 위한 스타일 적용
      const blueprintImages = pageElement.querySelectorAll('.blueprint-image, .floor-plan-image');
      const originalStyles = Array.from(blueprintImages).map(img => ({
        img,
        style: img.style.cssText
      }));

      // 이미지 스타일 임시 수정
      blueprintImages.forEach(img => {
        img.style.maxHeight = '100%';
        img.style.maxWidth = '100%';
        img.style.objectFit = 'contain';
        img.style.backgroundColor = 'white';
        img.style.display = 'block';
      });

      // 이미지 URL을 base64로 변환하는 함수 추가
      const convertImageToBase64 = async (imgElement) => {
        return new Promise((resolve, reject) => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          const img = new Image();
          img.crossOrigin = 'anonymous';  // CORS 허용
          
          img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            resolve(canvas.toDataURL('image/png'));
          };
          
          img.onerror = () => {
            console.error('이미지 로드 실패:', imgElement.src);
            // 이미지 로드 실패 시에도 계속 진행
            resolve(null);
          };
          
          // Firebase Storage URL에 토큰 파라미터 추가
          const url = new URL(imgElement.src);
          if (url.hostname.includes('firebasestorage.googleapis.com')) {
            // 기존 토큰이 있다면 제거
            url.searchParams.delete('token');
            // 새로운 타임스탬프 토큰 추가
            url.searchParams.append('token', Date.now().toString());
            // CORS 우회를 위한 프록시 서버 사용
            const proxyUrl = `https://cors-anywhere.herokuapp.com/${url.toString()}`;
            img.src = proxyUrl;
          } else {
            img.src = imgElement.src;
          }
        });
      };

      // 모든 이미지를 base64로 변환
      const imagePromises = Array.from(blueprintImages).map(async (img) => {
        if (img.src && !img.src.startsWith('data:')) {
          const base64Url = await convertImageToBase64(img);
          if (base64Url) {
            img.src = base64Url;
          }
        }
      });

      await Promise.all(imagePromises);

      // 페이지 크기 계산
      const rect = pageElement.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const absoluteTop = rect.top + scrollTop;

      // 스크롤 위치 조정
      window.scrollTo(0, absoluteTop);

      // 렌더링 안정화를 위한 대기
      await new Promise(resolve => setTimeout(resolve, 500));

      // HTML을 캔버스로 변환
      const canvas = await html2canvas(pageElement, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        logging: true,
        backgroundColor: '#ffffff',
        scrollY: -window.scrollY,
        height: rect.height,
        windowHeight: rect.height,
        onclone: (clonedDoc) => {
          // 클론된 문서의 이미지 스타일도 수정
          const clonedImages = clonedDoc.querySelectorAll('.blueprint-image, .floor-plan-image');
          clonedImages.forEach(img => {
            img.style.maxHeight = '100%';
            img.style.maxWidth = '100%';
            img.style.objectFit = 'contain';
            img.style.backgroundColor = 'white';
            img.style.display = 'block';
          });

          // 메모 요소 처리
          const memoElements = clonedDoc.getElementsByClassName('memo-content');
          Array.from(memoElements).forEach(memo => {
            const originalTextarea = pageElement.querySelector(`#${memo.id}`);
            if (originalTextarea) {
              const div = clonedDoc.createElement('div');
              const computedStyle = window.getComputedStyle(originalTextarea);
              
              div.className = 'memo-content';
              div.style.cssText = `
                flex: 1;
                width: 100%;
                border: 1px solid #ddd;
                border-radius: 4px;
                font-size: 14px;
                line-height: 1.5;
                padding: 10px;
                background: white;
                white-space: pre-wrap;
                word-break: break-word;
                min-height: 100px;
                font-family: ${computedStyle.fontFamily};
                color: ${computedStyle.color};
              `;

              const content = originalTextarea.value
                .replace(/\n/g, '<br>')
                .replace(/ /g, '&nbsp;');
              div.innerHTML = content || '작업 지시 사항을 입력하세요...';
              
              if (!content) {
                div.style.color = '#999';
              }

              memo.parentNode.replaceChild(div, memo);
            }
          });
        }
      });

      // 원래 스타일 복원
      originalStyles.forEach(({img, style}) => {
        img.style.cssText = style;
      });

      // 새 페이지 추가
      if (!isFirstPage) {
        pdf.addPage();
      }

      // 이미지 품질 설정 및 PDF에 추가
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, 'JPEG', 0, 0, pageWidth, pageHeight);

    } catch (error) {
      console.error('페이지 캡처 중 오류:', error);
      throw error;
    }
  };

  const generateSinglePdf = async () => {
    try {
      const pageElement = pageRefs.current[currentPage];
      if (!pageElement) return;

      const pdf = new jsPDF('l', 'mm', 'a4');
      await capturePageToPdf(pageElement, pdf);
      pdf.save(`작업지시서_페이지${currentPage + 1}.pdf`);
    } catch (error) {
      alert('PDF 생성 중 오류가 발생했습니다.');
    }
  };

  const generateAllPdf = async () => {
    try {
      const pdf = new jsPDF('l', 'mm', 'a4');

      const coverPage = document.querySelector('.cover-page')?.closest('.work-order-container');
      if (coverPage) {
        await capturePageToPdf(coverPage, pdf, true);
      }

      const validPages = Array.from(document.querySelectorAll('.page-wrapper'))
        .filter(element => {
          const workTypeText = element.querySelector('.work-type-text')?.textContent;
          return workTypeText && (workTypeText.includes('공정') || workTypeText.includes('사이즈 확인 요망'));
        });

      if (validPages.length === 0 && !coverPage) {
        alert('작업 종류가 지정된 페이지가 없습니다.');
        return;
      }

      const originalScroll = window.scrollY;

      try {
        for (let i = 0; i < validPages.length; i++) {
          await capturePageToPdf(validPages[i], pdf, false);
        }
      } finally {
        window.scrollTo(0, originalScroll);
      }

      pdf.save('작업지시서_전체.pdf');
    } catch (error) {
      alert('PDF 생성 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="pdf-controls">
      <button 
        className="pdf-save-button single"
        onClick={generateSinglePdf}
        title="현재 페이지 PDF 저장"
      >
        <span>📄</span>
      </button>
      <button 
        className="pdf-save-button all"
        onClick={generateAllPdf}
        title="전체 페이지 PDF 저장"
      >
        <span>📑</span>
      </button>
    </div>
  );
};

export default PdfControls; 