import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase';
import { collection, getDocs, addDoc, updateDoc, doc, query, orderBy, setDoc, deleteDoc, where } from 'firebase/firestore';
import './ProcessManagementModal.css';

const ProcessManagementModal = ({ onClose }) => {
  const [processes, setProcesses] = useState([]);
  const [newProcessName, setNewProcessName] = useState('');
  const [isMigrating, setIsMigrating] = useState(false);
  const [editingOrder, setEditingOrder] = useState({});
  const [selectedProcesses, setSelectedProcesses] = useState([]);

  useEffect(() => {
    fetchProcesses();
    
    // ESC 키 이벤트 핸들러 수정
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();
        onClose();
      }
    };

    // 이벤트 리스너를 window 객체에 직접 추가
    window.addEventListener('keydown', handleEscKey, true);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('keydown', handleEscKey, true);
    };
  }, [onClose]);

  const fetchProcesses = async () => {
    try {
      const processesRef = collection(db, 'estimateProcessesV2');
      const q = query(processesRef, orderBy('processOrder', 'asc'));
      const querySnapshot = await getDocs(q);
      const processesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log('현재 공정 데이터:', processesData);
      setProcesses(processesData);
    } catch (error) {
      console.error('공정 목록 조회 중 오류:', error);
      alert('공정 목록을 불러오는 중 오류가 발생했습니다.');
    }
  };

  const moveProcess = async (processId, direction) => { 
    const currentIndex = processes.findIndex(p => p.id === processId);
    if (currentIndex === -1) return;

    const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
    if (newIndex < 0 || newIndex >= processes.length) return;

    try {
      // 배열에서 순서 변경
      const newProcesses = [...processes];
      const [movedProcess] = newProcesses.splice(currentIndex, 1);
      newProcesses.splice(newIndex, 0, movedProcess);

      // processOrder 업데이트
      const updatedProcesses = newProcesses.map((process, index) => ({
        ...process,
        processOrder: index + 1
      }));

      // Firestore 업데이트
      for (const process of updatedProcesses) {
        const processRef = doc(db, 'estimateProcessesV2', process.id);
        await updateDoc(processRef, {
          processOrder: process.processOrder,
          updatedAt: new Date().toISOString()
        });
      }

      setProcesses(updatedProcesses);
    } catch (error) {
      console.error('공정 순서 변경 중 오류:', error);
      alert('공정 순서 변경 중 오류가 발생했습니다.');
    }
  };

  const addProcess = async () => {
    if (!newProcessName.trim()) {
      alert('공정명을 입력해주세요.');
      return;
    }

    try {
      const processesRef = collection(db, 'estimateProcessesV2');
      const maxOrderProcess = processes.reduce((max, p) => 
        (p.processOrder > max.processOrder ? p : max), 
        { processOrder: 0 }
      );

      await addDoc(processesRef, {
        name: newProcessName,
        processOrder: maxOrderProcess.processOrder + 1,
        isActive: true,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });

      setNewProcessName('');
      await fetchProcesses();
      alert('새 공정이 추가되었습니다.');
    } catch (error) {
      console.error('공정 추가 중 오류:', error);
      alert('공정 추가 중 오류가 발생했습니다.');
    }
  };

  const toggleProcessStatus = async (processId, currentStatus) => {
    try {
      const processRef = doc(db, 'estimateProcessesV2', processId);
      await updateDoc(processRef, {
        isActive: !currentStatus,
        updatedAt: new Date().toISOString()
      });
      await fetchProcesses();
    } catch (error) {
      console.error('공정 상태 변경 중 오류:', error);
      alert('공정 상태 변경 중 오류가 발생했습니다.');
    }
  };

  const migrateProcesses = async () => {
    if (!window.confirm('공정 데이터와 기존 견적들의 버전 데이터를 모두 업데이트하시겠습니까?')) {
      return;
    }

    setIsMigrating(true);
    try {
      // 1. 공정 데이터 마이그레이션
      const processesRef = collection(db, 'estimateProcessesV2');
      const q = query(processesRef, orderBy('processOrder', 'asc'));
      const querySnapshot = await getDocs(q);
      
      const processesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // 기존 ID와 새로운 ID 매핑 정의
      const processMapping = {
        'unknown_process': 'QOmGtilpMBNQ1Xhg2QNv',  // 알 수 없는 공정 -> 조명 시공
        '조명시공': 'QOmGtilpMBNQ1Xhg2QNv',  // 한글 ID -> 새로운 ID
        'lighting': 'QOmGtilpMBNQ1Xhg2QNv',  // 이전 영문 ID -> 새로운 ID
        'lighting_construction': 'QOmGtilpMBNQ1Xhg2QNv',  // 추가 매핑
        'light': 'QOmGtilpMBNQ1Xhg2QNv'  // 추가 매핑
      };

      // 2. 견적 버전 데이터 마이그레이션
      const addressesRef = collection(db, 'addresses');
      const addressesSnapshot = await getDocs(addressesRef);
      
      let updatedVersionsCount = 0;
      
      for (const addressDoc of addressesSnapshot.docs) {
        const address = addressDoc.data().description;
        if (!address) continue;

        console.log('주소 처리 중:', address);

        // 각 주소의 버전 데이터 가져오기
        const versionsRef = collection(db, 'estimateVersions');
        const versionsQuery = query(versionsRef, where('address', '==', address));
        try {
          const versionsSnapshot = await getDocs(versionsQuery);
          console.log(`${address}의 버전 수:`, versionsSnapshot.size);

          for (const versionDoc of versionsSnapshot.docs) {
            const versionData = versionDoc.data();
            if (!versionData.data?.processData) {
              console.log('프로세스 데이터 없음:', versionDoc.id);
              continue;
            }

            console.log('버전 데이터 처리 중:', versionDoc.id);

            // processData 업데이트
            const updatedProcessData = versionData.data.processData.map(process => {
              // 매핑된 ID가 있으면 새 ID로 업데이트
              const newId = processMapping[process.id];
              if (newId) {
                console.log(`공정 ID 변경: ${process.id} -> ${newId}`);
                return {
                  ...process,
                  id: newId,
                  name: '조명 시공'  // 새로운 이름으로 업데이트
                };
              }
              return process;
            });

            // 버전 데이터 업데이트
            await setDoc(versionDoc.ref, {
              ...versionData,
              data: {
                ...versionData.data,
                processData: updatedProcessData
              },
              updatedAt: new Date().toISOString()
            });

            updatedVersionsCount++;
            console.log('버전 업데이트 완료:', versionDoc.id);
          }
        } catch (error) {
          console.error(`${address} 처리 중 오류:`, error);
          continue;
        }
      }

      await fetchProcesses();
      alert(`마이그레이션이 완료되었습니다.\n- ${Object.keys(processMapping).length}개의 공정 매핑\n- ${updatedVersionsCount}개의 버전 데이터 업데이트`);
    } catch (error) {
      console.error('마이그레이션 중 오류:', error);
      alert('데이터 변경 중 오류가 발생했습니다.');
    } finally {
      setIsMigrating(false);
    }
  };

  const handleOrderChange = (processId, value) => {
    setEditingOrder(prev => ({
      ...prev,
      [processId]: value
    }));
  };

  const handleOrderBlur = async (processId) => {
    const newOrder = parseInt(editingOrder[processId]);
    if (isNaN(newOrder) || newOrder < 1 || newOrder > processes.length) {
      // 유효하지 않은 순서인 경우 원래 값으로 복원
      setEditingOrder(prev => ({
        ...prev,
        [processId]: processes.find(p => p.id === processId)?.processOrder || ''
      }));
      return;
    }

    try {
      const currentProcess = processes.find(p => p.id === processId);
      const currentOrder = currentProcess.processOrder;
      
      if (newOrder === currentOrder) return;

      const newProcesses = [...processes].sort((a, b) => a.processOrder - b.processOrder);
      
      // 이동할 프로세스 제거
      const processIndex = newProcesses.findIndex(p => p.id === processId);
      const [movedProcess] = newProcesses.splice(processIndex, 1);
      
      // 새 위치에 삽입
      newProcesses.splice(newOrder - 1, 0, movedProcess);

      // processOrder 재할당
      const updatedProcesses = newProcesses.map((process, index) => ({
        ...process,
        processOrder: index + 1
      }));

      // Firestore 업데이트
      for (const process of updatedProcesses) {
        const processRef = doc(db, 'estimateProcessesV2', process.id);
        await updateDoc(processRef, {
          processOrder: process.processOrder,
          updatedAt: new Date().toISOString()
        });
      }

      setProcesses(updatedProcesses);
      
      // 편집 상태 초기화
      setEditingOrder(prev => {
        const newState = { ...prev };
        delete newState[processId];
        return newState;
      });
    } catch (error) {
      console.error('공정 순서 변경 중 오류:', error);
      alert('공정 순서 변경 중 오류가 발생했습니다.');
    }
  };

  const handleOrderKeyPress = (e, processId) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const handleProcessSelect = (processId) => {
    setSelectedProcesses(prev => {
      if (prev.includes(processId)) {
        return prev.filter(id => id !== processId);
      } else {
        return [...prev, processId];
      }
    });
  };

  const handleDeleteProcesses = async () => {
    if (selectedProcesses.length === 0) {
      alert('삭제할 공정을 선택해주세요.');
      return;
    }

    if (!window.confirm(`선택한 ${selectedProcesses.length}개의 공정을 삭제하시겠습니까?\n이 작업은 되돌릴 수 없습니다.`)) {
      return;
    }

    try {
      // 선택된 공정들의 견적 데이터 확인
      const versionsRef = collection(db, 'estimateVersions');
      const versionsSnapshot = await getDocs(versionsRef);
      const versionsWithSelectedProcesses = [];

      for (const versionDoc of versionsSnapshot.docs) {
        const versionData = versionDoc.data();
        if (versionData.data?.processData) {
          const hasSelectedProcess = versionData.data.processData.some(process => 
            selectedProcesses.includes(process.id)
          );
          if (hasSelectedProcess) {
            versionsWithSelectedProcesses.push(versionData.address);
          }
        }
      }

      if (versionsWithSelectedProcesses.length > 0) {
        const uniqueAddresses = [...new Set(versionsWithSelectedProcesses)];
        const confirmDelete = window.confirm(
          `선택한 공정이 다음 주소의 견적에서 사용되고 있습니다:\n\n${uniqueAddresses.slice(0, 3).join('\n')}${uniqueAddresses.length > 3 ? '\n...' : ''}\n\n총 ${uniqueAddresses.length}개의 견적에 영향이 있습니다. 정말 삭제하시겠습니까?`
        );
        if (!confirmDelete) return;
      }

      // 공정 삭제 실행
      for (const processId of selectedProcesses) {
        const processRef = doc(db, 'estimateProcessesV2', processId);
        await deleteDoc(processRef);
      }

      setSelectedProcesses([]);
      await fetchProcesses();
      alert('선택한 공정이 삭제되었습니다.');
    } catch (error) {
      console.error('공정 삭제 중 오류:', error);
      alert('공정 삭제 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="process-mgmt-overlay" onClick={onClose}>
      <div className="process-mgmt-content" onClick={e => e.stopPropagation()}>
        <h2 className="process-mgmt-title">공정 관리</h2>

        <div className="process-mgmt-add-section">
          <input
            type="text"
            value={newProcessName}
            onChange={(e) => setNewProcessName(e.target.value)}
            placeholder="새 공정명 입력"
            className="process-mgmt-input"
          />
          <button 
            onClick={addProcess}
            className="process-mgmt-add-btn"
          >
            추가
          </button>
        </div>

        {selectedProcesses.length > 0 && (
          <button 
            onClick={handleDeleteProcesses}
            className="process-mgmt-delete-btn"
          >
            선택한 공정 삭제 ({selectedProcesses.length}개)
          </button>
        )}

        <button 
          onClick={migrateProcesses}
          className="process-mgmt-migrate-btn"
          disabled={isMigrating}
        >
          {isMigrating ? '변경 중...' : '공정 ID 일괄 변경'}
        </button>

        <div className="process-mgmt-list">
          {processes.map((process, index) => (
            <div 
              key={`${process.id}_${process.processOrder}`} 
              className={`process-mgmt-item ${selectedProcesses.includes(process.id) ? 'selected' : ''}`}
              onClick={() => handleProcessSelect(process.id)}
            >
              <div className="process-mgmt-order-controls" onClick={e => e.stopPropagation()}>
                <button
                  onClick={() => moveProcess(process.id, 'up')}
                  disabled={index === 0}
                  className="process-mgmt-order-btn"
                  title="위로 이동"
                >
                  ↑
                </button>
                <input
                  type="text"
                  className="process-mgmt-order-input"
                  value={editingOrder[process.id] !== undefined ? editingOrder[process.id] : process.processOrder}
                  onChange={(e) => handleOrderChange(process.id, e.target.value)}
                  onBlur={() => handleOrderBlur(process.id)}
                  onKeyPress={(e) => handleOrderKeyPress(e, process.id)}
                  title="순서 직접 입력"
                />
                <button
                  onClick={() => moveProcess(process.id, 'down')}
                  disabled={index === processes.length - 1}
                  className="process-mgmt-order-btn"
                  title="아래로 이동"
                >
                  ↓
                </button>
              </div>
              <span className="process-mgmt-name">
                {process.name}
                <span className="process-mgmt-id">
                  ID: {process.id}
                </span>
              </span>
              <button
                className={`process-mgmt-toggle ${process.isActive ? 'active' : 'inactive'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleProcessStatus(process.id, process.isActive);
                }}
              >
                {process.isActive ? '활성' : '비활성'}
              </button>
            </div>
          ))}
        </div>

        <button onClick={onClose} className="process-mgmt-close">
          닫기
        </button>
      </div>
    </div>
  );
};

export default ProcessManagementModal; 