import React from 'react';
import './PageControls.css';

const PageControls = ({ 
  onAddWorkType, 
  onAddMaterial, 
  onDelete, 
  onDuplicate, 
  onChangeTemplate, 
  onManageMaterial,
  onBulkUpload,
  hasPages = false 
}) => {
  return (
    <div className="page-control-buttons">
      <button
        className="control-button add-type"
        onClick={onAddWorkType}
        title="작업 종류 추가"
      >
        +
      </button>
      <button
        className="control-button bulk-upload"
        onClick={onBulkUpload}
        title="도면 일괄 업로드"
      >
        📑
      </button>
      {hasPages && (
        <>
          <button
            className="control-button manage-material"
            onClick={onManageMaterial}
            title="자재 관리"
          >
            🔧
          </button>
          <button
            className="control-button change-template"
            onClick={onChangeTemplate}
            title="템플릿 변경"
          >
            🎨
          </button>
          <button
            className="control-button duplicate"
            onClick={onDuplicate}
            title="페이지 복제"
          >
            📋
          </button>
          <button
            className="control-button delete"
            onClick={onDelete}
            title="페이지 삭제"
          >
            🗑
          </button>
        </>
      )}
    </div>
  );
};

export default PageControls; 