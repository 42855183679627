import React from 'react';
import './AddressFilter.css';

const AddressFilter = ({ addresses, selectedAddress, onAddressChange }) => {
  return (
    <div className="address-filter">
      <select 
        className="address-filter__select"
        value={selectedAddress || ''}
        onChange={(e) => onAddressChange(e.target.value)}
      >
        <option value="">모든 현장 보기</option>
        {addresses.map((addr) => (
          <option key={addr.id} value={addr.address}>
            {`[${addr.siteNumber}] ${addr.address}`}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AddressFilter; 