import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs, setDoc, deleteDoc, doc, updateDoc, query, orderBy, getDoc, runTransaction, where } from 'firebase/firestore';
import './AddressManagement.css';
import { Helmet } from 'react-helmet';

// 모달 컴포넌트 수정
const Modal = ({ message, onClose, onConfirm, showCancelButton }) => (
  <div className="AddressManagement-modal-overlay">
    <div className="AddressManagement-modal-content">
      <p>{message}</p>
      <div className="AddressManagement-modal-buttons">
        {showCancelButton ? (
          <>
            <button className="AddressManagement-modal-button" onClick={onConfirm}>예</button>
            <button className="AddressManagement-modal-button" onClick={onClose}>아니오</button>
          </>
        ) : (
          <button className="AddressManagement-modal-button" onClick={onClose}>확인</button>
        )}
      </div>
    </div>
  </div>
);

const AddressManagement = () => {
  const [addresses, setAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [editingSiteNumber, setEditingSiteNumber] = useState(null);
  const [tempSiteNumber, setTempSiteNumber] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdminMode, setIsAdminMode] = useState(false);
  const [titleClickCount, setTitleClickCount] = useState(0);

  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const addressesCollection = collection(db, 'addresses');
      const q = query(addressesCollection, orderBy('createdAt', 'desc'));
      const addressesSnapshot = await getDocs(q);
      
      if (addressesSnapshot.empty) {
        console.log('주소 데이터가 없습니다.');
        setAddresses([]);
        return;
      }

      const addressesList = addressesSnapshot.docs.map((doc, index) => ({
        id: doc.id,
        ...doc.data(),
        siteNumber: doc.data().siteNumber || addressesSnapshot.size - index
      }));
      
      console.log('로드된 주소 목록:', addressesList);
      setAddresses(addressesList);
    } catch (error) {
      console.error("Error fetching addresses:", error);
      setError('주소 목록을 불러오는 중 오류가 발생했습니다.');
      setModalMessage('주소 목록을 불러오는 중 오류가 발생했습니다.');
      setShowModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAddress = async (id) => {
    setDeleteId(id);
    setModalMessage('정말로 삭제하시겠습니까?');
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (deleteId) {
      try {
        // 1. addresses 컬렉션에서 해당 문서 정보 가져오기
        const addressDoc = await getDoc(doc(db, 'addresses', deleteId));
        const addressData = addressDoc.data();

        // 2. 주소 문서 삭제
        await deleteDoc(doc(db, 'addresses', deleteId));

        // 3. estimateVersions 문서 삭제 (description을 문서 ID로 사용)
        await deleteDoc(doc(db, 'estimateVersions', addressData.description));

        // 4. payments 문서 삭제
        await deleteDoc(doc(db, 'payments', deleteId));

        fetchAddresses();
        setModalMessage('삭제되었습니다.');
        setShowModal(true);
        setDeleteId(null);
      } catch (error) {
        console.error("Error deleting documents: ", error);
        setModalMessage('삭제 중 오류가 발생했습니다.');
        setShowModal(true);
      }
    }
  };

  const handleAddAddress = async () => {
    if (newAddress.trim()) {
      const addressesCollection = collection(db, 'addresses');
      const q = query(addressesCollection, orderBy('siteNumber', 'desc'));
      const addressesSnapshot = await getDocs(q);
      
      // 가장 큰 현장 번호 찾기
      let maxSiteNumber = 0;
      addressesSnapshot.docs.forEach(doc => {
        const siteNum = doc.data().siteNumber;
        if (siteNum > maxSiteNumber) maxSiteNumber = siteNum;
      });
      
      const newSiteNumber = maxSiteNumber + 1;
      
      try {
        // 새로운 문서 ID 생성 (타임스탬프 사용)
        const newDocId = Date.now().toString();
        
        await setDoc(doc(db, 'addresses', newDocId), {
          address: newAddress.trim(),
          date: '',
          description: newAddress.trim(),
          firstFloorPassword: '',
          unitPassword: '',
          supervisorName: '',
          contractorContact: '',
          contractAmount: '',
          contractDate: '',
          phoneLastFourDigits: '',
          email: '',
          isCompleted: true,
          createdAt: new Date(),
          siteNumber: newSiteNumber
        });
        
        setNewAddress('');
        fetchAddresses();
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    }
  };

  const handleToggleConstruction = async (id, isCompleted) => {
    await updateDoc(doc(db, 'addresses', id), { isCompleted: !isCompleted });
    fetchAddresses();
  };

  const handleSaveAddress = async (id) => {
    try {
      const address = addresses.find(addr => addr.id === id);
      console.log('=== 주소 수정 시작 ===');
      console.log('수정할 주소 ID:', id);
      console.log('새로운 주소 데이터:', address);
      
      await runTransaction(db, async (transaction) => {
        // 1. 주소 문서 읽기
        const addressDocRef = doc(db, 'addresses', id);
        const addressDoc = await transaction.get(addressDocRef);
        
        if (!addressDoc.exists()) {
          throw new Error("주소 문서가 존재하지 않습니다.");
        }
        
        const oldData = addressDoc.data();
        console.log('기존 주소 데이터:', oldData);

        // payments 문서 읽기
        const paymentDocRef = doc(db, 'payments', id);
        const paymentDoc = await transaction.get(paymentDocRef);

        // estimateVersions 컬렉션 처리
        if (oldData.description !== address.description) {
          // 기존 estimateVersions 문서들 찾기
          const versionsQuery = query(
            collection(db, 'estimateVersions'),
            where('address', '==', oldData.description)
          );
          const versionsSnapshot = await getDocs(versionsQuery);

          // 각 버전 문서 처리
          versionsSnapshot.forEach(versionDoc => {
            const versionData = versionDoc.data();
            const oldDocId = versionDoc.id;
            const versionName = oldDocId.split('_')[1]; // 버전명 추출
            const newDocId = `${address.description}_${versionName}`; // 새 문서 ID 생성

            // 새 문서 생성
            transaction.set(doc(db, 'estimateVersions', newDocId), {
              ...versionData,
              address: address.description,
              lastModified: new Date().toISOString()
            });

            // 기존 문서 삭제
            transaction.delete(versionDoc.ref);
          });
        }

        // schedules 컬렉션 조회
        console.log('=== schedules 컬렉션 업데이트 시작 ===');
        const schedulesCollection = collection(db, 'schedules');
        const schedulesQuery = query(schedulesCollection, where('address', '==', oldData.description));
        const schedulesSnapshot = await getDocs(schedulesQuery);
        
        console.log('검색 조건:', {
          oldAddress: oldData.description,
          newAddress: address.description
        });
        console.log('찾은 schedule 문서 수:', schedulesSnapshot.size);
        
        const scheduleRefs = schedulesSnapshot.docs.map(doc => ({
          ref: doc.ref,
          data: doc.data()
        }));
        
        console.log('수정할 schedule 문서들:', scheduleRefs.map(({ ref, data }) => ({
          docId: ref.id,
          address: data.address,
          eventsCount: data.events?.length || 0,
          events: data.events
        })));
        
        // 2. 모든 업데이트 작업 수행
        if (oldData.description !== address.description) {
          console.log('schedules 컬렉션 문서 이동 시작:', {
            from: oldData.description,
            to: address.description
          });

          schedulesSnapshot.docs.forEach(async scheduleDoc => {
            try {
              const scheduleData = scheduleDoc.data();
              
              // 이벤트 데이터 업데이트
              const updatedEvents = scheduleData.events.map(event => {
                if (event.title.startsWith(oldData.description)) {
                  return {
                    ...event,
                    title: event.title.replace(oldData.description, address.description)
                  };
                }
                return event;
              });

              // 새 문서 생성
              const newScheduleRef = doc(db, 'schedules', address.description);
              
              // 기존 데이터를 새 주소로 복사
              transaction.set(newScheduleRef, {
                ...scheduleData,
                address: address.description,
                events: updatedEvents,
                updatedAt: new Date()
              });

              // 이전 문서 삭제
              transaction.delete(doc(db, 'schedules', oldData.description));

              console.log('schedules 문서 이동 완료:', {
                docId: scheduleDoc.id,
                eventsCount: updatedEvents.length
              });
            } catch (error) {
              console.error('schedules 문서 이동 실패:', error);
              throw error;
            }
          });
        } else {
          console.log('주소 변경 없음 - schedules 업데이트 건너뜀');
        }

        // addresses 컬렉션 업데이트
        transaction.update(addressDocRef, {
          description: address.description,
          date: address.date,
          firstFloorPassword: address.firstFloorPassword,
          unitPassword: address.unitPassword,
          supervisorName: address.supervisorName,
          contractorContact: address.contractorContact,
          contractAmount: address.contractAmount,
          contractDate: address.contractDate,
          phoneLastFourDigits: address.phoneLastFourDigits,
          email: address.email
        });
        console.log('addresses 컬렉션 업데이트 완료');

        // payments 컬렉션 업데이트
        if (paymentDoc.exists()) {
          console.log('payments 문서 업데이트:', {
            id: id,
            newAddress: address.description
          });
          transaction.update(paymentDocRef, {
            address: address.description
          });
        }
      });

      console.log('=== 트랜잭션 완료 ===');
      setModalMessage('모든 변경사항이 저장되었습니다.');
      setShowModal(true);
      
      // 1초 후에 모달 닫기
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
      
      await fetchAddresses();
      console.log('주소 목록 새로고침 완료');

    } catch (error) {
      console.error('=== 트랜잭션 오류 ===');
      console.error('오류 내용:', error);
      console.error('오류 스택:', error.stack);
      setModalMessage('저장 중 오류가 발생했습니다: ' + error.message);
      setShowModal(true);
      // 에러 모달도 1초 후에 닫기
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setModalMessage('');
    setDeleteId(null);
  };

  const handleInputChange = (e, field, id = null) => {
    const value = e.target.value;
    if (id) {
      setAddresses(addresses.map(addr => 
        addr.id === id ? { ...addr, [field]: value } : addr
      ));
    } else {
      if (field === 'newAddress') {
        setNewAddress(value);
      }
    }
  };

  // 현장 번호 수정 함수 추가
  const handleSiteNumberChange = async (address, newSiteNumber) => {
    try {
      // 1. 중복 체크
      const existingAddress = addresses.find(
        addr => addr.siteNumber === parseInt(newSiteNumber) && addr.id !== address.id
      );

      if (existingAddress) {
        setModalMessage('이미 사용 중인 현장 번호입니다.');
        setShowModal(true);
        return;
      }

      // 2. 현장 번호 업데이트
      await updateDoc(doc(db, 'addresses', address.id), {
        siteNumber: parseInt(newSiteNumber)
      });

      // 3. 데이터 다시 불러오기
      fetchAddresses();
      
      setModalMessage('현장 번호가 수정되었습니다.');
      setShowModal(true);
    } catch (error) {
      console.error("Error updating site number: ", error);
      setModalMessage('현장 번호 수정 중 오류가 발생했습니다.');
      setShowModal(true);
    }
  };

  // 수정 시작 함수
  const handleStartEditSiteNumber = (address) => {
    setEditingSiteNumber(address.id);
    setTempSiteNumber(address.siteNumber.toString());
  };

  // 수정 완료 함수
  const handleCompleteSiteNumber = async (address) => {
    if (tempSiteNumber === address.siteNumber.toString()) {
      setEditingSiteNumber(null);
      return;
    }

    try {
      await handleSiteNumberChange(address, tempSiteNumber);
    } finally {
      setEditingSiteNumber(null);
    }
  };

  const handleDelete = async (addressId) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const addressDoc = await getDoc(doc(db, 'addresses', addressId));
        if (!addressDoc.exists()) {
          alert('이미 삭제된 주소입니다.');
          return;
        }

        const addressData = addressDoc.data();
        
        // 1. addresses 컬렉션에서 문서 삭제
        await deleteDoc(doc(db, 'addresses', addressId));
        
        // 2. payments 컬렉션에서 관련 문서 삭제
        const paymentsQuery = query(collection(db, 'payments'), where('addressId', '==', addressId));
        const paymentsSnapshot = await getDocs(paymentsQuery);
        const deletePayments = paymentsSnapshot.docs.map(doc => deleteDoc(doc.ref));
        await Promise.all(deletePayments);

        // 3. estimateVersions 컬렉션에서 관련 문서 삭제
        await deleteDoc(doc(db, 'estimateVersions', addressData.description));

        alert('삭제가 완료되었습니다.');
        fetchAddresses(); // 주소 목록 새로고침
      } catch (error) {
        console.error('삭제 중 오류:', error);
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const handleEdit = async (address) => {
    try {
      const oldData = address;
      const newDescription = window.prompt('수정할 주소를 입력하세요:', address.description);
      
      if (!newDescription || newDescription === address.description) {
        return;
      }

      // 트랜잭션으로 처리
      await runTransaction(db, async (transaction) => {
        // 1. addresses 컬렉션 업데이트
        const addressRef = doc(db, 'addresses', address.id);
        transaction.update(addressRef, {
          description: newDescription,
          lastModified: new Date().toISOString()
        });

        // 2. payments 컬렉션 업데이트
        const paymentsQuery = query(collection(db, 'payments'), where('addressId', '==', address.id));
        const paymentsSnapshot = await getDocs(paymentsQuery);
        paymentsSnapshot.docs.forEach(doc => {
          transaction.update(doc.ref, {
            address: newDescription
          });
        });

        // 3. estimateVersions 컬렉션 처리
        if (oldData.description !== newDescription) {
          const oldVersionsRef = doc(db, 'estimateVersions', oldData.description);
          const oldVersionsSnap = await transaction.get(oldVersionsRef);
          
          if (oldVersionsSnap.exists()) {
            const newVersionsRef = doc(db, 'estimateVersions', newDescription);
            transaction.set(newVersionsRef, oldVersionsSnap.data());
            transaction.delete(oldVersionsRef);
          }
        }
      });

      alert('수정이 완료되었습니다.');
      fetchAddresses(); // 주소 목록 새로고침
    } catch (error) {
      console.error('수정 중 오류:', error);
      alert('수정 중 오류가 발생했습니다.');
    }
  };

  // 클립보드 복사 함수 추가
  const handleCopyAddress = (address) => {
    const textToCopy = `${address.description}, 1층 비번: ${address.firstFloorPassword || '없음'}, 세대 비번: ${address.unitPassword || '없음'}`;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setModalMessage('주소가 클립보드에 복사되었습니다.');
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    }).catch(err => {
      console.error('클립보드 복사 실패:', err);
      setModalMessage('클립보드 복사에 실패했습니다.');
      setShowModal(true);
    });
  };

  // 제목 클릭 핸들러 추가
  const handleTitleClick = () => {
    setTitleClickCount(prev => {
      const newCount = prev + 1;
      if (newCount === 3) {
        setIsAdminMode(true);
        setModalMessage('관리자 모드가 활성화되었습니다.');
        setShowModal(true);
        return 0;
      }
      return newCount;
    });
  };

  return (
    <div className="AddressManagement-container">
      <Helmet>
        <link rel="manifest" href="/manifests/address-management-manifest.json" />
      </Helmet>
      <div className="AddressManagement-header"></div>
      <h1 className="AddressManagement-title" onClick={handleTitleClick}>현장 주소 관리</h1>
      {isAdminMode && (
        <div className="AddressManagement-input-section">
          <div className="AddressManagement-input-group">
            <input
              className="AddressManagement-input"
              type="text"
              value={newAddress}
              onChange={(e) => handleInputChange(e, 'newAddress')}
              placeholder="현장 주소 입력"
            />
            <button className="AddressManagement-button" onClick={handleAddAddress}>추가하기</button>
          </div>
        </div>
      )}
      <div className="AddressManagement-list">
        {isLoading ? (
          <div style={{ textAlign: 'center', padding: '20px', color: '#946A6A' }}>
            데이터를 불러오는 중...
          </div>
        ) : error ? (
          <div style={{ textAlign: 'center', padding: '20px', color: '#C57E66' }}>
            {error}
          </div>
        ) : addresses.length === 0 ? (
          <div style={{ textAlign: 'center', padding: '20px', color: '#946A6A' }}>
            등록된 주소가 없습니다.
          </div>
        ) : (
          addresses.map((address) => (
            <div key={address.id} className="AddressManagement-item">
              <h2 onClick={() => handleStartEditSiteNumber(address)} className="editable">
                {editingSiteNumber === address.id ? (
                  <input
                    className="AddressManagement-number-edit"
                    type="number"
                    value={tempSiteNumber}
                    onChange={(e) => setTempSiteNumber(e.target.value)}
                    onBlur={() => handleCompleteSiteNumber(address)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleCompleteSiteNumber(address);
                      } else if (e.key === 'Escape') {
                        setEditingSiteNumber(null);
                      }
                    }}
                    autoFocus
                  />
                ) : (
                  <>
                    현장 번호: {address.siteNumber}
                    <span className="AddressManagement-edit-hint"> (클릭하여 수정)</span>
                  </>
                )}
              </h2>
              <button 
                className="AddressManagement-button AddressManagement-copy-button"
                onClick={() => handleCopyAddress(address)}
              >
                주소 복사
              </button>
              <input
                className="AddressManagement-input"
                type="text"
                value={address.date}
                onInput={(e) => handleInputChange(e, 'date', address.id)}
                placeholder="시작 날짜"
              />
              <input
                className="AddressManagement-input"
                type="text"
                value={address.description}
                onInput={(e) => handleInputChange(e, 'description', address.id)}
                placeholder="현장 주소"
              />
              <input
                className="AddressManagement-input"
                type="text"
                value={address.firstFloorPassword}
                onInput={(e) => handleInputChange(e, 'firstFloorPassword', address.id)}
                placeholder="1층 비번"
              />
              <input
                className="AddressManagement-input"
                type="text"
                value={address.unitPassword}
                onInput={(e) => handleInputChange(e, 'unitPassword', address.id)}
                placeholder="세대 비번"
              />
              {isAdminMode && (
                <>
                  <input
                    className="AddressManagement-input"
                    type="text"
                    value={address.supervisorName}
                    onInput={(e) => handleInputChange(e, 'supervisorName', address.id)}
                    placeholder="계약자 성함"
                  />
                  <input
                    className="AddressManagement-input"
                    type="text"
                    value={address.contractorContact}
                    onInput={(e) => handleInputChange(e, 'contractorContact', address.id)}
                    placeholder="계약자 연락처"
                  />
                  <input
                    className="AddressManagement-input"
                    type="text"
                    value={address.contractAmount}
                    onInput={(e) => handleInputChange(e, 'contractAmount', address.id)}
                    placeholder="계약 금액"
                  />
                  <input
                    className="AddressManagement-input"
                    type="text"
                    value={address.contractDate}
                    onInput={(e) => handleInputChange(e, 'contractDate', address.id)}
                    placeholder="계약 날짜"
                  />
                  <input
                    className="AddressManagement-input"
                    type="text"
                    value={address.phoneLastFourDigits}
                    onInput={(e) => handleInputChange(e, 'phoneLastFourDigits', address.id)}
                    placeholder="전화번호 뒷자리"
                  />
                  <input
                    className="AddressManagement-input"
                    type="email"
                    value={address.email}
                    onInput={(e) => handleInputChange(e, 'email', address.id)}
                    placeholder="Email"
                  />
                  <button 
                    className="AddressManagement-button AddressManagement-save-button" 
                    onClick={() => handleSaveAddress(address.id)}
                  >
                    저장하기
                  </button>
                  <button 
                    className="AddressManagement-button AddressManagement-delete-button" 
                    onClick={() => handleDelete(address.id)}
                  >
                    삭제
                  </button>
                  <button 
                    className="AddressManagement-button AddressManagement-construction-button" 
                    onClick={() => handleToggleConstruction(address.id, address.isCompleted)}
                  >
                    {address.isCompleted ? '공사중' : '공사완료'}
                  </button>
                </>
              )}
            </div>
          ))
        )}
      </div>
      {showModal && (
        <Modal 
          message={modalMessage} 
          onClose={closeModal} 
          onConfirm={deleteId ? confirmDelete : closeModal}
          showCancelButton={!!deleteId}
        />
      )}
    </div>
  );
};

export default AddressManagement;
