import React from 'react';
import './PageMinimap.css';

const PageMinimap = ({ pages, currentPage, onPageSelect, onPageReorder }) => {
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();
    const sourceIndex = parseInt(e.dataTransfer.getData('text/plain'));
    if (sourceIndex !== targetIndex) {
      onPageReorder(sourceIndex, targetIndex);
    }
  };

  const renderPreview = (page, index) => {
    if (page.layoutType === 'template4') {
      return (
        <div className="minimap-preview text-preview">
          <div className="preview-text">
            {page.memo || 'No Text'}
          </div>
        </div>
      );
    }

    return (
      <div className="minimap-preview">
        {page.imageFile ? (
          <img src={page.imageFile} alt={`페이지 ${index + 1}`} />
        ) : (
          <div className="minimap-empty">No Image</div>
        )}
      </div>
    );
  };

  return (
    <div className="page-minimap">
      <div className="minimap-title">페이지 목록</div>
      <div className="minimap-container">
        {pages.map((page, index) => (
          <div
            key={index}
            className={`minimap-item ${currentPage === index ? 'active' : ''}`}
            onClick={() => onPageSelect(index)}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
          >
            {renderPreview(page, index)}
            <div className="minimap-info">
              <span className="page-number">Page {index + 1}</span>
              <span className="work-type">{page.workType || '작업 종류 미지정'}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PageMinimap; 