import { db, storage } from '../../firebase';
import { 
  collection, 
  addDoc, 
  updateDoc,
  doc,
  serverTimestamp,
  getDocs,
  orderBy,
  query,
  deleteDoc
} from 'firebase/firestore';
import { 
  ref, 
  uploadBytes, 
  getDownloadURL,
  deleteObject,
  uploadString
} from 'firebase/storage';

// 이미지를 Firebase Storage에 업로드하고 URL을 반환하는 함수
export const uploadImage = async (file, addressId, workType) => {
  if (!file) return null;

  try {
    const timestamp = new Date().getTime();
    const path = `addresses/${addressId}/workOrders/${timestamp}_${workType}.jpg`;
    const storageRef = ref(storage, path);
    const snapshot = await uploadBytes(storageRef, file);
    const imageUrl = await getDownloadURL(snapshot.ref);
    return imageUrl;
  } catch (error) {
    throw error;
  }
};

// base64 이미지를 File 객체로 변환하는 함수
export const base64ToFile = (base64String, filename) => {
  const arr = base64String.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

// 작업지시서 페이지를 Firestore에 저장하는 함수
export const saveWorkOrderPage = async (addressId, pageData) => {
  try {
    const workOrderRef = collection(db, 'addresses', addressId, 'workOrders');
    const docRef = await addDoc(workOrderRef, {
      memo: pageData.memo || '',
      workType: pageData.workType || '',
      layoutType: pageData.layoutType || 'template1',
      labels: pageData.labels || [],
      location: pageData.location || '위치 미지정',
      createdAt: pageData.createdAt || serverTimestamp(),
      imageUrl: null,
      floorPlanUrl: null
    });

    // 도면 이미지 처리
    if (pageData.imageFile) {
      let imageUrl = pageData.imageFile;
      if (pageData.imageFile.startsWith('data:')) {
        // base64 이미지인 경우 업로드
        const fileName = 'blueprint.jpg';
        const imageRef = ref(storage, `workOrders/${addressId}/${docRef.id}/${fileName}`);
        await uploadString(imageRef, pageData.imageFile, 'data_url');
        imageUrl = await getDownloadURL(imageRef);
      }
      await updateDoc(docRef, { imageUrl });
    }

    // 2D 평면도 처리
    if (pageData.floorPlanFile) {
      let floorPlanUrl = pageData.floorPlanFile;
      if (pageData.floorPlanFile.startsWith('data:')) {
        // base64 이미지인 경우 업로드
        const fileName = 'floorplan.jpg';
        const floorPlanRef = ref(storage, `workOrders/${addressId}/${docRef.id}/${fileName}`);
        await uploadString(floorPlanRef, pageData.floorPlanFile, 'data_url');
        floorPlanUrl = await getDownloadURL(floorPlanRef);
      }
      await updateDoc(docRef, { floorPlanUrl });
    }

    return docRef.id;
  } catch (error) {
    console.error('Error saving work order page:', error);
    throw error;
  }
};

// Storage에서 이미지를 삭제하는 함수
export const deleteStorageImage = async (imageUrl) => {
  if (!imageUrl) return;

  try {
    const imageRef = ref(storage, imageUrl);
    await deleteObject(imageRef);
  } catch (error) {
    throw error;
  }
};

// 작업지시서 페이지를 업데이트하는 함수
export const updateWorkOrderPage = async (addressId, pageId, pageData) => {
  try {
    const pageRef = doc(db, 'addresses', addressId, 'workOrders', pageId);
    const updateData = {
      memo: pageData.memo || '',
      workType: pageData.workType || '',
      layoutType: pageData.layoutType || 'template1',
      labels: pageData.labels || [],
      location: pageData.location || '위치 미지정',
      updatedAt: new Date(),
      arrowPosition: pageData.arrowPosition || null,
      isArrowReversed: pageData.isArrowReversed || false
    };

    if (pageData.imageFile === null) {
      const fileName = 'blueprint.jpg';
      const imageRef = ref(storage, `workOrders/${addressId}/${pageId}/${fileName}`);
      try {
        await deleteObject(imageRef);
        updateData.imageUrl = null;
      } catch (error) {
        // 이미지가 없는 경우 무시
      }
    } else if (pageData.imageFile && pageData.imageFile.startsWith('data:')) {
      const fileName = 'blueprint.jpg';
      const imageRef = ref(storage, `workOrders/${addressId}/${pageId}/${fileName}`);

      try {
        await deleteObject(imageRef);
      } catch (error) {
        // 이전 이미지가 없는 경우 무시
      }

      await uploadString(imageRef, pageData.imageFile, 'data_url');
      const imageUrl = await getDownloadURL(imageRef);
      updateData.imageUrl = imageUrl;
    }

    if (pageData.floorPlanFile === null) {
      const fileName = 'floorplan.jpg';
      const floorPlanRef = ref(storage, `workOrders/${addressId}/${pageId}/${fileName}`);
      try {
        await deleteObject(floorPlanRef);
        updateData.floorPlanUrl = null;
      } catch (error) {
        // 평면도가 없는 경우 무시
      }
    } else if (pageData.floorPlanFile && pageData.floorPlanFile.startsWith('data:')) {
      const fileName = 'floorplan.jpg';
      const floorPlanRef = ref(storage, `workOrders/${addressId}/${pageId}/${fileName}`);

      try {
        await deleteObject(floorPlanRef);
      } catch (error) {
        // 이전 평면도가 없는 경우 무시
      }

      await uploadString(floorPlanRef, pageData.floorPlanFile, 'data_url');
      const floorPlanUrl = await getDownloadURL(floorPlanRef);
      updateData.floorPlanUrl = floorPlanUrl;
    }

    await updateDoc(pageRef, updateData);
    return updateData;
  } catch (error) {
    throw error;
  }
};

// 주소에 해당하는 작업지시서 페이지들을 불러오는 함수
export const fetchWorkOrderPages = async (addressId) => {
  try {
    const workOrderRef = collection(db, 'addresses', addressId, 'workOrders');
    const q = query(workOrderRef, orderBy('createdAt', 'asc'));
    const snapshot = await getDocs(q);
    
    return snapshot.docs.map(doc => ({
      id: doc.id,
      imageUrl: doc.data().imageUrl || null,
      floorPlanUrl: doc.data().floorPlanUrl || null,
      memo: doc.data().memo || '',
      workType: doc.data().workType || '',
      layoutType: doc.data().layoutType || 'template1',
      labels: doc.data().labels || [],
      location: doc.data().location || '위치 미지정',
      createdAt: doc.data().createdAt,
      arrowPosition: doc.data().arrowPosition || null,
      isArrowReversed: doc.data().isArrowReversed || false
    }));
  } catch (error) {
    throw error;
  }
};

// 작업지시서 페이지를 삭제하는 함수
export const deleteWorkOrderPage = async (addressId, pageId, imageUrl, floorPlanUrl) => {
  try {
    // 이미지 삭제
    if (imageUrl && !imageUrl.startsWith('data:')) {
      try {
        const imageRef = ref(storage, imageUrl);
        await deleteObject(imageRef);
      } catch (error) {
        console.error('Error deleting image:', error);
      }
    }

    // 2D 평면도 이미지 삭제
    if (floorPlanUrl && !floorPlanUrl.startsWith('data:')) {
      try {
        const floorPlanRef = ref(storage, floorPlanUrl);
        await deleteObject(floorPlanRef);
      } catch (error) {
        console.error('Error deleting floor plan image:', error);
      }
    }

    // 문서 삭제
    const pageRef = doc(db, 'addresses', addressId, 'workOrders', pageId);
    await deleteDoc(pageRef);
  } catch (error) {
    console.error('Error deleting work order page:', error);
    throw error;
  }
}; 