import React, { useState } from 'react';
import './ResultModal.css';

const ResultModal = ({ 
  selectedMaterials, 
  outerBathroomMaterials, 
  innerBathroomMaterials, 
  allMaterials, 
  categories, 
  onClose, 
  address 
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const getSelectedMaterialInfo = (materialId) => {
    return allMaterials.find(m => m.id === materialId) || null;
  };

  const getCategoryName = (categoryId) => {
    const category = categories.find(c => c.id === categoryId);
    return category ? category.name : '';
  };

  // 선택된 자재를 카테고리별로 그룹화
  const getGroupedMaterials = (materials = []) => {
    return categories
      .map(category => {
        const categoryMaterials = allMaterials
          .filter(material => 
            material.categoryId === category.id && 
            materials.includes(material.id)
          );
        
        const material = categoryMaterials[0];
        
        return {
          category: category.name,
          name: categoryMaterials.length > 0 
            ? categoryMaterials.map(m => m.name).join(', ')
            : '선택되지 않음',
          isNoSelection: categoryMaterials.length === 0,
          material: material || null,
          order: category.order
        };
      })
      .sort((a, b) => a.order - b.order);
  };

  const generateShareText = () => {
    const title = "욕실자재 및 기타 선택사항";
    const separator = "\n----------------------------------------\n";
    
    let text = title + separator;
    
    if (address) {
      text += `주소: ${address}${separator}`;
    }

    // 외욕실 자재 목록
    text += "[ 외욕실 자재 ]\n";
    const outerMaterials = getGroupedMaterials(outerBathroomMaterials);
    outerMaterials.forEach(({ category, name }) => {
      text += `${category}: ${name}\n`;
    });
    
    text += separator;

    // 내욕실 자재 목록
    text += "[ 내욕실 자재 ]\n";
    const innerMaterials = getGroupedMaterials(innerBathroomMaterials);
    innerMaterials.forEach(({ category, name }) => {
      text += `${category}: ${name}\n`;
    });

    return text;
  };

  const handleShare = async () => {
    const shareText = generateShareText();
    
    if (navigator.share && /mobile|android|iphone/i.test(navigator.userAgent)) {
      // 모바일 기기에서는 네이티브 공유 기능 사용
      try {
        await navigator.share({
          title: 'AMARE DESIGN',
          text: shareText
        });
      } catch (error) {
        console.error('공유 실패:', error);
      }
    } else {
      // 데스크톱에서는 클립보드 복사
      try {
        await navigator.clipboard.writeText(shareText);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      } catch (error) {
        console.error('복사 실패:', error);
      }
    }
  };

  const outerMaterials = getGroupedMaterials(outerBathroomMaterials);
  const innerMaterials = getGroupedMaterials(innerBathroomMaterials);

  return (
    <div className="result-modal__overlay" onClick={onClose}>
      <div className="result-modal__content" onClick={e => e.stopPropagation()}>
        <div className="result-modal__header">
          <div className="result-modal__header-content">
            <h2 className="result-modal__title">선택하신 자재 목록</h2>
            <button className="result-modal__close" onClick={onClose}>×</button>
          </div>
        </div>
        <div className="result-modal__body">
          <div className="result-modal__section">
            <h3 className="result-modal__section-title">외욕실 자재</h3>
            <div className="result-modal__list">
              {outerMaterials.map((item, index) => (
                <div key={`outer-${index}`} className="result-modal__item">
                  {!item.isNoSelection && item.material && item.material.image && (
                    <img 
                      src={item.material.image} 
                      alt={item.material.name}
                      className="result-modal__item-image"
                    />
                  )}
                  <div className="result-modal__item-content">
                    <div className="result-modal__category">{item.category}</div>
                    <div className={`result-modal__material ${item.isNoSelection ? 'result-modal__material--no-selection' : ''}`}>
                      {item.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="result-modal__section">
            <h3 className="result-modal__section-title">내욕실 자재</h3>
            <div className="result-modal__list">
              {innerMaterials.map((item, index) => (
                <div key={`inner-${index}`} className="result-modal__item">
                  {!item.isNoSelection && item.material && item.material.image && (
                    <img 
                      src={item.material.image} 
                      alt={item.material.name}
                      className="result-modal__item-image"
                    />
                  )}
                  <div className="result-modal__item-content">
                    <div className="result-modal__category">{item.category}</div>
                    <div className={`result-modal__material ${item.isNoSelection ? 'result-modal__material--no-selection' : ''}`}>
                      {item.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="result-modal__footer">
          <button className="result-modal__share" onClick={handleShare}>
            {isCopied ? '복사완료!' : '공유하기'}
          </button>
          <button className="result-modal__confirm" onClick={onClose}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResultModal; 