import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, query, orderBy, doc, setDoc, getDoc } from 'firebase/firestore';
import './CostCalculation.css';

const CostCalculation = () => {
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [costs, setCosts] = useState({});
  const [contractors, setContractors] = useState({});
  const [hiddenProcesses, setHiddenProcesses] = useState([]);
  const [processOrder, setProcessOrder] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSecretMode, setIsSecretMode] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [clickTimer, setClickTimer] = useState(null);

  useEffect(() => {
    fetchAddresses();
    fetchContractors();
    fetchSettings();
  }, []);

  useEffect(() => {
    if (selectedAddress) {
      fetchCosts();
    }
  }, [selectedAddress]);

  const fetchSettings = async () => {
    try {
      const settingsDoc = await getDoc(doc(db, 'settings', 'processSettings'));
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        setHiddenProcesses(data.hiddenProcesses || []);
      }

      const orderDoc = await getDoc(doc(db, 'settings', 'processOrder'));
      if (orderDoc.exists()) {
        const savedOrder = orderDoc.data().order || [];
        setProcessOrder(savedOrder);
      }
    } catch (error) {
      console.error('설정 조회 중 오류:', error);
    }
  };

  const saveSettings = async () => {
    try {
      await setDoc(doc(db, 'settings', 'processSettings'), {
        hiddenProcesses,
        updatedAt: new Date().toISOString()
      });

      await setDoc(doc(db, 'settings', 'processOrder'), {
        order: processOrder,
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('설정 저장 중 오류:', error);
    }
  };

  const fetchContractors = async () => {
    try {
      const contractorsSnapshot = await getDocs(collection(db, 'contractors'));
      const contractorsData = {};
      contractorsSnapshot.docs.forEach(doc => {
        contractorsData[doc.id] = doc.data();
      });
      setContractors(contractorsData);
      
      const orderDoc = await getDoc(doc(db, 'settings', 'processOrder'));
      const savedOrder = orderDoc.exists() ? orderDoc.data().order : [];
      
      const currentProcessIds = Object.keys(contractorsData);
      let newProcessOrder = [...savedOrder];
      
      if (savedOrder.length > 0) {
        currentProcessIds.forEach(id => {
          if (!newProcessOrder.includes(id)) {
            newProcessOrder.push(id);
          }
        });
        
        newProcessOrder = newProcessOrder.filter(id => currentProcessIds.includes(id));
      } else {
        newProcessOrder = currentProcessIds;
      }
      
      setProcessOrder(newProcessOrder);
      
      if (JSON.stringify(newProcessOrder) !== JSON.stringify(savedOrder)) {
        await setDoc(doc(db, 'settings', 'processOrder'), {
          order: newProcessOrder,
          updatedAt: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('거래처 목록 조회 중 오류:', error);
    }
  };

  const fetchAddresses = async () => {
    try {
      const addressesCollection = collection(db, 'addresses');
      const q = query(addressesCollection, orderBy('createdAt', 'desc'));
      const addressesSnapshot = await getDocs(q);
      
      const addressesList = addressesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setAddresses(addressesList);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  const fetchCosts = async () => {
    try {
      const costsDoc = await getDoc(doc(db, 'costs', selectedAddress.id));
      if (costsDoc.exists()) {
        const data = costsDoc.data();
        setCosts(data.costs || {});
      } else {
        setCosts({});
      }
    } catch (error) {
      console.error('비용 데이터 조회 중 오류:', error);
    }
  };

  const handleCostChange = async (processId, value) => {
    if (!selectedAddress) return;

    // 콤마 제거 및 숫자만 추출
    const numericValue = value.replace(/,/g, '');

    const newCosts = {
      ...costs,
      [processId]: numericValue
    };
    setCosts(newCosts);

    try {
      await setDoc(doc(db, 'costs', selectedAddress.id), {
        costs: newCosts,
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('비용 저장 중 오류:', error);
    }
  };

  // 천단위 콤마 포맷팅 함수
  const formatNumber = (num) => {
    if (!num) return '';
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const toggleProcessVisibility = (processId) => {
    const newHiddenProcesses = hiddenProcesses.includes(processId)
      ? hiddenProcesses.filter(id => id !== processId)
      : [...hiddenProcesses, processId];
    setHiddenProcesses(newHiddenProcesses);
    saveSettings();
  };

  const moveProcess = (processId, direction) => {
    const currentIndex = processOrder.indexOf(processId);
    if (direction === 'up' && currentIndex > 0) {
      const newOrder = [...processOrder];
      [newOrder[currentIndex], newOrder[currentIndex - 1]] = 
      [newOrder[currentIndex - 1], newOrder[currentIndex]];
      setProcessOrder(newOrder);
      saveSettings();
    } else if (direction === 'down' && currentIndex < processOrder.length - 1) {
      const newOrder = [...processOrder];
      [newOrder[currentIndex], newOrder[currentIndex + 1]] = 
      [newOrder[currentIndex + 1], newOrder[currentIndex]];
      setProcessOrder(newOrder);
      saveSettings();
    }
  };

  const calculateTotal = () => {
    return Object.entries(costs)
      .filter(([processId]) => !hiddenProcesses.includes(processId))
      .reduce((sum, [, cost]) => sum + (Number(cost) || 0), 0);
  };

  const sortedProcesses = () => {
    const visibleProcesses = Object.entries(contractors)
      .filter(([id]) => !hiddenProcesses.includes(id));

    return visibleProcesses.sort((a, b) => {
      const aIndex = processOrder.indexOf(a[0]);
      const bIndex = processOrder.indexOf(b[0]);
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  };

  const handleTitleClick = () => {
    setClickCount(prev => prev + 1);
    
    if (clickTimer) {
      clearTimeout(clickTimer);
    }
    
    const timer = setTimeout(() => {
      setClickCount(0);
    }, 500);
    
    setClickTimer(timer);
    
    if (clickCount === 2) {
      setIsSecretMode(prev => !prev);
      setClickCount(0);
      clearTimeout(timer);
    }
  };

  return (
    <div className="cost-calculation-container">
      <div className="cost-calculation-content">
        <h1 className="cost-calculation-title" onClick={handleTitleClick}>현장별 결제 금액</h1>

        <div className="cost-calculation-address-section">
          <h2>현장 선택</h2>
          <select 
            className="cost-calculation-select"
            value={selectedAddress ? selectedAddress.id : ''}
            onChange={(e) => {
              const selected = addresses.find(addr => addr.id === e.target.value);
              setSelectedAddress(selected);
            }}
          >
            <option value="">현장을 선택하세요</option>
            {addresses.map(address => (
              <option key={address.id} value={address.id}>
                {address.description}
              </option>
            ))}
          </select>
        </div>

        {selectedAddress && (
          <div className="cost-calculation-process-section">
            <div className="cost-calculation-process-header-actions">
              <p className="cost-calculation-notice">이번 현장 내역을 여기 입력 하시면{'\n'}결제 확인 후 송금 드립니다.</p>
              {isSecretMode && (
                <button
                  className="cost-calculation-edit-button"
                  onClick={() => setIsEditMode(!isEditMode)}
                >
                  {isEditMode ? '완료' : '편집'}
                </button>
              )}
            </div>
            
            <div className="cost-calculation-process-list">
              {sortedProcesses().map(([processId, contractor]) => (
                <div key={processId} className="cost-calculation-process-item">
                  <div className="cost-calculation-process-header">
                    <div className="cost-calculation-process-info">
                      <h3>{processId}</h3>
                      <p className="cost-calculation-contractor">{contractor.name}</p>
                    </div>
                    {isEditMode && (
                      <div className="cost-calculation-process-actions">
                        <button
                          className="cost-calculation-move-button"
                          onClick={() => moveProcess(processId, 'up')}
                          disabled={processOrder.indexOf(processId) === 0}
                        >
                          ↑
                        </button>
                        <button
                          className="cost-calculation-move-button"
                          onClick={() => moveProcess(processId, 'down')}
                          disabled={processOrder.indexOf(processId) === processOrder.length - 1}
                        >
                          ↓
                        </button>
                        <button
                          className="cost-calculation-hide-button"
                          onClick={() => toggleProcessVisibility(processId)}
                        >
                          숨기기
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="cost-calculation-input-group">
                    <input
                      type="text"
                      placeholder="실제 비용"
                      className="cost-calculation-input"
                      value={formatNumber(costs[processId]) || ''}
                      onChange={(e) => handleCostChange(processId, e.target.value)}
                    />
                  </div>
                </div>
              ))}
            </div>

            {isEditMode && hiddenProcesses.length > 0 && (
              <div className="cost-calculation-hidden-processes">
                <h3>숨겨진 공정</h3>
                {hiddenProcesses.map(processId => contractors[processId] && (
                  <div key={processId} className="cost-calculation-hidden-process-item">
                    <span>{processId} ({contractors[processId].name})</span>
                    <button
                      className="cost-calculation-show-button"
                      onClick={() => toggleProcessVisibility(processId)}
                    >
                      보이기
                    </button>
                  </div>
                ))}
              </div>
            )}

            {isSecretMode && (
              <div className="cost-calculation-total-section">
                <h2>총 원가</h2>
                <div className="cost-calculation-total-row">
                  <span>총액:</span>
                  <span>{calculateTotal().toLocaleString()} 원</span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CostCalculation; 