import React, { useState } from 'react';
import MaterialSelection from './MaterialSelection';
import CustomerNumberInput from './CustomerNumberInput';
import './BathroomMaterial.css';

const BathroomMaterial = () => {
  const [customerData, setCustomerData] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [selectedBathroom, setSelectedBathroom] = useState('outer'); // 'outer' 또는 'inner'

  const handleCustomerNumberSubmit = (number, data) => {
    setCustomerData({
      customerNumber: number,
      ...data
    });
  };

  const handleTitleClick = () => {
    const currentTime = new Date().getTime();
    
    if (currentTime - lastClickTime < 1000) {
      setClickCount(prevCount => {
        const newCount = prevCount + 1;
        if (newCount === 3) {
          setIsAdmin(true);
          return 0;
        }
        return newCount;
      });
    } else {
      setClickCount(1);
    }
    
    setLastClickTime(currentTime);
  };

  const handleBathroomChange = (bathroom) => {
    setSelectedBathroom(bathroom);
  };

  return (
    <div className="bathroom-material">
      {!customerData ? (
        <CustomerNumberInput onSubmit={handleCustomerNumberSubmit} />
      ) : (
        <MaterialSelection 
          customerNumber={customerData.customerNumber} 
          isAdmin={isAdmin}
          bathroomType={selectedBathroom}
          onBathroomChange={handleBathroomChange}
        />
      )}
    </div>
  );
};

export default BathroomMaterial; 