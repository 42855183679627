import React, { useState, useEffect } from 'react';
import './MobileEstimate.css';
import MobileAddItemModal from './MobileAddItemModal';
import EditItemModal from '../estimate-version/EditItemModal';
import AITemplateModal from '../estimate-ai/components/AITemplateModal';
import { db } from '../../firebase';
import { doc, getDoc, collection, getDocs, query, orderBy, updateDoc, where, addDoc } from 'firebase/firestore';
import SelectVersionModal from '../estimate-version/SelectVersionModal';
import HiddenProcessList from '../process-hide/HiddenProcessList';
import HideProcessButton from '../process-hide/HideProcessButton';
import PdfButton from '../pdf/PdfButton';
import EmailPdfModal from '../EmailPdfModal';
import { styles, detailStyles } from '../../styles/pdf/pdfStyles';
import { filterProcessesForPDF } from '../pdf/utils';
import VersionManager from '../estimate-version/VersionManager';
import useKeyboardShortcut from '../../hooks/useKeyboardShortcut';
import { Helmet } from 'react-helmet';

const MobileEstimate = () => {
  // 공정 숨김 상태 관리
  const [hiddenProcesses, setHiddenProcesses] = useState({});
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [selectedProcessId, setSelectedProcessId] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [showSelectVersionModal, setShowSelectVersionModal] = useState(false);
  const [pendingModifications, setPendingModifications] = useState({});
  const [modificationCount, setModificationCount] = useState(0);
  const [showHiddenProcessModal, setShowHiddenProcessModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [availableProcesses, setAvailableProcesses] = useState([]);
  const [pendingEditItem, setPendingEditItem] = useState(null);
  
  // 예시 데이터
  const [estimateData, setEstimateData] = useState({
    title: '견적서',
    company: 'Amare design',
    businessNumber: '712.17.01551',
    address: '수성구 들안로 215',
    contact: '010.8694.4078',
    representative: '대표 권경대',
    processes: [],
    notes: {}
  });

  useEffect(() => {
    loadAddresses();
    loadProcesses();
  }, []);

  useEffect(() => {
    if (selectedAddress) {
      loadEstimateData(selectedAddress);
    }
  }, [selectedAddress]);

  const loadAddresses = async () => {
    try {
      const addressesCollection = collection(db, 'addresses');
      const q = query(addressesCollection, orderBy('siteNumber', 'desc'));
      const addressesSnapshot = await getDocs(q);
      const addressesList = addressesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAddresses(addressesList);
    } catch (error) {
      console.error('주소 목록 로딩 중 오류:', error);
    }
  };

  const loadProcesses = async () => {
    try {
      const processesRef = collection(db, 'estimateProcessesV2');
      const q = query(processesRef, orderBy('processOrder', 'asc'));
      const querySnapshot = await getDocs(q);
      const processesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAvailableProcesses(processesData);
    } catch (error) {
      console.error('공정 목록 로드 중 오류:', error);
    }
  };

  // processData 필터링 함수 수정
  const filteredProcessData = estimateData.processes.filter(process => 
    !hiddenProcesses[process.id]?.hidden
  );

  // getProcessName 함수 수정
  const getProcessName = (process) => {
    if (!process) return '';
    return process.name || process.id;
  };

  const loadEstimateData = async (address) => {
    try {
      console.log('주소 정보:', address);
      
      // 기본 견적서 데이터 로드
      const estimateRef = doc(db, 'estimates', address.id);
      const estimateDoc = await getDoc(estimateRef);
      
      if (estimateDoc.exists()) {
        const estimateData = estimateDoc.data();
        
        // 숨김 상태도 함께 적용
        if (estimateData.hiddenProcesses) {
          setHiddenProcesses(estimateData.hiddenProcesses);
        }
        
        // 공정 데이터를 가용 공정 목록과 매칭하여 설정
        setEstimateData(prevData => {
          const newData = {
            ...prevData,
            processes: (estimateData.processes || []).map(process => {
              const availableProcess = availableProcesses.find(p => p.id === process.id);
              return {
                ...process,
                name: availableProcess ? availableProcess.name : process.name
              };
            }),
            notes: estimateData.notes || {}
          };
          return newData;
        });
      }

      // 버전 데이터 로드
      try {
        console.group('📂 버전 데이터 로드');
        const versionsRef = collection(db, 'estimateVersions');
        const q = query(
          versionsRef,
          where('address', '==', address.description)
        );
        
        const versionsSnapshot = await getDocs(q);
        const versions = versionsSnapshot.docs
          .map(doc => ({
            id: doc.id,
            versionName: doc.data().versionName,
            createdAt: doc.data().createdAt,
            lastModified: doc.data().lastModified,
            address: doc.data().address,
            data: doc.data().data,
            hiddenProcesses: doc.data().hiddenProcesses
          }))
          .sort((a, b) => b.createdAt.localeCompare(a.createdAt));
        
        if (versions.length > 0) {
          const latestVersion = versions[0];
          console.group('🔄 최신 버전 데이터 처리');
          console.log('버전명:', latestVersion.versionName);
          console.log('생성일:', latestVersion.createdAt);
          console.log('버전 전체 데이터:', latestVersion);
          
          if (latestVersion.data) {
            // 버전의 프로세스 데이터를 가용 공정 목록과 매칭하여 업데이트
            setEstimateData(prevData => ({
              ...prevData,
              processes: (latestVersion.data.processData || []).map(process => {
                const availableProcess = availableProcesses.find(p => p.id === process.id);
                return {
                  ...process,
                  name: availableProcess ? availableProcess.name : process.name
                };
              }),
              notes: latestVersion.data.notes || {}
            }));
          }
          
          // 숨김 처리된 프로세스 상태 복원
          console.group('📋 숨긴 공정 상태 복원');
          if (latestVersion.hiddenProcesses) {
            console.log('복원할 숨긴 공정 데이터:', latestVersion.hiddenProcesses);
            setHiddenProcesses(latestVersion.hiddenProcesses);
            console.log('숨긴 공정 상태 복원 완료');
          } else {
            console.log('복원할 숨긴 공정 데이터 없음');
            setHiddenProcesses({});
          }
          console.groupEnd();
          
          setCurrentVersion(latestVersion);
          console.groupEnd();
        } else {
          console.log('⚠️ 버전 정보 없음');
          setHiddenProcesses({});
          setCurrentVersion(null);
        }
        console.groupEnd();
      } catch (versionError) {
        console.error('❌ 버전 데이터 로딩 중 오류:', versionError);
        console.error('버전 에러 상세:', versionError.message);
        setHiddenProcesses({});
        setCurrentVersion(null);
        alert('버전 목록을 불러오는 중 오류가 발생했습니다.');
      }
    } catch (error) {
      console.error('견적서 데이터 로딩 중 오류:', error);
      console.error('에러 상세:', error.message);
      alert('견적서 데이터를 불러오는데 실패했습니다.');
    }
  };

  // 공정 숨김 토글 함수
  const toggleProcessVisibility = (processId) => {
    console.group('👁️ 공정 표시 상태 변경');
    console.log('Process ID:', processId);
    console.log('현재 상태:', hiddenProcesses[processId]?.hidden ? '숨김' : '표시');
    
    const process = estimateData.processes.find(p => p.id === processId);
    console.log('공정명:', process?.name || processId);
    
    const newHiddenState = !hiddenProcesses[processId]?.hidden;
    console.log('변경될 상태:', newHiddenState ? '숨김' : '표시');
    console.log('변경 유형:', 'manual');

    const newHiddenProcesses = {
      ...hiddenProcesses,
      [processId]: {
        hidden: newHiddenState,
        type: 'manual'
      }
    };
    
    console.log('업데이트된 전체 숨김 상태:', newHiddenProcesses);
    console.log('✅ 상태 변경 완료');
    console.groupEnd();
    
    setHiddenProcesses(newHiddenProcesses);
  };

  // 추가 견적 항목만 필터링하는 함수
  const getAdditionalItems = () => {
    return estimateData.processes.reduce((items, process) => {
      const additionalItems = process.items.filter(item => item.isAdditional);
      return [...items, ...additionalItems.map(item => ({
        ...item,
        processName: process.name
      }))];
    }, []);
  };

  // 추가 견적 합계 계산
  const calculateAdditionalTotal = (items) => {
    return items.reduce((sum, item) => {
      if (item.excludeFromTotal) return sum;
      return sum + (parseInt(item.quantity) * parseInt(item.unitPrice));
    }, 0);
  };

  // 총 합계 계산 함수 수정
  const calculateTotal = (processes = estimateData.processes) => {
    // 기본 공정별 합계 계산
    const processTotal = processes.reduce((sum, process) => {
      if (!process || !Array.isArray(process.items)) return sum;
      
      const processTotal = process.items.reduce((itemSum, item) => {
        if (!item || item.isAdditional) return itemSum;  // 추가 항목은 제외
        const itemTotal = (parseInt(item.totalPrice) || 0);
        return itemSum + itemTotal;
      }, 0);
      
      return sum + processTotal;
    }, 0);

    // 추가 견적 합계 계산
    const additionalTotal = calculateAdditionalTotal(getAdditionalItems());
    
    // 총 합계 반환
    return processTotal + additionalTotal;
  };

  // 금액 포맷 함수
  const formatNumber = (number) => {
    return number.toLocaleString();
  };

  // 항목 추가 처리 함수
  const handleAddItem = (processId) => {
    setSelectedProcessId(processId);
    setShowAddItemModal(true);
  };

  // 새 항목 저장 함수
  const saveNewItem = (newItem) => {
    setEstimateData(prevData => {
      const updatedProcesses = prevData.processes.map(process => {
        if (process.id === selectedProcessId) {
          const updatedItems = [...process.items, newItem];
          const newTotal = updatedItems.reduce((sum, item) => 
            sum + (item.totalPrice || 0), 0);
          
          return {
            ...process,
            items: updatedItems,
            total: newTotal
          };
        }
        return process;
      });

      return {
        ...prevData,
        processes: updatedProcesses
      };
    });

    setShowAddItemModal(false);
    setSelectedProcessId(null);
  };

  // 항목 수정 처리 함수
  const handleEditItem = (process, item) => {
    if (!selectedAddress) {
      if (window.confirm('주소를 먼저 선택해주세요. 주소 선택 화면으로 이동하시겠습니까?')) {
        window.scrollTo({ 
          top: 0,
          behavior: 'smooth'
        });
      }
      return;
    }
    
    if (!isEditingMode) {
      alert('수정 모드가 아닙니다. 수정하시려면 수정 시작을 눌러주세요.');
      setPendingEditItem({ process, item });
      setShowSelectVersionModal(true);
      return;
    }

    setEditingItem({
      processId: process.id,
      item: item
    });
    setShowEditItemModal(true);
  };

  // 수정된 항목 저장 함수
  const handleSaveEdit = (editedItem) => {
    try {
      if (!selectedAddress || !isEditingMode) {
        return;
      }

      const process = estimateData.processes.find(p => p.id === editingItem.processId);
      const item = process?.items.find(i => i.uniqueId === editingItem.item.uniqueId);

      if (!item) {
        return;
      }

      // 합계 계산 - excludeFromTotal이 true일 경우 0으로 설정
      const newItemTotal = editedItem.excludeFromTotal 
        ? 0
        : (parseInt(editedItem.quantity) || 0) * (parseInt(editedItem.unitPrice) || 0);

      const changes = {
        ...editedItem,
        uniqueId: item.uniqueId,
        totalPrice: newItemTotal
      };

      // 수정 사항 저장
      const existingModification = Object.entries(pendingModifications)
        .find(([key, mod]) => mod.itemId === item.uniqueId);

      if (existingModification) {
        if (changes.quantity === item.quantity && 
            changes.unitPrice === item.unitPrice && 
            changes.note === item.note &&
            changes.name === item.name &&
            changes.unit === item.unit &&
            changes.excludeFromTotal === item.excludeFromTotal &&
            changes.isAdditional === item.isAdditional) {
          setPendingModifications(prev => {
            const newMods = { ...prev };
            delete newMods[existingModification[0]];
            return newMods;
          });
          setModificationCount(prev => prev - 1);
        }
      }

      if (changes.quantity !== item.quantity || 
          changes.unitPrice !== item.unitPrice || 
          changes.note !== item.note ||
          changes.name !== item.name ||
          changes.unit !== item.unit ||
          changes.excludeFromTotal !== item.excludeFromTotal ||
          changes.isAdditional !== item.isAdditional) {
        setPendingModifications(prev => ({
          ...prev,
          [changes.uniqueId]: {
            processId: editingItem.processId,
            itemId: changes.uniqueId,
            originalData: {
              name: item.name,
              quantity: item.quantity,
              unitPrice: item.unitPrice,
              unit: item.unit,
              note: item.note || '',
              excludeFromTotal: item.excludeFromTotal || false,
              isAdditional: item.isAdditional || false
            },
            modifiedData: {
              name: changes.name,
              quantity: parseInt(changes.quantity),
              unitPrice: parseInt(changes.unitPrice),
              unit: changes.unit,
              note: changes.note || '',
              excludeFromTotal: changes.excludeFromTotal,
              isAdditional: changes.isAdditional,
              totalPrice: newItemTotal
            },
            modifiedAt: new Date().toISOString()
          }
        }));
        setModificationCount(prev => prev + 1);
      }

      // UI 업데이트
      setEstimateData(prevData => ({
        ...prevData,
        processes: prevData.processes.map(p => {
          if (p.id !== editingItem.processId) return p;

          const updatedItems = p.items.map(i => {
            if (i.uniqueId !== item.uniqueId) return i;
            
            return {
              ...i,
              name: changes.name,
              quantity: parseInt(changes.quantity),
              unitPrice: parseInt(changes.unitPrice),
              unit: changes.unit,
              note: changes.note || '',
              excludeFromTotal: changes.excludeFromTotal,
              isAdditional: changes.isAdditional,
              totalPrice: newItemTotal
            };
          });

          return {
            ...p,
            items: updatedItems,
            total: updatedItems.reduce((sum, i) => sum + (i.totalPrice || 0), 0)
          };
        })
      }));

      setShowEditItemModal(false);
      setEditingItem(null);
    } catch (error) {
      console.error('항목 수정 중 오류:', error);
      alert('항목 수정 중 오류가 발생했습니다.');
    }
  };

  // 템플릿 모달 열기 핸들러
  const handleOpenTemplate = (process) => {
    setSelectedProcess(process);
    setShowTemplateModal(true);
  };

  // 템플릿 적용 핸들러
  const handleApplyTemplate = (templateData) => {
    setEstimateData(prevData => {
      const updatedProcesses = prevData.processes.map(process => {
        if (process.id === selectedProcess.id) {
          return {
            ...process,
            items: templateData.items,
            total: templateData.items.reduce((sum, item) => 
              sum + (parseInt(item.quantity) * parseInt(item.unitPrice)), 0)
          };
        }
        return process;
      });

      return {
        ...prevData,
        processes: updatedProcesses
      };
    });

    setShowTemplateModal(false);
    setSelectedProcess(null);
  };

  // 버전 선택 핸들러
  const handleVersionSelect = async (version) => {
    try {
      console.group('📂 버전 선택');
      console.log('선택된 버전:', version);
      
      if (!version) {
        console.log('❌ 버전 데이터가 없음');
        console.groupEnd();
        return;
      }

      setCurrentVersion(version);
      
      if (version.data) {
        // 공정 데이터를 가용 공정 목록과 매칭하여 업데이트
        setEstimateData(prevData => ({
          ...prevData,
          processes: (version.data.processData || []).map(process => {
            const availableProcess = availableProcesses.find(p => p.id === process.id);
            return {
              ...process,
              name: availableProcess ? availableProcess.name : process.name
            };
          }),
          notes: version.data.notes || {}
        }));
      }
      
      // 숨김 처리된 프로세스 상태 복원
      console.group('📋 숨긴 공정 상태 복원');
      if (version.hiddenProcesses) {
        console.log('복원할 숨긴 공정 데이터:', version.hiddenProcesses);
        setHiddenProcesses(version.hiddenProcesses);
        console.log('숨긴 공정 상태 복원 완료');
      } else {
        console.log('복원할 숨긴 공정 데이터 없음');
        setHiddenProcesses({});
      }
      console.groupEnd();
      
      setIsEditingMode(true);
      setShowSelectVersionModal(false);
      console.log('✅ 버전 데이터 복원 완료');
      console.groupEnd();
    } catch (error) {
      console.error('❌ 버전 선택 중 오류:', error);
      console.groupEnd();
      alert('버전을 불러오는 중 오류가 발생했습니다.');
    }
  };

  // 새 버전 생성 핸들러
  const handleNewVersion = async (versionName) => {
    try {
      if (!selectedAddress?.description) {
        throw new Error('주소 정보가 올바르지 않습니다.');
      }

      const newVersion = {
        versionName: versionName,
        createdAt: new Date().toISOString(),
        lastModified: new Date().toISOString(),
        address: selectedAddress.description,
        data: {
          processData: estimateData.processes,
          hiddenProcesses: hiddenProcesses,
          notes: estimateData.notes
        }
      };

      const versionsRef = collection(db, 'estimateVersions');
      const docRef = await addDoc(versionsRef, newVersion);
      
      const savedVersion = {
        id: docRef.id,
        ...newVersion
      };

      setCurrentVersion(savedVersion);
      setIsEditingMode(true);
      setShowSelectVersionModal(false);
    } catch (error) {
      console.error('새 버전 생성 중 오류:', error);
      alert(error.message || '새 버전을 생성하는 중 오류가 발생했습니다.');
    }
  };

  // 수정 시작 버전 핸들러
  const handleStartEdit = () => {
    if (!selectedAddress) {
      alert('주소를 선택해주세요.');
      return;
    }
    if (!selectedAddress.description) {
      alert('주소 정보가 올바르지 않습니다.');
      return;
    }
    setShowSelectVersionModal(true);
  };

  // 중간 저장 핸들러
  const handleInterimSave = async () => {
    try {
      console.group('🔄 중간 저장 시도');
      console.log('선택된 주소:', selectedAddress);
      console.log('현재 버전:', currentVersion);
      
      if (!selectedAddress?.description || !currentVersion) {
        console.log('❌ 주소 또는 현재 버전 정보 없음');
        console.groupEnd();
        alert('주소와 현재 버전 정보가 필요합니다.');
        return;
      }

      // 현재 프로세스 순서 수집
      const currentProcessOrder = estimateData.processes.reduce((acc, process) => {
        acc[process.id] = process.items.map(item => item.uniqueId);
        return acc;
      }, {});

      // 업데이트할 버전 데이터 생성
      const versionData = {
        ...currentVersion,
        data: {
          notes: estimateData.notes,
          processData: estimateData.processes,
          processOrder: currentProcessOrder
        },
        hiddenProcesses: hiddenProcesses || {}
      };
      
      // 저장할 데이터 로깅
      console.group('💾 저장할 버전 데이터 상세');
      console.log('전체 데이터 구조:', {
        ...versionData,
        hiddenProcesses: JSON.stringify(versionData.hiddenProcesses, null, 2)
      });
      console.log('숨긴 공정 데이터 (저장 전):', versionData.hiddenProcesses);
      console.groupEnd();

      // 중간 저장 실행
      const savedVersion = await VersionManager.saveInterimVersion(selectedAddress.description, versionData);
      
      console.group('🎉 중간 저장 완료');
      console.log('저장된 버전:', savedVersion);
      console.log('저장된 숨긴 공정 데이터:', savedVersion.hiddenProcesses);
      console.groupEnd();
      
      alert('중간 저장이 완료되었습니다.');
    } catch (error) {
      console.error('❌ 중간 저장 중 오류:', error);
      console.error('오류 상세:', {
        name: error.name,
        message: error.message,
        stack: error.stack
      });
      console.groupEnd();
      alert(`중간 저장 중 오류가 발생했습니다: ${error.message}`);
    }
  };

  // 키보드 단축키 설정
  useKeyboardShortcut({
    isEditingMode,
    currentVersion,
    onSave: handleInterimSave
  });

  // 숨김 공정 관리 버튼 렌더링
  const renderHiddenProcessButton = () => {
    if (!selectedAddress) return null;
    
    return (
      <button 
        className="me__hidden-process-button"
        onClick={() => setShowHiddenProcessModal(true)}
      >
        숨긴 공정 관리
      </button>
    );
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        if (showAddItemModal) {
          setShowAddItemModal(false);
          setSelectedProcessId(null);
          return;
        }
        if (showEditItemModal) {
          setShowEditItemModal(false);
          setEditingItem(null);
          setSelectedProcessId(null);
          return;
        }
        if (showTemplateModal) {
          setShowTemplateModal(false);
          setSelectedProcess(null);
          return;
        }
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [showAddItemModal, showEditItemModal, showTemplateModal]);

  // 컴포넌트 마운트 시 소계가 0인 공정들을 자동으로 숨김 처리
  useEffect(() => {
    const autoHideZeroTotalProcesses = () => {
      const newHiddenProcesses = { ...hiddenProcesses };
      estimateData.processes.forEach(process => {
        // 수동으로 설정된 상태가 없는 경우에만 자동 숨김 적용
        if (!newHiddenProcesses[process.id]?.type) {
          newHiddenProcesses[process.id] = {
            hidden: process.total === 0,
            type: 'auto'
          };
        }
      });
      setHiddenProcesses(newHiddenProcesses);
    };

    autoHideZeroTotalProcesses();
  }, [estimateData.processes]); // estimateData.processes가 변경될 때마다 실행

  return (
    <div className="me">
      <Helmet>
        <link rel="manifest" href="/manifests/mobile-estimate-manifest.json" />
      </Helmet>
      <div className="me__header">
        <h1 className="me__title">{estimateData.title}</h1>
        <select 
          className="me__address-select"
          value={selectedAddress?.id || ''}
          onChange={(e) => {
            const address = addresses.find(addr => addr.id === e.target.value);
            setSelectedAddress(address);
            if (!address) {
              // 주소가 선택되지 않았을 때 초기 상태로 리셋
              setEstimateData({
                title: '견적서',
                company: 'Amare design',
                businessNumber: '712.17.01551',
                address: '수성구 들안로 215',
                contact: '010.8694.4078',
                representative: '대표 권경대',
                processes: [],
                notes: {}
              });
              setHiddenProcesses({});
              setCurrentVersion(null);
              setIsEditingMode(false);
            }
          }}
        >
          <option value="">주소를 선택하세요</option>
          {addresses.map(address => (
            <option key={address.id} value={address.id}>
              {address.siteNumber}번 - {address.description}
            </option>
          ))}
        </select>
        
        <div className="me__logo">
          <img 
            src="/images/logo.png" 
            alt="Amare Design Logo" 
            style={{
              width: '40px',
              height: '40px',
              objectFit: 'cover',
              borderRadius: '50%'
            }}
          />
        </div>

        <div className="me__company">
          <p className="me__company-text">{estimateData.company}</p>
          <p className="me__company-text">사업자번호: {estimateData.businessNumber}</p>
          <p className="me__company-text">{estimateData.address}</p>
          <p className="me__company-text">{estimateData.contact}</p>
          <p className="me__company-text">{estimateData.representative}</p>
        </div>
      </div>

      {isEditingMode ? (
        <div className="me__version-controls">
          <button 
            className="me__version-button"
            onClick={handleInterimSave}
            disabled={!currentVersion}
          >
            중간 저장
          </button>
          <button 
            className="me__version-button me__version-button--cancel"
            onClick={() => {
              if (window.confirm('진행 중인 수정사항이 모두 취소됩니다. 계속하시겠습니까?')) {
                setIsEditingMode(false);
                setPendingModifications({});
                setModificationCount(0);
                loadEstimateData(selectedAddress);
              }
            }}
          >
            수정 취소
          </button>
        </div>
      ) : (
        <div className="me__version-controls">
          <button 
            className="me__version-button"
            onClick={handleStartEdit}
          >
            수정 시작
          </button>
        </div>
      )}

      <div className="me__estimate-buttons">
        <PdfButton
          selectedAddress={selectedAddress?.description}
          processData={estimateData.processes}
          notes={estimateData.notes}
          hiddenProcesses={hiddenProcesses}
          calculateTotal={calculateTotal}
          getProcessName={getProcessName}
          formatNumber={formatNumber}
          filterProcessesForPDF={filterProcessesForPDF}
          styles={styles}
          detailStyles={detailStyles}
          getAdditionalItems={getAdditionalItems}
          calculateAdditionalTotal={calculateAdditionalTotal}
        />
        <button 
          className="me__email-button"
          onClick={() => setShowEmailModal(true)}
        >
          이메일 전송
        </button>
      </div>

      {renderHiddenProcessButton()}

      <div className="me__summary">
        <h2 className="me__section-title">공정별 합계</h2>
        {filteredProcessData.map(process => (
          <div key={process.id} className="me__process-row">
            <span className="me__process-name">{process.name}</span>
            <span className="me__process-amount">
              {formatNumber(process.total)}원
            </span>
          </div>
        ))}

        <div className="me__process-total">
          <span className="me__process-total-name">총 합계 (VAT 별도)</span>
          <span className="me__process-total-amount">
            {formatNumber(calculateTotal(filteredProcessData))}원
          </span>
        </div>
        <div className="me__process-total">
          <span className="me__process-total-name">총 합계 (VAT 포함)</span>
          <span className="me__process-total-amount">
            {formatNumber(Math.round(calculateTotal(filteredProcessData) * 1.1))}원
          </span>
        </div>
      </div>

      <div className="me__detailed">
        <h2 className="me__section-title">상세 견적서</h2>
        {filteredProcessData.map(process => (
          <div key={process.id} className="me__detailed-process">
            <div className="me__detailed-header">
              <HideProcessButton
                processId={process.id}
                isHidden={hiddenProcesses[process.id]?.hidden}
                onToggle={toggleProcessVisibility}
              />
              <span className="me__detailed-process-name">{process.name}</span>
              <div className="me__detailed-buttons">
                <button 
                  className="me__detailed-button"
                  onClick={() => handleOpenTemplate(process)}
                >
                  템플릿
                </button>
                <button 
                  className="me__detailed-button"
                  onClick={() => handleAddItem(process.id)}
                >
                  항목추가
                </button>
              </div>
            </div>
            
            <div className="me__detailed-list">
              {process.items.length > 0 ? (
                process.items.map((item, index) => (
                  <div 
                    key={index} 
                    className="me__detailed-item"
                    onClick={() => handleEditItem(process, item)}
                  >
                    <div className="me__detailed-grid">
                      <div className="me__detailed-cell">
                        <span className={`me__detailed-text ${item.isAdditional ? 'me__detailed-text--additional' : ''}`}>
                          {item.name}
                        </span>
                      </div>
                      <div className="me__detailed-cell">
                        <span className={`me__detailed-text ${item.isAdditional ? 'me__detailed-text--additional' : ''}`}>
                          {item.quantity} {item.unit}
                        </span>
                      </div>
                      <div className="me__detailed-cell">
                        <span className={`me__detailed-text ${item.isAdditional ? 'me__detailed-text--additional' : ''}`}>
                          {formatNumber(item.unitPrice)}원
                        </span>
                      </div>
                      <div className="me__detailed-cell">
                        <span className={`me__detailed-text ${item.isAdditional ? 'me__detailed-text--additional' : ''}`}>
                          {formatNumber(item.excludeFromTotal ? 0 : (item.quantity * item.unitPrice))}원
                        </span>
                      </div>
                    </div>
                    {item.note && (
                      <div className="me__detailed-note">
                        <span className="me__detailed-note-label">비고:</span>
                        <span className={`me__detailed-text ${item.isAdditional ? 'me__detailed-text--additional' : ''}`}>
                          {item.note}
                        </span>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="me__detailed-empty">
                  항목이 없습니다
                </div>
              )}
            </div>
            
            <div className="me__detailed-subtotal">
              소계: {formatNumber(process.total)}원
            </div>
          </div>
        ))}

        {/* 추가 견적 요약 섹션 */}
        {(() => {
          const additionalItems = getAdditionalItems();
          if (additionalItems.length > 0) {
            const isHidden = hiddenProcesses['additional']?.hidden;
            return (
              <div className="me__detailed-process">
                <div className="me__detailed-header">
                  <HideProcessButton 
                    processId="additional"
                    isHidden={isHidden}
                    onToggle={() => toggleProcessVisibility('additional')}
                  />
                  <span className="me__detailed-process-name">추가 견적 요약</span>
                </div>
                {!isHidden && (
                  <>
                    <div className="me__detailed-list">
                      {additionalItems.map((item, index) => (
                        <div 
                          key={index} 
                          className="me__detailed-item"
                          onClick={() => handleEditItem({ id: item.processId, name: item.processName }, item)}
                        >
                          <div className="me__detailed-grid">
                            <div className="me__detailed-cell">
                              <span className="me__detailed-text">
                                {item.name}
                              </span>
                            </div>
                            <div className="me__detailed-cell">
                              <span className="me__detailed-text">
                                {item.quantity} {item.unit}
                              </span>
                            </div>
                            <div className="me__detailed-cell">
                              <span className="me__detailed-text">
                                {formatNumber(item.unitPrice)}원
                              </span>
                            </div>
                            <div className="me__detailed-cell">
                              <span className="me__detailed-text">
                                {formatNumber(item.excludeFromTotal ? 0 : (item.quantity * item.unitPrice))}원
                              </span>
                            </div>
                          </div>
                          {item.note && (
                            <div className="me__detailed-note">
                              <span className="me__detailed-note-label">비고:</span>
                              <span className="me__detailed-text">
                                {item.note}
                              </span>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="me__detailed-subtotal">
                      추가 견적 소계: {formatNumber(calculateAdditionalTotal(additionalItems))}원
                    </div>
                  </>
                )}
              </div>
            );
          }
          return null;
        })()}
      </div>

      <div className="me__total">
        <div className="me__total-row">
          <span className="me__total-label">총 합계 (VAT 별도)</span>
          <span className="me__total-value">{formatNumber(calculateTotal(filteredProcessData))}원</span>
        </div>
        <div className="me__total-row me__total-row--vat">
          <span className="me__total-label">총 합계 (VAT 포함)</span>
          <span className="me__total-value">
            {formatNumber(Math.round(calculateTotal(filteredProcessData) * 1.1))}원
          </span>
        </div>
      </div>

      {showAddItemModal && (
        <MobileAddItemModal
          processId={selectedProcessId}
          onSave={saveNewItem}
          onClose={() => {
            setShowAddItemModal(false);
            setSelectedProcessId(null);
          }}
        />
      )}

      {showEditItemModal && editingItem && (
        <EditItemModal
          item={editingItem.item}
          processId={editingItem.processId}
          onSave={handleSaveEdit}
          onClose={() => {
            setShowEditItemModal(false);
            setEditingItem(null);
          }}
        />
      )}

      {showTemplateModal && selectedProcess && (
        <AITemplateModal
          processId={selectedProcess.id}
          processName={selectedProcess.name}
          currentItems={selectedProcess.items}
          onSave={handleApplyTemplate}
          onClose={() => {
            setShowTemplateModal(false);
            setSelectedProcess(null);
          }}
        />
      )}

      {showHiddenProcessModal && (
        <HiddenProcessList 
          hiddenProcesses={hiddenProcesses}
          onToggle={toggleProcessVisibility}
          getProcessName={getProcessName}
          showModal={showHiddenProcessModal}
          onShowModal={() => setShowHiddenProcessModal(true)}
          onCloseModal={() => setShowHiddenProcessModal(false)}
          processData={estimateData.processes}
          onHiddenProcessesChange={(updatedHiddenProcesses) => {
            setHiddenProcesses(updatedHiddenProcesses);
          }}
        />
      )}

      {showSelectVersionModal && selectedAddress && selectedAddress.description && (
        <SelectVersionModal
          selectedAddress={selectedAddress.description}
          onSelect={handleVersionSelect}
          onNewVersion={handleNewVersion}
          onClose={() => {
            setShowSelectVersionModal(false);
            setIsEditingMode(false);
          }}
          onSelectVersion={handleVersionSelect}
        />
      )}

      {showEmailModal && (
        <EmailPdfModal
          selectedAddress={selectedAddress?.description}
          processData={estimateData.processes}
          notes={estimateData.notes}
          hiddenProcesses={hiddenProcesses}
          onClose={() => setShowEmailModal(false)}
        />
      )}
    </div>
  );
};

export default MobileEstimate;  