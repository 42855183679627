import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc, collection, query, orderBy, getDocs } from 'firebase/firestore';
import './ContractorManagement.css';

const ContractorManagement = () => {
  const [selectedProcess, setSelectedProcess] = useState('');
  const [contractors, setContractors] = useState({});
  const [processes, setProcesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingContractor, setEditingContractor] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showProcessOrderModal, setShowProcessOrderModal] = useState(false);
  const [processOrder, setProcessOrder] = useState([]);
  const [showEmptyProcessModal, setShowEmptyProcessModal] = useState(false);
  const [newContractor, setNewContractor] = useState({
    name: '',
    phone: '',
    processName: ''
  });
  const [activeRowIndex, setActiveRowIndex] = useState(null);

  // 공정 순서 저장 함수
  const saveProcessOrder = async (newOrder) => {
    try {
      const orderRef = doc(db, 'settings', 'processOrder');
      await setDoc(orderRef, { order: newOrder });
      console.log('공정 순서 저장 완료');
    } catch (error) {
      console.error('공정 순서 저장 실패:', error);
      throw error;
    }
  };

  // 공정 순서 불러오기
  const loadProcessOrder = async () => {
    try {
      const orderRef = doc(db, 'settings', 'processOrder');
      const orderDoc = await getDoc(orderRef);
      if (orderDoc.exists()) {
        return orderDoc.data().order;
      }
      return [];
    } catch (error) {
      console.error('공정 순서 불러오기 실패:', error);
      return [];
    }
  };

  // 공정 목록 로드
  useEffect(() => {
    const loadProcesses = async () => {
      try {
        console.log('[ContractorManagement] 공정 목록 로딩 시작');
        const contractorsRef = collection(db, 'contractors');
        const contractorsSnapshot = await getDocs(contractorsRef);
        
        if (!contractorsSnapshot.empty) {
          let processesList = contractorsSnapshot.docs.map(doc => ({
            id: doc.id,
            name: doc.id
          }));

          // 저장된 순서 불러오기
          const savedOrder = await loadProcessOrder();
          
          if (savedOrder.length > 0) {
            // 저장된 순서대로 정렬
            processesList.sort((a, b) => {
              const aIndex = savedOrder.indexOf(a.id);
              const bIndex = savedOrder.indexOf(b.id);
              if (aIndex === -1) return 1;
              if (bIndex === -1) return -1;
              return aIndex - bIndex;
            });
          }

          setProcesses(processesList);
          setProcessOrder(processesList.map(p => p.id));
          setSelectedProcess('전체 보기');
        } else {
          console.log('[ContractorManagement] 공정 목록이 없습니다.');
          setProcesses([]);
          setSelectedProcess('전체 보기');
        }
      } catch (error) {
        console.error('[ContractorManagement] 공정 목록 로딩 실패:', error);
        setError('공정 목록을 불러오는데 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };

    loadProcesses();
  }, []);

  // 거래처 데이터 로드
  useEffect(() => {
    const loadContractors = async () => {
      if (!selectedProcess) return;

      try {
        console.log('[ContractorManagement] 거래처 데이터 로딩 시작');
        setLoading(true);
        
        // 항상 전체 데이터를 로드
        const contractorsData = {};
        const contractorsRef = collection(db, 'contractors');
        const contractorsSnapshot = await getDocs(contractorsRef);
        
        contractorsSnapshot.docs.forEach(doc => {
          const data = doc.data();
          if (data.contractorsJson) {
            try {
              contractorsData[doc.id] = JSON.parse(data.contractorsJson);
            } catch (error) {
              console.error(`[ContractorManagement] JSON 파싱 에러 (${doc.id}):`, error);
              contractorsData[doc.id] = [];
            }
          } else if (data.contractors) {
            // 기존 형식의 데이터를 JSON 문자열로 변환
            const jsonString = JSON.stringify(data.contractors);
            contractorsData[doc.id] = data.contractors;
            // 백그라운드에서 마이그레이션 실행
            migrateContractorData(doc.id, jsonString);
          } else {
            contractorsData[doc.id] = [];
          }
        });
        
        console.log('[ContractorManagement] 전체 거래처 데이터:', contractorsData);
        setContractors(contractorsData);
      } catch (error) {
        console.error('[ContractorManagement] 거래처 데이터 로딩 실패:', error);
        setError('거래처 데이터를 불러오는데 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };

    loadContractors();
  }, [selectedProcess]);

  // 데이터 마이그레이션 함수
  const migrateContractorData = async (processName, jsonString) => {
    try {
      const processRef = doc(db, 'contractors', processName);
      await setDoc(processRef, {
        contractorsJson: jsonString
      });
      console.log(`[ContractorManagement] 마이그레이션 완료: ${processName}`);
    } catch (error) {
      console.error(`[ContractorManagement] 마이그레이션 실패 (${processName}):`, error);
    }
  };

  // 거래처 추가
  const addContractor = async (processName, contractor) => {
    try {
      console.log('[ContractorManagement] 거래처 추가 시작:', { processName, contractor });
      const processRef = doc(db, 'contractors', processName);
      const processDoc = await getDoc(processRef);
      
      let currentContractors = [];
      if (processDoc.exists()) {
        const data = processDoc.data();
        if (data.contractorsJson) {
          currentContractors = JSON.parse(data.contractorsJson);
        } else if (data.contractors) {
          currentContractors = data.contractors;
        }
      }
      
      const updatedContractors = [...currentContractors, contractor];
      await setDoc(processRef, {
        contractorsJson: JSON.stringify(updatedContractors)
      });
      
      setContractors(prev => ({
        ...prev,
        [processName]: updatedContractors
      }));
      console.log('[ContractorManagement] 거래처 추가 완료');
    } catch (error) {
      console.error('[ContractorManagement] 거래처 추가 실패:', error);
      throw new Error('거래처 추가에 실패했습니다.');
    }
  };

  // 거래처 수정
  const updateContractor = async (processName, index, updatedContractor) => {
    try {
      console.log('[ContractorManagement] 거래처 수정 시작:', { processName, index, updatedContractor });
      const processRef = doc(db, 'contractors', processName);
      const processDoc = await getDoc(processRef);
      
      if (!processDoc.exists()) {
        throw new Error('해당 공정의 거래처 데이터가 없습니다.');
      }
      
      const data = processDoc.data();
      let currentContractors = [];
      if (data.contractorsJson) {
        currentContractors = JSON.parse(data.contractorsJson);
      } else if (data.contractors) {
        currentContractors = data.contractors;
      }
      
      currentContractors[index] = updatedContractor;
      
      await setDoc(processRef, {
        contractorsJson: JSON.stringify(currentContractors)
      });
      
      setContractors(prev => ({
        ...prev,
        [processName]: currentContractors
      }));
      console.log('[ContractorManagement] 거래처 수정 완료');
    } catch (error) {
      console.error('[ContractorManagement] 거래처 수정 실패:', error);
      throw new Error('거래처 수정에 실패했습니다.');
    }
  };

  // 거래처 삭제
  const deleteContractor = async (processName, index) => {
    try {
      console.log('[ContractorManagement] 거래처 삭제 시작:', { processName, index });
      const processRef = doc(db, 'contractors', processName);
      const processDoc = await getDoc(processRef);
      
      if (!processDoc.exists()) {
        throw new Error('해당 공정의 거래처 데이터가 없습니다.');
      }
      
      const data = processDoc.data();
      let currentContractors = [];
      if (data.contractorsJson) {
        currentContractors = JSON.parse(data.contractorsJson);
      } else if (data.contractors) {
        currentContractors = data.contractors;
      }
      
      currentContractors.splice(index, 1);
      
      await setDoc(processRef, {
        contractorsJson: JSON.stringify(currentContractors)
      });
      
      setContractors(prev => ({
        ...prev,
        [processName]: currentContractors
      }));
      console.log('[ContractorManagement] 거래처 삭제 완료');
    } catch (error) {
      console.error('[ContractorManagement] 거래처 삭제 실패:', error);
      throw new Error('거래처 삭제에 실패했습니다.');
    }
  };

  // 전체 거래처 목록 가져오기
  const getAllContractors = () => {
    // processes 배열의 순서대로 거래처를 정렬
    return processes.reduce((acc, process) => {
      const contractorList = contractors[process.name] || [];
      return [...acc, ...contractorList.map(contractor => ({
        ...contractor,
        process: process.name
      }))];
    }, []);
  };

  // 새 거래처 추가
  const handleAddContractor = async () => {
    const targetProcess = selectedProcess === '전체 보기' ? newContractor.processName : selectedProcess;
    
    if (!targetProcess || !newContractor.name || !newContractor.phone) {
      alert('모든 필드를 입력해주세요.');
      return;
    }

    try {
      const newContractorData = {
        name: newContractor.name,
        phone: newContractor.phone,
        createdAt: new Date()
      };

      await addContractor(targetProcess, newContractorData);
      
      // 입력 필드 초기화
      setNewContractor({ name: '', phone: '', processName: '' });
      alert('거래처가 추가되었습니다.');
    } catch (error) {
      alert(error.message);
    }
  };

  // 수정 모달 열기
  const handleEdit = (contractor, index) => {
    const processName = selectedProcess === '전체 보기' ? contractor.process : selectedProcess;
    const processContractors = contractors[processName] || [];
    const realIndex = selectedProcess === '전체 보기' 
      ? processContractors.findIndex(c => c.name === contractor.name && c.phone === contractor.phone)
      : index;
      
    setEditingContractor({...contractor, realIndex});
    setEditingIndex(realIndex);
    setShowEditModal(true);
  };

  // 수정 저장
  const handleSaveEdit = async () => {
    if (!editingContractor.name || !editingContractor.phone) {
      alert('모든 필드를 입력해주세요.');
      return;
    }

    try {
      const processName = selectedProcess === '전체 보기' ? editingContractor.process : selectedProcess;
      const { realIndex, ...contractorData } = editingContractor;
      await updateContractor(processName, editingIndex, contractorData);
      setShowEditModal(false);
      setEditingContractor(null);
      setEditingIndex(null);
      alert('거래처 정보가 수정되었습니다.');
    } catch (error) {
      alert(error.message);
    }
  };

  // 삭제 처리
  const handleDelete = async (contractor, index) => {
    if (!window.confirm('정말 이 거래처를 삭제하시겠습니까?')) {
      return;
    }

    try {
      const processName = selectedProcess === '전체 보기' ? contractor.process : selectedProcess;
      const processContractors = contractors[processName] || [];
      const realIndex = selectedProcess === '전체 보기'
        ? processContractors.findIndex(c => c.name === contractor.name && c.phone === contractor.phone)
        : index;

      await deleteContractor(processName, realIndex);
      alert('거래처가 삭제되었습니다.');
    } catch (error) {
      alert(error.message);
    }
  };

  // 모바일 디바이스 체크 함수
  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  // 공유할 텍스트 생성 함수
  const generateShareText = () => {
    const displayContractors = selectedProcess === '전체 보기' 
      ? getAllContractors()
      : contractors[selectedProcess] || [];

    let shareText = '[인테리어 거래처 목록]\n\n';
    
    if (selectedProcess === '전체 보기') {
      // processes 배열의 순서대로 공정별 거래처 목록 생성
      processes.forEach(process => {
        const processContractors = displayContractors.filter(
          contractor => contractor.process === process.name
        );
        
        if (processContractors.length > 0) {
          shareText += `[${process.name}]\n`;
          processContractors.forEach(contractor => {
            shareText += `${contractor.name}: ${contractor.phone}\n`;
          });
          shareText += '\n';
        }
      });
    } else {
      shareText += `[${selectedProcess}]\n`;
      displayContractors.forEach(contractor => {
        shareText += `${contractor.name}: ${contractor.phone}\n`;
      });
    }

    return shareText.trim();
  };

  // 공유 처리 함수
  const handleShare = async () => {
    const shareText = generateShareText();

    if (isMobileDevice()) {
      // 모바일 디바이스: Web Share API 사용
      if (navigator.share) {
        try {
          await navigator.share({
            title: '인테리어 거래처 목록',
            text: shareText
          });
        } catch (error) {
          if (error.name !== 'AbortError') {
            alert('공유하기가 실패했습니다.');
          }
        }
      } else {
        // Web Share API를 지원하지 않는 경우 클립보드에 복사
        handleClipboardCopy(shareText);
      }
    } else {
      // 데스크탑: 클립보드에 복사
      handleClipboardCopy(shareText);
    }
  };

  // 클립보드 복사 함수
  const handleClipboardCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('클립보드에 복사되었습니다.');
    } catch (error) {
      alert('클립보드 복사에 실패했습니다.');
    }
  };

  // 공정 순서 변경 핸들러
  const handleProcessOrderChange = async (sourceIndex, destinationIndex) => {
    const newOrder = [...processOrder];
    const [removed] = newOrder.splice(sourceIndex, 1);
    newOrder.splice(destinationIndex, 0, removed);
    setProcessOrder(newOrder);

    // processes 상태도 업데이트
    const newProcesses = [...processes];
    const [removedProcess] = newProcesses.splice(sourceIndex, 1);
    newProcesses.splice(destinationIndex, 0, removedProcess);
    setProcesses(newProcesses);

    try {
      await saveProcessOrder(newOrder);
    } catch (error) {
      alert('공정 순서 저장에 실패했습니다.');
    }
  };

  // 번호 입력 핸들러 수정
  const handleOrderNumberChange = async (index, newOrderNumber, shouldSave = false) => {
    const orderNum = parseInt(newOrderNumber);
    if (isNaN(orderNum) || orderNum < 1 || orderNum > processes.length) {
      return;
    }

    const targetIndex = orderNum - 1;
    if (index !== targetIndex && shouldSave) {
      await handleProcessOrderChange(index, targetIndex);
    }
  };

  // 키 입력 핸들러 추가
  const handleKeyPress = async (event, index, value) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      await handleOrderNumberChange(index, value, true);
    }
  };

  // 공정 순서 모달 수정
  const ProcessOrderModal = () => {
    // 순서 번호 상태 관리 추가
    const [orderNumbers, setOrderNumbers] = useState(
      processes.map((_, index) => (index + 1).toString())
    );

    // 번호 입력 핸들러 수정
    const handleNumberChange = (index, value) => {
      const newOrderNumbers = [...orderNumbers];
      newOrderNumbers[index] = value;
      setOrderNumbers(newOrderNumbers);
    };

    return (
      <div className="cm-modal">
        <div className="cm-modal__content">
          <h3 className="cm-modal__title">공정 순서 관리</h3>
          <div className="process-order-list">
            {processes.map((process, index) => (
              <div key={process.id} className="process-order-item">
                <div className="process-order-info">
                  <input
                    type="number"
                    min="1"
                    max={processes.length}
                    value={orderNumbers[index]}
                    onChange={(e) => handleNumberChange(index, e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e, index, e.target.value)}
                    className="process-order-number"
                  />
                  <span className="process-name">{process.name}</span>
                </div>
                <div className="process-order-buttons">
                  {index > 0 && (
                    <button
                      onClick={() => handleProcessOrderChange(index, index - 1)}
                      className="cm-button cm-button--move"
                    >
                      ↑
                    </button>
                  )}
                  {index < processes.length - 1 && (
                    <button
                      onClick={() => handleProcessOrderChange(index, index + 1)}
                      className="cm-button cm-button--move"
                    >
                      ↓
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="cm-modal__buttons">
            <button
              className="cm-button"
              onClick={() => setShowProcessOrderModal(false)}
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    );
  };

  // 연락처 복사 핸들러
  const handleCopyPhone = async (e, phone) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(phone);
      alert('연락처가 복사되었습니다.');
    } catch (error) {
      alert('연락처 복사에 실패했습니다.');
    }
    setActiveRowIndex(null);
  };

  // 행 클릭 핸들러
  const handleRowClick = (index) => {
    setActiveRowIndex(activeRowIndex === index ? null : index);
  };

  // 액션 버튼 클릭 핸들러
  const handleActionClick = (e, action, contractor, index) => {
    e.stopPropagation(); // 이벤트 버블링 방지
    setActiveRowIndex(null);
    
    if (action === 'edit') {
      handleEdit(contractor, index);
    } else if (action === 'delete') {
      handleDelete(contractor, index);
    }
  };

  // 미등록 공정 목록을 가져오는 함수
  const getEmptyProcesses = () => {
    // 전체 공정에서 거래처가 없는 공정 필터링
    const allContractors = getAllContractors();
    
    return processes.filter(process => {
      // 해당 공정의 거래처가 있는지 확인
      const hasContractors = allContractors.some(contractor => contractor.process === process.name);
      return !hasContractors;
    });
  };

  // 미등록 공정 선택 핸들러
  const handleEmptyProcessSelect = (processName) => {
    setSelectedProcess(processName);
    setShowEmptyProcessModal(false);
  };

  // 미등록 공정 모달 컴포넌트
  const EmptyProcessModal = () => {
    const emptyProcesses = getEmptyProcesses();

    return (
      <div className="cm-modal">
        <div className="cm-modal__content">
          <h3 className="cm-modal__title">미등록 공정 목록</h3>
          <div className="empty-process-list">
            {emptyProcesses.length > 0 ? (
              emptyProcesses.map((process) => (
                <div
                  key={process.id}
                  className="empty-process-item"
                  onClick={() => handleEmptyProcessSelect(process.name)}
                >
                  <span className="empty-process-name">{process.name}</span>
                  <button className="cm-button cm-button--small">선택</button>
                </div>
              ))
            ) : (
              <div className="empty-process-message">
                모든 공정에 거래처가 등록되어 있습니다.
              </div>
            )}
          </div>
          <div className="cm-modal__buttons">
            <button
              className="cm-button"
              onClick={() => setShowEmptyProcessModal(false)}
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return <div className="cm-loading">데이터를 불러오는 중...</div>;
  }

  if (error) {
    return <div className="cm-error">{error}</div>;
  }

  // 표시할 거래처 목록
  const displayContractors = selectedProcess === '전체 보기' 
    ? getAllContractors()
    : contractors[selectedProcess] || [];

  return (
    <div className="cm-container">
      <header className="cm-header">
        <h1 className="cm-header__title">거래처 관리</h1>
      </header>
      
      <div className="cm-select">
        <div className="cm-select__header">
          <select 
            className="cm-select__dropdown"
            value={selectedProcess} 
            onChange={(e) => setSelectedProcess(e.target.value)}
          >
            <option value="">공정을 선택하세요</option>
            <option value="전체 보기">전체 보기</option>
            {processes.map(process => (
              <option key={process.id} value={process.name}>{process.name}</option>
            ))}
          </select>
          <button 
            className="cm-button cm-button--order"
            onClick={() => setShowProcessOrderModal(true)}
          >
            순서 관리
          </button>
          <button 
            className="cm-button cm-button--empty"
            onClick={() => setShowEmptyProcessModal(true)}
          >
            미등록 공정
          </button>
        </div>
      </div>

      {selectedProcess && (
        <div className="cm-form">
          <div className="cm-form__row">
            {selectedProcess === '전체 보기' && (
              <select
                className="cm-form__input"
                value={newContractor.processName}
                onChange={(e) => setNewContractor({...newContractor, processName: e.target.value})}
              >
                <option value="">공정 선택</option>
                {processes.map(process => (
                  <option key={process.id} value={process.name}>{process.name}</option>
                ))}
              </select>
            )}
            <input
              className="cm-form__input"
              type="text"
              placeholder="거래처명"
              value={newContractor.name}
              onChange={(e) => setNewContractor({...newContractor, name: e.target.value})}
            />
            <input
              className="cm-form__input"
              type="text"
              placeholder="전화번호"
              value={newContractor.phone}
              onChange={(e) => setNewContractor({...newContractor, phone: e.target.value})}
            />
            <button 
              className="cm-button cm-form__button"
              onClick={handleAddContractor}
            >
              추가
            </button>
            <button 
              className="cm-button cm-form__button"
              onClick={handleShare}
            >
              공유
            </button>
          </div>
        </div>
      )}

      {selectedProcess && (
        <div className="cm-table">
          <table>
            <thead>
              <tr className="cm-table__header">
                <th className="cm-table__header-cell cm-table__header-cell--no">No.</th>
                {selectedProcess === '전체 보기' && (
                  <th className="cm-table__header-cell">공정</th>
                )}
                <th className="cm-table__header-cell">거래처명</th>
                <th className="cm-table__header-cell">연락처</th>
              </tr>
            </thead>
            <tbody>
              {displayContractors.map((contractor, index) => (
                <>
                  <tr 
                    key={index} 
                    className={`cm-table__row ${activeRowIndex === index ? 'cm-table__row--active' : ''}`}
                    onClick={() => handleRowClick(index)}
                  >
                    <td className="cm-table__cell cm-table__cell--no">{index + 1}</td>
                    {selectedProcess === '전체 보기' && (
                      <td className="cm-table__cell cm-table__cell--process">{contractor.process}</td>
                    )}
                    <td className="cm-table__cell">{contractor.name}</td>
                    <td className="cm-table__cell">{contractor.phone}</td>
                  </tr>
                  {activeRowIndex === index && (
                    <tr className="cm-table__action-row">
                      <td colSpan={selectedProcess === '전체 보기' ? 4 : 3}>
                        <div className="cm-table__actions">
                          <button 
                            className="cm-table__action-button cm-table__action-button--edit"
                            onClick={(e) => handleActionClick(e, 'edit', contractor, index)}
                          >
                            수정
                          </button>
                          <button 
                            className="cm-table__action-button cm-table__action-button--delete"
                            onClick={(e) => handleActionClick(e, 'delete', contractor, index)}
                          >
                            삭제
                          </button>
                          <button 
                            className="cm-table__action-button cm-table__action-button--copy"
                            onClick={(e) => handleCopyPhone(e, contractor.phone)}
                          >
                            복사
                          </button>
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showEditModal && (
        <div className="cm-modal">
          <div className="cm-modal__content">
            <h3 className="cm-modal__title">거래처 정보 수정</h3>
            <input
              className="cm-modal__input"
              type="text"
              placeholder="거래처명"
              value={editingContractor?.name || ''}
              onChange={(e) => setEditingContractor({
                ...editingContractor,
                name: e.target.value
              })}
            />
            <input
              className="cm-modal__input"
              type="text"
              placeholder="전화번호"
              value={editingContractor?.phone || ''}
              onChange={(e) => setEditingContractor({
                ...editingContractor,
                phone: e.target.value
              })}
            />
            <div className="cm-modal__buttons">
              <button 
                className="cm-button cm-modal__button"
                onClick={handleSaveEdit}
              >
                저장
              </button>
              <button 
                className="cm-button cm-modal__button"
                onClick={() => {
                  setShowEditModal(false);
                  setEditingContractor(null);
                  setEditingIndex(null);
                }}
              >
                취소
              </button>
            </div>
          </div>
        </div>
      )}

      {showProcessOrderModal && <ProcessOrderModal />}
      {showEmptyProcessModal && <EmptyProcessModal />}
    </div>
  );
};

export default ContractorManagement; 