import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import './AISettingsModal.css';

const AISettingsModal = ({ isOpen, onClose }) => {
  const [voiceEnabled, setVoiceEnabled] = useState(true);
  const [voiceRate, setVoiceRate] = useState(1.0);
  const [voicePitch, setVoicePitch] = useState(1.0);
  const [googleTTSKey, setGoogleTTSKey] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      loadSettings();
    }
  }, [isOpen]);

  const loadSettings = async () => {
    try {
      const settingsRef = doc(db, 'settings', 'ai');
      const settingsDoc = await getDoc(settingsRef);
      
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        setVoiceEnabled(data.voiceEnabled ?? true);
        setVoiceRate(data.voiceRate ?? 1.0);
        setVoicePitch(data.voicePitch ?? 1.0);
      }
      
      const apiKeysRef = doc(db, 'config', 'apiKeys');
      const apiKeysDoc = await getDoc(apiKeysRef);
      
      if (apiKeysDoc.exists()) {
        const data = apiKeysDoc.data();
        setGoogleTTSKey(data.googleTTS || '');
      }
      
      setLoading(false);
    } catch (error) {
      console.error('AI 설정 로드 실패:', error);
      setLoading(false);
    }
  };

  const saveSettings = async () => {
    try {
      const settingsRef = doc(db, 'settings', 'ai');
      await setDoc(settingsRef, {
        voiceEnabled,
        voiceRate,
        voicePitch,
        updatedAt: new Date()
      });
      
      const apiKeysRef = doc(db, 'config', 'apiKeys');
      const apiKeysDoc = await getDoc(apiKeysRef);
      
      if (apiKeysDoc.exists()) {
        const data = apiKeysDoc.data();
        await setDoc(apiKeysRef, {
          ...data,
          googleTTS: googleTTSKey,
          updatedAt: new Date()
        });
      } else {
        await setDoc(apiKeysRef, {
          googleTTS: googleTTSKey,
          updatedAt: new Date()
        });
      }
      
      onClose();
    } catch (error) {
      console.error('AI 설정 저장 실패:', error);
      alert('설정 저장에 실패했습니다.');
    }
  };

  // 음성 테스트 함수
  const testVoice = () => {
    if (!voiceEnabled) return;
    
    // API 키가 비어 있는지 확인
    if (!googleTTSKey || googleTTSKey.trim() === '') {
      alert('Google TTS API 키가 설정되지 않았습니다. API 키를 입력해주세요.');
      return;
    }
    
    import('./VoiceService').then(module => {
      const VoiceService = module.default;
      const voiceService = new VoiceService();
      
      voiceService.updateSettings({
        voiceEnabled,
        voiceRate,
        voicePitch
      });
      
      // API 키 업데이트
      voiceService.updateApiKey(googleTTSKey);
      
      voiceService.testVoice();
    }).catch(error => {
      console.error('음성 서비스 로드 실패:', error);
    });
  };

  if (!isOpen) return null;

  return (
    <div className="ai-settings-modal-overlay" onClick={onClose}>
      <div className="ai-settings-modal" onClick={e => e.stopPropagation()}>
        <div className="ai-settings-modal-header">
          <h3>AI 설정</h3>
          <button className="close-button" onClick={onClose}>×</button>
        </div>

        <div className="ai-settings-modal-content">
          {loading ? (
            <div className="ai-settings-loading">설정을 불러오는 중...</div>
          ) : (
            <div className="ai-settings-section">
              <label className="ai-settings-option">
                <div className="ai-settings-option-text">
                  <span className="ai-settings-option-title">음성 알림 활성화</span>
                  <span className="ai-settings-option-description">
                    일정 알림을 음성으로 들을 수 있습니다.
                  </span>
                </div>
                <input
                  type="checkbox"
                  checked={voiceEnabled}
                  onChange={(e) => setVoiceEnabled(e.target.checked)}
                />
              </label>
              
              <div className="ai-settings-divider"></div>
              
              <h4 className="ai-settings-section-title">Google Cloud TTS API 설정</h4>
              <div className="ai-settings-api-key">
                <div className="ai-settings-option-text">
                  <span className="ai-settings-option-title">Google Cloud TTS API 키</span>
                  <span className="ai-settings-option-description">
                    Google Cloud Text-to-Speech API 키를 입력하세요.
                  </span>
                </div>
                <input
                  type="text"
                  className="ai-settings-api-input"
                  value={googleTTSKey}
                  onChange={(e) => setGoogleTTSKey(e.target.value)}
                  placeholder="API 키 입력"
                />
              </div>
              
              <div className="ai-settings-divider"></div>
              
              <h4 className="ai-settings-section-title">음성 설정</h4>
              
              <div className={`ai-settings-voice-options ${!voiceEnabled ? 'disabled' : ''}`}>
                <div className="ai-settings-slider-option">
                  <div className="ai-settings-slider-header">
                    <span className="ai-settings-option-title">음성 속도</span>
                    <span className="ai-settings-slider-value">{voiceRate.toFixed(1)}</span>
                  </div>
                  <input
                    type="range"
                    min="0.5"
                    max="3"
                    step="0.1"
                    value={voiceRate}
                    onChange={(e) => setVoiceRate(parseFloat(e.target.value))}
                    disabled={!voiceEnabled}
                    className="ai-settings-slider"
                  />
                </div>
                
                <div className="ai-settings-slider-option">
                  <div className="ai-settings-slider-header">
                    <span className="ai-settings-option-title">음성 높낮이</span>
                    <span className="ai-settings-slider-value">{voicePitch.toFixed(1)}</span>
                  </div>
                  <input
                    type="range"
                    min="0.5"
                    max="2"
                    step="0.1"
                    value={voicePitch}
                    onChange={(e) => setVoicePitch(parseFloat(e.target.value))}
                    disabled={!voiceEnabled}
                    className="ai-settings-slider"
                  />
                </div>
                
                <button 
                  className="ai-settings-test-button"
                  onClick={testVoice}
                  disabled={!voiceEnabled || !googleTTSKey}
                >
                  음성 테스트
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="ai-settings-modal-footer">
          <button 
            className="ai-settings-save-button"
            onClick={saveSettings}
          >
            저장
          </button>
          <button 
            className="ai-settings-cancel-button"
            onClick={onClose}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default AISettingsModal; 