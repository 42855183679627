// PDF 관련 유틸리티 함수들이 들어갈 파일 
export const filterProcessesForPDF = (processes, hiddenProcesses) => {
  return processes.filter(process => {
    // hiddenProcesses가 없거나, 해당 공정이 hiddenProcesses에 없으면 표시
    if (!hiddenProcesses || !hiddenProcesses[process.id]) {
      return true;
    }
    
    // 숨김 처리되었다가 다시 표시된 공정도 포함
    const isHidden = hiddenProcesses[process.id].hidden;
    return !isHidden;
  });
};

export const formatNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const calculateTotal = (processes) => {
  return processes.reduce((total, process) => total + process.total, 0);
};

export const getProcessName = (process) => {
  const processNames = {
    design: '디자인',
    demolition: '철거폐기물',
    window: '창호금속',
    plumbing: '배관 공사',
    carpentry: '목공사',
    door: '문공사',
    tile: '타일 공사',
    film: '필름공사',
    floor_protection: '바닥 보양 공사',
    wood_flooring: '바닥재 공사',
    floor_tile: '바닥 타일 공사',
    painting: '도장공사',
    wallpaper: '도배공사',
    bathroom: '욕실시공',
    hardware: '하드웨어, 악세서리',
    electrical: '전기공사',
    furniture: '가구공사',
    silicon: '실리콘 공사',
    ac: '에어컨공사',
    supervision_utilities: '현장감리/공과잡비',
    misc_additional: '기타잡비/별도공사',
    QOmGtilpMBNQ1Xhg2QNv: '조명 시공',  // 새로 추가된 공정
    unknown_process: '알 수 없는 공정'  // 기본값으로 사용될 매핑
  };

  // process가 객체인 경우 name 속성 사용
  if (typeof process === 'object' && process !== null) {
    // 1. 먼저 processNames에서 ID로 매핑된 이름이 있는지 확인
    if (process.id && processNames[process.id]) {
      return processNames[process.id];
    }
    // 2. name 속성이 있으면 사용
    if (process.name) {
      return process.name;
    }
    // 3. 둘 다 없으면 '알 수 없는 공정' 반환
    return '알 수 없는 공정';
  }
  
  // processId가 직접 전달된 경우를 위한 매핑
  // 1. 먼저 매핑된 이름이 있는지 확인
  if (processNames[process]) {
    return processNames[process];
  }
  
  // 2. 매핑된 이름이 없으면 ID 그대로 반환
  return process;
};