import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './VoiceService.css';

class VoiceService {
  constructor() {
    this.voiceEnabled = true;
    this.voiceRate = 1.0;
    this.voicePitch = 1.0;
    this.isSpeaking = false;
    this.currentSpeechText = '';
    this.currentSpeechPosition = 0;
    this.speechQueue = [];
    this.currentSpeechId = null;
    this.audioElement = null;
    this.apiKey = null;
    
    // 오디오 요소 생성
    this.createAudioElement();
    
    // 로컬 스토리지에서 음성 상태 복원
    this.restoreSpeechState();
    
    // 음성 설정 로드
    this.loadVoiceSettings();
    
    // API 키 로드
    this.loadApiKey();
  }
  
  // 오디오 요소 생성
  createAudioElement() {
    this.audioElement = new Audio();
    
    // 오디오 이벤트 리스너 설정
    this.audioElement.onended = () => {
      console.log('음성 재생 완료');
      this.handleSpeechEnd();
    };
    
    this.audioElement.onerror = (error) => {
      console.error('오디오 재생 오류:', error);
      this.isSpeaking = false;
    };
  }
  
  // API 키 로드
  async loadApiKey() {
    try {
      const docRef = doc(db, 'config', 'apiKeys');
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const { googleTTS } = docSnap.data();
        this.apiKey = googleTTS;
        console.log('Google TTS API 키 로드 완료');
      } else {
        console.error('API 키를 찾을 수 없습니다.');
      }
    } catch (error) {
      console.error('API 키 로드 실패:', error);
    }
  }
  
  // 모바일 기기 감지 함수
  isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
  
  // 로컬 스토리지에서 음성 상태 복원
  restoreSpeechState() {
    try {
      const savedSpeechState = localStorage.getItem('speechState');
      if (savedSpeechState) {
        const { text, position } = JSON.parse(savedSpeechState);
        if (text && position !== undefined) {
          console.log(`저장된 음성 상태 복원: 위치 ${position}/${text.length}`);
          this.currentSpeechText = text;
          this.currentSpeechPosition = position;
        }
      }
    } catch (error) {
      console.error('음성 상태 복원 실패:', error);
    }
  }
  
  // 음성 상태 저장
  saveSpeechState() {
    if (this.currentSpeechText) {
      try {
        localStorage.setItem('speechState', JSON.stringify({
          text: this.currentSpeechText,
          position: this.currentSpeechPosition
        }));
      } catch (error) {
        console.error('음성 상태 저장 실패:', error);
      }
    }
  }
  
  // 음성 설정 로드
  async loadVoiceSettings() {
    try {
      const settingsRef = doc(db, 'settings', 'ai');
      const settingsDoc = await getDoc(settingsRef);
      
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        this.voiceEnabled = data.voiceEnabled ?? true;
        this.voiceRate = data.voiceRate ?? 1.0;
        this.voicePitch = data.voicePitch ?? 1.0;
      }
      
      return {
        voiceEnabled: this.voiceEnabled,
        voiceRate: this.voiceRate,
        voicePitch: this.voicePitch
      };
    } catch (error) {
      console.error('음성 설정 로드 실패:', error);
      return {
        voiceEnabled: true,
        voiceRate: 1.0,
        voicePitch: 1.0
      };
    }
  }
  
  // 음성 중지
  stopSpeech() {
    if (this.audioElement) {
      this.audioElement.pause();
      this.audioElement.currentTime = 0;
    }
    
    this.isSpeaking = false;
    
    console.log(`음성 중지: 위치 ${this.currentSpeechPosition}/${this.currentSpeechText.length || 0}`);
    
    // 로컬 스토리지에 즉시 저장
    this.saveSpeechState();
    
    return {
      text: this.currentSpeechText,
      position: this.currentSpeechPosition
    };
  }
  
  // 음성 재생 완료 처리
  handleSpeechEnd() {
    console.log('음성 부분 완료');
    
    // 현재 위치 업데이트
    if (this.onPositionChange) {
      this.onPositionChange(this.currentSpeechPosition);
    }
    
    // 다음 부분 재생
    if (this.speechQueue.length > 0) {
      const nextText = this.speechQueue[0];
      this.speechQueue = this.speechQueue.slice(1);
      
      if (this.onQueueChange) {
        this.onQueueChange(this.speechQueue);
      }
      
      setTimeout(() => {
        this.speakFromPosition(nextText, 0, this.onSpeakingChange, this.onPositionChange, this.onQueueChange);
      }, 500);
    } else {
      this.isSpeaking = false;
      
      if (this.onSpeakingChange) {
        this.onSpeakingChange(false);
      }
    }
  }
  
  // Google Cloud TTS API를 사용하여 오디오 생성
  async synthesizeSpeech(text) {
    console.log('synthesizeSpeech 호출됨, API 키 상태:', this.apiKey ? '설정됨' : '설정되지 않음');
    
    if (!this.apiKey) {
      console.error('Google TTS API 키가 설정되지 않았습니다. AI 설정에서 API 키를 입력해주세요.');
      alert('Google TTS API 키가 설정되지 않았습니다. AI 설정에서 API 키를 입력해주세요.');
      return null;
    }

    // 텍스트가 비어있는지 확인
    if (!text || text.trim() === '') {
      console.error('텍스트가 비어있습니다.');
      return null;
    }
    
    try {
      const response = await fetch(`https://texttospeech.googleapis.com/v1/text:synthesize?key=${this.apiKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          input: {
            text: text.trim()
          },
          voice: {
            languageCode: 'ko-KR',
            name: 'ko-KR-Neural2-A',
            ssmlGender: 'FEMALE'
          },
          audioConfig: {
            audioEncoding: 'MP3',
            speakingRate: this.voiceRate || 1.0,
            pitch: this.voicePitch || 1.0,
            volumeGainDb: 0,
            effectsProfileId: ['small-bluetooth-speaker-class-device']
          }
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`API 요청 실패: ${response.status} - ${JSON.stringify(errorData)}`);
      }
      
      const data = await response.json();
      return data.audioContent;
    } catch (error) {
      console.error('음성 합성 실패:', error);
      return null;
    }
  }
  
  // 특정 위치부터 음성 시작
  async speakFromPosition(text, position, onSpeakingChange = null, onPositionChange = null, onQueueChange = null) {
    if (!this.voiceEnabled || !text) return;
    
    // 콜백 저장
    this.onSpeakingChange = onSpeakingChange;
    this.onPositionChange = onPositionChange;
    this.onQueueChange = onQueueChange;
    
    try {
      // 위치가 텍스트 범위를 벗어나면 처음부터 시작
      const startPosition = (position < 0) ? 0 : (position >= text.length ? 0 : position);
      const remainingText = text.substring(startPosition);
      
      if (remainingText.trim() === '') {
        console.log('남은 텍스트가 없어 처음부터 시작합니다.');
        this.currentSpeechPosition = 0;
        this.speakFromPosition(text, 0, onSpeakingChange, onPositionChange, onQueueChange);
        return;
      }
      
      // 기존 음성 중지
      if (this.audioElement) {
        this.audioElement.pause();
        this.audioElement.currentTime = 0;
      }
      
      // 텍스트 분할 (긴 텍스트 처리)
      const maxLength = this.isMobileDevice() ? 200 : 500;
      
      if (remainingText.length > maxLength) {
        // 문장 끝 위치 찾기
        const sentenceEnds = ['. ', '? ', '! ', '.\n', '?\n', '!\n', ', ', '.\t', '?\t', '!\t'];
        let splitPosition = maxLength;
        
        // 적절한 문장 끝 위치 찾기
        for (let i = maxLength; i >= maxLength / 2; i--) {
          const char = remainingText.substring(i - 2, i);
          if (sentenceEnds.some(end => char.includes(end))) {
            splitPosition = i;
            break;
          }
        }
        
        const firstPart = remainingText.substring(0, splitPosition).trim();
        const restPart = text.substring(startPosition + splitPosition).trim();
        
        // 나머지 부분을 대기열에 추가
        if (restPart.length > 0) {
          this.speechQueue = [restPart, ...this.speechQueue];
          if (onQueueChange) onQueueChange(this.speechQueue);
        }
        
        console.log(`텍스트 분할: 첫 부분 ${firstPart.length}자, 나머지 ${restPart.length}자`);
        
        // 첫 부분만 재생
        this.currentSpeechText = text;
        this.currentSpeechPosition = startPosition;
        
        if (onPositionChange) onPositionChange(startPosition);
        if (onSpeakingChange) onSpeakingChange(true);
        
        this.isSpeaking = true;
        
        // Google Cloud TTS로 음성 합성
        const audioContent = await this.synthesizeSpeech(firstPart);
        
        if (audioContent) {
          // base64 오디오 데이터를 재생
          const audioSrc = `data:audio/mp3;base64,${audioContent}`;
          this.audioElement.src = audioSrc;
          
          // 오디오 재생
          const playPromise = this.audioElement.play();
          
          if (playPromise !== undefined) {
            playPromise.then(() => {
              console.log(`음성 시작: ${startPosition}부터, 길이: ${firstPart.length}`);
              this.isSpeaking = true;
              if (onSpeakingChange) onSpeakingChange(true);
              
              // 재생 완료 후 위치 업데이트
              this.currentSpeechPosition = startPosition + splitPosition;
            }).catch(error => {
              console.error('오디오 재생 실패:', error);
              this.isSpeaking = false;
              if (onSpeakingChange) onSpeakingChange(false);
            });
          }
        } else {
          console.error('음성 합성 실패');
          this.isSpeaking = false;
          if (onSpeakingChange) onSpeakingChange(false);
        }
      } else {
        // 짧은 텍스트는 바로 재생
        this.currentSpeechText = text;
        this.currentSpeechPosition = startPosition;
        
        if (onPositionChange) onPositionChange(startPosition);
        if (onSpeakingChange) onSpeakingChange(true);
        
        this.isSpeaking = true;
        
        // Google Cloud TTS로 음성 합성
        const audioContent = await this.synthesizeSpeech(remainingText.trim());
        
        if (audioContent) {
          // base64 오디오 데이터를 재생
          const audioSrc = `data:audio/mp3;base64,${audioContent}`;
          this.audioElement.src = audioSrc;
          
          // 오디오 재생
          const playPromise = this.audioElement.play();
          
          if (playPromise !== undefined) {
            playPromise.then(() => {
              console.log(`음성 시작: ${startPosition}부터, 길이: ${remainingText.length}`);
              this.isSpeaking = true;
              if (onSpeakingChange) onSpeakingChange(true);
              
              // 재생 완료 후 위치 업데이트
              this.currentSpeechPosition = text.length;
            }).catch(error => {
              console.error('오디오 재생 실패:', error);
              this.isSpeaking = false;
              if (onSpeakingChange) onSpeakingChange(false);
            });
          }
        } else {
          console.error('음성 합성 실패');
          this.isSpeaking = false;
          if (onSpeakingChange) onSpeakingChange(false);
        }
      }
    } catch (error) {
      console.error('음성 재생 오류:', error);
      this.isSpeaking = false;
      if (onSpeakingChange) onSpeakingChange(false);
    }
  }
  
  // 알림 음성 재생
  speakNotification(text, onSpeakingChange = null, onPositionChange = null, onQueueChange = null) {
    if (!this.voiceEnabled || !text) return;
    
    // 기존 음성 중지
    this.stopSpeech();
    
    // 새 알림 재생
    this.speakFromPosition(text, 0, onSpeakingChange, onPositionChange, onQueueChange);
  }
  
  // 처음부터 다시 재생
  resetAndPlayFromBeginning(text, onSpeakingChange = null, onPositionChange = null, onQueueChange = null) {
    if (!this.voiceEnabled || !text) return;
    
    // 기존 음성 중지
    this.stopSpeech();
    
    // 대기열 초기화
    this.speechQueue = [];
    if (onQueueChange) onQueueChange(this.speechQueue);
    
    // 처음부터 재생
    this.speakFromPosition(text, 0, onSpeakingChange, onPositionChange, onQueueChange);
  }
  
  // 음성 테스트
  async testVoice() {
    if (!this.voiceEnabled) return;
    
    const testText = "안녕하세요, 음성 테스트입니다.";
    
    try {
      // 기존 음성 중지
      this.stopSpeech();
      
      // Google Cloud TTS로 음성 합성
      const audioContent = await this.synthesizeSpeech(testText);
      
      if (audioContent) {
        // base64 오디오 데이터를 재생
        const audioSrc = `data:audio/mp3;base64,${audioContent}`;
        this.audioElement.src = audioSrc;
        
        // 오디오 재생
        const playPromise = this.audioElement.play();
        
        if (playPromise !== undefined) {
          playPromise.then(() => {
            console.log('테스트 음성 재생 시작');
          }).catch(error => {
            console.error('테스트 음성 재생 실패:', error);
          });
        }
      } else {
        console.error('테스트 음성 합성 실패');
      }
    } catch (error) {
      console.error('테스트 음성 재생 오류:', error);
    }
  }
  
  // 설정 업데이트
  updateSettings(settings) {
    this.voiceEnabled = settings.voiceEnabled ?? this.voiceEnabled;
    this.voiceRate = settings.voiceRate ?? this.voiceRate;
    this.voicePitch = settings.voicePitch ?? this.voicePitch;
  }
  
  // API 키 업데이트
  updateApiKey(apiKey) {
    this.apiKey = apiKey;
    console.log('Google TTS API 키 업데이트 완료');
  }
  
  // 설정 저장
  async saveSettings() {
    try {
      const settingsRef = doc(db, 'settings', 'ai');
      await setDoc(settingsRef, {
        voiceEnabled: this.voiceEnabled,
        voiceRate: this.voiceRate,
        voicePitch: this.voicePitch,
        updatedAt: new Date()
      }, { merge: true });
      
      return true;
    } catch (error) {
      console.error('음성 설정 저장 실패:', error);
      return false;
    }
  }
  
  // 상태 반환
  getState() {
    return {
      voiceEnabled: this.voiceEnabled,
      voiceRate: this.voiceRate,
      voicePitch: this.voicePitch,
      isSpeaking: this.isSpeaking,
      currentSpeechText: this.currentSpeechText,
      currentSpeechPosition: this.currentSpeechPosition,
      speechQueue: [...this.speechQueue]
    };
  }
  
  // 정리
  cleanup() {
    this.stopSpeech();
    this.saveSpeechState();
    
    // 오디오 요소 정리
    if (this.audioElement) {
      this.audioElement.onended = null;
      this.audioElement.onerror = null;
      this.audioElement = null;
    }
  }
  
  // 사용 가능한 음성 목록 반환
  getVoices() {
    return [
      { name: 'ko-KR-Neural2-A', gender: 'FEMALE' },
      { name: 'ko-KR-Neural2-B', gender: 'FEMALE' },
      { name: 'ko-KR-Neural2-C', gender: 'MALE' },
      { name: 'ko-KR-Standard-A', gender: 'FEMALE' },
      { name: 'ko-KR-Standard-B', gender: 'FEMALE' },
      { name: 'ko-KR-Standard-C', gender: 'MALE' },
      { name: 'ko-KR-Standard-D', gender: 'MALE' }
    ];
  }
}

export default VoiceService; 