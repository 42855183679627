import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase';
import { 
  collection, 
  query, 
  getDocs, 
  addDoc, 
  deleteDoc, 
  doc, 
  updateDoc,
  where,
  orderBy,
  writeBatch,
  setDoc,
  getDoc,
  limit,
  serverTimestamp
} from 'firebase/firestore';
import CategoryList from './CategoryList';
import MaterialList from './MaterialList';
import MaterialForm from './MaterialForm';
import MaterialAdmin from './MaterialAdmin';
import ResultModal from './ResultModal';
import './MaterialSelection.css';

const BATHROOM_MATERIALS_COLLECTION = 'bathroomMaterialSelections';

const MaterialSelection = ({ customerNumber, isAdmin = false, bathroomType: initialBathroomType = 'outer', onBathroomChange }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [editingMaterial, setEditingMaterial] = useState(null);
  const [showMaterialForm, setShowMaterialForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [adminMode, setAdminMode] = useState(isAdmin);
  const [clickCount, setClickCount] = useState(0);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [selectionMode, setSelectionMode] = useState(false);
  const [address, setAddress] = useState('');
  const [showResultModal, setShowResultModal] = useState(false);
  const materialsRef = useRef(null);
  const [bathroomType, setBathroomType] = useState(initialBathroomType);
  const [outerBathroomMaterials, setOuterBathroomMaterials] = useState([]);
  const [innerBathroomMaterials, setInnerBathroomMaterials] = useState([]);
  const [outerBathroomEnabled, setOuterBathroomEnabled] = useState(true);
  const [innerBathroomEnabled, setInnerBathroomEnabled] = useState(true);

  const handleTitleClick = () => {
    const currentTime = new Date().getTime();
    
    if (currentTime - lastClickTime < 500) {
      setClickCount(prevCount => {
        const newCount = prevCount + 1;
        if (newCount === 3) {
          setAdminMode(true);
          return 0;
        }
        return newCount;
      });
    } else {
      setClickCount(1);
    }
    
    setLastClickTime(currentTime);
  };

  const getSelectedMaterialsText = () => {
    const selectedItems = categories
      .sort((a, b) => a.order - b.order)
      .map(category => {
        const material = materials.find(m => 
          m.categoryId === category.id && selectedMaterials.includes(m.id)
        );
        return {
          category: category.name,
          material: material ? material.name : '선택되지 않음'
        };
      });

    return selectedItems.map(item => `${item.category}: ${item.material}`).join('\n');
  };

  const handleToggleSelect = (materialId) => {
    setSelectedMaterials(prevSelected => {
      const material = materials.find(m => m.id === materialId);
      if (!material || !material.categoryId) return prevSelected;
      
      // 이미 선택된 자재인지 확인
      const isAlreadySelected = prevSelected.includes(materialId);
      
      if (isAlreadySelected) {
        // 이미 선택된 경우 아무 동작하지 않음
        return prevSelected;
      } else {
        // 같은 카테고리의 기존 선택 제거
        const otherCategoriesSelection = prevSelected.filter(id => {
          const mat = materials.find(m => m.id === id);
          return mat && mat.categoryId !== material.categoryId;
        });
        
        // 새로운 선택 추가
        const newSelection = [...otherCategoriesSelection, materialId];

        // 다음 카테고리로 자동 이동
        const currentCategoryIndex = categories.findIndex(cat => cat.id === material.categoryId);
        if (currentCategoryIndex < categories.length - 1) {
          const nextCategory = categories[currentCategoryIndex + 1];
          setTimeout(() => setSelectedCategory(nextCategory), 300);
        }

        return newSelection;
      }
    });
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    
    // 모바일에서만 스크롤 적용
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        materialsRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 100);
    }
  };

  // 카테고리와 자재 데이터 불러오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // 카테고리 데이터 불러오기
        const categoryRef = collection(db, 'BathroomMaterial', 'data', 'categories');
        const categoryQuery = query(categoryRef, orderBy('order', 'asc'));
        const categorySnapshot = await getDocs(categoryQuery);
        const categoryData = categorySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCategories(categoryData);
        
        // 첫 번째 카테고리 자동 선택
        if (categoryData.length > 0) {
          setSelectedCategory(categoryData[0]);
        }

        // 자재 데이터 불러오기
        const materialRef = collection(db, 'BathroomMaterial', 'data', 'materials');
        const materialQuery = query(materialRef);
        const materialSnapshot = await getDocs(materialQuery);
        const materialData = materialSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // 각 카테고리에 '선택안함' 자재가 없다면 추가
        for (const category of categoryData) {
          const hasNoSelection = materialData.some(m => 
            m.categoryId === category.id && m.isNoSelection
          );

          if (!hasNoSelection) {
            try {
              const noSelectionRef = await addDoc(materialRef, {
                name: '선택안함',
                model: '선택안함',
                categoryId: category.id,
                isNoSelection: true,
                vendorPrice: 0,
                consumerPrice: 0,
                createdAt: new Date()
              });

              materialData.push({
                id: noSelectionRef.id,
                name: '선택안함',
                model: '선택안함',
                categoryId: category.id,
                isNoSelection: true,
                vendorPrice: 0,
                consumerPrice: 0
              });
            } catch (err) {
              console.error('선택안함 자재 추가 오류:', err);
            }
          }
        }

        setMaterials(materialData);
      } catch (err) {
        console.error('데이터 불러오기 오류:', err);
        setError('데이터를 불러오는 중 오류가 발생했습니다.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // 카테고리 관리 핸들러
  const handleAddCategory = async (categoryData) => {
    try {
      const categoryRef = collection(db, 'BathroomMaterial', 'data', 'categories');
      
      // 현재 카테고리들 중 가장 큰 order 값을 찾습니다
      const maxOrder = categories.reduce((max, cat) => 
        cat.order > max ? cat.order : max, 0
      );
      
      const docRef = await addDoc(categoryRef, {
        name: categoryData.name,
        order: maxOrder + 1,
        createdAt: new Date()
      });
      
      const newCategory = { 
        id: docRef.id, 
        name: categoryData.name,
        order: maxOrder + 1
      };

      // 새 카테고리에 '선택안함' 자재 추가
      const materialRef = collection(db, 'BathroomMaterial', 'data', 'materials');
      const noSelectionRef = await addDoc(materialRef, {
        name: '선택안함',
        model: '선택안함',
        categoryId: newCategory.id,
        isNoSelection: true,
        vendorPrice: 0,
        consumerPrice: 0,
        createdAt: new Date()
      });

      const noSelectionMaterial = {
        id: noSelectionRef.id,
        name: '선택안함',
        model: '선택안함',
        categoryId: newCategory.id,
        isNoSelection: true,
        vendorPrice: 0,
        consumerPrice: 0
      };
      
      setCategories([...categories, newCategory]);
      setMaterials([...materials, noSelectionMaterial]);
      setSelectedCategory(newCategory);
    } catch (err) {
      console.error('카테고리 추가 오류:', err);
      alert('카테고리 추가 중 오류가 발생했습니다.');
    }
  };

  const handleEditCategory = async (categoryId, categoryData) => {
    try {
      const categoryRef = doc(db, 'BathroomMaterial', 'data', 'categories', categoryId);
      await updateDoc(categoryRef, {
        name: categoryData.name,
        updatedAt: new Date()
      });

      setCategories(categories.map(category =>
        category.id === categoryId ? { ...category, ...categoryData } : category
      ));
    } catch (err) {
      console.error('카테고리 수정 오류:', err);
      alert('카테고리 수정 중 오류가 발생했습니다.');
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    if (!window.confirm('이 카테고리를 삭제하시겠습니까? 관련된 모든 자재도 삭제됩니다.')) {
      return;
    }

    try {
      // 카테고리 삭제
      const categoryRef = doc(db, 'BathroomMaterial', 'data', 'categories', categoryId);
      await deleteDoc(categoryRef);

      // 관련된 자재 삭제
      const materialRef = collection(db, 'BathroomMaterial', 'data', 'materials');
      const materialQuery = query(materialRef, where('categoryId', '==', categoryId));
      const materialSnapshot = await getDocs(materialQuery);
      
      const deletePromises = materialSnapshot.docs.map(doc => 
        deleteDoc(doc.ref)
      );
      await Promise.all(deletePromises);

      setCategories(categories.filter(category => category.id !== categoryId));
      setMaterials(materials.filter(material => material.categoryId !== categoryId));
      
      if (selectedCategory?.id === categoryId) {
        setSelectedCategory(null);
      }
    } catch (err) {
      console.error('카테고리 삭제 오류:', err);
      alert('카테고리 삭제 중 오류가 발생했습니다.');
    }
  };

  // 자재 관리 핸들러
  const handleAddMaterial = async (materialData) => {
    try {
      const materialRef = collection(db, 'BathroomMaterial', 'data', 'materials');
      const docRef = await addDoc(materialRef, {
        ...materialData,
        createdAt: new Date()
      });

      const newMaterial = {
        id: docRef.id,
        ...materialData
      };
      setMaterials([...materials, newMaterial]);
    } catch (err) {
      console.error('자재 추가 오류:', err);
      alert('자재 추가 중 오류가 발생했습니다.');
    }
  };

  const handleEditMaterial = async (materialId, materialData) => {
    try {
      const materialRef = doc(db, 'BathroomMaterial', 'data', 'materials', materialId);
      await updateDoc(materialRef, {
        ...materialData,
        updatedAt: new Date()
      });

      setMaterials(materials.map(material =>
        material.id === materialId ? { ...material, ...materialData } : material
      ));
      setEditingMaterial(null);
    } catch (err) {
      console.error('자재 수정 오류:', err);
      alert('자재 수정 중 오류가 발생했습니다.');
    }
  };

  const handleDeleteMaterial = async (materialId) => {
    if (!window.confirm('이 자재를 삭제하시겠습니까?')) {
      return;
    }

    try {
      const materialRef = doc(db, 'BathroomMaterial', 'data', 'materials', materialId);
      await deleteDoc(materialRef);
      
      setMaterials(materials.filter(material => material.id !== materialId));
    } catch (err) {
      console.error('자재 삭제 오류:', err);
      alert('자재 삭제 중 오류가 발생했습니다.');
    }
  };

  const startEditMaterial = (material) => {
    setEditingMaterial(material);
    setShowMaterialForm(true);
  };

  const handleAddNewMaterial = () => {
    setShowMaterialForm(true);
  };

  const handleMaterialFormSubmit = async (materialData) => {
    try {
      if (editingMaterial) {
        // 수정 모드
        const materialRef = doc(db, 'BathroomMaterial', 'data', 'materials', editingMaterial.id);
        await updateDoc(materialRef, {
          ...materialData,
          updatedAt: new Date()
        });

        setMaterials(materials.map(material =>
          material.id === editingMaterial.id ? { ...material, ...materialData } : material
        ));
      } else {
        // 추가 모드
        const materialRef = collection(db, 'BathroomMaterial', 'data', 'materials');
        const docRef = await addDoc(materialRef, {
          ...materialData,
          categoryId: selectedCategory.id,
          createdAt: new Date()
        });

        const newMaterial = {
          id: docRef.id,
          ...materialData,
          categoryId: selectedCategory.id
        };
        setMaterials([...materials, newMaterial]);
      }
      setShowMaterialForm(false);
      setEditingMaterial(null);
    } catch (err) {
      console.error('자재 저장 오류:', err);
      alert('자재 저장 중 오류가 발생했습니다.');
    }
  };

  const handleMaterialFormClose = () => {
    setShowMaterialForm(false);
    setEditingMaterial(null);
  };

  const handleCloneMaterial = async (material) => {
    try {
      const clonedData = {
        ...material,
        name: `${material.name} (복사본)`,
        createdAt: new Date()
      };
      delete clonedData.id; // ID 제거 (새로운 ID가 생성되어야 함)

      const materialRef = collection(db, 'BathroomMaterial', 'data', 'materials');
      const docRef = await addDoc(materialRef, clonedData);

      const newMaterial = {
        id: docRef.id,
        ...clonedData
      };
      setMaterials([...materials, newMaterial]);
    } catch (err) {
      console.error('자재 복제 오류:', err);
      alert('자재 복제 중 오류가 발생했습니다.');
    }
  };

  const handleReorderCategories = async (newCategories) => {
    try {
      const batch = writeBatch(db);

      newCategories.forEach((category, index) => {
        const categoryRef = doc(db, 'BathroomMaterial', 'data', 'categories', category.id);
        batch.update(categoryRef, { order: index });
      });

      await batch.commit();
      setCategories(newCategories);
    } catch (error) {
      console.error('카테고리 순서 변경 오류:', error);
      alert('카테고리 순서 변경에 실패했습니다.');
    }
  };

  // 주소 정보 불러오기
  const loadAddressInfo = async () => {
    try {
      console.log('고객번호:', customerNumber);
      
      const addressesRef = collection(db, 'addresses');
      const q = query(
        addressesRef, 
        where('phoneLastFourDigits', '==', customerNumber)
      );
      
      console.log('주소 쿼리 생성됨');
      
      const querySnapshot = await getDocs(q);
      console.log('찾은 문서 수:', querySnapshot.size);

      if (!querySnapshot.empty) {
        // 가장 최근 문서를 찾기 위해 JavaScript로 정렬
        const sortedDocs = querySnapshot.docs.sort((a, b) => {
          const aData = a.data();
          const bData = b.data();
          return bData.lastModified?.toDate() - aData.lastModified?.toDate();
        });

        const addressData = sortedDocs[0].data();
        console.log('찾은 주소 데이터:', addressData);
        
        // description 필드를 주소로 사용
        const address = addressData.description || '주소 정보가 없습니다';
        console.log('설정할 주소:', address);
        
        setAddress(address);
      } else {
        console.log('주소를 찾을 수 없음');
        setAddress('주소 정보가 없습니다');
      }
    } catch (error) {
      console.error('주소 정보 로딩 중 오류:', error);
      setAddress('주소 정보를 불러오는 중 오류가 발생했습니다');
    }
  };

  // 욕실 타입 변경 시 선택된 자재 저장
  const handleBathroomTypeChange = (newType) => {
    console.log('욕실 타입 변경 시도:', {
      현재타입: bathroomType,
      새타입: newType,
      동일여부: bathroomType === newType
    });

    // 타입이 같으면 변경하지 않음
    if (bathroomType === newType) {
      console.log('이미 선택된 타입이므로 변경하지 않음');
      return;
    }

    // 공사없음 선택 시 확인 모달 표시
    if (newType.includes('no_construction')) {
      const bathroomName = newType.startsWith('outer') ? '외욕실' : '내욕실';
      const hasSelectedMaterials = newType.startsWith('outer') ? 
        outerBathroomMaterials.length > 0 : 
        innerBathroomMaterials.length > 0;

      if (hasSelectedMaterials && !window.confirm(`${bathroomName}에 선택된 자재가 있습니다.\n선택된 자재를 초기화하시겠습니까?`)) {
        return;
      }

      // 선택된 자재 초기화
      if (newType.startsWith('outer')) {
        setOuterBathroomMaterials([]);
        setOuterBathroomEnabled(false);
      } else {
        setInnerBathroomMaterials([]);
        setInnerBathroomEnabled(false);
      }
    } else {
      // 일반 욕실 선택 시 enabled 상태만 변경
      if (newType === 'outer') {
        setOuterBathroomEnabled(true);
      } else if (newType === 'inner') {
        setInnerBathroomEnabled(true);
      }
    }

    console.log('욕실 타입 변경 실행:', newType);
    setBathroomType(newType);

    // 타입 변경 후 자동 저장
    saveSelectedMaterials();

    // 부모 컴포넌트에 변경 알림
    if (onBathroomChange) {
      onBathroomChange(newType);
    }
  };

  // 컴포넌트 마운트 시 데이터 로드
  useEffect(() => {
    const loadInitialData = async () => {
      if (customerNumber) {
        setLoading(true);
        try {
          // 선택된 자재 정보 먼저 로드
          await loadSelectedMaterials();

          // 카테고리와 자재 데이터 로드
          const categoryRef = collection(db, 'BathroomMaterial', 'data', 'categories');
          const categoryQuery = query(categoryRef, orderBy('order', 'asc'));
          const categorySnapshot = await getDocs(categoryQuery);
          const categoryData = categorySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setCategories(categoryData);
          
          // 첫 번째 카테고리 자동 선택
          if (categoryData.length > 0) {
            setSelectedCategory(categoryData[0]);
          }

          // 자재 데이터 불러오기
          const materialRef = collection(db, 'BathroomMaterial', 'data', 'materials');
          const materialQuery = query(materialRef);
          const materialSnapshot = await getDocs(materialQuery);
          const materialData = materialSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setMaterials(materialData);

          // 주소 정보 로드
          await loadAddressInfo();
        } catch (error) {
          console.error('데이터 로딩 오류:', error);
          setError('데이터를 불러오는 중 오류가 발생했습니다.');
        } finally {
          setLoading(false);
        }
      }
    };

    loadInitialData();
  }, [customerNumber]);

  // 선택된 자재가 변경될 때마다 저장 (디바운스 적용)
  useEffect(() => {
    const saveData = async () => {
      if (!customerNumber || loading || materials.length === 0) return;

      try {
        const selectedMaterialsRef = doc(db, BATHROOM_MATERIALS_COLLECTION, customerNumber);
        const data = {
          outer: {
            materials: outerBathroomMaterials,
            enabled: outerBathroomEnabled,
            type: bathroomType === 'outer_no_construction' ? 'noConstruction' : 'construction'
          },
          inner: {
            materials: innerBathroomMaterials,
            enabled: innerBathroomEnabled,
            type: bathroomType === 'inner_no_construction' ? 'noConstruction' : 'construction'
          },
          address: address || '',
          updatedAt: serverTimestamp()
        };
        console.log('저장할 데이터:', data);
        await setDoc(selectedMaterialsRef, data);
        console.log('저장 완료');
      } catch (error) {
        console.error('자재 선택 저장 오류:', error);
        setError('자재 선택을 저장하는 중 오류가 발생했습니다.');
      }
    };

    // 디바운스 적용
    const timeoutId = setTimeout(saveData, 500);
    return () => clearTimeout(timeoutId);
  }, [customerNumber, loading, materials, outerBathroomMaterials, innerBathroomMaterials, outerBathroomEnabled, innerBathroomEnabled, bathroomType, address]);

  // 선택된 자재 불러오기
  const loadSelectedMaterials = async () => {
    if (!customerNumber) return;

    try {
      const selectedMaterialsRef = doc(db, BATHROOM_MATERIALS_COLLECTION, customerNumber);
      const selectedMaterialsDoc = await getDoc(selectedMaterialsRef);

      console.log('로드된 데이터:', selectedMaterialsDoc.data());

      if (selectedMaterialsDoc.exists()) {
        const data = selectedMaterialsDoc.data();
        
        // 외욕실 데이터 설정
        if (data.outer) {
          setOuterBathroomMaterials(data.outer.materials || []);
          setOuterBathroomEnabled(data.outer.enabled !== false);
          if (data.outer.type === 'noConstruction') {
            setBathroomType('outer_no_construction');
          }
        }
        
        // 내욕실 데이터 설정
        if (data.inner) {
          setInnerBathroomMaterials(data.inner.materials || []);
          setInnerBathroomEnabled(data.inner.enabled !== false);
          if (data.inner.type === 'noConstruction') {
            setBathroomType('inner_no_construction');
          }
        }
        
        // 현재 선택된 욕실 타입에 따라 selectedMaterials 설정
        const currentMaterials = bathroomType.startsWith('outer') ? 
          (data.outer?.materials || []) : 
          (data.inner?.materials || []);
        setSelectedMaterials(currentMaterials);
      }
    } catch (error) {
      console.error('선택된 자재 로드 오류:', error);
      setError('선택된 자재를 불러오는 중 오류가 발생했습니다.');
    }
  };

  // 욕실 타입이 변경될 때 선택된 자재 업데이트
  useEffect(() => {
    if (!loading && materials.length > 0) {
      const currentMaterials = bathroomType.startsWith('outer') ? 
        outerBathroomMaterials : 
        innerBathroomMaterials;
      setSelectedMaterials(currentMaterials);
    }
  }, [bathroomType, materials, loading, outerBathroomMaterials, innerBathroomMaterials]);

  // 선택된 자재 저장
  const saveSelectedMaterials = async () => {
    if (!customerNumber) return;

    try {
      const selectedMaterialsRef = doc(db, BATHROOM_MATERIALS_COLLECTION, customerNumber);
      const data = {
        outer: {
          materials: outerBathroomMaterials,
          enabled: outerBathroomEnabled,
          type: bathroomType === 'outer_no_construction' ? 'noConstruction' : 'construction'
        },
        inner: {
          materials: innerBathroomMaterials,
          enabled: innerBathroomEnabled,
          type: bathroomType === 'inner_no_construction' ? 'noConstruction' : 'construction'
        },
        address: address || '',
        updatedAt: serverTimestamp()
      };
      console.log('저장할 데이터:', data);
      await setDoc(selectedMaterialsRef, data);
      console.log('저장 완료');
    } catch (error) {
      console.error('선택된 자재 저장 오류:', error);
      setError('자재 선택을 저장하는 중 오류가 발생했습니다.');
    }
  };

  // 모든 선택 초기화
  const handleResetAllSelections = async () => {
    if (!window.confirm('모든 선택된 자재를 초기화하시겠습니까?')) {
      return;
    }

    try {
      // 선택된 자재 초기화
      setSelectedMaterials([]);
      setOuterBathroomMaterials([]);
      setInnerBathroomMaterials([]);
      setOuterBathroomEnabled(true);
      setInnerBathroomEnabled(true);
      setBathroomType('outer');
      
      if (categories.length > 0) {
        setSelectedCategory(categories[0]);
      }

      // Firestore에 초기화된 상태 저장
      const selectedMaterialsRef = doc(db, BATHROOM_MATERIALS_COLLECTION, customerNumber);
      await setDoc(selectedMaterialsRef, {
        outer: {
          materials: [],
          enabled: true,
          type: 'construction'
        },
        inner: {
          materials: [],
          enabled: true,
          type: 'construction'
        },
        address: '',
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      console.error('선택 초기화 오류:', error);
      setError('선택 초기화 중 오류가 발생했습니다.');
    }
  };

  // 자재 선택 처리
  const handleMaterialSelect = (materialId) => {
    const material = materials.find(m => m.id === materialId);
    if (!material || !material.categoryId) return;

    // 현재 욕실 타입에 따라 자재 배열 업데이트
    if (bathroomType.startsWith('outer')) {
      setOuterBathroomMaterials(prev => {
        // 같은 카테고리의 기존 선택 제거
        const otherCategoriesSelection = prev.filter(id => {
          const mat = materials.find(m => m.id === id);
          return mat && mat.categoryId !== material.categoryId;
        });
        return [...otherCategoriesSelection, materialId];
      });
    } else {
      setInnerBathroomMaterials(prev => {
        // 같은 카테고리의 기존 선택 제거
        const otherCategoriesSelection = prev.filter(id => {
          const mat = materials.find(m => m.id === id);
          return mat && mat.categoryId !== material.categoryId;
        });
        return [...otherCategoriesSelection, materialId];
      });
    }

    // 선택된 자재 상태 업데이트
    setSelectedMaterials(prev => {
      // 같은 카테고리의 기존 선택 제거
      const otherCategoriesSelection = prev.filter(id => {
        const mat = materials.find(m => m.id === id);
        return mat && mat.categoryId !== material.categoryId;
      });
      const newSelection = [...otherCategoriesSelection, materialId];

      // 다음 카테고리로 자동 이동
      const currentCategoryIndex = categories.findIndex(cat => cat.id === material.categoryId);
      if (currentCategoryIndex < categories.length - 1) {
        const nextCategory = categories[currentCategoryIndex + 1];
        setTimeout(() => setSelectedCategory(nextCategory), 300);
      }

      return newSelection;
    });
  };

  if (loading) {
    return <div className="bathroom-material-selection__loading">데이터를 불러오는 중...</div>;
  }

  if (error) {
    return <div className="bathroom-material-selection__error">{error}</div>;
  }

  return (
    <div className="bathroom-material-selection">
      <div className="bathroom-material-selection__header">
        <h2 className="bathroom-material-selection__title" onClick={handleTitleClick}>
          AMARE DESIGN
        </h2>
        <p className="bathroom-material-selection__customer-number">고객번호: {customerNumber}</p>
        {address && (
          <p className="bathroom-material-selection__address">
            주소: {address}
          </p>
        )}
        <div className="bathroom-material-selection__guide-container">
          <p className="bathroom-material-selection__guide">자재의 제목을 클릭하면 선택됩니다.</p>
          <p className="bathroom-material-selection__guide">이미지를 클릭하면 확대됩니다.</p>
          <p className="bathroom-material-selection__guide">먼저 공사하는 욕실을 추가해주세요.</p>
        </div>
        <div className="bathroom-material-selection__selection-info">
          <div className="bathroom-material-selection__selection-header">
            <p>선택된 자재</p>
            <div className="bathroom-material-selection__selection-header-buttons">
              <button
                className="bathroom-material-selection__show-result-btn"
                onClick={() => setShowResultModal(true)}
              >
                선택 결과 보기
              </button>
              {adminMode && (
                <button
                  className="bathroom-material-selection__reset-btn"
                  onClick={handleResetAllSelections}
                >
                  전체 초기화
                </button>
              )}
            </div>
          </div>
          <p className="bathroom-material-selection__selected-items">{getSelectedMaterialsText()}</p>
          
          <div className="bathroom-material__bathroom-selector">
            <div className="bathroom-material__bathroom-group">
              <button
                className={`bathroom-material__bathroom-btn ${
                  bathroomType === 'outer' ? 'bathroom-material__bathroom-btn--active' : ''
                }`}
                onClick={() => handleBathroomTypeChange('outer')}
              >
                외욕실
              </button>
              <button
                className={`bathroom-material__bathroom-btn bathroom-material__bathroom-btn--no-construction ${
                  bathroomType === 'outer_no_construction' ? 'bathroom-material__bathroom-btn--active' : ''
                }`}
                onClick={() => handleBathroomTypeChange('outer_no_construction')}
              >
                외욕실 공사없음
              </button>
              <button
                className={`bathroom-material__bathroom-btn ${
                  bathroomType === 'inner' ? 'bathroom-material__bathroom-btn--active' : ''
                }`}
                onClick={() => handleBathroomTypeChange('inner')}
              >
                내욕실
              </button>
              <button
                className={`bathroom-material__bathroom-btn bathroom-material__bathroom-btn--no-construction ${
                  bathroomType === 'inner_no_construction' ? 'bathroom-material__bathroom-btn--active' : ''
                }`}
                onClick={() => handleBathroomTypeChange('inner_no_construction')}
              >
                내욕실 공사없음
              </button>
            </div>
          </div>
          {((bathroomType === 'outer' && outerBathroomEnabled) || (bathroomType === 'inner' && innerBathroomEnabled)) && (
            <div className="bathroom-material__current-bathroom">
              현재 {bathroomType.startsWith('outer') ? '외욕실' : '내욕실'} 자재 선택 중
            </div>
          )}
        </div>
      </div>
      
      <div className="bathroom-material-selection__content">
        <div className="bathroom-material-selection__categories">
          <CategoryList
            categories={categories}
            onSelectCategory={handleCategorySelect}
            selectedCategory={selectedCategory}
            isAdmin={adminMode}
            onAdd={handleAddCategory}
            onEdit={handleEditCategory}
            onDelete={handleDeleteCategory}
            onReorder={handleReorderCategories}
          />
        </div>

        <div className="bathroom-material-selection__materials-section" ref={materialsRef}>
          {adminMode && selectedCategory && (
            <button 
              className="bathroom-material-selection__add-material-btn"
              onClick={handleAddNewMaterial}
            >
              + 새 자재 추가
            </button>
          )}
          <MaterialList
            displayMaterials={selectedCategory ? materials.filter(m => m.categoryId === selectedCategory.id) : []}
            allMaterials={materials}
            isAdmin={adminMode}
            onEdit={startEditMaterial}
            onDelete={handleDeleteMaterial}
            onClone={handleCloneMaterial}
            selectedMaterials={selectedMaterials}
            onToggleSelect={handleMaterialSelect}
            categories={categories}
            onShowResults={() => setShowResultModal(true)}
            onResetSelection={handleResetAllSelections}
          />
        </div>
      </div>

      {showMaterialForm && (
        <MaterialForm
          categoryId={selectedCategory?.id}
          onClose={handleMaterialFormClose}
          onSubmit={handleMaterialFormSubmit}
          initialData={editingMaterial}
        />
      )}

      {showResultModal && (
        <ResultModal
          selectedMaterials={selectedMaterials}
          outerBathroomMaterials={outerBathroomMaterials}
          innerBathroomMaterials={innerBathroomMaterials}
          allMaterials={materials}
          categories={categories}
          onClose={() => setShowResultModal(false)}
          address={address}
        />
      )}
    </div>
  );
};

export default MaterialSelection; 