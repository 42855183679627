import React, { useState, useEffect } from 'react';
import {
  addMaterialType,
  deleteMaterialType,
  subscribeToMaterialTypes,
  addMaterialName,
  deleteMaterialName,
  subscribeToMaterialNames
} from './workOrderMaterialsService';
import './MaterialManageModal.css';

const AccordionSection = ({ title, isActive, onToggle, children }) => (
  <div className={`material-manager-section ${isActive ? 'active' : ''}`}>
    <div className="material-manager-section-header" onClick={onToggle}>
      <span className="material-manager-section-title">{title}</span>
      <span className="material-manager-section-arrow">▼</span>
    </div>
    <div className="material-manager-section-content">
      <div className="material-manager-section-body">
        {children}
      </div>
    </div>
  </div>
);

const MaterialManageModal = ({ onClose }) => {
  const [materialTypes, setMaterialTypes] = useState([]);
  const [materialNames, setMaterialNames] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [newType, setNewType] = useState('');
  const [newName, setNewName] = useState('');
  const [activeSection, setActiveSection] = useState('type');
  const [selectedItems, setSelectedItems] = useState({
    type: null,
    name: null
  });

  useEffect(() => {
    const unsubscribeMaterialTypes = subscribeToMaterialTypes((data) => {
      setMaterialTypes(data.sort((a, b) => a.name.localeCompare(b.name)));
    });

    return () => {
      unsubscribeMaterialTypes();
    };
  }, []);

  useEffect(() => {
    if (!selectedType) {
      setMaterialNames([]);
      return;
    }

    const unsubscribe = subscribeToMaterialNames(selectedType.id, (data) => {
      setMaterialNames(data.sort((a, b) => a.name.localeCompare(b.name)));
    });

    return () => unsubscribe();
  }, [selectedType]);

  const handleAddMaterialType = async (e) => {
    e.preventDefault();
    if (!newType.trim()) return;

    try {
      await addMaterialType(newType.trim());
      setNewType('');
    } catch (error) {
      alert('마감재 종류 추가 중 오류가 발생했습니다.');
    }
  };

  const handleAddMaterialName = async (e) => {
    e.preventDefault();
    if (!newName.trim() || !selectedType) return;

    try {
      await addMaterialName(newName.trim(), selectedType.id);
      setNewName('');
    } catch (error) {
      alert('자재 이름 추가 중 오류가 발생했습니다.');
    }
  };

  const handleDeleteItem = async (type, id, e) => {
    e.stopPropagation();
    
    try {
      switch (type) {
        case 'type':
          if (window.confirm('이 마감재 종류를 삭제하면 연결된 모든 자재 이름도 함께 삭제됩니다. 계속하시겠습니까?')) {
            await deleteMaterialType(id);
            if (selectedType?.id === id) {
              setSelectedType(null);
              setSelectedItems(prev => ({ ...prev, type: null, name: null }));
            }
          }
          break;
        case 'name':
          await deleteMaterialName(id);
          if (selectedItems.name === id) {
            setSelectedItems(prev => ({ ...prev, name: null }));
          }
          break;
      }
    } catch (error) {
      console.error('삭제 중 오류 발생:', error);
      alert('삭제 중 오류가 발생했습니다.');
    }
  };

  const renderItemList = (items, type) => (
    <div className="material-manager-list">
      {items.map(item => (
        <div 
          key={item.id} 
          className={`material-manager-item ${selectedItems[type] === item.id ? 'selected' : ''}`}
          onClick={() => {
            setSelectedItems(prev => ({ ...prev, [type]: item.id }));
            if (type === 'type') setSelectedType(item);
          }}
        >
          <span className="material-manager-item-name">{item.name}</span>
          <button
            type="button"
            className="material-manager-item-delete"
            onClick={(e) => handleDeleteItem(type, item.id, e)}
          >
            ×
          </button>
        </div>
      ))}
    </div>
  );

  const renderAddForm = (value, onChange, onSubmit, placeholder) => (
    <form onSubmit={onSubmit} className="material-manager-form">
      <input
        type="text"
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        className="material-manager-input"
      />
      <button
        type="submit"
        className="material-manager-button"
        disabled={!value.trim()}
      >
        추가
      </button>
    </form>
  );

  return (
    <div className="material-manager-overlay" onClick={onClose}>
      <div className="material-manager-container" onClick={e => e.stopPropagation()}>
        <div className="material-manager-header">
          <h2 className="material-manager-title">자재 관리</h2>
          <button className="material-manager-close" onClick={onClose}>×</button>
        </div>

        <div className="material-manager-content">
          <AccordionSection 
            title="마감재 종류" 
            isActive={activeSection === 'type'}
            onToggle={() => setActiveSection(activeSection === 'type' ? '' : 'type')}
          >
            {renderItemList(materialTypes, 'type')}
            {renderAddForm(
              newType,
              setNewType,
              handleAddMaterialType,
              "새 마감재 종류 입력"
            )}
          </AccordionSection>

          <AccordionSection 
            title={`자재 이름${selectedType ? ` - ${selectedType.name}` : ''}`}
            isActive={activeSection === 'name'}
            onToggle={() => setActiveSection(activeSection === 'name' ? '' : 'name')}
          >
            <div className={!selectedType ? 'material-manager-section-disabled' : ''}>
              {selectedType ? (
                <>
                  {renderItemList(materialNames, 'name')}
                  {renderAddForm(
                    newName,
                    setNewName,
                    handleAddMaterialName,
                    "새 자재 이름 입력"
                  )}
                </>
              ) : (
                <div style={{ textAlign: 'center', color: '#666', padding: '8px' }}>
                  마감재 종류를 먼저 선택해주세요
                </div>
              )}
            </div>
          </AccordionSection>
        </div>
      </div>
    </div>
  );
};

export default MaterialManageModal; 