import React, { useState, useEffect } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import SavedItemsModal from './saved-items/SavedItemsModal';
import SaveItemMemoModal from './saved-items/SaveItemMemoModal';
import './EditItemModal.css';

const AddItemModal = ({ processId, onSave, onClose }) => {
  const [newItem, setNewItem] = useState({
    name: '',
    quantity: '',
    unitPrice: '',
    unit: '',
    note: '',
    isAdditional: false
  });
  const [showSavedItems, setShowSavedItems] = useState(false);
  const [showMemoModal, setShowMemoModal] = useState(false);

  const handleSave = () => {
    if (!newItem.name.trim() || 
        newItem.quantity === '' || 
        newItem.unitPrice === '') {
      alert('품목명, 수량, 단가를 모두 입력해주세요.');
      return;
    }
    
    onSave({
      ...newItem,
      uniqueId: `new_${Date.now()}`,
      quantity: parseInt(newItem.quantity) || 0,
      unitPrice: parseInt(newItem.unitPrice) || 0,
      totalPrice: (parseInt(newItem.quantity) || 0) * (parseInt(newItem.unitPrice) || 0),
      isNewItem: true,
      isAdditional: newItem.isAdditional
    });
  };

  const handleSaveItem = async () => {
    try {
      if (!newItem.name.trim() || !newItem.unitPrice) {
        alert('품목명과 단가를 입력해주세요.');
        return;
      }
      setShowMemoModal(true);
    } catch (error) {
      console.error('품목 저장 중 오류:', error);
      alert('품목 저장 중 오류가 발생했습니다.');
    }
  };

  const handleSaveWithMemo = async (itemWithMemo) => {
    try {
      console.group('💾 품목 저장');
      console.log('저장할 데이터:', {
        processId,
        name: itemWithMemo.name,
        unit: itemWithMemo.unit,
        unitPrice: parseInt(itemWithMemo.unitPrice) || 0,
        note: itemWithMemo.note,
        memo: itemWithMemo.memo,
        createdAt: new Date().toISOString()
      });

      const docRef = await addDoc(collection(db, 'savedItems'), {
        processId,
        name: itemWithMemo.name,
        unit: itemWithMemo.unit,
        unitPrice: parseInt(itemWithMemo.unitPrice) || 0,
        note: itemWithMemo.note,
        memo: itemWithMemo.memo,
        createdAt: new Date().toISOString()
      });

      console.log('✅ 저장 완료. Document ID:', docRef.id);
      console.groupEnd();

      setShowMemoModal(false);
      alert('품목이 저장되었습니다.');
    } catch (error) {
      console.error('❌ 품목 저장 중 오류:', error);
      console.groupEnd();
      alert('품목 저장 중 오류가 발생했습니다.');
    }
  };

  const handleSelectSavedItem = (item) => {
    setNewItem({
      ...newItem,
      name: item.name,
      unit: item.unit,
      unitPrice: item.unitPrice.toString(),
      note: item.note || '',
      quantity: '1',  // 기본값 1로 설정
      isAdditional: item.isAdditional || false
    });
    setShowSavedItems(false);
  };

  const getDisplayName = () => {
    if (!newItem.name) return '';
    
    return (
      <div className="item-name-display">
        <span>{newItem.name}</span>
        {newItem.isAdditional && <span className="additional-tag">(추가)</span>}
      </div>
    );
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        if (showSavedItems) {
          setShowSavedItems(false);
        } else {
          onClose();
        }
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose, showSavedItems]);

  return (
    <div className="edit-modal-overlay">
      <div className="edit-modal">
        <div className="edit-modal-content">
          <h3>품목 추가</h3>
          {newItem.name && getDisplayName()}
          <div className="edit-field">
            <label>품목명</label>
            <input
              type="text"
              value={newItem.name}
              onChange={(e) => setNewItem(prev => ({
                ...prev,
                name: e.target.value
              }))}
              placeholder="품목명 입력"
            />
          </div>
          <div className="edit-field">
            <label>수량</label>
            <input
              type="number"
              value={newItem.quantity}
              onChange={(e) => setNewItem(prev => ({
                ...prev,
                quantity: e.target.value
              }))}
              placeholder="수량 입력"
            />
          </div>
          <div className="edit-field">
            <label>단위</label>
            <input
              type="text"
              value={newItem.unit}
              onChange={(e) => setNewItem(prev => ({
                ...prev,
                unit: e.target.value
              }))}
              placeholder="단위 입력"
            />
          </div>
          <div className="edit-field">
            <label>단가</label>
            <input
              type="number"
              value={newItem.unitPrice}
              onChange={(e) => setNewItem(prev => ({
                ...prev,
                unitPrice: e.target.value
              }))}
              placeholder="단가 입력"
            />
          </div>
          <div className="edit-field">
            <label>비고</label>
            <input
              type="text"
              value={newItem.note}
              onChange={(e) => setNewItem(prev => ({
                ...prev,
                note: e.target.value
              }))}
              placeholder="비고 입력"
            />
          </div>
          <div className="checkbox-group">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={newItem.isAdditional}
                onChange={(e) => setNewItem(prev => ({
                  ...prev,
                  isAdditional: e.target.checked
                }))}
              />
              추가 품목
            </label>
          </div>
          <div className="total-price">
            <label>합계:</label>
            <span>
              {formatNumber((parseInt(newItem.quantity) || 0) * (parseInt(newItem.unitPrice) || 0))}원
            </span>
          </div>
          
          {/* 품목 관리 버튼 그룹 추가 */}
          <div className="item-management-buttons">
            <button onClick={() => setShowSavedItems(true)}>
              저장된 품목 검색
            </button>
            <button onClick={handleSaveItem}>
              현재 품목 저장
            </button>
          </div>

          <div className="edit-buttons">
            <button onClick={handleSave}>저장</button>
            <button onClick={onClose}>취소</button>
          </div>
        </div>
      </div>

      {showMemoModal && (
        <SaveItemMemoModal
          item={newItem}
          onSave={handleSaveWithMemo}
          onClose={() => setShowMemoModal(false)}
        />
      )}

      {showSavedItems && (
        <SavedItemsModal
          processId={processId}
          onSelect={handleSelectSavedItem}
          onClose={() => setShowSavedItems(false)}
        />
      )}
    </div>
  );
};

export default AddItemModal; 