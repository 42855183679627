import React, { useState } from 'react';
import './ProcessContainer.css';

const ProcessContainer = ({ 
  process, 
  config, 
  allProcesses, 
  onConfigChange, 
  onLinkChange,
  index 
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  // 현재 공정을 제외한 선택 가능한 공정 목록
  const availableProcesses = allProcesses.filter(p => p !== process);

  const handleHeaderClick = () => {
    onConfigChange(process, 'include', !config.include);
  };

  const handleCheckboxClick = (e) => {
    e.stopPropagation();
  };
  
  const toggleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };
  
  // 연결된 공정 목록 (배열로 변환)
  const linkedProcesses = config.linkedProcesses || [];
  
  // 공정 연결 처리
  const handleProcessLinkChange = (selectedProcess, isChecked) => {
    const currentLinkedProcesses = [...linkedProcesses];
    
    if (isChecked) {
      // 추가
      if (!currentLinkedProcesses.includes(selectedProcess)) {
        currentLinkedProcesses.push(selectedProcess);
      }
    } else {
      // 제거
      const index = currentLinkedProcesses.indexOf(selectedProcess);
      if (index !== -1) {
        currentLinkedProcesses.splice(index, 1);
      }
    }
    
    onConfigChange(process, 'linkedProcesses', currentLinkedProcesses);
  };

  return (
    <div className={`processcontainer-container ${config.include ? 'processcontainer-selected' : ''}`}>
      <div className="processcontainer-header">
        <div 
          className="processcontainer-title"
          onClick={handleHeaderClick}
        >
          <label className="processcontainer-label">
            {process}
          </label>
          <div 
            className="processcontainer-checkbox-group"
            onClick={handleCheckboxClick}
          >
            <input
              type="checkbox"
              id={`process-${index}-${process}`}
              checked={config.include}
              onChange={(e) => onConfigChange(process, 'include', e.target.checked)}
            />
          </div>
        </div>
        
        <div className="processcontainer-actions">
          <input
            type="number"
            className="processcontainer-days-input"
            value={config.days}
            onChange={(e) => onConfigChange(process, 'days', e.target.value)}
            placeholder="일수"
            disabled={!config.include}
          />
          <button 
            className={`processcontainer-expand-button ${isExpanded ? 'expanded' : ''}`}
            onClick={toggleExpand}
            disabled={!config.include}
          >
            {isExpanded ? '접기' : '연결'}
          </button>
        </div>
      </div>
      
      {isExpanded && config.include && (
        <div className="processcontainer-expanded-content">
          <div className="processcontainer-linked-processes">
            <div className="processcontainer-linked-header">연결할 공정 선택</div>
            <div className="processcontainer-process-list">
              {availableProcesses.map(p => (
                <label key={p} className="processcontainer-process-item">
                  <input
                    type="checkbox"
                    checked={linkedProcesses.includes(p)}
                    onChange={(e) => handleProcessLinkChange(p, e.target.checked)}
                  />
                  <span>{p}</span>
                </label>
              ))}
            </div>
            
            {linkedProcesses.length > 0 && (
              <div className="processcontainer-link-options">
                <label className="processcontainer-link-option">
                  <input
                    type="checkbox"
                    checked={config.linkToFirstDay}
                    onChange={(e) => {
                      if (e.target.checked) {
                        onConfigChange(process, 'linkToLastDay', false);
                      }
                      onConfigChange(process, 'linkToFirstDay', e.target.checked);
                    }}
                  />
                  <span>첫째날에만 연결</span>
                </label>
                <label className="processcontainer-link-option">
                  <input
                    type="checkbox"
                    checked={config.linkToLastDay}
                    onChange={(e) => {
                      if (e.target.checked) {
                        onConfigChange(process, 'linkToFirstDay', false);
                      }
                      onConfigChange(process, 'linkToLastDay', e.target.checked);
                    }}
                  />
                  <span>마지막 날짜에만 연결</span>
                </label>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProcessContainer; 