import React from 'react';
import { db } from '../../firebase';
import { collection, doc, getDoc, setDoc, updateDoc, query, where, getDocs, deleteDoc } from 'firebase/firestore';

class VersionManager {
  constructor() {
    this.collection = 'estimateVersions';
  }

  // 버전 저장
  async saveVersion(address, version) {
    try {
      console.group('💾 VersionManager - 버전 저장');
      console.log('주소:', address);
      console.log('버전 데이터:', version);
      
      if (!address || !version || !version.versionName) {
        throw new Error('필수 데이터가 누락되었습니다.');
      }

      // 문서 ID를 주소_버전명 형식으로 생성
      const docId = `${address}_${version.versionName}`;
      console.log('생성될 문서 ID:', docId);

      // hiddenProcesses 데이터 로깅
      console.group('📋 숨긴 공정 상태 저장');
      if (version.hiddenProcesses) {
        console.log('숨긴 공정 데이터:', version.hiddenProcesses);
        console.log('숨긴 공정 ID 목록:', Object.keys(version.hiddenProcesses));
        console.log('숨긴 공정 개수:', Object.keys(version.hiddenProcesses).length);
      } else {
        console.log('숨긴 공정 데이터 없음');
      }
      console.groupEnd();

      // 저장할 데이터 준비
      const versionWithId = {
        ...version,
        versionId: docId,
        lastModified: new Date().toISOString(),
        hiddenProcesses: version.hiddenProcesses || {}
      };

      // Firestore에 저장
      const docRef = doc(db, this.collection, docId);
      await setDoc(docRef, versionWithId);

      // 저장 후 데이터 확인
      const savedDoc = await getDoc(docRef);
      const savedData = savedDoc.data();

      console.log('✅ 버전 저장 완료');
      console.groupEnd();
      return savedData;
    } catch (error) {
      console.error('❌ 버전 저장 중 오류:', error);
      console.groupEnd();
      throw error;
    }
  }

  // 중간 저장 (기존 버전 업데이트)
  async saveInterimVersion(address, version) {
    try {
      console.group('🔄 VersionManager - 중간 저장');
      console.log('주소:', address);
      console.log('버전 데이터:', version);
      
      // 숨긴 공정 상태 로깅
      console.group('📋 숨긴 공정 상태 저장');
      if (version.hiddenProcesses) {
        console.log('숨긴 공정 데이터:', version.hiddenProcesses);
        console.log('숨긴 공정 ID 목록:', Object.keys(version.hiddenProcesses));
        console.log('숨긴 공정 개수:', Object.keys(version.hiddenProcesses).length);
      } else {
        console.log('숨긴 공정 데이터 없음');
      }
      console.groupEnd();

      const docId = `${address}_${version.versionName}`;
      console.log('업데이트할 문서 ID:', docId);

      // 업데이트할 데이터 준비
      const updateData = {
        ...version,
        lastModified: new Date().toISOString(),
        hiddenProcesses: version.hiddenProcesses || {}
      };

      // Firestore 문서 업데이트
      const docRef = doc(db, this.collection, docId);
      await updateDoc(docRef, updateData);

      // 저장 후 데이터 확인
      const savedDoc = await getDoc(docRef);
      const savedData = savedDoc.data();

      console.log('✅ 중간 저장 완료');
      console.groupEnd();
      return savedData;
    } catch (error) {
      console.error('❌ 중간 저장 중 오류:', error);
      console.groupEnd();
      throw error;
    }
  }

  // 버전 불러오기
  async loadVersion(address, versionName) {
    try {
      console.group('📥 VersionManager - 버전 로드');
      const docId = `${address}_${versionName}`;
      console.log('로드할 문서 ID:', docId);
      
      const docRef = doc(db, this.collection, docId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        
        // 숨긴 공정 상태 로깅
        console.group('📋 숨긴 공정 상태 로드 결과');
        if (data.hiddenProcesses) {
          console.log('숨긴 공정 데이터 존재:', true);
          console.log('데이터:', JSON.stringify(data.hiddenProcesses, null, 2));
          console.log('숨긴 공정 ID 목록:', Object.keys(data.hiddenProcesses));
          console.log('숨긴 공정 개수:', Object.keys(data.hiddenProcesses).length);
          Object.entries(data.hiddenProcesses).forEach(([processId, status]) => {
            console.log(`공정 ID ${processId}:`, {
              hidden: status.hidden,
              type: status.type
            });
          });
        } else {
          console.log('숨긴 공정 데이터 없음');
        }
        console.groupEnd();

        console.log('✅ 버전 로드 완료');
        console.groupEnd();
        return data;
      }
      
      console.log('❌ 문서가 존재하지 않음');
      console.groupEnd();
      return null;
    } catch (error) {
      console.error('❌ 버전 로드 중 오류:', error);
      console.groupEnd();
      throw error;
    }
  }

  // 주소에 해당하는 모든 버전 목록 가져오기
  async getVersionList(address) {
    try {
      if (!address) {
        throw new Error('주소 정보가 필요합니다.');
      }

      const versionsRef = collection(db, this.collection);
      const q = query(versionsRef, where('address', '==', address));
      const querySnapshot = await getDocs(q);
      
      const versions = [];
      querySnapshot.forEach((doc) => {
        versions.push(doc.data());
      });
      
      // 최신 수정순으로 정렬
      return versions.sort((a, b) => 
        new Date(b.lastModified) - new Date(a.lastModified)
      );
    } catch (error) {
      console.error('버전 목록 조회 중 오류:', error);
      throw error;
    }
  }

  // 버전 삭제
  async deleteVersion(address, versionName) {
    try {
      console.group('🗑️ VersionManager - 버전 삭제');
      console.log('주소:', address);
      console.log('버전명:', versionName);
      
      if (!address || !versionName) {
        throw new Error('주소와 버전명이 필요합니다.');
      }

      // 문서 ID 생성 (address_versionName 형식)
      const docId = `${address}_${versionName}`;
      console.log('삭제할 문서 ID:', docId);

      const docRef = doc(db, this.collection, docId);
      await deleteDoc(docRef);

      console.log('✅ 버전 삭제 완료');
      console.groupEnd();
    } catch (error) {
      console.error('❌ 버전 삭제 중 오류:', error);
      console.groupEnd();
      throw error;
    }
  }

  // 버전 이름 수정
  async updateVersionName(address, oldVersionName, newVersionName) {
    try {
      // 기존 버전 데이터 로드
      const oldVersion = await this.loadVersion(address, oldVersionName);
      if (!oldVersion) {
        throw new Error('수정할 버전을 찾을 수 없습니다.');
      }

      // 새로운 버전으로 데이터 복사 및 저장
      const newVersion = {
        ...oldVersion,
        versionName: newVersionName,
        lastModified: new Date().toISOString()
      };
      await this.saveVersion(address, newVersion);

      // 기존 버전 삭제
      await this.deleteVersion(address, oldVersionName);

      return newVersion;
    } catch (error) {
      console.error('❌ 버전 이름 수정 중 오류:', error);
      throw error;
    }
  }
}

export default new VersionManager(); 