import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase';
import { collection, addDoc, getDocs, query, where, updateDoc, doc, orderBy, deleteDoc, getDoc, setDoc } from 'firebase/firestore';
import { saveAreaPrices, getAreaPrices } from '../../../firebase';
import AIPromptInput from './AIPromptInput';
import AIService from '../services/AIService';
import './AITemplateModal.css';
import { useNavigate } from 'react-router-dom';
import TemplateItemsModal from './TemplateItemsModal';

const AITemplateModal = ({ 
  processId, 
  processName, 
  currentItems, 
  onSave, 
  onClose 
}) => {
  const [templates, setTemplates] = useState([]);
  const [userInput, setUserInput] = useState({
    requirement: '',
    size: '',
    keywords: '',
    specialNotes: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [testResults, setTestResults] = useState(null);
  const [isItemsExpanded, setIsItemsExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showLineupSelect, setShowLineupSelect] = useState(false);
  const [selectedLineup, setSelectedLineup] = useState(null);
  const [lineupSelectHandlers, setLineupSelectHandlers] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const [editingNote, setEditingNote] = useState(null);
  const [editingText, setEditingText] = useState('');
  const navigate = useNavigate();
  const [deleteConfirmMap, setDeleteConfirmMap] = useState({});

  useEffect(() => {
    loadTemplates().then(templates => {
      setTemplates(templates);
    });
  }, []);

  const handleSearchInputChange = async (e) => {
    const newSearchQuery = e.target.value;
    setSearchQuery(newSearchQuery);
    
    try {
      setIsLoading(true);
      const searchTemplates = await loadTemplates();
      
      if (!newSearchQuery.trim()) {
        setTemplates(searchTemplates);
        return;
      }

      const searchResults = searchTemplates.filter(template => {
        const searchLower = newSearchQuery.toLowerCase();
        const searchNumber = searchLower.replace(/[^0-9]/g, '');

        return (
          template.processName?.toLowerCase().includes(searchLower) ||
          template.userInput?.requirement?.toLowerCase().includes(searchLower) ||
          template.userInput?.keywords?.toLowerCase().includes(searchLower) ||
          template.userInput?.specialNotes?.toLowerCase().includes(searchLower) ||
          (searchNumber && template.userInput?.size?.toString() === searchNumber) ||
          template.items?.some(item => 
            item.name?.toLowerCase().includes(searchLower)
          ) ||
          template.items?.some(item => 
            item.note?.toLowerCase().includes(searchLower)
          ) ||
          (searchNumber && template.items?.reduce((sum, item) => 
            sum + (parseInt(item.quantity) * parseInt(item.unitPrice)), 0
          ).toString().includes(searchNumber))
        );
      });

      setTemplates(searchResults);
    } catch (error) {
      console.error('검색 중 오류:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApplyTemplate = async (template) => {
    try {
      if (!template || !template.items) {
        alert('템플릿 데이터가 올바르지 않습니다.');
        return;
      }

      const isConfirmed = window.confirm(
        '템플릿을 적용하면 현재 입력된 데이터가 모두 초기화됩니다. 계속하시겠습니까?'
      );

      if (!isConfirmed) return;

      setIsLoading(true);

      const updatedItems = template.items.map(item => ({
        ...item,
        uniqueId: `${item.name}_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`
      }));

      onSave({
        processId,
        processName,
        items: updatedItems,
        templateId: template.id,
        userInput: template.userInput || {
          requirement: '',
          size: '',
          keywords: '',
          specialNotes: ''
        }
      }, 'replace');

      alert('템플릿이 적용되었습니다.');
      onClose();

    } catch (error) {
      console.error('템플릿 적용 중 오류:', error);
      alert('템플릿 적용 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  const loadTemplates = async () => {
    try {
      const templatesQuery = query(
        collection(db, 'ai-templates'),
        where('processId', '==', processId),
        orderBy('createdAt', 'desc')
      );
      const querySnapshot = await getDocs(templatesQuery);
      const loadedTemplates = [];
      querySnapshot.forEach((doc) => {
        loadedTemplates.push({ id: doc.id, ...doc.data() });
      });
      setTemplates(loadedTemplates);
      return loadedTemplates;
    } catch (error) {
      alert('템플릿을 불러오는 중 오류가 발생했습니다.');
      return [];
    }
  };

  const handleSaveTemplate = async () => {
    try {
      setIsLoading(true);
      
      const templateData = {
        processId,
        processName,
        items: currentItems,
        userInput,
        createdAt: new Date().toISOString(),
        useCount: 0
      };

      const docRef = await addDoc(collection(db, 'ai-templates'), templateData);
      
      const newTemplate = {
        id: docRef.id,
        ...templateData
      };
      
      setTemplates(prevTemplates => [newTemplate, ...prevTemplates]);
      
      onSave({ id: docRef.id, ...templateData }, 'save');
      
      alert('템플릿이 저장되었습니다.');
      
    } catch (error) {
      console.error('템플릿 저장 중 오류:', error);
      alert('저장 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (templateId) => {
    setDeleteConfirmMap(prev => ({
      ...prev,
      [templateId]: true
    }));

    // 3초 후 자동으로 확인 상태 리셋
    setTimeout(() => {
      setDeleteConfirmMap(prev => ({
        ...prev,
        [templateId]: false
      }));
    }, 3000);
  };

  const handleDeleteConfirm = async (templateId) => {
    try {
      await deleteDoc(doc(db, 'ai-templates', templateId));
      setTemplates(templates.filter(t => t.id !== templateId));
      setDeleteConfirmMap(prev => ({
        ...prev,
        [templateId]: false
      }));
    } catch (error) {
      console.error('템플릿 삭제 중 오류:', error);
      alert('템플릿 삭제 중 오류가 발생했습니다.');
    }
  };

  const convertToAreaRange = (size) => {
    if (!size) return null;
    // "55평" 형식에서 숫자만 추출
    const numericSize = parseInt(size.replace(/[^0-9]/g, ''));
    if (isNaN(numericSize)) return null;
    
    // 정확한 평수로 반환
    return `${numericSize}평대`;
  };

  const handleOneMinEstimate = async (template) => {
    try {
      setShowLineupSelect(true); // 모달 표시
      
      // 라인업 선택 대기
      const lineup = await new Promise((resolve, reject) => {
        const handleSelect = (lineupId) => {
          setShowLineupSelect(false);
          resolve(lineupId);
        };
        
        const handleClose = () => {
          setShowLineupSelect(false);
          reject(new Error('선택 취소됨'));
        };

        // 상태로 선택 핸들러 저장
        setLineupSelectHandlers({ handleSelect, handleClose });
      });

      // 2. 템플릿의 평수 정보 확인 및 변환
      const templateSize = template.userInput?.size;
      const areaRange = convertToAreaRange(templateSize);
      
      if (!areaRange) {
        alert('템플릿에 평수 정보가 없거나 올바르지 않습니다.');
        return;
      }

      // 3. 템플릿 아이템들의 총 가격 계산
      const totalPrice = template.items.reduce((sum, item) => {
        return sum + (parseInt(item.quantity) * parseInt(item.unitPrice));
      }, 0);

      // 4. process-mappings에서 매핑 정보 가져오기
      const mappingDoc = await getDoc(doc(db, 'process-mappings', 'default'));
      if (!mappingDoc.exists()) {
        alert('공정 매핑 정보가 없습니다.');
        return;
      }

      const mappings = mappingDoc.data();
      const oneMinProcess = mappings[template.processId];
      
      if (!oneMinProcess) {
        alert('이 공정에 대한 1분견적 이 없습니다.');
        return;
      }

      // 5. lineupPrices 문서 가져오기
      const lineupPricesDoc = await getDoc(doc(db, 'lineupPrices', 'default'));
      const lineupPrices = lineupPricesDoc.exists() ? lineupPricesDoc.data() : {};

      // 6. 선택된 라인업의 가격 데이터 업데이트
      const updatedPrices = {
        ...lineupPrices,
        [lineup]: {
          ...lineupPrices[lineup],
          [areaRange]: {
            ...lineupPrices[lineup]?.[areaRange],
            [oneMinProcess]: totalPrice.toString()
          }
        }
      };

      // 7. Firestore에 저장
      await setDoc(doc(db, 'lineupPrices', 'default'), updatedPrices);

      const lineupNames = {
        'refresh': '리프레쉬',
        'harmony': '하모니',
        'collection': '컬렉션'
      };

      alert(`${lineupNames[lineup]} 라인업 ${areaRange}의 ${oneMinProcess} 공정 가격이 ${totalPrice.toLocaleString()}원으로 업데이트되었습니다.`);

    } catch (error) {
      if (error.message !== '선택 취소됨') {
        console.error('1분견적 적용 중 오류:', error);
        alert('1분견적 적용 중 오류가 발생했습니다.');
      }
    }
  };

  const handleUpdateNote = async (templateId, field, newValue) => {
    try {
      const templateRef = doc(db, 'ai-templates', templateId);
      await updateDoc(templateRef, {
        [`userInput.${field}`]: newValue
      });

      // 로컬 상태 업데이트
      setTemplates(templates.map(template => 
        template.id === templateId 
          ? { 
              ...template, 
              userInput: { 
                ...template.userInput, 
                [field]: newValue 
              } 
            } 
          : template
      ));

      setEditingNote(null);
      setEditingText('');
    } catch (error) {
      console.error(`${field} 업데이트 중 오류:`, error);
      alert(`${field} 업데이트 중 오류가 발생했습니다.`);
    }
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        // 이벤트 전파 중지
        event.stopPropagation();
        
        // 하위 모달이 열려있는 경우 처리
        if (showLineupSelect) {
          setShowLineupSelect(false);
          return;
        }
        if (selectedItems) {
          setSelectedItems(null);
          return;
        }
        if (editingNote) {
          setEditingNote(null);
          setEditingText('');
          return;
        }
        if (showDeleteConfirm) {
          setShowDeleteConfirm(false);
          setSelectedTemplate(null);
          return;
        }
        
        // 모든 하위 모달이 닫혀있는 경우 템플릿 모달 닫기
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscKey, true);  // 캡처 단계에서 이벤트 처리
    return () => {
      window.removeEventListener('keydown', handleEscKey, true);
    };
  }, [onClose, showLineupSelect, selectedItems, editingNote, showDeleteConfirm]);

  return (
    <div className="ai-template-modal-overlay">
      <div className="ai-template-modal">
        <div className="modal-header">
          <h3>{processName} 가격 템플릿</h3>
        </div>

        <div className="modal-content">
          {showDeleteConfirm && (
            <div className="delete-confirm">
              <div className="delete-confirm-content">
                <p>정말 삭제하시겠습니까?</p>
                <div className="delete-confirm-buttons">
                  <button 
                    className="cancel-button"
                    onClick={() => {
                      setShowDeleteConfirm(false);
                      setSelectedTemplate(null);
                    }}
                  >
                    취소
                  </button>
                  <button 
                    className="confirm-button"
                    onClick={handleDeleteConfirm}
                  >
                    삭제
                  </button>
                </div>
              </div>
            </div>
          )}
          
          <div className="current-items-section">
            <div className="section-header" onClick={() => setIsItemsExpanded(!isItemsExpanded)}>
              <h4 className="section-title">현재 품목 목록</h4>
              <button className="toggle-button">
                {isItemsExpanded ? '접기 ▼' : '펼치기 ▶'}
              </button>
            </div>
            
            {isItemsExpanded && (
              <div className="current-items-list">
                <div className="items-header">
                  <span className="col-name">품목명</span>
                  <span className="col-quantity">수량</span>
                  <span className="col-price">단가</span>
                </div>
                {currentItems.map((item, index) => (
                  <div key={index} className="current-item-row">
                    <span className="col-name">{item.name}</span>
                    <span className="col-quantity">{item.quantity} {item.unit}</span>
                    <span className="col-price">{item.unitPrice.toLocaleString()}원</span>
                  </div>
                ))}
                <div className="items-footer">
                  <div className="footer-left">
                    <span>총 {currentItems.length}개 품목</span>
                  </div>
                  <div className="footer-right">
                    <span>합계: {currentItems.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0).toLocaleString()}원</span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="ai-input-section">
            <div className="section-header">
              <h4 className="section-title">템플릿 검색</h4>
            </div>

            <div className="prompt-search">
              <div className="prompt-search-container">
                <input
                  type="text"
                  placeholder="키워드로 검색 (예: 30평, 200만원, 도배)"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  className="prompt-search-input"
                />
                {isLoading && <span className="searching-indicator">검색 중...</span>}
              </div>
            </div>

            <AIPromptInput
              value={userInput}
              onChange={setUserInput}
              isLoading={isLoading}
            />
          </div>

          {templates.length > 0 && (
            <div className="ai-template-section">
              <h4 className="section-title">템플릿 추천</h4>
              <div className="ai-template-list">
                {templates.map((template, index) => (
                  <div key={index} className="ai-template-preview">
                    <div className="ai-template-header">
                      <span className="ai-template-name">{template.processName}</span>
                      <span className="ai-template-date">
                        {new Date(template.createdAt).toLocaleDateString()}
                      </span>
                    </div>
                    <div className="ai-template-details">
                      <div className="ai-template-info-row">
                        <span className="info-label">특이사항:</span>
                        {editingNote === `${template.id}_specialNotes` ? (
                          <div className="edit-note-container">
                            <textarea
                              className="edit-note-input"
                              value={editingText}
                              onChange={(e) => setEditingText(e.target.value)}
                              autoFocus
                              onBlur={() => {
                                if (editingText.trim() !== template.userInput.specialNotes) {
                                  handleUpdateNote(template.id, 'specialNotes', editingText);
                                }
                                setEditingNote(null);
                                setEditingText('');
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                  e.preventDefault();
                                  handleUpdateNote(template.id, 'specialNotes', editingText);
                                }
                                if (e.key === 'Escape') {
                                  setEditingNote(null);
                                  setEditingText('');
                                }
                              }}
                            />
                            <div className="edit-note-hint">
                              Enter: 저장, Esc: 취소
                            </div>
                          </div>
                        ) : (
                          <span 
                            className="info-value editable"
                            onDoubleClick={() => {
                              setEditingNote(`${template.id}_specialNotes`);
                              setEditingText(template.userInput.specialNotes || '');
                            }}
                          >
                            {template.userInput.specialNotes || '(더블클릭하여 입력)'}
                          </span>
                        )}
                      </div>
                      <div className="ai-template-info-row">
                        <span className="info-label">요구사항:</span>
                        {editingNote === `${template.id}_requirement` ? (
                          <div className="edit-note-container">
                            <textarea
                              className="edit-note-input"
                              value={editingText}
                              onChange={(e) => setEditingText(e.target.value)}
                              autoFocus
                              onBlur={() => {
                                if (editingText.trim() !== template.userInput.requirement) {
                                  handleUpdateNote(template.id, 'requirement', editingText);
                                }
                                setEditingNote(null);
                                setEditingText('');
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                  e.preventDefault();
                                  handleUpdateNote(template.id, 'requirement', editingText);
                                }
                                if (e.key === 'Escape') {
                                  setEditingNote(null);
                                  setEditingText('');
                                }
                              }}
                            />
                            <div className="edit-note-hint">
                              Enter: 저장, Esc: 취소
                            </div>
                          </div>
                        ) : (
                          <span 
                            className="info-value editable"
                            onDoubleClick={() => {
                              setEditingNote(`${template.id}_requirement`);
                              setEditingText(template.userInput.requirement || '');
                            }}
                          >
                            {template.userInput.requirement || '(더블클릭하여 입력)'}
                          </span>
                        )}
                      </div>
                      <div className="ai-template-info-row">
                        <span className="info-label">평수:</span>
                        {editingNote === `${template.id}_size` ? (
                          <div className="edit-note-container">
                            <input
                              type="text"
                              className="edit-note-input"
                              value={editingText}
                              onChange={(e) => {
                                const numericValue = e.target.value.replace(/[^0-9]/g, '');
                                if (numericValue === '' || parseInt(numericValue) <= 100) {
                                  setEditingText(numericValue);
                                }
                              }}
                              autoFocus
                              onBlur={() => {
                                if (editingText.trim() !== template.userInput.size) {
                                  handleUpdateNote(template.id, 'size', editingText);
                                }
                                setEditingNote(null);
                                setEditingText('');
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  handleUpdateNote(template.id, 'size', editingText);
                                }
                                if (e.key === 'Escape') {
                                  setEditingNote(null);
                                  setEditingText('');
                                }
                              }}
                            />
                            <div className="edit-note-hint">
                              Enter: 저장, Esc: 취소
                            </div>
                          </div>
                        ) : (
                          <span 
                            className="info-value editable"
                            onDoubleClick={() => {
                              setEditingNote(`${template.id}_size`);
                              setEditingText(template.userInput.size || '');
                            }}
                          >
                            {template.userInput.size ? `${template.userInput.size}평` : '(더블클릭하여 입력)'}
                          </span>
                        )}
                      </div>
                      <div className="ai-template-info-row total-price">
                        <span className="info-label">총 공사금액:</span>
                        <span className="info-value price">
                          {template.items?.reduce((sum, item) => 
                            sum + (parseInt(item.quantity) * parseInt(item.unitPrice)), 0
                          ).toLocaleString()}원
                        </span>
                      </div>
                      {template.userInput.keywords && (
                        <div className="ai-template-keywords">
                          {template.userInput.keywords.split(',').map((keyword, idx) => (
                            <span key={idx} className="keyword-tag">
                              {keyword.trim()}
                            </span>
                          ))}
                        </div>
                      )}
                      {template.items && (
                        <div 
                          className="ai-template-items" 
                          onClick={() => setSelectedItems(template.items)}
                        >
                          <span className="info-label">포함 품목:</span>
                          <div className="items-tags">
                            {template.items.slice(0, 3).map((item, idx) => (
                              <span key={idx} className="item-tag">
                                {item.name}
                              </span>
                            ))}
                            {template.items.length > 3 && (
                              <span className="item-tag more">
                                +{template.items.length - 3}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ai-template-footer">
                      <span className="ai-template-use-count">
                        사용 {template.useCount || 0}회
                      </span>
                      <div className="ai-template-buttons">
                        <button 
                          className="ai-template-apply-button"
                          onClick={() => handleApplyTemplate(template)}
                        >
                          적용
                        </button>
                        <button 
                          className="ai-template-one-min-button"
                          onClick={() => handleOneMinEstimate(template)}
                        >
                          1분견적
                        </button>
                        {deleteConfirmMap[template.id] ? (
                          <button 
                            className="ai-template-delete-button confirm"
                            onClick={() => handleDeleteConfirm(template.id)}
                          >
                            확인
                          </button>
                        ) : (
                          <button 
                            className="ai-template-delete-button"
                            onClick={() => handleDeleteClick(template.id)}
                          >
                            삭제
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="modal-footer">
          <button 
            className="ai-template-save-button"
            onClick={handleSaveTemplate}
            disabled={isLoading}
          >
            현재 견적 템플릿으로 저장
          </button>
        </div>
      </div>

      {showLineupSelect && (
        <LineupSelectModal 
          onSelect={lineupSelectHandlers?.handleSelect}
          onClose={lineupSelectHandlers?.handleClose}
        />
      )}

      {selectedItems && (
        <TemplateItemsModal
          items={selectedItems}
          onClose={() => setSelectedItems(null)}
        />
      )}
    </div>
  );
};

// LineupSelectModal 컴포넌트 수정
const LineupSelectModal = ({ onSelect, onClose }) => {
  const lineups = [
    { id: 'refresh', name: '리프레쉬', color: '#7CB342' },
    { id: 'harmony', name: '하모니', color: '#946A6A' },
    { id: 'collection', name: '컬렉션', color: '#5D4037' }
  ];

  // ESC 키 이벤트 핸들러 추가
  React.useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscKey, true);
    return () => window.removeEventListener('keydown', handleEscKey, true);
  }, [onClose]);

  // overlay 클릭 이벤트 제거하고 e.stopPropagation() 추가
  return (
    <div className="lineup-select-modal-overlay" onClick={(e) => e.stopPropagation()}>
      <div className="lineup-select-modal" onClick={e => e.stopPropagation()}>
        <h3>라인업 선택</h3>
        <div className="lineup-buttons">
          {lineups.map(lineup => (
            <button
              key={lineup.id}
              onClick={() => onSelect(lineup.id)}
              className="lineup-select-button"
              style={{ '--lineup-color': lineup.color }}
            >
              {lineup.name}
            </button>
          ))}
        </div>
        <button className="cancel-button" onClick={onClose}>취소</button>
      </div>
    </div>
  );
};

export default AITemplateModal; 