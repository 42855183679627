import React, { useEffect } from 'react';
import './HiddenProcessList.css';

const HiddenProcessList = ({ 
  hiddenProcesses, 
  onToggle, 
  getProcessName,
  showModal,
  onShowModal,
  onCloseModal,
  onHiddenProcessesChange,
  processData
}) => {
  const handleRestore = (processId) => {
    onToggle(processId);
    onCloseModal();
    
    if (onHiddenProcessesChange) {
      const updatedProcesses = { ...hiddenProcesses };
      delete updatedProcesses[processId];
      onHiddenProcessesChange(updatedProcesses);
    }
  };

  const handleHideZeroProcesses = () => {
    if (!processData) return;

    const updatedProcesses = { ...hiddenProcesses };
    let changesCount = 0;

    processData.forEach(process => {
      if (process.total === 0 && !updatedProcesses[process.id]?.hidden) {
        updatedProcesses[process.id] = {
          hidden: true,
          type: 'auto'
        };
        changesCount++;
      }
    });

    if (changesCount > 0) {
      if (onHiddenProcessesChange) {
        onHiddenProcessesChange(updatedProcesses);
      }
      alert(`${changesCount}개의 0원 공정이 숨겨졌습니다.`);
    } else {
      alert('숨길 0원 공정이 없습니다.');
    }
  };

  const handleShowZeroProcesses = () => {
    if (!processData) return;

    const updatedProcesses = { ...hiddenProcesses };
    let changesCount = 0;

    // 자동으로 숨겨진 0원 공정만 표시
    Object.entries(hiddenProcesses).forEach(([processId, status]) => {
      if (status.hidden && status.type === 'auto') {
        delete updatedProcesses[processId];
        changesCount++;
      }
    });

    if (changesCount > 0) {
      if (onHiddenProcessesChange) {
        onHiddenProcessesChange(updatedProcesses);
      }
      alert(`${changesCount}개의 0원 공정이 표시되었습니다.`);
    } else {
      alert('표시할 0원 공정이 없습니다.');
    }
  };

  useEffect(() => {
    if (showModal) {
      const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          onCloseModal();
        }
      };

      window.addEventListener('keydown', handleKeyDown, true);
      return () => {
        window.removeEventListener('keydown', handleKeyDown, true);
      };
    }
  }, [showModal, onCloseModal]);

  return (
    <>
      <button 
        className="hidden-process-button"
        onClick={onShowModal}
      >
        숨긴 공정 보기
      </button>

      {showModal && (
        <div className="hidden-process-modal-overlay">
          <div className="hidden-process-modal-container">
            <div className="hidden-process-modal-header">
              <h3>숨긴 공정 목록</h3>
              <div className="hidden-process-button-group">
                <button 
                  className="hide-zero-process-button"
                  onClick={handleHideZeroProcesses}
                >
                  0원 공정 모두 숨기기
                </button>
                <button 
                  className="show-zero-process-button"
                  onClick={handleShowZeroProcesses}
                >
                  0원 공정 모두 표시
                </button>
              </div>
            </div>
            
            <div className="hidden-process-modal-body">
              <div className="hidden-process-list">
                {Object.entries(hiddenProcesses)
                  .filter(([_, status]) => status.hidden)
                  .map(([processId, status]) => (
                    <div key={processId} className="hidden-process-item">
                      <span className="hidden-process-name">
                        {getProcessName(processId)}
                      </span>
                      <span className="hidden-process-type">
                        {status.type === 'auto' ? '(자동)' : '(수동)'}
                      </span>
                      <button 
                        onClick={() => handleRestore(processId)}
                        className="hidden-process-restore"
                      >
                        복원
                      </button>
                    </div>
                  ))}
              </div>
            </div>
            
            <div className="hidden-process-modal-footer">
              <button 
                className="hidden-process-close"
                onClick={onCloseModal}
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HiddenProcessList; 