import React, { useState } from 'react';
import './MaterialForm.css';

const MaterialForm = ({ categoryId, onClose, onSubmit, initialData }) => {
  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    model: initialData?.model || '',
    vendorPrice: initialData?.vendorPrice ? initialData.vendorPrice.toLocaleString() : '0',
    consumerPrice: initialData?.consumerPrice ? initialData.consumerPrice.toLocaleString() : '0',
    image: initialData?.image || '',
  });

  const handlePriceChange = (e, field) => {
    // 숫자와 콤마를 제외한 모든 문자 제거
    const value = e.target.value.replace(/[^\d,]/g, '');
    // 콤마를 제거하고 숫자만 추출
    const numericValue = value.replace(/,/g, '');
    
    if (numericValue === '' || /^\d+$/.test(numericValue)) {
      // 숫자를 천 단위 콤마가 있는 문자열로 변환
      const formattedValue = numericValue === '' ? '0' : Number(numericValue).toLocaleString();
      setFormData(prev => ({ ...prev, [field]: formattedValue }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      vendorPrice: Number(formData.vendorPrice.replace(/,/g, '')),
      consumerPrice: Number(formData.consumerPrice.replace(/,/g, '')),
      categoryId,
    });
  };

  return (
    <div className="material-form__overlay">
      <div className="material-form">
        <h3 className="material-form__title">
          {initialData ? '자재 수정' : '새 자재 추가'}
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="material-form__field">
            <label htmlFor="name" className="material-form__label">
              자재명
            </label>
            <input
              id="name"
              type="text"
              className="material-form__input"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              placeholder="자재명을 입력하세요"
              required
            />
          </div>

          <div className="material-form__field">
            <label htmlFor="model" className="material-form__label">
              모델명
            </label>
            <input
              id="model"
              type="text"
              className="material-form__input"
              value={formData.model}
              onChange={(e) => setFormData({ ...formData, model: e.target.value })}
              placeholder="모델명을 입력하세요"
              required
            />
          </div>

          <div className="material-form__field">
            <label htmlFor="vendorPrice" className="material-form__label">
              업체가격
            </label>
            <input
              id="vendorPrice"
              type="text"
              className="material-form__input"
              value={formData.vendorPrice}
              onChange={(e) => handlePriceChange(e, 'vendorPrice')}
              placeholder="업체가격을 입력하세요"
            />
          </div>

          <div className="material-form__field">
            <label htmlFor="consumerPrice" className="material-form__label">
              소비자가격
            </label>
            <input
              id="consumerPrice"
              type="text"
              className="material-form__input"
              value={formData.consumerPrice}
              onChange={(e) => handlePriceChange(e, 'consumerPrice')}
              placeholder="소비자가격을 입력하세요"
            />
          </div>

          <div className="material-form__field">
            <label htmlFor="image" className="material-form__label">
              이미지 URL
            </label>
            <input
              id="image"
              type="text"
              className="material-form__input"
              value={formData.image}
              onChange={(e) => setFormData({ ...formData, image: e.target.value })}
              placeholder="이미지 URL을 입력하세요"
            />
          </div>

          <div className="material-form__actions">
            <button type="button" className="material-form__cancel" onClick={onClose}>
              취소
            </button>
            <button type="submit" className="material-form__submit">
              {initialData ? '수정' : '추가'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MaterialForm; 