import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import koLocale from '@fullcalendar/core/locales/ko';
import { addDays, isWeekend } from 'date-fns';
import './AutoSchedule.css';
import { db, saveSchedule, fetchSchedules, deleteSchedule } from '../../firebase';
import { collection, getDocs, query, orderBy, getDoc, doc, updateDoc, setDoc, limit, deleteDoc, where } from 'firebase/firestore';
import MessageModal from './MessageModal';
import ProcessManagementModal from './ProcessManagementModal';
import ProcessContainer from './ProcessContainer';
import { useSwipeable } from 'react-swipeable';
import MaterialManagement from './MaterialManagement';
import NotificationPanel from './NotificationPanel';
import AISettingsModal from './AISettingsModal';
import { Helmet } from 'react-helmet';
import AddressFilter from './AddressFilter';
import ProcessFilter from './ProcessFilter';

const AutoSchedule = () => {
  const [activeTab, setActiveTab] = useState('notification'); // 'notification' or 'calendar' or 'settings'
  const [processes, setProcesses] = useState({});
  const [processOrder, setProcessOrder] = useState([]); // 공정 순서 상태 추가
  const [scheduleInputs, setScheduleInputs] = useState([{
    address: '',
    startDate: '',
    workOnHolidays: false,
    wallpaperWeekend: false,
    filmWeekend: false,
    cleaningWeekend: false,    // 입주 청소 주말
    siliconWeekend: false,     // 실리콘 공사 주말
    siliconPMWeekend: false,   // 실리콘 공사 오후 주말
    processDays: {}
  }]);
  const [events, setEvents] = useState([]);
  const [addressColors] = useState(new Map());
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [editedEvent, setEditedEvent] = useState(null);
  const [savedAddresses, setSavedAddresses] = useState([]); // 저장된 주소 목록
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [recentAddresses, setRecentAddresses] = useState(() => {
    const saved = localStorage.getItem('recentAddresses');
    return saved ? JSON.parse(saved) : [];
  });
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [selectedMessageAddress, setSelectedMessageAddress] = useState('');
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [showAISettings, setShowAISettings] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(-1); // 초기값을 -1로 변경
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [selectedAddressForColor, setSelectedAddressForColor] = useState(null);
  const [colorPickerPosition, setColorPickerPosition] = useState({ top: 0, left: 0 });
  const [showMaterialModal, setShowMaterialModal] = useState(false);
  const [selectedMaterialAddress, setSelectedMaterialAddress] = useState(null);
  const [materials, setMaterials] = useState({});  // 객체로 변경
  const [selectedFilterAddress, setSelectedFilterAddress] = useState('');
  const [selectedFilterProcess, setSelectedFilterProcess] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const colorPalette = [
    // 파스텔 톤
    '#FFB3B3', '#BDFCC9', '#B3E0FF', '#FFD9B3', '#D9B3FF',
    '#FFB3D9', '#B3FFB3', '#B3D9FF', '#FFE5B3', '#E5B3FF',
    // 밝은 톤
    '#FF9999', '#99FF99', '#99CCFF', '#FFCC99', '#CC99FF',
    '#FF99CC', '#CCFF99', '#99FFCC', '#FFB366', '#B366FF',
    // 중간 톤
    '#FF8080', '#80FF80', '#80B3FF', '#FFB380', '#B380FF',
    '#FF80B3', '#B3FF80', '#80FFB3', '#FF9933', '#9933FF',
    // 진한 톤
    '#FF6666', '#66FF66', '#6699FF', '#FF9966', '#9966FF',
    '#FF6699', '#99FF66', '#66FF99', '#FF8000', '#8000FF',
    // 그레이 톤
    '#F5F5F5', '#E0E0E0', '#BDBDBD', '#9E9E9E', '#757575',
    '#616161', '#424242', '#303030', '#212121', '#000000'
  ];

  // 2025년 한국 공휴일 데이터
  const holidays = [
    { title: '신정', start: '2025-01-01' },
    { title: '설날', start: '2025-01-28' },
    { title: '설날 연휴', start: '2025-01-29' },
    { title: '설날 연휴', start: '2025-01-30' },
    { title: '3·1절', start: '2025-03-01' },
    { title: '어린이날', start: '2025-05-05' },
    { title: '부처님오신날', start: '2025-05-06' },
    { title: '현충일', start: '2025-06-06' },
    { title: '광복절', start: '2025-08-15' },
    { title: '추석', start: '2025-10-06' },
    { title: '추석 연휴', start: '2025-10-07' },
    { title: '추석 연휴', start: '2025-10-08' },
    { title: '개천절', start: '2025-10-03' },
    { title: '한글날', start: '2025-10-09' },
    { title: '크리스마스', start: '2025-12-25' }
  ].map(holiday => ({
    ...holiday,
    display: 'background',
    backgroundColor: '#FFE6E6',
    classNames: ['holiday-event'],
    allDay: true,
    overlap: false,
    rendering: 'background'
  }));

  const getAddressColor = (addr) => {
    if (!addressColors.has(addr)) {
      const usedColors = Array.from(addressColors.values());
      const availableColors = colorPalette.filter(color => !usedColors.includes(color));
      const newColor = availableColors[0] || colorPalette[addressColors.size % colorPalette.length];
      addressColors.set(addr, newColor);
    }
    return addressColors.get(addr);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const isHoliday = (date) => {
    const formattedDate = formatDate(date);
    return holidays.some(holiday => holiday.start === formattedDate);
  };

  const handleInputChange = async (index, field, value) => {
    const newInputs = [...scheduleInputs];
    if (field.includes('.')) {
      const [parent, child, subfield] = field.split('.');
      if (subfield) {
        newInputs[index][parent][child][subfield] = value;
      } else {
        newInputs[index][parent][child] = value;
      }
    } else {
      newInputs[index][field] = value;
    }
    setScheduleInputs(newInputs);

    // 주소가 변경되었고, 새로운 주소가 있는 경우 Firebase에 저장
    if (field === 'address' && value) {
      try {
        const scheduleData = {
          ...newInputs[index],
          updatedAt: new Date()
        };
        await saveSchedule(scheduleData);
      } catch (error) {
        console.error('스케줄 저장 실패:', error);
      }
    }
  };

  // 가장 큰 현장 번호를 가져오는 함수 추가
  const getLatestSiteNumber = async () => {
    try {
      const addressesCollection = collection(db, 'addresses');
      const q = query(addressesCollection, orderBy('siteNumber', 'desc'), limit(1));
      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        return snapshot.docs[0].data().siteNumber;
      }
      return 0;  // 현장이 없는 경우 0 반환
    } catch (error) {
      console.error('현장 번호 조회 실패:', error);
      return 0;
    }
  };

  const addNewSchedule = async () => {
    try {
      const latestNumber = await getLatestSiteNumber();
      const newSiteNumber = latestNumber + 1;

      // processes 깊은 복사
      const initialProcessDays = Object.keys(processes).reduce((acc, key) => {
        acc[key] = { ...processes[key] };
        return acc;
      }, {});

      const newSchedule = {
        address: '',
        startDate: '',
        workOnHolidays: false,
        wallpaperWeekend: false,
        filmWeekend: false,
        cleaningWeekend: false,
        siliconWeekend: false,
        siliconPMWeekend: false,
        processDays: initialProcessDays,
        events: [],
        createdAt: new Date(),
        updatedAt: new Date(),
        siteNumber: newSiteNumber
      };

      setScheduleInputs(prev => [...prev, newSchedule]);
      setActiveAccordion(scheduleInputs.length);

      console.log(`새 현장이 추가되었습니다. (현장 번호: ${newSiteNumber})`);
    } catch (error) {
      console.error('새 현장 추가 실패:', error);
      alert('새 현장 추가에 실패했습니다.');
    }
  };

  const removeSchedule = async (index) => {
    try {
      const scheduleToRemove = scheduleInputs[index];
      
      // 주소가 있는 경우에만 Firebase에서 삭제
      if (scheduleToRemove.address) {
        await deleteSchedule(scheduleToRemove.address);
        
        // 해당 주소의 이벤트들도 제거
        const newEvents = events.filter(event => 
          event.title.split('\n')[0] !== scheduleToRemove.address
        );
        setEvents(newEvents);
      }
      
      // 입력 폼에서 제거
      const newInputs = scheduleInputs.filter((_, i) => i !== index);
      
      // 만약 마지막 현장을 삭제하는 경우, 새로운 빈 현장 추가
      if (newInputs.length === 0) {
        newInputs.push({
          address: '',
          startDate: '',
          workOnHolidays: false,
          wallpaperWeekend: false,
          filmWeekend: false,
          cleaningWeekend: false,
          siliconWeekend: false,
          siliconPMWeekend: false,
          processDays: { ...processes },
          siteNumber: 1
        });
      }
      
      setScheduleInputs(newInputs);
      
    } catch (error) {
      console.error('스케줄 삭제 실패:', error);
      // 에러가 발생해도 UI에서는 제거
      const newInputs = scheduleInputs.filter((_, i) => i !== index);
      if (newInputs.length === 0) {
        newInputs.push({
          address: '',
          startDate: '',
          workOnHolidays: false,
          wallpaperWeekend: false,
          filmWeekend: false,
          cleaningWeekend: false,
          siliconWeekend: false,
          siliconPMWeekend: false,
          processDays: { ...processes },
          siteNumber: 1
        });
      }
      setScheduleInputs(newInputs);
    }
  };

  const getEventStyle = (process) => {
    if (process === '사무실 상담' || process === '현장상담') {
      return {
        backgroundColor: '#000000',
        textColor: '#FFFFFF'
      };
    }
    return null;
  };

  const isWorkableDay = (date, input, process) => {
    const day = date.getDay();
    const isWeekend = day === 0 || day === 6;

    // 공휴일 체크
    if (isHoliday(date) && !input.workOnHolidays) {
      return false;
    }

    // 주말 체크
    if (isWeekend) {
      // 도배 공사 주말 체크
      if (process === '도배 공사' && input.wallpaperWeekend) {
        return true;
      }
      // 필름 공사 주말 체크
      if (process === '필름 공사' && input.filmWeekend) {
        return true;
      }
      // 입주 청소 주말 체크
      if (process === '입주 청소' && input.cleaningWeekend) {
        return true;
      }
      // 실리콘 공사 주말 체크
      if (process === '실리콘 공사' && input.siliconWeekend) {
        return true;
      }
      // 실리콘 공사 오후 주말 체크
      if (process === '실리콘 공사 오후' && input.siliconPMWeekend) {
        return true;
      }
      return false;
    }

    return true;
  };

  const generateScheduleForSite = async (index) => {
    const input = scheduleInputs[index];

    // 입력 데이터 유효성 검사
    if (!input.address || !input.startDate) {
      let errorMessage = '다음 항목을 확인해주세요:\n';
      if (!input.address) errorMessage += '- 현장 주소를 선택해주세요.\n';
      if (!input.startDate) errorMessage += '- 공사 시작일을 선택해주세요.\n';
      alert(errorMessage);
      return;
    }

    // 선택된 공정이 하나라도 있는지 확인
    const hasSelectedProcess = Object.values(input.processDays).some(
      config => config.include && config.days && parseInt(config.days) > 0
    );
    
    if (!hasSelectedProcess) {
      alert('최소 하나의 공정을 선택하고 일수를 입력해주세요.');
      return;
    }

    const newEvents = [];
    let currentDate = new Date(input.startDate);
    const addressColor = getAddressColor(input.address);
    
    // 처리된 공정 추적
    const processedProcesses = new Set();

    // 모든 공정 순회
    Object.entries(input.processDays).forEach(([process, config]) => {
      // 이미 처리된 공정이면 스킵
      if (processedProcesses.has(process)) return;
      if (!config.include || !config.days || parseInt(config.days) <= 0) return;

      // 연결된 공정 목록 (배열로 변환)
      const linkedProcesses = config.linkedProcesses || [];

      // 시작일과 종료일 계산
      const startDate = new Date(currentDate);
      const workingDays = [];
      let tempDate = new Date(startDate);
      let remainingDays = parseInt(config.days);
      let latestEndDate = null; // 가장 늦은 종료일을 추적

      // 작업 가능한 날짜 찾기
      while (remainingDays > 0) {
        if (isWorkableDay(tempDate, input, process)) {
          workingDays.push(new Date(tempDate));
          remainingDays--;
        }
        tempDate.setDate(tempDate.getDate() + 1);
      }

      if (workingDays.length === 0) return;

      const eventStartDate = workingDays[0];
      const eventEndDate = workingDays[workingDays.length - 1];
      latestEndDate = eventEndDate; // 현재 공정의 종료일 저장

      // 현재 공정의 이벤트 생성
      const event = {
        title: `${input.address}\n> ${process}`,
        start: formatDate(eventStartDate),
        end: formatDate(addDays(eventEndDate, 1)),
        color: getEventStyle(process)?.backgroundColor || addressColor,
        textColor: getEventStyle(process)?.textColor || '#000000',
        allDay: true,
        days: config.days
      };
      newEvents.push(event);

      // 연결된 공정이 있는 경우
      if (linkedProcesses.length > 0) {
        // 각 연결된 공정에 대해 처리
        linkedProcesses.forEach(linkedProcess => {
          // 연결된 공정이 활성화되어 있는지 확인
          const linkedConfig = input.processDays[linkedProcess];
          if (!linkedConfig) return; // 공정이 존재하지 않으면 스킵
          
          // 연결된 공정이 활성화되어 있지 않더라도 자동으로 활성화
          processedProcesses.add(linkedProcess);
          
          // 마지막 날짜에만 연결 옵션이 활성화된 경우
          if (config.linkToLastDay) {
            const lastDay = workingDays[workingDays.length - 1];
            
            const linkedEvent = {
              title: `${input.address}\n> ${linkedProcess}`,
              start: formatDate(lastDay),
              end: formatDate(addDays(lastDay, 1)),
              color: getEventStyle(linkedProcess)?.backgroundColor || addressColor,
              textColor: getEventStyle(linkedProcess)?.textColor || '#000000',
              allDay: true,
              days: 1
            };
            newEvents.push(linkedEvent);
          } 
          // 첫째날에만 연결 옵션이 활성화된 경우
          else if (config.linkToFirstDay) {
            const firstDay = workingDays[0];
            
            const linkedEvent = {
              title: `${input.address}\n> ${linkedProcess}`,
              start: formatDate(firstDay),
              end: formatDate(addDays(firstDay, 1)),
              color: getEventStyle(linkedProcess)?.backgroundColor || addressColor,
              textColor: getEventStyle(linkedProcess)?.textColor || '#000000',
              allDay: true,
              days: 1
            };
            newEvents.push(linkedEvent);
          }
          // 첫째날, 마지막날 연결 옵션이 모두 비활성화된 경우 또는 설정되지 않은 경우
          else {
            // 연결된 공정의 작업일 계산
            let linkedWorkingDays = [];
            let linkedTempDate = new Date(startDate);
            // 연결된 공정의 일수가 설정되어 있지 않은 경우 기본값으로 1일 설정
            let linkedRemainingDays = linkedConfig.days ? parseInt(linkedConfig.days) : 1;

            while (linkedRemainingDays > 0) {
              if (isWorkableDay(linkedTempDate, input, linkedProcess)) {
                linkedWorkingDays.push(new Date(linkedTempDate));
                linkedRemainingDays--;
              }
              linkedTempDate.setDate(linkedTempDate.getDate() + 1);
            }

            if (linkedWorkingDays.length > 0) {
              const linkedEndDate = linkedWorkingDays[linkedWorkingDays.length - 1];
              // 더 늦은 종료일로 업데이트
              latestEndDate = latestEndDate > linkedEndDate ? latestEndDate : linkedEndDate;

              const linkedEvent = {
                title: `${input.address}\n> ${linkedProcess}`,
                start: formatDate(linkedWorkingDays[0]),
                end: formatDate(addDays(linkedWorkingDays[linkedWorkingDays.length - 1], 1)),
                color: getEventStyle(linkedProcess)?.backgroundColor || addressColor,
                textColor: getEventStyle(linkedProcess)?.textColor || '#000000',
                allDay: true,
                days: linkedConfig.days
              };
              newEvents.push(linkedEvent);
            }
          }
        });
      }

      // 다음 공정의 시작일 설정 - 가장 늦은 종료일 다음날로 설정
      currentDate = addDays(latestEndDate, 1);
      processedProcesses.add(process);
    });

    if (newEvents.length === 0) {
      alert('생성할 수 있는 일정이 없습니다.\n공정 선택과 일수를 확인해주세요.');
      return;
    }

    try {
      const scheduleData = {
        address: input.address,
        startDate: input.startDate,
        workOnHolidays: input.workOnHolidays || false,
        wallpaperWeekend: input.wallpaperWeekend || false,
        filmWeekend: input.filmWeekend || false,
        cleaningWeekend: input.cleaningWeekend || false,
        siliconWeekend: input.siliconWeekend || false,
        siliconPMWeekend: input.siliconPMWeekend || false,
        processDays: input.processDays,
        events: newEvents,
        color: addressColor,
        updatedAt: new Date()
      };

      await saveSchedule(scheduleData);

      // 기존 이벤트에서 해당 주소의 이벤트들을 제거하고 새 이벤트 추가
      const filteredEvents = events.filter(event => 
        event.title.split('\n')[0] !== input.address
      );
      setEvents([...filteredEvents, ...newEvents]);

      // 캘린더 뷰로 전환
      setActiveTab('calendar');
    } catch (error) {
      alert('스케줄 저장에 실패했습니다.');
    }
  };

  const handleEventClick = (clickInfo) => {
    const eventTitle = clickInfo.event.title;
    const titleLines = eventTitle.split('\n');
    const clickedAddress = titleLines[0] === '[메모]' ? '' : titleLines[0];
    
    // 메모 추출
    let memo = '';
    if (clickInfo.event.extendedProps?.memo) {
      // extendedProps에 memo가 있으면 그것을 사용
      memo = clickInfo.event.extendedProps.memo;
    } else {
      // 제목에서 메모 추출 시도
      const memoLine = titleLines.find(line => line.startsWith('메모:'));
      if (memoLine) {
        memo = memoLine.substring(4).trim();
      } else if (titleLines[0] === '[메모]' && titleLines.length > 1) {
        // [메모] 형식인 경우 두 번째 줄부터 메모로 간주
        memo = titleLines.slice(1).join('\n');
      }
    }
    
    // 공정 추출 (> 제거)
    const selectedProcesses = titleLines
      .filter(line => line.startsWith('> '))
      .map(process => process.replace('> ', ''));

    // end 날짜 처리 수정
    const endDate = clickInfo.event.end || addDays(clickInfo.event.start, 1);
    const adjustedEndDate = new Date(endDate.getTime() - 24*60*60*1000);

    const event = {
      title: eventTitle,
      start: formatDate(clickInfo.event.start),
      end: formatDate(adjustedEndDate),
      address: clickedAddress,
      processes: selectedProcesses,
      originalEvent: clickInfo.event,
      memo: memo
    };

    setSelectedEvent(event);
    setEditedEvent(event);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    setEditedEvent(null);
    setShowDeleteConfirm(false);
    setSearchTerm(''); // 검색어 초기화
  };

  // 컴포넌트 마운트 시 주소 목록 불러오기
  useEffect(() => {
    fetchSavedAddresses();
  }, []);

  // 저장된 주소 목록 불러오기 함수
  const fetchSavedAddresses = async () => {
    try {
      const addressesCollection = collection(db, 'addresses');
      const q = query(addressesCollection, orderBy('siteNumber', 'desc'));
      const addressesSnapshot = await getDocs(q);
      const addressesList = addressesSnapshot.docs.map(doc => ({
        id: doc.id,
        address: doc.data().description,
        siteNumber: doc.data().siteNumber
      }));
      setSavedAddresses(addressesList);
    } catch (error) {
      alert('주소 목록을 불러오는데 실패했습니다.');
    }
  };

  // 초기화 함수 수정
  const resetSchedule = async (index) => {
    try {
      const currentAddress = scheduleInputs[index].address;
      
      if (currentAddress) {
        await deleteSchedule(currentAddress);
        const filteredEvents = events.filter(event => 
          event.title.split('\n')[0] !== currentAddress
        );
        setEvents(filteredEvents);
        addressColors.delete(currentAddress);
      }
      
      const newInputs = [...scheduleInputs];
      newInputs[index] = {
        ...newInputs[index],
        address: '',
        startDate: '',
        workOnHolidays: false,
        wallpaperWeekend: false,
        filmWeekend: false,
        cleaningWeekend: false,
        siliconWeekend: false,
        siliconPMWeekend: false,
        processDays: processes
      };
      setScheduleInputs(newInputs);

    } catch (error) {
      alert('스케줄 초기화에 실패했습니다.');
      const newInputs = [...scheduleInputs];
      newInputs[index] = {
        ...newInputs[index],
        address: '',
        startDate: '',
        workOnHolidays: false,
        wallpaperWeekend: false,
        filmWeekend: false,
        cleaningWeekend: false,
        siliconWeekend: false,
        siliconPMWeekend: false,
        processDays: processes
      };
      setScheduleInputs(newInputs);
    }
  };

  // saveEventChanges 함수 수정
  const saveEventChanges = async () => {
    try {
      if (!editedEvent.start || !editedEvent.end) {
        alert('시작일과 종료일을 입력해주세요.');
        return;
      }

      const addressColor = editedEvent.address ? getAddressColor(editedEvent.address) : '#808080';

      const endDate = new Date(editedEvent.end);
      endDate.setDate(endDate.getDate() + 1);

      let eventTitle;
      if (editedEvent.memo && !editedEvent.address) {
        eventTitle = `[메모]\n${editedEvent.memo}`;
      } else {
        const processText = editedEvent.processes?.map(process => `> ${process}`).join('\n') || '';
        eventTitle = `${editedEvent.address || ''}\n${processText}${editedEvent.memo ? `\n메모: ${editedEvent.memo}` : ''}`;
      }

      const newEvent = {
        title: eventTitle,
        start: editedEvent.start,
        end: formatDate(endDate),
        color: addressColor,
        textColor: '#000000',
        allDay: true,
        memo: editedEvent.memo || '',
        address: editedEvent.address || '',
        processes: editedEvent.processes || []
      };

      try {
        if (selectedEvent.address && selectedEvent.address !== editedEvent.address) {
          const oldScheduleDocRef = doc(db, 'schedules', selectedEvent.address);
          const oldScheduleDoc = await getDoc(oldScheduleDocRef);
          
          if (oldScheduleDoc.exists()) {
            const oldScheduleData = oldScheduleDoc.data();
            const filteredEvents = oldScheduleData.events.filter(event => 
              !(event.start === selectedEvent.start && event.title === selectedEvent.title)
            );
            
            await updateDoc(oldScheduleDocRef, {
              events: filteredEvents,
              updatedAt: new Date()
            });
          }
        }

        // 주소가 있는 경우 해당 주소의 스케줄 문서에 저장
        if (editedEvent.address) {
          const scheduleDocRef = doc(db, 'schedules', editedEvent.address);
          const scheduleDoc = await getDoc(scheduleDocRef);
          
          if (scheduleDoc.exists()) {
            const scheduleData = scheduleDoc.data();
            const existingEvents = scheduleData.events || [];
            
            let updated = false;
            const updatedEvents = existingEvents.map(event => {
              // 원본 이벤트와 시작일, 제목이 일치하는 이벤트를 찾아 업데이트
              if (selectedEvent.originalEvent && 
                  event.start === selectedEvent.start && 
                  event.title === selectedEvent.originalEvent.title) {
                updated = true;
                return newEvent;
              }
              return event;
            });
            
            if (!updated) {
              updatedEvents.push(newEvent);
            }
            
            await updateDoc(scheduleDocRef, {
              events: updatedEvents,
              address: editedEvent.address,
              updatedAt: new Date()
            });
          } else {
            // 문서가 없는 경우 새로 생성
            await setDoc(scheduleDocRef, {
              events: [newEvent],
              address: editedEvent.address,
              createdAt: new Date(),
              updatedAt: new Date()
            });
          }
        } else {
          // 주소가 없는 메모 이벤트인 경우 'memos' 문서에 저장
          const memosDocRef = doc(db, 'schedules', 'memos');
          const memosDoc = await getDoc(memosDocRef);
          
          if (memosDoc.exists()) {
            const memosData = memosDoc.data();
            const existingMemos = memosData.events || [];
            
            let updated = false;
            const updatedMemos = existingMemos.map(event => {
              if (selectedEvent.originalEvent && 
                  event.start === selectedEvent.start && 
                  event.title === selectedEvent.originalEvent.title) {
                updated = true;
                return newEvent;
              }
              return event;
            });
            
            if (!updated) {
              updatedMemos.push(newEvent);
            }
            
            await updateDoc(memosDocRef, {
              events: updatedMemos,
              updatedAt: new Date()
            });
          } else {
            await setDoc(memosDocRef, {
              events: [newEvent],
              createdAt: new Date(),
              updatedAt: new Date()
            });
          }
        }
      } catch (error) {
        console.error('이벤트 저장 중 오류 발생:', error);
        throw error;
      }

      // UI 업데이트
      let updatedEvents;
      if (selectedEvent.originalEvent) {
        updatedEvents = events.filter(event => {
          return !(event.start === selectedEvent.start && 
                  event.title === selectedEvent.originalEvent.title);
        });
        updatedEvents.push(newEvent);
      } else {
        updatedEvents = [...events, newEvent];
      }

      setEvents(updatedEvents);
      closeModal();
    } catch (error) {
      console.error('저장 실패:', error);
      alert('저장에 실패했습니다. 다시 시도해주세요.');
    }
  };

  // deleteEvent 함수 추가 (saveEventChanges 함수 근처에 추가)
  const deleteEvent = async () => {
    setShowDeleteConfirm(true);
  };

  const confirmDelete = async () => {
    try {
      const newEvents = events.filter(event => {
        return !(event.start === selectedEvent.start && event.title === selectedEvent.title);
      });
      setEvents(newEvents);

      const address = selectedEvent.address;
      const currentSchedule = await getDoc(doc(db, 'schedules', address));
      
      if (currentSchedule.exists()) {
        const scheduleData = currentSchedule.data();
        const updatedEvents = scheduleData.events.filter(event => {
          return !(event.start === selectedEvent.start && event.title === selectedEvent.title);
        });

        await saveSchedule({
          ...scheduleData,
          events: updatedEvents
        });
      }

      setShowDeleteConfirm(false);
      closeModal();
    } catch (error) {
      alert('이벤트 삭제에 실패했습니다.');
    }
  };

  // 삭제 취소 처리
  const cancelDelete = () => {
    setShowDeleteConfirm(false);
  };

  // FullCalendar 컴포넌트에 대한 ref 추가
  const calendarRef = useRef(null);

  // 이벤트 수정 핸들러 수정
  const handleEventChange = (field, value) => {
    if (field === 'address' && value) {
      const newRecentAddresses = [
        value,
        ...recentAddresses.filter(addr => addr !== value)
      ].slice(0, 5);
      
      setRecentAddresses(newRecentAddresses);
      localStorage.setItem('recentAddresses', JSON.stringify(newRecentAddresses));
    }
    
    setEditedEvent(prev => {
      const newEvent = { ...prev };
      
      if (field === 'start') {
        newEvent.start = value;
        if (new Date(value) > new Date(newEvent.end)) {
          newEvent.end = value;
        }
      } else if (field === 'end') {
        if (new Date(value) < new Date(newEvent.start)) {
          newEvent.end = newEvent.start;
        } else {
          newEvent.end = value;
        }
      } else if (field === 'memo') {
        // 메모 필드 특별 처리
        newEvent.memo = value;
        
        // 메모가 변경되면 제목도 업데이트
        if (value && !newEvent.address) {
          newEvent.title = `[메모]\n${value}`;
        } else if (newEvent.address) {
          const processText = newEvent.processes?.map(process => `> ${process}`).join('\n') || '';
          newEvent.title = `${newEvent.address}\n${processText}${value ? `\n메모: ${value}` : ''}`;
        }
      } else {
        newEvent[field] = value;
      }

      if ((field === 'start' || field === 'end') && calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(value);
      }
      
      return newEvent;
    });
  };

  // 스케줄 저장 함수
  const handleSaveSchedule = async () => {
    try {
      for (const input of scheduleInputs) {
        if (!input.address || !input.startDate) continue;
        
        // 해당 주소의 이벤트만 필터링
        const addressEvents = events.filter(event => 
          event.title.split('\n')[0] === input.address
        );

        const color = getAddressColor(input.address);

        // 이벤트 데이터를 포함여 저장
        const scheduleData = {
          address: input.address,
          startDate: input.startDate,
          workOnHolidays: input.workOnHolidays,
          wallpaperWeekend: input.wallpaperWeekend,
          filmWeekend: input.filmWeekend,
          cleaningWeekend: input.cleaningWeekend,
          siliconWeekend: input.siliconWeekend,
          siliconPMWeekend: input.siliconPMWeekend,
          processDays: input.processDays,
          events: addressEvents.map(event => ({
            ...event,
            color // 각 이벤트에 색상 정보 추가
          })),
          color // 스케줄에도 색상 정보 저장
        };

        await saveSchedule(scheduleData);
      }
    } catch (error) {
      console.error('스케줄 저장 실패:', error);
      alert('스케줄 저장에 실패했습니다.');
    }
  };

  // 스케줄 불러오기
  const loadSchedules = async () => {
    try {
      const savedSchedules = await fetchSchedules();
      
      // 모든 이벤트 추출
      let allEvents = [];
      savedSchedules.forEach(schedule => {
        if (schedule.events && Array.isArray(schedule.events)) {
          // 각 이벤트에 메모 필드가 있는지 확인하고 없으면 추가
          const eventsWithMemo = schedule.events.map(event => {
            // 메모 필드가 없고 제목에 메모가 포함된 경우 추출
            if (!event.memo) {
              const titleLines = event.title.split('\n');
              const memoLine = titleLines.find(line => line.startsWith('메모:'));
              if (memoLine) {
                event.memo = memoLine.substring(4).trim();
              } else if (titleLines[0] === '[메모]' && titleLines.length > 1) {
                event.memo = titleLines.slice(1).join('\n');
              }
            }
            
            // 색상 정보 추가
            return {
              ...event,
              color: schedule.color || getAddressColor(schedule.address)
            };
          });
          
          allEvents = [...allEvents, ...eventsWithMemo];
        }
      });
      
      // 주소별 색상 정보 저장
      savedSchedules.forEach(schedule => {
        if (schedule.color && schedule.address) {
          addressColors.set(schedule.address, schedule.color);
        }
      });
      
      // 이벤트 상태 업데이트
      setEvents(allEvents);

      const contractorsRef = collection(db, 'contractors');
      const contractorsSnapshot = await getDocs(contractorsRef);
      
      const orderRef = doc(db, 'settings', 'processOrder');
      const orderDoc = await getDoc(orderRef);
      const savedOrder = orderDoc.exists() ? orderDoc.data().order : [];
      
      if (contractorsSnapshot.docs.length > 0) {
        const savedProcessList = contractorsSnapshot.docs.map(doc => doc.id);
        
        if (savedOrder.length > 0) {
          savedProcessList.sort((a, b) => {
            const aIndex = savedOrder.indexOf(a);
            const bIndex = savedOrder.indexOf(b);
            if (aIndex === -1) return 1;
            if (bIndex === -1) return -1;
            return aIndex - bIndex;
          });
        }
        
        const initialProcesses = savedProcessList.reduce((acc, key) => {
          acc[key] = { days: '1', include: false };
          return acc;
        }, {});

        if (savedSchedules.length > 0) {
          const newInputs = savedSchedules
            .filter(schedule => schedule.address)
            .sort((a, b) => (b.siteNumber || 0) - (a.siteNumber || 0))
            .map(schedule => {
              const mergedProcessDays = savedProcessList.reduce((acc, key) => {
                if (schedule.processDays?.[key]) {
                  acc[key] = { ...schedule.processDays[key] };
                } else {
                  acc[key] = { ...initialProcesses[key] };
                }
                return acc;
              }, {});

              return {
                ...schedule,
                processDays: mergedProcessDays
              };
            });

          setScheduleInputs(newInputs.length > 0 ? newInputs : [{
            address: '',
            startDate: '',
            workOnHolidays: false,
            wallpaperWeekend: false,
            filmWeekend: false,
            cleaningWeekend: false,
            siliconWeekend: false,
            siliconPMWeekend: false,
            processDays: { ...initialProcesses },
            siteNumber: 1
          }]);

          const firstScheduleWithProcesses = newInputs.find(input => 
            input.processDays && Object.keys(input.processDays).length > 0
          );

          if (firstScheduleWithProcesses) {
            setProcesses(firstScheduleWithProcesses.processDays);
          } else {
            setProcesses(initialProcesses);
          }
        } else {
          setScheduleInputs([{
            address: '',
            startDate: '',
            workOnHolidays: false,
            wallpaperWeekend: false,
            filmWeekend: false,
            cleaningWeekend: false,
            siliconWeekend: false,
            siliconPMWeekend: false,
            processDays: { ...initialProcesses },
            siteNumber: 1
          }]);
          setProcesses(initialProcesses);
        }
      }
    } catch (error) {
      console.error('스케줄 불러오기 실패:', error);
    }
  };

  // 컴포넌트 마운트 시 초기화
  useEffect(() => {
    const initializeData = async () => {
      try {
        // 공정 순서 불러오기
        const savedOrder = await loadProcessOrder();
        setProcessOrder(savedOrder);
        
        await loadSchedules();
      } catch (error) {
        console.error('초기화 실패:', error);
      }
    };

    initializeData();
  }, []);

  // 공정 순서 불러오기 함수 추가
  const loadProcessOrder = async () => {
    try {
      const orderRef = doc(db, 'settings', 'processOrder');
      const orderDoc = await getDoc(orderRef);
      if (orderDoc.exists()) {
        return orderDoc.data().order;
      }
      return [];
    } catch (error) {
      console.error('공정 순서 불러오기 실패:', error);
      return [];
    }
  };

  // 공정 목록을 순서대로 정렬하는 함수
  const getSortedProcesses = () => {
    if (!processOrder.length) return Object.keys(processes);
    
    return Object.keys(processes).sort((a, b) => {
      const aIndex = processOrder.indexOf(a);
      const bIndex = processOrder.indexOf(b);
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  };

  // dateClick 핸들러 수정
  const handleDateClick = (info) => {
    const clickedDate = info.dateStr;
    const sortedAddresses = getSortedAddresses();
    // 선택된 필터 주소가 있으면 그것을 사용, 없으면 정렬된 주소 목록의 첫 번째 주소 사용
    const initialAddress = selectedFilterAddress || (sortedAddresses.length > 0 ? sortedAddresses[0].address : '');
    const initialColor = initialAddress ? getAddressColor(initialAddress) : '';
    
    const newEvent = {
      address: initialAddress,
      processes: [],
      start: clickedDate,
      end: clickedDate,
      title: '',
      color: initialColor,
      originalEvent: null,
      memo: ''
    };
    
    setEditedEvent(newEvent);
    setSelectedEvent({
      ...newEvent,
      title: '',
      start: clickedDate,
      originalEvent: null
    });
  };

  // 뒤로가기와 ESC 키 방지를 위한 useEffect 추가
  useEffect(() => {
    // 뒤로가기 방지
    const preventGoBack = (e) => {
      e.preventDefault();
      e.stopPropagation();
      window.history.pushState(null, '', window.location.href);
    };

    // ESC 키 방지
    const preventEsc = (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        
        // 삭제 확인 모달이 열려있으면 먼저 닫기
        if (showDeleteConfirm) {
          setShowDeleteConfirm(false);
          return;
        }
        
        // 이벤트 상세 모달이 열려있으면 닫기
        if (selectedEvent) {
          closeModal();
        }
      }
    };

    // 이벤트 리스너 등록
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', preventGoBack);
    window.addEventListener('keydown', preventEsc);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('popstate', preventGoBack);
      window.removeEventListener('keydown', preventEsc);
    };
  }, [selectedEvent, showDeleteConfirm]); // 의존성 배열에 상태 추가

  // handleShare 함수 수정
  const handleShare = async (index) => {
    const currentAddress = scheduleInputs[index]?.address;
    const addressEvents = events
      .filter(event => event.title.split('\n')[0] === currentAddress)
      .sort((a, b) => new Date(a.start) - new Date(b.start));

    const shareText = `[아마레디자인 일정공유]
현장 주소: ${currentAddress || ''}

[공정 일정]
${addressEvents.map(event => {
  const processes = event.title.split('\n').slice(1).map(process => process.replace('> ', ''));
  const startDate = new Date(event.start);
  const endDate = new Date(event.end);
  endDate.setDate(endDate.getDate() - 1); // exclusive end date 보정

  // 시작일과 종료일이 같은 경우 날짜 하나만 표시
  const dateText = formatDate(startDate) === formatDate(endDate)
    ? `${formatDate(startDate)}`
    : `${formatDate(startDate)} ~ ${formatDate(endDate)}`;

  // 모든 공정을 표시
  return processes.map(process => `${process}: ${dateText}`).join('\n');
}).join('\n')}`;

    // 데스크톱 환경에서는 클립보드 복사만 실행
    if (window.innerWidth >= 768) {
      try {
        await navigator.clipboard.writeText(shareText);
        alert('클립보드에 복사되었습니다!');
      } catch (err) {
        console.error('클립보드 복사 실패:', err);
        alert('클립보드 복사에 실패했습니다.');
      }
      return;
    }

    // 모바일 환경에서는 공유 기능 사용
    try {
      await navigator.share({
        title: '아마레디자인 일정공유',
        text: shareText,
        url: ''  // URL 제거
      });
    } catch (err) {
      console.error('공유 실패:', err);
    }
  };

  // addDays 함수가 없다면 추가
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  // 정렬된 주소 목록을 반환하는 함수
  const getSortedAddresses = () => {
    if (!savedAddresses.length) return [];

    // 주소 목록을 최근 선택 순으로 정렬
    return [...savedAddresses].sort((a, b) => {
      const aIndex = recentAddresses.indexOf(a.address);
      const bIndex = recentAddresses.indexOf(b.address);
      
      if (aIndex === -1 && bIndex === -1) {
        // 둘 다 최근 선택 목록에 없으면 현장 번호로 정렬
        return b.siteNumber - a.siteNumber;
      }
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  };

  useEffect(() => {
    if (selectedEvent && editedEvent && !editedEvent.address) {
      const sortedAddresses = getSortedAddresses();
      if (sortedAddresses.length > 0) {
        // 선택된 필터 주소가 있으면 그것을 사용, 없으면 정렬된 주소 목록의 첫 번째 주소 사용
        const topAddress = selectedFilterAddress || sortedAddresses[0].address;
        setEditedEvent(prev => ({
          ...prev,
          address: topAddress
        }));
      }
    }
  }, [selectedEvent, editedEvent, savedAddresses, recentAddresses, selectedFilterAddress]);

  // 자재 데이터 로드 함수 추가
  const loadMaterials = async (address) => {
    try {
      const materialsRef = collection(db, 'materials');
      const q = query(
        materialsRef,
        where('address', '==', address)
      );
      
      const snapshot = await getDocs(q);
      const materialsList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      const groupedMaterials = materialsList.reduce((acc, material) => {
        const process = material.process || '미지정';
        if (!acc[process]) {
          acc[process] = [];
        }
        acc[process].push(material);
        return acc;
      }, {});

      setMaterials(prev => ({
        ...prev,
        [address]: groupedMaterials
      }));
    } catch (error) {
      alert('자재 데이터를 불러오는데 실패했습니다.');
    }
  };

  // MessageModal이 열릴 때 자재 데이터 로드
  const handleMessageClick = (address) => {
    setSelectedMessageAddress(address);
    setShowMessageModal(true);
    loadMaterials(address);  // 자재 데이터 로드
  };

  // 아코디언 토글 함수 추가
  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? -1 : index);
  };

  // 공정 설정 변경 핸들러
  const handleProcessConfigChange = async (index, process, field, value) => {
    const newInputs = [...scheduleInputs];
    newInputs[index].processDays[process] = {
      ...newInputs[index].processDays[process],
      [field]: value
    };
    setScheduleInputs(newInputs);

    if (newInputs[index].address) {
      try {
        const scheduleData = {
          ...newInputs[index],
          updatedAt: new Date()
        };
        await saveSchedule(scheduleData);
      } catch (error) {
        alert('공정 설정 저장에 실패했습니다.');
      }
    }
  };

  // 공정 연결 핸들러
  const handleProcessLinkChange = (index, sourceProcess, targetProcess) => {
    const newInputs = [...scheduleInputs];
    const processDays = newInputs[index].processDays;
    
    // 새로운 연결 방식 (linkedProcesses 배열 사용)
    if (!processDays[sourceProcess].linkedProcesses) {
      processDays[sourceProcess].linkedProcesses = [];
    }
    
    // 기존 연결 방식 호환성 유지 (linkedProcess 속성 제거)
    if (processDays[sourceProcess].linkedProcess) {
      delete processDays[sourceProcess].linkedProcess;
    }
    
    // 새로운 연결 설정
    if (targetProcess) {
      // 체크박스가 선택된 경우 (추가)
      if (!processDays[sourceProcess].linkedProcesses.includes(targetProcess)) {
        processDays[sourceProcess].linkedProcesses.push(targetProcess);
      }
    } else {
      // targetProcess가 없는 경우는 ProcessContainer에서 직접 처리하므로 여기서는 아무것도 하지 않음
    }
    
    setScheduleInputs(newInputs);
  };

  // 색상 선택기 표시 함수
  const showColorPicker = (address, event) => {
    event.stopPropagation();
    const buttonRect = event.currentTarget.getBoundingClientRect();
    setColorPickerPosition({
      top: buttonRect.bottom + window.scrollY + 5,
      left: buttonRect.left + window.scrollX
    });
    setSelectedAddressForColor(address);
    setColorPickerVisible(true);
  };

  // 색상 변경 함수
  const handleColorChange = async (color) => {
    if (!selectedAddressForColor) return;

    try {
      addressColors.set(selectedAddressForColor, color);

      const updatedEvents = events.map(event => {
        if (event.title.split('\n')[0] === selectedAddressForColor) {
          return {
            ...event,
            color: getEventStyle(event.title.split('\n')[1]?.replace('> ', ''))?.backgroundColor || color
          };
        }
        return event;
      });
      setEvents(updatedEvents);

      const scheduleRef = doc(db, 'schedules', selectedAddressForColor);
      const scheduleDoc = await getDoc(scheduleRef);
      
      if (scheduleDoc.exists()) {
        const scheduleData = scheduleDoc.data();
        await updateDoc(scheduleRef, {
          ...scheduleData,
          color: color,
          events: updatedEvents.filter(event => 
            event.title.split('\n')[0] === selectedAddressForColor
          ),
          updatedAt: new Date()
        });
      }

      setColorPickerVisible(false);
    } catch (error) {
      alert('색상 변경에 실패했습니다.');
    }
  };

  // 색상 선택기 닫기
  const closeColorPicker = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setColorPickerVisible(false);
    setSelectedAddressForColor(null);
  };

  // 스와이프 핸들러 추가
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (calendarRef.current) {
        calendarRef.current.getApi().next();
      }
    },
    onSwipedRight: () => {
      if (calendarRef.current) {
        calendarRef.current.getApi().prev();
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
    delta: 50,
  });

  const handleMaterialClick = (address) => {
    setShowMaterialModal(true);
    setSelectedMaterialAddress(address);
  };

  const closeMaterialModal = () => {
    setShowMaterialModal(false);
    setSelectedMaterialAddress(null);
  };

  // getFilteredEvents 함수 수정
  const getFilteredEvents = () => {
    let filteredEvents = events;
    
    // 주소 필터 적용
    if (selectedFilterAddress) {
      filteredEvents = filteredEvents.filter(event => 
        event.title.split('\n')[0] === selectedFilterAddress
      );
    }
    
    // 공정 필터 적용
    if (selectedFilterProcess) {
      filteredEvents = filteredEvents.filter(event => {
        const processLines = event.title.split('\n').slice(1);
        return processLines.some(line => 
          line.replace('> ', '') === selectedFilterProcess
        );
      });
    }
    
    return filteredEvents;
  };

  return (
    <div className="schedule-container">
      <Helmet>
        {/* 매니페스트 파일은 index.html에서 이미 로드되고 있으므로 여기서는 제거합니다 */}
      </Helmet>
      <div className="tab-table">
        <div
          className={`tab-cell ${activeTab === 'notification' ? 'active' : ''}`}
          onClick={() => setActiveTab('notification')}
        >
          스케줄 알림
        </div>
        <div
          className={`tab-cell ${activeTab === 'calendar' ? 'active' : ''}`}
          onClick={() => setActiveTab('calendar')}
        >
          캘린더
        </div>
        <div
          className={`tab-cell ${activeTab === 'settings' ? 'active' : ''}`}
          onClick={() => setActiveTab('settings')}
        >
          설정
        </div>
      </div>

      {/* 주소 필터와 공정 필터 추가 */}
      {activeTab === 'calendar' && (
        <div className="filters-container">
          <AddressFilter
            addresses={getSortedAddresses()}
            selectedAddress={selectedFilterAddress}
            onAddressChange={setSelectedFilterAddress}
          />
          <ProcessFilter
            processes={Object.keys(processes)}
            selectedProcess={selectedFilterProcess}
            onProcessChange={setSelectedFilterProcess}
          />
        </div>
      )}

      {activeTab === 'notification' && (
        <div className="notification-section">
          <div className="notification-scrollable-content">
            <NotificationPanel 
              schedules={getFilteredEvents()}
              onAISettingsClick={() => setShowAISettings(true)}
            />
          </div>
        </div>
      )}

      {activeTab === 'calendar' && (
        <div className="calendar-section">
          <div className="calendar-view">
            <div {...swipeHandlers} className="calendar-wrapper">
              <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                locale={{
                  ...koLocale,
                  dayCellContent: ({ date }) => date.getDate(),
                }}
                events={[...getFilteredEvents(), ...holidays]}
                height="100%"
                headerToolbar={{
                  left: 'prev',
                  center: 'title',
                  right: 'next'
                }}
                dayHeaderFormat={{ weekday: 'short' }}
                eventClick={handleEventClick}
                dateClick={handleDateClick}
                fixedWeekCount={false}
                selectable={true}
                eventDragStart={false}
                editable={false}
                dayCellClassNames={({ date }) => {
                  const formattedDate = formatDate(date);
                  return holidays.some(holiday => holiday.start === formattedDate) ? 'holiday-cell' : '';
                }}
              />
            </div>
          </div>
        </div>
      )}

      {activeTab === 'settings' && (
        <div className="settings-section">
          <div className="process-management-button-container">
            <button 
              className="process-management-button"
              onClick={() => setShowProcessModal(true)}
            >
              공정 관리
            </button>
          </div>

          <div className="settings-scrollable-content">
            {scheduleInputs.map((input, index) => (
              <div key={index} className="schedule-form">
                <div 
                  className={`accordion-header ${activeAccordion === index ? 'active' : ''}`}
                  onClick={() => toggleAccordion(index)}
                >
                  <div className="accordion-header-content">
                    <h3 className="accordion-title">
                      현장 {index + 1}{input.address ? `: ${input.address}` : ''}
                    </h3>
                    <div className="header-buttons-container">
                      <button 
                        className="header-button header-button-share"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleShare(index);
                        }}
                        title="이 현장의 일정을 공유합니다"
                      >
                        공유
                      </button>
                      <button 
                        className="header-button header-button-sms"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMessageClick(input.address);
                        }}
                        title="문자로 일정을 전송합니다"
                      >
                        문자
                      </button>
                      <button 
                        className="header-button header-button-material"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMaterialClick(input.address);
                        }}
                        title="자재 관리"
                      >
                        자재
                      </button>
                      <button 
                        className="header-button header-button-color"
                        onClick={(e) => showColorPicker(input.address, e)}
                        title="일정 색상을 변경합니다"
                        style={{ display: input.address ? 'block' : 'none' }}
                      >
                        <div 
                          className="color-preview"
                          style={{ 
                            backgroundColor: addressColors.get(input.address) || colorPalette[0]
                          }}
                        />
                      </button>
                      <button 
                        className="header-button header-button-reset"
                        onClick={(e) => {
                          e.stopPropagation();
                          resetSchedule(index);
                        }}
                        title="이 현장의 스케줄을 초기화합니다"
                      >
                        초기화
                      </button>
                      <button 
                        className="header-button header-button-remove"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeSchedule(index);
                        }}
                      >
                        삭제
                      </button>
                    </div>
                  </div>
                  <div className={`accordion-arrow ${activeAccordion === index ? 'active' : ''}`} />
                </div>
                
                <div className={`accordion-content ${activeAccordion === index ? 'active' : ''}`}>
                  <div className="input-section">
                    <div className="input-group">
                      <label className="input-label">현장 주소</label>
                      <select
                        className="input-field"
                        value={input.address}
                        onChange={(e) => handleInputChange(index, 'address', e.target.value)}
                      >
                        <option value="">현장 주소를 선택하세요</option>
                        {getSortedAddresses().map((addr) => (
                          <option key={addr.id} value={addr.address}>
                            {`[${addr.siteNumber}] ${addr.address}`}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="input-group">
                      <label className="input-label">공사 시작일</label>
                      <input
                        type="date"
                        className="input-field"
                        value={input.startDate}
                        onChange={(e) => handleInputChange(index, 'startDate', e.target.value)}
                      />
                    </div>

                    <div className="checkbox-container">
                      <div className="checkbox-group">
                        <input
                          type="checkbox"
                          id={`all-process-${index}`}
                          checked={Object.values(input.processDays).every(config => config.include)}
                          onChange={(e) => {
                            const newInputs = [...scheduleInputs];
                            Object.keys(newInputs[index].processDays).forEach(process => {
                              newInputs[index].processDays[process].include = e.target.checked;
                            });
                            setScheduleInputs(newInputs);
                          }}
                        />
                        <label 
                          htmlFor={`all-process-${index}`} 
                          className="checkbox-label"
                        >
                          전체 선택
                        </label>
                      </div>

                      <div className="checkbox-group">
                        <input
                          type="checkbox"
                          id={`holiday-work-${index}`}
                          checked={input.workOnHolidays}
                          onChange={(e) => handleInputChange(index, 'workOnHolidays', e.target.checked)}
                        />
                        <label 
                          htmlFor={`holiday-work-${index}`}
                          className="checkbox-label"
                        >
                          명절/국경일/공휴일
                        </label>
                      </div>

                      <div className="checkbox-group">
                        <input
                          type="checkbox"
                          id={`wallpaper-weekend-${index}`}
                          checked={input.wallpaperWeekend}
                          onChange={(e) => handleInputChange(index, 'wallpaperWeekend', e.target.checked)}
                        />
                        <label 
                          htmlFor={`wallpaper-weekend-${index}`}
                          className="checkbox-label"
                        >
                          도배 공사 주말
                        </label>
                      </div>

                      <div className="checkbox-group">
                        <input
                          type="checkbox"
                          id={`film-weekend-${index}`}
                          checked={input.filmWeekend}
                          onChange={(e) => handleInputChange(index, 'filmWeekend', e.target.checked)}
                        />
                        <label 
                          htmlFor={`film-weekend-${index}`}
                          className="checkbox-label"
                        >
                          필름 공사 주말
                        </label>
                      </div>

                      <div className="checkbox-group">
                        <input
                          type="checkbox"
                          id={`cleaning-weekend-${index}`}
                          checked={input.cleaningWeekend}
                          onChange={(e) => handleInputChange(index, 'cleaningWeekend', e.target.checked)}
                        />
                        <label 
                          htmlFor={`cleaning-weekend-${index}`}
                          className="checkbox-label"
                        >
                          입주 청소 주말
                        </label>
                      </div>

                      <div className="checkbox-group">
                        <input
                          type="checkbox"
                          id={`silicon-weekend-${index}`}
                          checked={input.siliconWeekend}
                          onChange={(e) => handleInputChange(index, 'siliconWeekend', e.target.checked)}
                        />
                        <label 
                          htmlFor={`silicon-weekend-${index}`}
                          className="checkbox-label"
                        >
                          실리콘 공사 주말
                        </label>
                      </div>

                      <div className="checkbox-group">
                        <input
                          type="checkbox"
                          id={`silicon-pm-weekend-${index}`}
                          checked={input.siliconPMWeekend}
                          onChange={(e) => handleInputChange(index, 'siliconPMWeekend', e.target.checked)}
                        />
                        <label 
                          htmlFor={`silicon-pm-weekend-${index}`}
                          className="checkbox-label"
                        >
                          실리콘 공사 오후 주말
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="process-grid">
                    {Object.entries(input.processDays).map(([process, config]) => (
                      <ProcessContainer
                        key={process}
                        process={process}
                        config={config}
                        allProcesses={Object.keys(input.processDays)}
                        onConfigChange={(process, field, value) => 
                          handleProcessConfigChange(index, process, field, value)
                        }
                        onLinkChange={(process, targetProcess) =>
                          handleProcessLinkChange(index, process, targetProcess)
                        }
                        index={index}
                      />
                    ))}
                  </div>
                  <div className="button-group">
                    <button 
                      className="button generate-button" 
                      onClick={() => generateScheduleForSite(index)}
                    >
                      스케줄 생성
                    </button>
                  </div>
                </div>
              </div>
            ))}

            <div className="button-group">
              <button className="button add-button" onClick={addNewSchedule}>
                새 현장 추가
              </button>
            </div>
          </div>

          {/* 색상 선택기 */}
          {colorPickerVisible && (
            <>
              <div className="color-picker-overlay" onClick={closeColorPicker} />
              <div 
                className="color-picker-container"
                style={{
                  top: colorPickerPosition.top,
                  left: colorPickerPosition.left
                }}
              >
                <div className="color-palette">
                  {colorPalette.map((color, index) => (
                    <div
                      key={index}
                      className="color-option"
                      style={{ backgroundColor: color }}
                      onClick={() => handleColorChange(color)}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {selectedEvent && (
        <div className="event-modal-overlay" onClick={closeModal}>
          <div className="event-modal" onClick={e => e.stopPropagation()}>
            <div className="event-modal-header">
              <h3>공사 상세 정보</h3>
              <button className="close-button" onClick={closeModal}>×</button>
            </div>
            <div className="event-modal-content">
              <div className="event-detail">
                <label>현장 주소 (선택)</label>
                <select
                  className="input-field"
                  value={editedEvent.address || ''}
                  onChange={(e) => handleEventChange('address', e.target.value)}
                >
                  <option value="">현장주소를 선택하세요</option>
                  {getSortedAddresses().map((addr) => (
                    <option key={addr.id} value={addr.address}>
                      {`[${addr.siteNumber}] ${addr.address}`}
                    </option>
                  ))}
                </select>
              </div>
              <div className="event-detail">
                <label>공정 선택 (선택)</label>
                <input
                  type="text"
                  className="process-search-input"
                  placeholder="공정 검색..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="process-checkboxes">
                  {Object.keys(processes)
                    .filter(process => 
                      process.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                    .map(process => (
                      <div key={process} className="process-checkbox-item">
                        <input
                          type="checkbox"
                          id={`process-${process}`}
                          checked={editedEvent.processes?.includes(process)}
                          onChange={(e) => handleEventChange('processes', e.target.checked ? [...editedEvent.processes, process] : editedEvent.processes.filter(p => p !== process))}
                        />
                        <label htmlFor={`process-${process}`}>{process}</label>
                      </div>
                    ))}
                </div>
              </div>
              <div className="event-detail">
                <label>메모</label>
                <textarea
                  className="input-field memo-input"
                  value={editedEvent.memo || ''}
                  onChange={(e) => handleEventChange('memo', e.target.value)}
                  placeholder="메모를 입력하세요"
                  rows={3}
                />
              </div>
              <div className="event-detail">
                <label>시작일</label>
                <input
                  type="date"
                  className="input-field"
                  value={editedEvent.start}
                  onChange={(e) => handleEventChange('start', e.target.value)}
                />
              </div>
              <div className="event-detail">
                <label>종료일</label>
                <input
                  type="date"
                  className="input-field"
                  value={editedEvent.end}
                  onChange={(e) => handleEventChange('end', e.target.value)}
                />
              </div>
              <div className="modal-button-group">
                <button className="modal-button modal-button-save" onClick={saveEventChanges}>
                  저장
                </button>
                <button className="modal-button modal-button-cancel" onClick={closeModal}>
                  취소
                </button>
                <button className="modal-button modal-button-delete" onClick={deleteEvent}>
                  삭제
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDeleteConfirm && (
        <div className="confirm-modal-overlay">
          <div className="confirm-modal">
            <div className="confirm-modal-message">
              이 일정을 삭제하시겠습니까?
            </div>
            <div className="confirm-modal-buttons">
              <button className="confirm-modal-button" onClick={confirmDelete}>
                확인
              </button>
              <button className="confirm-modal-button" onClick={cancelDelete}>
                취소
              </button>
            </div>
          </div>
        </div>
      )}

      <MessageModal
        isOpen={showMessageModal}
        onClose={() => setShowMessageModal(false)}
        address={selectedMessageAddress}
        events={events}
        processes={getSortedProcesses()}
        materials={materials}
      />

      <ProcessManagementModal
        isOpen={showProcessModal}
        onClose={() => setShowProcessModal(false)}
        processes={processes}
        setProcesses={setProcesses}
        scheduleInputs={scheduleInputs}
        setScheduleInputs={setScheduleInputs}
      />

      {showMaterialModal && (
        <MaterialManagement 
          onClose={closeMaterialModal} 
          selectedAddress={selectedMaterialAddress}
        />
      )}

      <AISettingsModal
        isOpen={showAISettings}
        onClose={() => setShowAISettings(false)}
      />
    </div>
  );
};

export default AutoSchedule; 