import React, { useState } from 'react';
import './BulkUploadModal.css';

const BulkUploadModal = ({ onClose, onUpload }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedWorkType, setSelectedWorkType] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('template2');

  const workTypes = [
    '도배',
    '바닥',
    '목공',
    '전기/조명',
    '필름',
    '제작가구',
    '에어컨',
    '이미지만',
    '텍스트만'
  ];

  const templates = [
    { id: 'template1', name: '기본 템플릿' },
    { id: 'template2', name: '2D 평면도 템플릿' },
    { id: 'template3', name: '이미지 전용 템플릿' },
    { id: 'template4', name: '텍스트 전용 템플릿' }
  ];

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const sortFiles = (files) => {
    return [...files].sort((a, b) => {
      // 파일 이름에서 숫자 추출
      const aMatch = a.name.match(/\d+/);
      const bMatch = b.name.match(/\d+/);
      
      // 숫자가 있는 경우 숫자로 정렬
      if (aMatch && bMatch) {
        return parseInt(aMatch[0]) - parseInt(bMatch[0]);
      }
      
      // 숫자가 없는 경우 이름으로 정렬
      return a.name.localeCompare(b.name);
    });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const newFiles = Array.from(e.dataTransfer.files).filter(file => 
      file.type.startsWith('image/')
    );

    // 기존 파일과 새 파일 합치기
    const combinedFiles = [...selectedFiles, ...newFiles];
    setSelectedFiles(sortFiles(combinedFiles));
  };

  const handleFileSelect = (e) => {
    const newFiles = Array.from(e.target.files).filter(file => 
      file.type.startsWith('image/')
    );

    // 기존 파일과 새 파일 합치기
    const combinedFiles = [...selectedFiles, ...newFiles];
    setSelectedFiles(sortFiles(combinedFiles));
  };

  const handleUpload = () => {
    if (selectedFiles.length > 0 && selectedWorkType) {
      onUpload(selectedFiles, selectedWorkType, selectedTemplate);
      onClose();
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(newFiles);
  };

  return (
    <div className="bulk-upload-modal-overlay" onClick={onClose}>
      <div className="bulk-upload-modal-content" onClick={e => e.stopPropagation()}>
        <h2 className="bulk-upload-modal-title">도면 일괄 업로드</h2>
        
        <div className="work-type-select-container">
          <select
            className="work-type-select"
            value={selectedWorkType}
            onChange={(e) => setSelectedWorkType(e.target.value)}
          >
            <option value="">공정을 선택하세요</option>
            {workTypes.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        </div>

        <div className="work-type-select-container">
          <select
            className="work-type-select"
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(e.target.value)}
          >
            {templates.map((template) => (
              <option key={template.id} value={template.id}>{template.name}</option>
            ))}
          </select>
        </div>

        <div 
          className={`bulk-upload-area ${isDragging ? 'dragging' : ''}`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleFileSelect}
            style={{ display: 'none' }}
            id="bulk-file-upload"
          />
          <label htmlFor="bulk-file-upload" className="bulk-upload-label">
            <div className="upload-icon">📁</div>
            <div className="upload-text">
              {isDragging
                ? '여기에 파일을 놓으세요'
                : '클릭하거나 파일을 드래그하여 업로드하세요'}
            </div>
          </label>
        </div>

        {selectedFiles.length > 0 && (
          <div className="selected-files">
            <h3>선택된 파일 ({selectedFiles.length}개)</h3>
            <div className="file-list">
              {selectedFiles.map((file, index) => (
                <div key={index} className="file-item">
                  <span>{file.name}</span>
                  <button 
                    className="file-remove-button"
                    onClick={() => handleRemoveFile(index)}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="bulk-upload-modal-buttons">
          <button 
            onClick={handleUpload}
            className="upload-button"
            disabled={selectedFiles.length === 0 || !selectedWorkType}
          >
            업로드
          </button>
          <button onClick={onClose} className="cancel-button">
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkUploadModal; 