import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { db } from '../../../../src/firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import './SharedEstimatePage.css';

const SharedEstimatePage = () => {
  const [searchParams] = useSearchParams();
  const [estimateData, setEstimateData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [addressData, setAddressData] = useState(null);

  useEffect(() => {
    const fetchAddressData = async () => {
      const shareId = searchParams.get('id');

      if (!shareId) {
        setError('잘못된 접근입니다.');
        setLoading(false);
        return;
      }

      try {
        const shareDoc = await getDoc(doc(db, 'sharedEstimates', shareId));
        
        if (!shareDoc.exists()) {
          setError('해당 링크를 찾을 수 없습니다.');
          return;
        }

        const shareData = shareDoc.data();
        
        const expiryDate = shareData.expiryDate.toDate();
        if (expiryDate < new Date()) {
          setError('만료된 견적서입니다.');
          return;
        }

        const addressesRef = collection(db, 'addresses');
        const q = query(addressesRef, where('description', '==', shareData.address));
        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.empty) {
          setError('해당 주소를 찾을 수 없습니다.');
          return;
        }

        const addressDoc = querySnapshot.docs[0];
        const addressData = addressDoc.data();
        setAddressData(addressData);

        const estimateVersionsRef = collection(db, 'estimateVersions');
        const estimateQuery = query(estimateVersionsRef, where('address', '==', shareData.address));
        const estimateSnapshot = await getDocs(estimateQuery);
        
        if (estimateSnapshot.empty) {
          setError('견적서를 찾을 수 없습니다.');
          return;
        }

        const estimateDocs = estimateSnapshot.docs;
        let latestEstimateDoc = estimateDocs[0];
        let latestDate = new Date(latestEstimateDoc.data().lastModified || latestEstimateDoc.data().createdAt);

        for (let i = 1; i < estimateDocs.length; i++) {
          const currentDoc = estimateDocs[i];
          const currentDate = new Date(currentDoc.data().lastModified || currentDoc.data().createdAt);
          
          if (currentDate > latestDate) {
            latestEstimateDoc = currentDoc;
            latestDate = currentDate;
          }
        }

        const estimateData = latestEstimateDoc.data();

        const processedEstimateData = {
          ...estimateData,
          address: shareData.address,
          expiryDate: expiryDate.toISOString(),
          items: estimateData.items || [],
          totalAmount: estimateData.totalAmount || 0
        };

        setEstimateData(processedEstimateData);
      } catch (error) {
        alert('견적서 데이터를 불러오는데 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };

    fetchAddressData();
  }, [searchParams]);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    
    if (password === addressData?.phoneLastFourDigits) {
      setIsAuthenticated(true);
      setError(null);
    } else {
      setError('잘못된 비밀번호입니다.');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (estimateData?.data?.processData) {
          const additionalItems = estimateData.data.processData.reduce((items, process) => {
            if (process.id === 'misc_additional' && Array.isArray(process.items)) {
              const additionalProcessItems = process.items.filter(item => item.isAdditional);
              return [...items, ...additionalProcessItems.map(item => ({
                ...item,
                processName: '추가 견적'
              }))];
            }
            
            if (Array.isArray(process.items)) {
              const additionalProcessItems = process.items.filter(item => item.isAdditional);
              return [...items, ...additionalProcessItems.map(item => ({
                ...item,
                processName: process.processName || process.name || '추가 견적'
              }))];
            }
            
            return items;
          }, []);
        }
      } catch (error) {
        // 에러 처리
      }
    };

    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated, addressData, estimateData]);

  if (loading) {
    return (
      <div className="shared-estimate-page-loading">
        <p>견적서를 불러오는 중...</p>
      </div>
    );
  }

  if (error && isAuthenticated) {
    return (
      <div className="shared-estimate-page-error">
        <p>{error}</p>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="shared-estimate-page-container">
        <div className="shared-estimate-page-header">
          <h1>견적서 확인</h1>
          <p className="address">{addressData?.description}</p>
        </div>
        <form onSubmit={handlePasswordSubmit} className="shared-estimate-page-password-form">
          <div className="form-group">
            <label>비밀번호</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="비밀번호 4자리 입력"
              maxLength="4"
              className="shared-estimate-page-password-input"
              autoFocus
            />
          </div>
          {error && <p className="shared-estimate-page-error">{error}</p>}
          <button type="submit" className="shared-estimate-page-password-button">
            확인
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="shared-estimate-page-container">
      <div className="shared-estimate-page-header">
        <h1>추가견적서요약</h1>
        <p className="address">{estimateData?.address}</p>
        <div className="customer-message-section">
          <h3>안내사항</h3>
          <p>
            {`인테리어 공사의 추가 금액은
고객님께서 견적서에 없는 내용을 요청하신 경우, 상호 협의를 거쳐 반영됩니다.
견적내용에 대해 틀린부분, 기타 문의사항이 있으시면 언제든지 편하게 말씀해 주세요. 😊

단, 추가 요청해 주신 내용이 이 페이지에 반영되지 않았다면,
번거로우시겠지만 업체에 한 번 더 문의 부탁드립니다.
현장 감리에 집중하다 보니 업데이트가 다소 지연될 수 있는 점
너그럽게 양해해 주시면 감사하겠습니다. 🙇‍♂️`}
          </p>
        </div>
      </div>

      <div className="shared-estimate-page-content">
        {(() => {
          const additionalItems = estimateData?.data?.processData?.reduce((items, process) => {
            if (process.id === 'misc_additional' && Array.isArray(process.items)) {
              const additionalProcessItems = process.items.filter(item => item.isAdditional);
              return [...items, ...additionalProcessItems.map(item => ({
                ...item,
                processName: '추가 견적'
              }))];
            }
            
            if (Array.isArray(process.items)) {
              const additionalProcessItems = process.items.filter(item => item.isAdditional);
              return [...items, ...additionalProcessItems.map(item => ({
                ...item,
                processName: process.processName || process.name || '추가 견적'
              }))];
            }
            
            return items;
          }, []);

          if (additionalItems && additionalItems.length > 0) {
            return (
              <>
                <div className="shared-estimate-page-table">
                  <div className="shared-estimate-page-table-header">
                    <div className="shared-estimate-page-header-col item">품목</div>
                    <div className="shared-estimate-page-header-col quantity">수량</div>
                    <div className="shared-estimate-page-header-col unit">단위</div>
                    <div className="shared-estimate-page-header-col unit-price">단가</div>
                    <div className="shared-estimate-page-header-col amount">금액</div>
                  </div>
                  
                  {additionalItems.map((item, index) => (
                    <div key={`additional-${index}`} className="shared-estimate-page-table-row">
                      <div className="shared-estimate-page-table-content">
                        <div className="shared-estimate-page-col item">{item.name || ''}</div>
                        <div className="shared-estimate-page-col quantity">{item.quantity || 0}</div>
                        <div className="shared-estimate-page-col unit">{item.unit || ''}</div>
                        <div className="shared-estimate-page-col unit-price">
                          {(item.unitPrice || 0).toLocaleString()}
                        </div>
                        <div className="shared-estimate-page-col amount">
                          {item.name.includes('미장, 벽돌공사') ? 0 : ((item.quantity || 0) * (item.unitPrice || 0)).toLocaleString()}
                        </div>
                      </div>
                      {item.note && (
                        <div className="shared-estimate-page-note">
                          <span className="shared-estimate-page-note-label">비고:</span>
                          <span className="shared-estimate-page-note-text">{item.note}</span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div className="shared-estimate-page-summary">
                  <div className="shared-estimate-page-total-amount">
                    <span>총 금액:</span>
                    <span className="amount">
                      {additionalItems.reduce((total, item) => {
                        const itemAmount = item.name.includes('미장, 벽돌공사') ? 0 : ((item.quantity || 0) * (item.unitPrice || 0));
                        return total + itemAmount;
                      }, 0).toLocaleString()}원
                    </span>
                  </div>
                </div>
              </>
            );
          }
          return null;
        })()}
      </div>
    </div>
  );
};

export default SharedEstimatePage; 