import React from 'react';
import './TemplateChangeModal.css';

const TemplateChangeModal = ({ onClose, onSelect, currentTemplate }) => {
  const handleTemplateSelect = (template) => {
    if (window.confirm('템플릿을 변경하면 현재 등록된 라벨이 모두 삭제됩니다. 계속하시겠습니까?')) {
      onSelect(template);
    }
  };

  return (
    <div className="template-modal-overlay" onClick={onClose}>
      <div className="template-modal-content" onClick={e => e.stopPropagation()}>
        <h2 className="template-modal-title">템플릿 변경</h2>
        <div className="template-options">
          <div 
            className={`template-option ${currentTemplate === 'template1' ? 'active' : ''}`}
            onClick={() => handleTemplateSelect('template1')}
          >
            <div className="template-preview template1-preview">
              <div className="preview-main"></div>
              <div className="preview-side">
                <div className="preview-materials"></div>
                <div className="preview-memo"></div>
              </div>
            </div>
            <div className="template-label">기본 템플릿</div>
          </div>
          
          <div 
            className={`template-option ${currentTemplate === 'template2' ? 'active' : ''}`}
            onClick={() => handleTemplateSelect('template2')}
          >
            <div className="template-preview template2-preview">
              <div className="preview-main">
                <div className="preview-blueprint"></div>
                <div className="preview-memo"></div>
              </div>
              <div className="preview-side">
                <div className="preview-materials"></div>
                <div className="preview-floor-plan"></div>
              </div>
            </div>
            <div className="template-label">2D 평면도 템플릿</div>
          </div>

          <div 
            className={`template-option ${currentTemplate === 'template3' ? 'active' : ''}`}
            onClick={() => handleTemplateSelect('template3')}
          >
            <div className="template-preview template3-preview">
              <div className="preview-full-image"></div>
            </div>
            <div className="template-label">이미지 전용 템플릿</div>
          </div>

          <div 
            className={`template-option ${currentTemplate === 'template4' ? 'active' : ''}`}
            onClick={() => handleTemplateSelect('template4')}
          >
            <div className="template-preview template4-preview">
              <div className="preview-full-text">Aa</div>
            </div>
            <div className="template-label">텍스트 전용 템플릿</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateChangeModal; 